export const WEBCAL_ACCOUNTS_CREATE_REQUEST = 'WEBCAL_ACCOUNTS_CREATE_REQUEST'
export const WEBCAL_ACCOUNTS_CREATE_SUCCESS = 'WEBCAL_ACCOUNTS_CREATE_SUCCESS'
export const WEBCAL_ACCOUNTS_CREATE_FAILURE = 'WEBCAL_ACCOUNTS_CREATE_FAILURE'

export function create(frameId, sync_url) {
  return {
    type: WEBCAL_ACCOUNTS_CREATE_REQUEST,
    api: {
      data: { sync_url },
      endpoint: `frames/${frameId}/webcal_accounts`,
      authenticated: true,
      types: [
        WEBCAL_ACCOUNTS_CREATE_REQUEST,
        WEBCAL_ACCOUNTS_CREATE_SUCCESS,
        WEBCAL_ACCOUNTS_CREATE_FAILURE,
      ],
      method: 'POST',
    },
  }
}

export const WEBCAL_ACCOUNTS_REQUEST = 'WEBCAL_ACCOUNTS_REQUEST'
export const WEBCAL_ACCOUNTS_SUCCESS = 'WEBCAL_ACCOUNTS_SUCCESS'
export const WEBCAL_ACCOUNTS_FAILURE = 'WEBCAL_ACCOUNTS_FAILURE'

export function listWebcalAccounts(frameId) {
  return {
    type: WEBCAL_ACCOUNTS_REQUEST,
    api: {
      endpoint: `frames/${frameId}/webcal_accounts`,
      authenticated: true,
      types: [WEBCAL_ACCOUNTS_REQUEST, WEBCAL_ACCOUNTS_SUCCESS, WEBCAL_ACCOUNTS_FAILURE],
      method: 'GET',
    },
  }
}

export const WEBCAL_ACCOUNTS_DESTROY_REQUEST = 'WEBCAL_ACCOUNTS_DESTROY_REQUEST'
export const WEBCAL_ACCOUNTS_DESTROY_SUCCESS = 'WEBCAL_ACCOUNTS_DESTROY_SUCCESS'
export const WEBCAL_ACCOUNTS_DESTROY_FAILURE = 'WEBCAL_ACCOUNTS_DESTROY_FAILURE'

export function destroyWebcalAccount(frameId, accountId) {
  let endpoint = ['frames', frameId, 'webcal_accounts', accountId].join('/')
  return {
    type: WEBCAL_ACCOUNTS_DESTROY_REQUEST,
    api: {
      endpoint,
      authenticated: true,
      types: [
        WEBCAL_ACCOUNTS_DESTROY_REQUEST,
        WEBCAL_ACCOUNTS_DESTROY_SUCCESS,
        WEBCAL_ACCOUNTS_DESTROY_FAILURE,
      ],
      method: 'DELETE',
    },
  }
}
