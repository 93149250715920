import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Loading from '../Loading'

import { DateConverter } from '../../utilities/DateConverter'

const ProgressBar = ({ color, percent, label }) => (
  <div className="progress">
    <div
      className={`progress-bar bg-${color}`}
      role="progressbar"
      style={{ width: `${percent}%` }}
      aria-valuenow={percent}
      aria-valuemin="0"
      aria-valuemax="100"
    >
      {percent}% of {label}
    </div>
  </div>
)

ProgressBar.propTypes = {
  color: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
}

const OnlineTable = ({ buckets }) => (
  <table className="table table-striped">
    <thead>
      <tr className="d-none d-sm-table-row">
        <th scope="col">Prior Version</th>
        <th scope="col">Online</th>
        <th scope="col">Offline</th>
        <th scope="col">No Token</th>
        <th scope="col">Sample Size</th>
      </tr>
    </thead>
    <tbody>
      {buckets.map((bucket) => {
        const totalCount = parseFloat(bucket.online + bucket.offline + bucket.no_token)
        return (
          <tr key={bucket.version}>
            <td>{bucket.version}</td>
            <td>{parseInt((bucket.online / totalCount) * 100)}%</td>
            <td>{parseInt((bucket.offline / totalCount) * 100)}%</td>
            <td>{parseInt((bucket.no_token / totalCount) * 100)}%</td>
            <td>{parseInt(totalCount)}</td>
          </tr>
        )
      })}
    </tbody>
  </table>
)

OnlineTable.propTypes = {
  buckets: PropTypes.array.isRequired,
}

const totalsFrom = (buckets) => {
  let total = 0
  Object.values(buckets).forEach((v) => (total += v))
  return total
}

const percentOf = (numerator, denominator) => {
  if (denominator === 0) return 0
  return (numerator / denominator) * 100
}

const chartFromBuckets = ({ id, title, desc, controlBuckets, experimentBuckets }) => {
  if (controlBuckets && experimentBuckets) {
    const dataArray = [['Latency', 'Control Group', 'Experimental Group']]
    const controlTotal = totalsFrom(controlBuckets)
    const experimentTotal = totalsFrom(experimentBuckets)
    if (controlBuckets.non_starters && experimentBuckets.non_starters) {
      dataArray.push([
        'None Sent',
        percentOf(controlBuckets.non_starters, controlTotal),
        percentOf(experimentBuckets.non_starters, experimentTotal),
      ])
    }
    if (controlBuckets.none_downloaded && experimentBuckets.none_downloaded) {
      dataArray.push([
        'None Downloaded',
        percentOf(controlBuckets.none_downloaded, controlTotal),
        percentOf(experimentBuckets.none_downloaded, experimentTotal),
      ])
    }
    if (controlBuckets.not_downloaded && experimentBuckets.not_downloaded) {
      dataArray.push([
        'Not Downloaded',
        percentOf(controlBuckets.not_downloaded, controlTotal),
        percentOf(experimentBuckets.not_downloaded, experimentTotal),
      ])
    }
    dataArray.push([
      '<1m',
      percentOf(controlBuckets.one_min, controlTotal),
      percentOf(experimentBuckets.one_min, experimentTotal),
    ])
    dataArray.push([
      '1-2m',
      percentOf(controlBuckets.two_min, controlTotal),
      percentOf(experimentBuckets.two_min, experimentTotal),
    ])
    dataArray.push([
      '2-5m',
      percentOf(controlBuckets.five_min, controlTotal),
      percentOf(experimentBuckets.five_min, experimentTotal),
    ])
    dataArray.push([
      '5-10m',
      percentOf(controlBuckets.ten_min, controlTotal),
      percentOf(experimentBuckets.ten_min, experimentTotal),
    ])
    dataArray.push([
      '10+m',
      percentOf(controlBuckets.more_than_ten_min, controlTotal),
      percentOf(experimentBuckets.more_than_ten_min, experimentTotal),
    ])

    // eslint-disable-next-line no-undef
    const data = google.visualization.arrayToDataTable(dataArray)

    const options = {
      title,
      vAxis: { title: `Percent of Frames (Exp: ${experimentTotal}, Cont: ${controlTotal})` },
      hAxis: { title: desc },
      seriesType: 'bars',
      colors: ['#52C7EB', '#4AAFB3', '#52B389'],
    }

    // eslint-disable-next-line no-undef
    const chart = new google.visualization.ComboChart(document.getElementById(id))
    chart.draw(data, options)
  }
}

const DeploymentDeliverability = ({
  loading,
  backFunc,
  deployment,
  version,
  refreshData,
  refreshedData,
  specificToDeployment,
}) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://www.gstatic.com/charts/loader.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
  useEffect(() => {
    if (deployment) {
      const drawChart = () => {
        chartFromBuckets({
          id: 'activation-chart',
          title: 'Frame Activation => Message Download',
          desc:
            'Time it takes between when new a Frame is activated and when it downloads its first photo',
          controlBuckets: deployment.attributes.new_device_activation_control_latency_buckets,
          experimentBuckets: deployment.attributes.new_device_activation_experiment_latency_buckets,
        })
        chartFromBuckets({
          id: 'new-senders',
          title: 'New Frames: Photo Sent => Photo Downloaded',
          desc:
            'Time it takes between when the first photo is sent to a new Frame and when it is downloaded',
          controlBuckets: deployment.attributes.new_device_sending_control_latency_buckets,
          experimentBuckets: deployment.attributes.new_device_sending_experiment_latency_buckets,
        })
        chartFromBuckets({
          id: 'existing-senders',
          title: 'Existing Frames: Photo Sent => Photo Downloaded',
          desc:
            'Time it takes between when the first photo is sent to a randomly chosen old Frame and when it is downloaded',
          controlBuckets: deployment.attributes.existing_device_sending_control_latency_buckets,
          experimentBuckets:
            deployment.attributes.existing_device_sending_experiment_latency_buckets,
        })
      }

      // eslint-disable-next-line no-undef
      google.charts.load('current', { packages: ['corechart'] })
      // eslint-disable-next-line no-undef
      google.charts.setOnLoadCallback(drawChart)
    }
  }, [deployment])
  /* eslint-disable camelcase */
  const {
    first_control_new_device_at,
    last_new_device_at,
    new_device_success_rate,
    new_device_deployed_count,
    new_device_goal,
    existing_device_success_rate,
    existing_device_deployed_count,
    last_refreshed_health_metrics_at,
    new_device_control_online_rate,
    new_device_experiment_online_rate,
    existing_device_control_online_rate,
    existing_device_experiment_online_rate,
    new_device_control_watchdog_rate,
    new_device_experiment_watchdog_rate,
    existing_device_control_watchdog_rate,
    existing_device_experiment_watchdog_rate,
    new_device_online_buckets,
    existing_device_online_buckets,
    refreshing,
  } = deployment ? deployment.attributes : {}
  const neverCollected = last_refreshed_health_metrics_at === null
  return (
    <div className="col-lg-12 col-xl-8">
      <div className="row align-items-center">
        <div className="col-2">
          <p className="mb-0">
            <button type="button" onClick={backFunc} className="btn btn-sm btn-info">
              ← Back
            </button>
          </p>
        </div>
        {!loading && (
          <div className="col-8">
            {specificToDeployment && (
              <h1 className="text-center mb-0">
                {version.attributes.kind} {version.attributes.name}
              </h1>
            )}
            {refreshing ? (
              <p className="text-center text-muted">Refreshing Data...</p>
            ) : (
              <>
                {!neverCollected && (
                  <p className="text-center text-muted">
                    Data last refreshed at{' '}
                    <b>{DateConverter.call(last_refreshed_health_metrics_at)}</b>
                  </p>
                )}
              </>
            )}
          </div>
        )}
        {!loading && !refreshedData && !refreshing && specificToDeployment && (
          <div className="col-2">
            <p className="mb-0 text-right">
              <a href="#" onClick={refreshData} className="btn btn-sm btn-success">
                Refresh Data
              </a>
            </p>
          </div>
        )}
      </div>
      <hr />
      <div className="row align-items-center">
        <div className="col-12">
          {loading ? (
            <Loading />
          ) : (
            <>
              {neverCollected && !refreshing && specificToDeployment ? (
                <>
                  <p className="text-center">Data on this deployment has never been collected.</p>
                  {!refreshedData && (
                    <p className="text-center">
                      <a href="#" onClick={refreshData} className="btn btn-sm btn-success">
                        Collect Data
                      </a>
                    </p>
                  )}
                </>
              ) : (
                <>
                  {specificToDeployment && (
                    <>
                      {first_control_new_device_at && last_new_device_at ? (
                        <p className="text-sm text-center">
                          First Control group new device activated at{' '}
                          <b>{DateConverter.call(first_control_new_device_at)}</b>. Latest
                          Deployment new device activated at{' '}
                          <b>{DateConverter.call(last_new_device_at)}</b>
                        </p>
                      ) : (
                        <p className="text-danger text-center">
                          Warning! This deployment is still very young. We're still deploying this
                          version to newly activated devices immediately upon activation to simulate
                          what would happen if the version went Live.
                        </p>
                      )}
                      {new_device_success_rate && (
                        <>
                          <p>
                            The goal is to deploy to {new_device_goal} newly activated devices. So
                            far, {new_device_deployed_count} devices have been activated.
                          </p>
                          <p>
                            {parseInt(new_device_success_rate)}% of those{' '}
                            {new_device_deployed_count} devices have successfully installed this
                            version.
                          </p>
                          <ProgressBar
                            color="success"
                            percent={parseInt(new_device_success_rate)}
                            label={`New Devices (${new_device_deployed_count} activated so far)`}
                          />
                          {new_device_success_rate < 50 && (
                            <p className="text-danger">
                              Warning! Ignore all data referencing <b>new devices</b> below because
                              less than half of the new devices have actually installed this new
                              version.
                            </p>
                          )}
                        </>
                      )}
                      <br />
                      {existing_device_success_rate && (
                        <>
                          <p>
                            We randomly chose {existing_device_deployed_count} older devices. If
                            they're on and connected to Wi-Fi, they should install this new version.
                          </p>
                          <p>
                            So far, {parseInt(existing_device_success_rate)}% of them have
                            successfully installed this version.
                          </p>
                          <ProgressBar
                            color="info"
                            percent={parseInt(existing_device_success_rate)}
                            label={`${existing_device_deployed_count} Existing Devices`}
                          />
                          {existing_device_success_rate < 50 && (
                            <p className="text-danger">
                              Warning! Ignore all data referencing <b>existing devices</b> below
                              because less than half of the new devices have actually installed this
                              new version.
                            </p>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <br />
                  {new_device_control_online_rate && new_device_experiment_online_rate && (
                    <div>
                      <h3>New Devices with Active Pushy Connections</h3>
                      <p>
                        Of <b>{new_device_goal} control group</b> devices that were activated just
                        before this deployment started, what percent were connected to Pushy at{' '}
                        {DateConverter.call(last_refreshed_health_metrics_at)}?
                      </p>
                      <ProgressBar
                        color="danger"
                        percent={parseInt(new_device_control_online_rate)}
                        label="New Devices on Pushy (Control)"
                      />
                      <br />
                      <p>
                        Of <b>{new_device_deployed_count} newly activated devices</b>
                        {new_device_success_rate && (
                          <>
                            ({parseInt(new_device_success_rate)}% of which have installed this
                            version)
                          </>
                        )}
                        , what percent were connected to Pushy at{' '}
                        {DateConverter.call(last_refreshed_health_metrics_at)}?
                      </p>
                      <ProgressBar
                        color="success"
                        percent={parseInt(new_device_experiment_online_rate)}
                        label="New Devices on Pushy (Experimental Group)"
                      />
                      <br />
                      {new_device_online_buckets && (
                        <OnlineTable buckets={Object.values(new_device_online_buckets)} />
                      )}
                    </div>
                  )}
                  <br />
                  {existing_device_control_online_rate && existing_device_experiment_online_rate && (
                    <div>
                      <h3>Existing Devices with Active Pushy Connections</h3>
                      <p>
                        Of {existing_device_deployed_count} randomly sampled older devices{' '}
                        <b>(control group)</b>, what percent were connected to Pushy at{' '}
                        {DateConverter.call(last_refreshed_health_metrics_at)}?
                      </p>
                      <ProgressBar
                        color="danger"
                        percent={parseInt(existing_device_control_online_rate)}
                        label="Existing Devices on Pushy (Control)"
                      />
                      <br />
                      <p>
                        Of {existing_device_deployed_count} randomly sampled older devices{' '}
                        {existing_device_success_rate && (
                          <>
                            ({parseInt(existing_device_success_rate)}% of which have installed this
                            version)
                          </>
                        )}
                        , what percent were connected to Pushy at{' '}
                        {DateConverter.call(last_refreshed_health_metrics_at)}?
                      </p>
                      <ProgressBar
                        color="info"
                        percent={parseInt(existing_device_experiment_online_rate)}
                        label="Existing Devices on Pushy (Experimental Group)"
                      />
                      <br />
                      {existing_device_online_buckets && (
                        <OnlineTable buckets={Object.values(existing_device_online_buckets)} />
                      )}
                    </div>
                  )}
                  <br />
                  {new_device_control_watchdog_rate && new_device_experiment_watchdog_rate && (
                    <div>
                      <h3>New Devices Filing Status Reports</h3>
                      <p>
                        Of <b>{new_device_goal} control group</b> devices that were activated just
                        before this deployment started, what percent filed a status report in the 48
                        hours leading up to {DateConverter.call(last_refreshed_health_metrics_at)}?
                      </p>
                      <ProgressBar
                        color="danger"
                        percent={parseInt(new_device_control_watchdog_rate)}
                        label="New Devices Filing Status Reports (Control)"
                      />
                      <br />
                      <p>
                        Of <b>{new_device_deployed_count} newly activated devices</b>
                        {new_device_success_rate && (
                          <>
                            ({parseInt(new_device_success_rate)}% of which have installed this
                            version)
                          </>
                        )}
                        , what percent filed a status report in the 48 hours leading up to{' '}
                        {DateConverter.call(last_refreshed_health_metrics_at)}?
                      </p>
                      <ProgressBar
                        color="success"
                        percent={parseInt(new_device_experiment_watchdog_rate)}
                        label="New Devices Filing Status Reports (Experimental Group)"
                      />
                    </div>
                  )}
                  <br />
                  {existing_device_control_watchdog_rate &&
                    existing_device_experiment_watchdog_rate && (
                      <div>
                        <h3>Existing Devices Filing Status Reports</h3>
                        <p>
                          Of {existing_device_deployed_count} randomly sampled older devices
                          (control group), what percent filed a status report in the 48 hours
                          leading up to {DateConverter.call(last_refreshed_health_metrics_at)}?
                        </p>
                        <ProgressBar
                          color="danger"
                          percent={parseInt(existing_device_control_watchdog_rate)}
                          label="Existing Devices Filing Status Reports (Control)"
                        />
                        <br />
                        <p>
                          Of {existing_device_deployed_count} randomly sampled older devices{' '}
                          {existing_device_success_rate && (
                            <>
                              ({parseInt(existing_device_success_rate)}% of which have installed
                              this version)
                            </>
                          )}
                          , what percent filed a status report in the 48 hours leading up to{' '}
                          {DateConverter.call(last_refreshed_health_metrics_at)}?
                        </p>
                        <ProgressBar
                          color="info"
                          percent={parseInt(existing_device_experiment_watchdog_rate)}
                          label="Existing Devices Filing Status Reports (Experimental Group)"
                        />
                      </div>
                    )}
                  <br />
                  <div id="existing-senders" style={{ height: '500px' }} />
                  <div id="activation-chart" style={{ height: '500px' }} />
                  <div id="new-senders" style={{ height: '500px' }} />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
  /* eslint-enable camelcase */
}

DeploymentDeliverability.propTypes = {
  loading: PropTypes.bool.isRequired,
  deployment: PropTypes.object,
  version: PropTypes.object,
  backFunc: PropTypes.func.isRequired,
  specificToDeployment: PropTypes.bool.isRequired,
  refreshData: PropTypes.func,
  refreshedData: PropTypes.bool,
}

export default DeploymentDeliverability
