import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FullStory from 'react-fullstory'

import { Link } from 'react-router-dom'

import { Analytics } from '../utilities/Analytics'

export default class SyncSuccess extends Component {
  componentDidMount() {
    Analytics.track('Saw Calendar Sync Success')
    window.scrollTo(0, 0)
  }

  render() {
    const { frame } = this.props
    return (
      <div>
        <FullStory org="R6R1G" />
        <p>
          <Link to={`/frames/${frame.id}/sync?activating=true&showing_sync_form=true`}>
            &lt; back
          </Link>
        </p>
        <div className="register-box">
          <h4>Sync Underway!</h4>
          <div className="alert alert-primary" role="alert">
            If this is your first time syncing this calendar, it can take up to <b>12 hours</b> for
            all your events to load. Don't worry, it gets faster moving forward!
          </div>
          <div className="row mt-4">
            <div className="col-6 text-left">
              <Link
                to={`/frames/${frame.id}/sync?activating=true&showing_sync_form=true`}
                className="btn btn-success"
              >
                Sync another Account
              </Link>
            </div>
            <div className="col-6 text-right">
              <Link to={`/frames/${frame.id}/final-step`} className="btn btn-outline-primary">
                Finish
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SyncSuccess.propTypes = {
  frame: PropTypes.object.isRequired,
}
