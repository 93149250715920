export const CATEGORIES_CREATE_REQUEST = 'CATEGORIES_CREATE_REQUEST'
export const CATEGORIES_CREATE_SUCCESS = 'CATEGORIES_CREATE_SUCCESS'
export const CATEGORIES_CREATE_FAILURE = 'CATEGORIES_CREATE_FAILURE'

export function create(frameId, data) {
  let endpoint = `frames/${frameId}/categories`
  return {
    type: CATEGORIES_CREATE_REQUEST,
    api: {
      data,
      endpoint,
      authenticated: true,
      types: [CATEGORIES_CREATE_REQUEST, CATEGORIES_CREATE_SUCCESS, CATEGORIES_CREATE_FAILURE],
      method: 'POST',
    },
  }
}

export const CATEGORIES_REQUEST = 'CATEGORIES_REQUEST'
export const CATEGORIES_SUCCESS = 'CATEGORIES_SUCCESS'
export const CATEGORIES_FAILURE = 'CATEGORIES_FAILURE'

export function list(frameId) {
  let endpoint = `frames/${frameId}/categories`
  return {
    type: CATEGORIES_REQUEST,
    api: {
      endpoint,
      authenticated: true,
      types: [CATEGORIES_REQUEST, CATEGORIES_SUCCESS, CATEGORIES_FAILURE],
      method: 'GET',
    },
  }
}

export const CATEGORIES_UPDATE_REQUEST = 'CATEGORIES_UPDATE_REQUEST'
export const CATEGORIES_UPDATE_SUCCESS = 'CATEGORIES_UPDATE_SUCCESS'
export const CATEGORIES_UPDATE_FAILURE = 'CATEGORIES_UPDATE_FAILURE'

export function update(frameId, id, data) {
  let endpoint = `frames/${frameId}/categories/${id}`
  return {
    type: CATEGORIES_UPDATE_REQUEST,
    api: {
      data,
      endpoint,
      authenticated: true,
      types: [CATEGORIES_UPDATE_REQUEST, CATEGORIES_UPDATE_SUCCESS, CATEGORIES_UPDATE_FAILURE],
      method: 'PUT',
    },
  }
}

export const CATEGORIES_DESTROY_REQUEST = 'CATEGORIES_DESTROY_REQUEST'
export const CATEGORIES_DESTROY_SUCCESS = 'CATEGORIES_DESTROY_SUCCESS'
export const CATEGORIES_DESTROY_FAILURE = 'CATEGORIES_DESTROY_FAILURE'

export function destroy(frameId, id, reassignToCategoryId) {
  let endpoint = `frames/${frameId}/categories/${id}?reassign_to_category_id=${reassignToCategoryId}`
  return {
    type: CATEGORIES_DESTROY_REQUEST,
    api: {
      endpoint,
      authenticated: true,
      types: [CATEGORIES_DESTROY_REQUEST, CATEGORIES_DESTROY_SUCCESS, CATEGORIES_DESTROY_FAILURE],
      method: 'DELETE',
    },
  }
}
