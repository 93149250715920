import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { Analytics } from '../utilities/Analytics'
import PhoneInputModal from './PhoneInputModal'

import MobileImg from '../../assets/images/mobile-app.jpg'
import GooglePlayIcon from '../../assets/images/google-play-icon.png'

const MonthInReviewPromoPage = () => {
  const [showPhoneInputModal, setShowPhoneInputModal] = useState(false)
  const isAndroid = /android/i.test(window.navigator.userAgent)

  return (
    <Container id="phone-input-modal-root">
      <PhoneInputModal isOpen={showPhoneInputModal} setVisibility={setShowPhoneInputModal} />

      <Title>Download the Skylight app to access your Month in Review!</Title>
      <HiddenOnMobile>
        <Description>
          Once you log in, your personalized Month in Review will be available in the activity page.
        </Description>
      </HiddenOnMobile>
      <TwoColumnGrid>
        <LeftSide>
          <GetSkylight>
            <img
              src="https://skylight-marketing-assets.s3.amazonaws.com/skylight_app_image.png"
              alt="Skylight Logo"
              width={40}
              height={40}
            />
            <GetSkylight.Text>
              <HiddenTextOnMobile>Get the </HiddenTextOnMobile>Skylight App
            </GetSkylight.Text>
            <div id="activation-qr-code" />
          </GetSkylight>
          <HiddenOnMobile>
            <Badges>
              <BadgeAnchorCtn
                href="https://apps.apple.com/us/app/skylight-app/id1438779037"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Download on the Apple Store"
                  src="https://skylight-marketing-assets.s3.amazonaws.com/app-store-badge.png"
                  width="150"
                  height="40"
                />
              </BadgeAnchorCtn>
              <BadgeAnchorCtn
                href="https://play.google.com/store/apps/details?id=com.skylightframe.mobile&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Get it on Google Play"
                  src="https://skylight-marketing-assets.s3.amazonaws.com/google-play-badge.png"
                  width="150"
                  height="40"
                />
              </BadgeAnchorCtn>
            </Badges>
            <SubtleText>or</SubtleText>
            <Button
              type="button"
              className="btn btn-primary"
              onClick={() => setShowPhoneInputModal(true)}
            >
              Text me the App link
            </Button>
          </HiddenOnMobile>
          <HiddenOnDesktop>
            <Description>
              Upload photos and videos straight to your Skylight from your phone or sync your
              phone's calendars with your Skylight Calendar.
            </Description>
          </HiddenOnDesktop>
        </LeftSide>

        <RightSide>
          <MobileImage src={MobileImg} alt="Skylight Mobile" />
        </RightSide>
      </TwoColumnGrid>
      <HiddenOnDesktop>
        <BlackButtonLink
          className="btn btn-dark"
          href={
            isAndroid
              ? 'https://play.google.com/store/apps/details?id=com.skylightframe.mobile'
              : 'https://apps.apple.com/us/app/skylight-app/id1438779037'
          }
        >
          {isAndroid ? (
            <img src={GooglePlayIcon} alt="Google Play" width="32" height="32" />
          ) : (
            <svg
              fill="white"
              version="1.1"
              viewBox="0 0 32 32"
              height="1.5em"
              width="1.5em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M23.023 17.093c-0.033-3.259 2.657-4.822 2.777-4.901-1.512-2.211-3.867-2.514-4.705-2.548-2.002-0.204-3.91 1.18-4.926 1.18-1.014 0-2.583-1.15-4.244-1.121-2.185 0.033-4.199 1.271-5.323 3.227-2.269 3.936-0.58 9.769 1.631 12.963 1.081 1.561 2.37 3.318 4.061 3.254 1.63-0.064 2.245-1.055 4.215-1.055s2.524 1.055 4.248 1.021c1.753-0.032 2.864-1.591 3.936-3.159 1.24-1.814 1.751-3.57 1.782-3.659-0.038-0.017-3.416-1.312-3.451-5.202zM19.783 7.53c0.897-1.089 1.504-2.602 1.34-4.108-1.294 0.053-2.861 0.86-3.79 1.948-0.832 0.965-1.561 2.502-1.365 3.981 1.444 0.112 2.916-0.734 3.816-1.821z" />
            </svg>
          )}
          <span>Get Skylight for {isAndroid ? 'Android' : 'iOS'}</span>
        </BlackButtonLink>
      </HiddenOnDesktop>
    </Container>
  )
}

MonthInReviewPromoPage.propTypes = {}

export default MonthInReviewPromoPage

const Container = styled.div`
  max-width: 650px;
  margin: 2.5rem auto;
  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const Title = styled.h1`
  font-weight: bold;
  text-align: center;
  font-size: 2.75rem;
  @media (max-width: 650px) {
    font-size: 2rem;
  }
`

const Description = styled.p`
  text-align: center;
  margin: 2rem 0;
  @media (max-width: 650px) {
    text-align: left;
    margin: 1rem 0;
  }
`

const TwoColumnGrid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100vw;
  @media (max-width: 650px) {
    flex-direction: row-reverse;
    justify-content: center;
    margin: 3rem 0.5rem;
  }
`
const LeftSide = styled.div`
  width: 50%;
  @media (max-width: 650px) {
    width: 100%;
    max-width: 350px;
  }
`

const GetSkylight = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  width: 100%;
`

GetSkylight.Text = styled.p`
  margin: 0 0 0 0.875rem;
`

const BadgeAnchorCtn = styled.a``

const Badges = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const SubtleText = styled.p`
  color: rgb(158, 158, 158) !important;
  font-size: 0.875rem;
  margin: 1rem 0 1rem 156px;
`

const Button = styled.button`
  width: 100%;
  :last-of-type {
    margin-top: 1.5rem;
    :hover {
      opacity: 0.7;
    }
  }
`

const RightSide = styled.div``

const HiddenOnMobile = styled.div`
  @media (max-width: 650px) {
    display: none;
  }
`

const HiddenOnDesktop = styled.div`
  @media (min-width: 650px) {
    display: none;
  }
  width: 100%;
  text-align: center;
`

const HiddenTextOnMobile = styled.span`
  @media (max-width: 650px) {
    display: none;
  }
`

const MobileImage = styled.img`
  height: auto;
  @media (max-width: 650px) {
    width: 100px;
    margin-right: 2rem;
  }
`

const BlackButtonLink = styled.a`
  background: black;
  text-decoration: none;
  width: 100%;
  max-width: 320px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  > span {
    margin-left: 0.5rem;
  }
  > svg {
    margin-bottom: 0.1rem;
  }
`
