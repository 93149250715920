import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import NavContainer from '../../containers/NavContainer'
import DeploymentDeliverability from './DeploymentDeliverability'

import {
  FLEET_HEALTH_COMPARISON_CLEAROUT,
  showExperimental,
  showControl,
} from '../../actions/admin/fleetHealths'

const FleetHealthComparison = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { comparison, gettingComparisonExperimental, gettingComparisonControl } = useSelector(
    (state) => state.fleetHealths
  )

  useEffect(() => {
    dispatch({ type: FLEET_HEALTH_COMPARISON_CLEAROUT })
    dispatch(showExperimental(params.experimentalId))
    dispatch(showControl(params.controlId))
  }, [dispatch])

  return (
    <>
      <NavContainer history={history} />
      <div className="row justify-content-md-center">
        <DeploymentDeliverability
          loading={gettingComparisonExperimental || gettingComparisonControl}
          deployment={comparison}
          backFunc={history.goBack}
          specificToDeployment={false}
        />
      </div>
    </>
  )
}

export default FleetHealthComparison
