import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import MobilePromoBanner from './MobilePromoBanner'
import { Session } from '../utilities/Session'
import { Analytics } from '../utilities/Analytics'

import skylightLogo from '../../assets/images/skylight-logo.png'

export default class Nav extends Component {
  constructor(props) {
    super(props)
    this.state = { showingDropdown: false }
    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.toggleAndSignOut = this.toggleAndSignOut.bind(this)
    this.trackAndCloseDropdown = this.trackAndCloseDropdown.bind(this)
  }

  toggleDropdown() {
    this.setState((prevState) => ({
      showingDropdown: !prevState.showingDropdown,
    }))
  }

  trackAndCloseDropdown() {
    Analytics.track('Clicked Link Plus Account From Homepage')
    this.setState((prevState) => ({
      showingDropdown: !prevState.showingDropdown,
    }))
  }

  toggleAndSignOut() {
    const { signOut } = this.props
    this.toggleDropdown()
    signOut()
  }

  render() {
    const {
      loggedIn,
      isAdmin,
      uploading,
      email,
      reminderProfileId,
      reminderProfileToken,
      subscriptionStatus,
      containerStyles,
    } = this.props
    const { showingDropdown } = this.state
    return (
      <Container style={containerStyles}>
        <MobilePromoBanner />
        <nav className="navbar navbar-expand-lg justify-content-between">
          <Link className="navbar-brand" to="/">
            <img src={skylightLogo} alt="Skylight" width={120} height={32} />
          </Link>
          <ul className="navbar-nav ml-auto">
            {isAdmin && (
              <li className="nav-item">
                <Link className="nav-link" to="/admin-dash/fleet-health">
                  Health
                </Link>
              </li>
            )}
            {isAdmin && (
              <li className="nav-item">
                <Link className="nav-link" to="/admin-dash/versions">
                  Deployments
                </Link>
              </li>
            )}
            {isAdmin && (
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  Frames
                </Link>
              </li>
            )}
            {isAdmin && (
              <li className="nav-item">
                <Link className="nav-link" to="/admin-dash/users">
                  Users
                </Link>
              </li>
            )}
            {loggedIn && (
              <li className={`nav-item dropdown text-left z-1 ${showingDropdown ? 'show' : ''}`}>
                <a
                  onClick={this.toggleDropdown}
                  className="nav-link dropdown-toggle logged-in-as pt-0"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Logged in as
                  <br />
                  {email}
                </a>
                <div
                  className={`dropdown-menu text-right dropdown-menu-right position-absolute ${
                    showingDropdown ? 'show' : ''
                  }`}
                >
                  <Link onClick={this.toggleDropdown} to="/frames/new" className="dropdown-item">
                    Activate New Device &nbsp;{' '}
                    <span
                      className="oi oi-power-standby"
                      title="Activate New Device"
                      aria-hidden="true"
                    />
                  </Link>
                  {reminderProfileId && (
                    <a
                      href={`/reminder_profiles/${reminderProfileId}?token=${reminderProfileToken}`}
                      className="dropdown-item"
                    >
                      Change Notification Preferences &nbsp;{' '}
                      <span
                        className="oi oi-loop-circular"
                        title="Change Notification Preferences"
                        aria-hidden="true"
                      />
                    </a>
                  )}
                  {subscriptionStatus !== 'plus' && (
                    <Link
                      onClick={this.trackAndCloseDropdown}
                      className="dropdown-item"
                      to="/link-plus-account"
                    >
                      Link your Plus Account &nbsp;{' '}
                      <span
                        className="oi oi-fork"
                        title="Link your Plus Account"
                        aria-hidden="true"
                      />
                    </Link>
                  )}
                  <Link onClick={this.toggleDropdown} className="dropdown-item" to="/user/edit">
                    Update your Email &nbsp;{' '}
                    <span className="oi oi-cog" title="Update Your Email" aria-hidden="true" />
                  </Link>
                  <Link
                    onClick={this.initiateDeleteAccount}
                    className="dropdown-item"
                    to="/delete-account"
                  >
                    Delete account &nbsp;{' '}
                    <span className="oi oi-trash" title="Delete account" aria-hidden="true" />
                  </Link>
                  <div className="dropdown-divider" />
                  <Link
                    onClick={this.toggleAndSignOut}
                    className="dropdown-item"
                    to={Session.afterLogOutPage()}
                  >
                    Log Out &nbsp;{' '}
                    <span className="oi oi-account-login" title="Log Out" aria-hidden="true" />
                  </Link>
                </div>
              </li>
            )}
          </ul>
        </nav>
        {uploading && (
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
              <div className="alert alert-primary" role="alert">
                Upload in Progress... Please <b>don't leave</b> or reload this website.
              </div>
            </div>
          </div>
        )}
      </Container>
    )
  }
}

Nav.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  email: PropTypes.string,
  signOut: PropTypes.func,
  uploading: PropTypes.bool,
  reminderProfileId: PropTypes.number,
  reminderProfileToken: PropTypes.string,
  subscriptionStatus: PropTypes.string,
  containerStyles: PropTypes.object,
}

const Container = styled.div`
  margin: 0px 0px 30px -15px;
  padding-left: 16px;
  padding-right: 15px;
  min-height: 56px;
  width: 100vw;
  box-shadow: 0 1px 10px -1px rgba(0, 0, 0, 0.12), 0 1px 4px -1px rgba(0, 0, 0, 0.12);
`
