import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import NavContainer from '../NavContainer'

import NewPlusUser from '../../components/admin/NewPlusUser'

import { create } from '../../actions/admin/users'

class AdminNewPlusUserContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { email: '', successMsg: '' }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.newPlusUser !== prevProps.newPlusUser) {
      const successMsg = `New Plus user created!`
      const email = ''
      this.setState({ email, successMsg })
    }
  }

  handleSubmit(event) {
    const { email } = this.state
    this.props.dispatch(create({ email }))
    event.preventDefault()
  }

  handleEmailChange(event) {
    const email = event.target.value
    this.setState({ email })
  }

  render() {
    const { history, creatingPlusUser, errors } = this.props
    return (
      <div>
        <NavContainer history={history} />
        <div className="row justify-content-md-center">
          <NewPlusUser
            parentState={this.state}
            creatingPlusUser={creatingPlusUser}
            errors={errors}
            handleSubmit={this.handleSubmit}
            handleEmailChange={this.handleEmailChange}
          />
        </div>
      </div>
    )
  }
}

AdminNewPlusUserContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  newPlusUser: PropTypes.object,
  creatingPlusUser: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => {
  const { creatingPlusUser, newPlusUser, errors } = state.users
  return { creatingPlusUser, newPlusUser, errors }
}

export default connect(mapStateToProps)(AdminNewPlusUserContainer)
