import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { Analytics } from '../utilities/Analytics'
import PhoneInputModal from './PhoneInputModal'
import skylightFrameImg from '../../assets/images/activation-devices.png'
import GooglePlayIcon from '../../assets/images/google-play-icon.png'
import activationQRCode from '../../assets/images/activation-qr-code.png'
import Nav from './Nav'

const MobilePromoPage = () => {
  const history = useHistory()

  useEffect(() => {
    const showDownloadAppPage = JSON.parse(localStorage.getItem('showDownloadAppPage'))
    if (showDownloadAppPage === false) return history.replace('/register')
    Analytics.track('Saw Mobile Activation Encouragement')
  }, [history])

  const handleSkip = () => {
    localStorage.setItem('showDownloadAppPage', false)
    localStorage.setItem('showMobilePromoBanner', true)
    history.replace('/register')
  }

  return (
    <Container>
      <Nav loggedIn={false} isAdmin={false} containerStyles={{ margin: 0 }} />
      <InnerContainer>
        <LeftColumn>
          <MobileAppExample
            src={skylightFrameImg}
            alt="Skylight App on Phone Screen"
            width={600}
            height={600}
          />
        </LeftColumn>
        <RightColumn>
          <AppDetails>
            <Title>Access exclusive features in the app</Title>
            <Description>
              Upload faster to your Skylight. Prepare a Frame as a gift. Or, sync your Skylight
              Calendar with your phone's calendars.
            </Description>
            <HiddenOnMobile>
              <GetSkylight>
                <GetSkylight.QR
                  src={activationQRCode}
                  alt="Activation QR Code"
                  width={160}
                  height={160}
                />
                <TextContainer>
                  <GetSkylight.Header>Download the app</GetSkylight.Header>
                  <GetSkylight.Text>
                    Scan this QR code to download the Skylight app from the App Store or Google
                    Play.
                  </GetSkylight.Text>
                  <HiddenOnMobile>
                    <Badges>
                      <BadgeAnchorCtn
                        href="https://apps.apple.com/us/app/skylight-app/id1438779037"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt="Download on the Apple Store"
                          src="https://skylight-marketing-assets.s3.amazonaws.com/app-store-badge.png"
                          width="150"
                          height="40"
                        />
                      </BadgeAnchorCtn>
                      <BadgeAnchorCtn
                        href="https://play.google.com/store/apps/details?id=com.skylightframe.mobile&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt="Get it on Google Play"
                          src="https://skylight-marketing-assets.s3.amazonaws.com/google-play-badge.png"
                          width="150"
                          height="40"
                        />
                      </BadgeAnchorCtn>
                    </Badges>
                  </HiddenOnMobile>
                </TextContainer>
              </GetSkylight>
            </HiddenOnMobile>
            <HiddenOnDesktop>
              <Button
                type="button"
                className="no-style-btn"
                as="a"
                href="https://onelink.to/279m43?"
              >
                <ButtonText>Download the app</ButtonText>
              </Button>
            </HiddenOnDesktop>
            <OutlineButton type="button" className="no-style-btn" onClick={handleSkip}>
              <OutlineBtnText>Or, continue on our website</OutlineBtnText>
            </OutlineButton>
          </AppDetails>
        </RightColumn>
      </InnerContainer>
    </Container>
  )
}

MobilePromoPage.propTypes = {}

export default MobilePromoPage

const Container = styled.div`
  position: fixed;
  height: 100%;
  max-width: 100%;
  margin: 0px -15px;
`

const InnerContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
  }
`

const LeftColumn = styled.div`
  width: 50%;
  background-color: #dcf1f3;
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
  align-items: center;
  @media (max-width: 1300px) {
    width: auto;
    overflow: hidden;
    justify-content: center;
  }
  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    overflow: hidden;
  }
`

const MobileAppExample = styled.img`
  margin-right: 40px;
  @media (max-width: 1300px) {
    margin: 0;
  }
  @media (max-width: 700px) {
    max-width: 100%;
    width: auto;
    height: auto;
  }
`

const RightColumn = styled.div`
  width: 50%;
  display: flex;
  height: 100%;
  align-items: center;
  @media (max-width: 1300px) {
    width: auto;
  }
  @media (max-width: 700px) {
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 16px;
  }
  @media (max-height: 800px) {
    padding-bottom: 60px;
  }
`

const AppDetails = styled.div`
  width: 600px;
  margin: 0px 40px;
  @media (max-width: 700px) {
    width: auto;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
`

const Title = styled.h1`
  && {
    font-family: 'P22MackinacMedium';
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 8px;
    color: #2b2b2b;
  }
`

const Description = styled.p`
  && {
    font-family: 'FilsonProRegular';
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    color: #444444;
  }
`

const GetSkylight = styled.div`
  display: flex;
  align-items: center;
  background-color: #dcf1f3;
  border-radius: 24px;
  padding: 16px;
`

GetSkylight.QR = styled.img`
  padding: 16px;
  background-color: white;
  border-radius: 16px;
`

GetSkylight.Header = styled.h2`
  && {
    font-family: 'P22MackinacMedium';
    color: #444444;
    font-size: 26px;
    line-height: 32px;
  }
`

GetSkylight.Text = styled.p`
  && {
    font-family: 'FilsonProRegular';
    color: #444444;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }
`

const BadgeAnchorCtn = styled.a``

const Badges = styled.section`
  display: flex;
  img {
    margin-right: 16px;
  }
`

const Button = styled.button`
  min-height: 56px;
  width: 100%;
  background-color: #2178af;
  margin: 0px 0px 8px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 700px) {
    align-self: center;
  }
  :hover {
    background-color: #7aaecf;
    text-decoration: none;
  }
  :active {
    background-color: #154d70;
  }
  :focus {
    border: 2px solid #444444;
  }
`

const ButtonText = styled.h4`
  && {
    color: white;
    font-family: 'FilsonProMedium';
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
  }
`
const OutlineButton = styled.button`
  min-height: 56px;
  text-align: left;
  border-radius: 4px;
  @media (max-width: 700px) {
    align-self: center;
  }
  :focus {
    border: 2px solid #444444;
  }
`

const OutlineBtnText = styled.h4`
  && {
    color: #2178af;
    font-family: 'FilsonProMedium';
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
    :hover {
      color: #7aaecf;
    }
    :active {
      color: #154d70;
    }
  }
`

const HiddenOnMobile = styled.div`
  @media (max-width: 700px) {
    display: none;
  }
`

const HiddenOnDesktop = styled.div`
  @media (min-width: 700px) {
    display: none;
  }
  width: 100%;
  text-align: center;
`

const TextContainer = styled.div`
  margin: 0px 16px;
`
