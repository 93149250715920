import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class InProgressUploads extends Component {
  render() {
    const { messages } = this.props
    return (
      <div className="row">
        <div className="col-12">
          <div className="in-progress-upload-container">
            <h3>Uploads in Progress...</h3>
            <div className="row justify-content-around align-items-center">
              {Object.values(messages).map((message, idx) => (
                <div key={idx} className="col-md-4">
                  <div className="frame-message-box mb-3">
                    {message.assetType === 'photo' ? (
                      <img alt="Asset being uploaded" src={message.url} className="message-img" />
                    ) : (
                      <video muted controls>
                        <source src={message.url} type="video/mp4" />
                      </video>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

InProgressUploads.propTypes = {
  messages: PropTypes.object.isRequired,
}
