const USER_ID_STORAGE_KEY = 'user_id'
const TOKEN_STORAGE_KEY = 'token'
const EMAIL_STORAGE_KEY = 'email'
const ADMIN_STORAGE_KEY = 'is_admin'

const getId = () => localStorage.getItem(USER_ID_STORAGE_KEY) || null
const getToken = () => localStorage.getItem(TOKEN_STORAGE_KEY) || null
const getEmail = () => localStorage.getItem(EMAIL_STORAGE_KEY) || null
const isAdmin = () => localStorage.getItem(ADMIN_STORAGE_KEY) == 'true'
const isLoggedIn = () => !!getToken()

const setEmail = (email) => localStorage.setItem(EMAIL_STORAGE_KEY, email)

const afterLogOutPage = () => {
  if (isAdmin()) return '/admin'
  if (isLoggedIn()) return '/register'
  return null
}

export const Session = {
  getId,
  getToken,
  getEmail,
  setEmail,
  isAdmin,
  isLoggedIn,
  logIn: (id, attrs) => {
    localStorage.setItem(USER_ID_STORAGE_KEY, id)
    localStorage.setItem(TOKEN_STORAGE_KEY, attrs.token)
    setEmail(attrs.email)
    if (attrs.role == 'admin') localStorage.setItem(ADMIN_STORAGE_KEY, 'true')
  },
  logOut: () => {
    localStorage.removeItem(USER_ID_STORAGE_KEY)
    localStorage.removeItem(TOKEN_STORAGE_KEY)
    localStorage.removeItem(EMAIL_STORAGE_KEY)
    localStorage.removeItem(ADMIN_STORAGE_KEY)
  },
  afterLogOutPage,
}
