import { REMINDER_PROFILES_REQUEST, REMINDER_PROFILES_SUCCESS } from '../actions/reminderProfiles'

const DEFAULT_STATE = {
  reminderProfileId: null,
  reminderProfileToken: null,
}

export function reminderProfiles(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case REMINDER_PROFILES_REQUEST:
      return Object.assign({}, state, DEFAULT_STATE)
    case REMINDER_PROFILES_SUCCESS:
      let { data } = action.response
      if (data) {
        let { attributes } = data
        return Object.assign({}, state, {
          reminderProfileId: attributes.id,
          reminderProfileToken: attributes.token,
        })
      } else {
        return state
      }
    default:
      return state
  }
}
