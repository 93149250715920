import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { UrlParams } from '../../utilities/UrlParams'

import UserList from '../../components/admin/UserList'
import NavContainer from '../NavContainer'

import { list } from '../../actions/admin/users'

class UsersContainer extends Component {
  constructor(props) {
    super(props)
    let urlParams = UrlParams.get()
    let searchQuery = urlParams.q || ''
    this.state = { searchQuery }
    this.search = this.search.bind(this)
    this.updateSearchQuery = this.updateSearchQuery.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(list(this.state.searchQuery))
  }

  updateSearchQuery(searchQuery) {
    this.setState({ searchQuery })
  }

  search(searchQuery) {
    let { dispatch, history } = this.props
    let url = '/admin-dash/users'
    if (searchQuery) url += `?q=${ searchQuery }`
    history.push(url)
    dispatch(list(searchQuery))
    this.updateSearchQuery(searchQuery)
  }

  render() {
    const { history, loggedIn, isAdmin, users, gettingUsers } = this.props
    return (
      <div>
        <NavContainer
          history={history} />
      { loggedIn && isAdmin &&
        <div className='row justify-content-md-center'>
          <UserList
            loading={gettingUsers} 
            users={users}
            searchFunc={this.search}
            searchQuery={this.state.searchQuery}
            updateSearchQuery={this.updateSearchQuery} />
        </div>
      }
      </div>
    )
  }
}

UsersContainer.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  gettingUsers: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired
}

const mapStateToProps = (state) => {
  const { loggedIn, isAdmin } = state.session
  const { gettingUsers, users } = state.users
  return {
    loggedIn, isAdmin,
    gettingUsers, users
  }
}

export default connect(mapStateToProps)(UsersContainer)