import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import { Analytics } from '../utilities/Analytics'

const ROLE_LABELS = {
  none: 'Calendars',
  owner: 'Your Calendars',
  reader: 'Shared Calendars',
  freeBusyReader: 'Shared Calendars',
  writer: 'Shared Calendars',
}

const reloadPage = () => {
  window.location.href = window.location.href.replace('#', '')
}

export default class CalendarPicker extends Component {
  componentDidMount() {
    Analytics.track('Saw Calendar Picker')
    window.scrollTo(0, 0)
  }

  render() {
    const {
      frame,
      availableCalendars,
      selectedCalendarIds,
      toggleCalendar,
      save,
      toggleSelectAll,
    } = this.props
    const isAllSelected = availableCalendars.length === selectedCalendarIds.length
    const calendarGroupsByRole = {}
    availableCalendars.forEach((calendar) => {
      let { role } = calendar
      if (!role) role = 'none'
      const roleLabel = ROLE_LABELS[role]
      if (!calendarGroupsByRole[roleLabel]) calendarGroupsByRole[roleLabel] = []
      calendarGroupsByRole[roleLabel].push(calendar)
    })
    return (
      <div>
        <p>
          <Link to={`/frames/${frame.id}/sync?activating=true&showing_sync_form=true`}>
            &lt; back
          </Link>
        </p>
        <div className="register-box">
          <h4>Success! Which calendars do you want to sync?</h4>
          <p>We'll sync all events from the calendars you pick.</p>
          <div className="form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input"
                onChange={toggleSelectAll}
                checked={isAllSelected}
              />
              <b>{isAllSelected ? 'Deselect All' : 'Select All'}</b>
            </label>
          </div>
          {Object.keys(calendarGroupsByRole).map((role) => (
            <div key={role} className="pt-2">
              <h5>{role}</h5>
              {calendarGroupsByRole[role].map((calendar) => (
                <div key={calendar.id} className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      checked={selectedCalendarIds.indexOf(calendar.id) !== -1}
                      type="checkbox"
                      onChange={() => toggleCalendar(calendar.id)}
                    />
                    {calendar.name}
                  </label>
                </div>
              ))}
            </div>
          ))}
          <div className="row mt-4">
            <div className="col-6" />
            <div className="text-right col-6">
              <a
                href="#"
                onClick={save}
                disabled={selectedCalendarIds.length === 0}
                className="btn btn-success"
              >
                Sync!
              </a>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <p className="text-muted">
                Not seeing all your Calendars? Try{' '}
                <a onClick={reloadPage} href="#">
                  reloading this page
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CalendarPicker.propTypes = {
  availableCalendars: PropTypes.array.isRequired,
  selectedCalendarIds: PropTypes.array.isRequired,
  toggleCalendar: PropTypes.func.isRequired,
  toggleSelectAll: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  frame: PropTypes.object.isRequired,
}
