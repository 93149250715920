import {
  MIGRATE_SAME_FRAME_REQUEST,
  TRANSFER_FRAME_USER_REQUEST,
  TRANSFER_FRAME_USER_FAILURE,
  COPY_NEW_FRAME_REQUEST,
  COPY_NEW_FRAME_FAILURE,
} from '../../actions/admin/frameMigration'

const DEFAULT_STATE = { errors: [] }

export function frameMigration(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case MIGRATE_SAME_FRAME_REQUEST:
    case COPY_NEW_FRAME_REQUEST:
    case TRANSFER_FRAME_USER_REQUEST:
      return { ...state, errors: [] }
    case COPY_NEW_FRAME_FAILURE:
    case TRANSFER_FRAME_USER_FAILURE:
      return { ...state, errors: action.errors }
    default:
      return state
  }
}
