export const PURCHASE_METADATA_REQUEST = 'PURCHASE_METADATA_REQUEST'
export const PURCHASE_METADATA_SUCCESS = 'PURCHASE_METADATA_SUCCESS'
export const PURCHASE_METADATA_FAILURE = 'PURCHASE_METADATA_FAILURE'

export function getPurchaseMetadata(frame) {
  return {
    type: PURCHASE_METADATA_REQUEST,
    api: {
      endpoint: `frames/${frame.id}/purchase_metadata`,
      authenticated: true,
      types: [PURCHASE_METADATA_REQUEST, PURCHASE_METADATA_SUCCESS, PURCHASE_METADATA_FAILURE],
      method: 'GET',
    },
  }
}

export const UPDATE_POST_ACTIVATION_ATTRIBUTION_REQUEST =
  'UPDATE_POST_ACTIVATION_ATTRIBUTION_REQUEST'
export const UPDATE_POST_ACTIVATION_ATTRIBUTION_SUCCESS =
  'UPDATE_POST_ACTIVATION_ATTRIBUTION_SUCCESS'
export const UPDATE_POST_ACTIVATION_ATTRIBUTION_FAILURE =
  'UPDATE_POST_ACTIVATION_ATTRIBUTION_FAILURE'

export function updatePostActivationAttribution(frame, marketingChannel, salesChannel) {
  return {
    type: UPDATE_POST_ACTIVATION_ATTRIBUTION_REQUEST,
    api: {
      endpoint: `frames/${frame.id}/purchase_metadata`,
      data: { marketing_channel: marketingChannel, sales_channel: salesChannel },
      authenticated: true,
      types: [
        UPDATE_POST_ACTIVATION_ATTRIBUTION_REQUEST,
        UPDATE_POST_ACTIVATION_ATTRIBUTION_SUCCESS,
        UPDATE_POST_ACTIVATION_ATTRIBUTION_FAILURE,
      ],
      method: 'PUT',
    },
  }
}
