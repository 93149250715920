import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export default class IntersectionObs extends Component {
  constructor(props) {
    super(props)
    this.loadingRef = React.createRef()
    this.createObserver = this.createObserver.bind(this)
    this.destroyObserver = this.destroyObserver.bind(this)
  }

  componentDidMount() {
    this.createObserver()
  }

  componentWillUnmount() {
    this.destroyObserver()
  }

  createObserver() {
    const { appendPreloaded, numPages } = this.props
    const options = {
      root: null,
      rootMargin: '0px 0px 50px 0px',
      threshold: 0.1,
    }
    this.observer = new IntersectionObserver((entries) => {
      const { currentPage } = this.props
      const pagesFetched = numPages + 1
      if (entries[0].isIntersecting) {
        appendPreloaded()
      }
      if (pagesFetched === currentPage) {
        this.destroyObserver()
      }
    }, options)
    if (numPages > 1) this.observer.observe(this.loadingRef.current)
  }

  destroyObserver() {
    this.observer.disconnect()
  }

  render() {
    return <IOCtn ref={this.loadingRef}>Loading...</IOCtn>
  }
}

IntersectionObs.propTypes = {
  appendPreloaded: PropTypes.func.isRequired,
  numPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
}

const IOCtn = styled.div`
  align-self: flex-end;
  position: absolute;
  bottom: 0;
`
