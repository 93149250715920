import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Nav from './Nav'

export default class CheckYourEmail extends Component {
  render() {
    return (
      <div>
        <Nav loggedIn={false} isAdmin={false} />
        <div className="row justify-content-md-center">
          <div className="col-lg-6 col-xl-4">
            <div className="register-box">
              <h1>Check Your Email</h1>
              <p>
                We've sent you a link that you can use to log in to your Skylight Frame account.
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CheckYourEmail.propTypes = {}
