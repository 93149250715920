import axios from 'axios'
import { Session } from '../utilities/Session'

export const HttpWrapper = {
  call: (endpoint, method, data, authenticated, isAdmin) => {
    let token = Session.getToken()
    let id = Session.getId()
    let config = {
      headers: { 'Content-Type': 'application/json' },
      method: method || 'GET',
    }
    config.data = data
    let base = BASE_URL
    if (isAdmin) {
      base += 'admin/'
    } else {
      base += 'api/'
    }
    if (authenticated) {
      if (token) {
        let creds = [id, token].join(':')
        config.headers['Authorization'] = ['Basic', btoa(creds)].join(' ')
      } else {
        console.warn('No token saved')
      }
    }

    config.url = base + endpoint

    return axios(config)
  },
}
