import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FullStory from 'react-fullstory'

import { Link } from 'react-router-dom'

import { Analytics } from '../utilities/Analytics'

export default class SyncFailure extends Component {
  componentDidMount() {
    Analytics.track('Saw Calendar Sync Failure')
    window.scrollTo(0, 0)
  }

  render() {
    const { frame } = this.props
    return (
      <div className="register-box">
        <FullStory org="R6R1G" />
        <h1>Oops!</h1>
        <p>Your Calendar failed to sync.</p>
        <p>Please email help@skylightframe.com and we'll get you sorted out.</p>
        <p>Apologies for the inconvenience!</p>
      </div>
    )
  }
}

SyncFailure.propTypes = {
  frame: PropTypes.object.isRequired,
}
