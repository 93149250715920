import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import AdminFramesContainer from '../../containers/admin/AdminFramesContainer'

import { DateConverter } from '../../utilities/DateConverter'

import Loading from '../Loading'
import Errors from '../Errors'

const SOURCE_ENUM_TO_VALUE = {
  free: 'Free',
  shopify: 'Recharge',
  ios: 'iOS',
  android: 'Android',
  stripe_ios: 'Stripe',
  stripe_android: 'Stripe',
}

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export default class User extends Component {
  render() {
    const {
      dispatch,
      loading,
      user,
      backFunc,
      upgrade,
      downgrade,
      plusThirty,
      cancelTrial,
      newTrial,
      errors,
    } = this.props
    const {
      email,
      created_at,
      trial_days_remaining,
      reminder_profile_url,
      subscribed_to_emails,
      plus,
      trialing,
      plus_renewal_cadence,
      plus_source,
    } = user?.attributes || {}
    const plusSource = trialing ? 'Trial' : SOURCE_ENUM_TO_VALUE[plus_source]
    const plusRenewalCadence = plus_renewal_cadence ? capitalize(plus_renewal_cadence) : 'None'
    return (
      <div className="col-lg-12 col-xl-8">
        <Errors errors={errors} />
        <div className="row align-items-center">
          <div className="col-1">
            <p className="mb-0">
              <button type="button" onClick={backFunc} className="btn btn-sm btn-info">
                ←
              </button>
            </p>
          </div>
          {!loading && (
            <div className="col-7">
              <h3 className="mb-0">{email}</h3>
              <p className="text-muted mb-0">
                Signed up at <b>{DateConverter.call(created_at)}</b>
              </p>
              {plus && (
                <p className="text-muted mb-0">
                  Plus source: <b>{plusSource}</b> Cadence: <b>{plusRenewalCadence}</b>
                </p>
              )}
              {trial_days_remaining ? (
                <p className="text-muted">
                  Trial: <b>{trial_days_remaining} days remaining</b>{' '}
                  <div className="btn-group">
                    <button type="button" className="btn btn-sm btn-success" onClick={plusThirty}>
                      <span className="oi oi-plus" title="Add 30 Days" aria-hidden="true" /> 30 Days
                    </button>
                    <button type="button" className="btn btn-sm btn-danger" onClick={cancelTrial}>
                      <span className="oi oi-trash" title="Cancel" aria-hidden="true" /> Cancel
                    </button>
                  </div>
                </p>
              ) : (
                <p className="text-muted">
                  Trial: Not on trial &nbsp;
                  <div className="btn-group">
                    <button type="button" className="btn btn-sm btn-success" onClick={newTrial}>
                      <span
                        className="oi oi-reload"
                        title="Grant Another Trial"
                        aria-hidden="true"
                      />{' '}
                      Grant Another Trial
                    </button>
                  </div>
                </p>
              )}
              {reminder_profile_url && (
                <p className="text-muted">
                  Marketing Emails: <b>{subscribed_to_emails ? 'Subscribed' : 'Unsubscribed'}</b>{' '}
                  <a href={reminder_profile_url}>
                    <span className="oi oi-gear" title="Edit" aria-hidden="true" /> Edit
                  </a>
                </p>
              )}
              <Link to={`/reset-password/${email}`}>Send password reset email</Link>
            </div>
          )}
          {!loading && (
            <div className="col-4">
              <div className="mb-0 text-right">
                <div className="btn-group">
                  {plus ? (
                    <button type="button" onClick={downgrade} className="btn btn-sm btn-danger">
                      Cancel Plus
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={upgrade}
                      className="btn btn-sm upgrade-to-plus-btn"
                    >
                      Upgrade to Plus
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <hr />
        <div className="row align-items-center">
          <div className="col-12">
            {loading ? <Loading /> : <AdminFramesContainer dispatch={dispatch} />}
          </div>
        </div>
      </div>
    )
  }
}

User.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.object,
  backFunc: PropTypes.func.isRequired,
  upgrade: PropTypes.func.isRequired,
  downgrade: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  plusThirty: PropTypes.func.isRequired,
  cancelTrial: PropTypes.func.isRequired,
  newTrial: PropTypes.func.isRequired,
}
