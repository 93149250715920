export const DEVICE_DEPLOYMENTS_CREATE_REQUEST = 'DEVICE_DEPLOYMENTS_CREATE_REQUEST'
export const DEVICE_DEPLOYMENTS_CREATE_SUCCESS = 'DEVICE_DEPLOYMENTS_CREATE_SUCCESS'
export const DEVICE_DEPLOYMENTS_CREATE_FAILURE = 'DEVICE_DEPLOYMENTS_CREATE_FAILURE'

export function update(deploymentId, frameId){
  return {
    type: DEVICE_DEPLOYMENTS_CREATE_REQUEST,
    api: {
      endpoint: 'deployments/' + deploymentId.toString() + '/devices/' + frameId.toString(),
      isAdmin: true,
      authenticated: true,
      types: [DEVICE_DEPLOYMENTS_CREATE_REQUEST, DEVICE_DEPLOYMENTS_CREATE_SUCCESS, DEVICE_DEPLOYMENTS_CREATE_FAILURE],
      method: 'PUT'
    }
  }
}