import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import NavContainer from '../NavContainer'
import DeploymentDeliverability from '../../components/admin/DeploymentDeliverability'

import { show, updateHealthMetrics } from '../../actions/admin/deployments'

class DeploymentDeliverabilityContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { refreshedData: false }
    this.refreshData = this.refreshData.bind(this)
  }

  componentDidMount() {
    const { match, dispatch } = this.props
    const { id } = match.params
    dispatch(show(id))
  }

  refreshData() {
    const { match, dispatch } = this.props
    const { id } = match.params
    dispatch(updateHealthMetrics(id))
    this.setState({ refreshedData: true })
  }

  render() {
    const { refreshedData } = this.state
    const { history, loggedIn, isAdmin, deployment, version, gettingDeployment } = this.props
    return (
      <div>
        <NavContainer history={history} />
        {loggedIn && isAdmin && (
          <div className="row justify-content-md-center">
            <DeploymentDeliverability
              loading={gettingDeployment}
              deployment={deployment}
              version={version}
              refreshData={this.refreshData}
              refreshedData={refreshedData}
              backFunc={history.goBack}
              specificToDeployment
            />
          </div>
        )}
      </div>
    )
  }
}

DeploymentDeliverabilityContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  loggedIn: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  gettingDeployment: PropTypes.bool.isRequired,
  deployment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.object.isRequired,
  }),
  version: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.object.isRequired,
  }),
}

const mapStateToProps = (state) => {
  const { loggedIn, isAdmin } = state.session
  const { gettingDeployment, deployment, version } = state.deployments
  return {
    loggedIn,
    isAdmin,
    gettingDeployment,
    deployment,
    version,
  }
}

export default connect(mapStateToProps)(DeploymentDeliverabilityContainer)
