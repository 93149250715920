import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ABTestGroup } from '../utilities/ABTestGroup'
import { PLUS_UPSELL_FEATURES } from '../constants/constants'
import FreeTrialVideo from './FreeTrialVideo'

import blackCheck from '../../assets/images/black-check.png'

export default function WelcomeToFreeTrial({ frame }) {
  window.scrollTo(0, 0)
  const nextStepLink = `/frames/${frame.id}/activate?activating=true`

  return (
    <FreeTrialPageCtn className="register-box">
      <Title>We think you'll really like Plus features so please enjoy a free 30-day trial!</Title>
      <Subtitle>No credit card or commitment required!</Subtitle>
      <PlusFeaturesList>
        {Object.keys(PLUS_UPSELL_FEATURES).map((featureTitle) => (
          <PlusFeatureListItem key={featureTitle}>
            <img src={blackCheck} alt="Checkmark" />
            <section className="ml-2">
              <b>{featureTitle}</b>
              {PLUS_UPSELL_FEATURES[featureTitle]}
            </section>
          </PlusFeatureListItem>
        ))}
      </PlusFeaturesList>
      <MobileAppAdText>
        Start using our mobile app, <b>"Skylight App"</b> today!
      </MobileAppAdText>
      <BtnGroup>
        <img
          src="https://skylight-marketing-assets.s3.amazonaws.com/skylight_app_image.png"
          alt="Skylight Logo"
          width={40}
          height={40}
        />
        <BadgeAnchorCtn
          href="https://apps.apple.com/us/app/skylight-app/id1438779037"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt="Download on the Apple Store"
            src="https://skylight-marketing-assets.s3.amazonaws.com/app-store-badge.png"
            width="136"
            height="40"
          />
        </BadgeAnchorCtn>
        <BadgeAnchorCtn
          href="https://play.google.com/store/apps/details?id=com.skylightframe.mobile&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt="Get it on Google Play"
            src="https://skylight-marketing-assets.s3.amazonaws.com/google-play-badge.png"
            width="136"
            height="40"
          />
        </BadgeAnchorCtn>
      </BtnGroup>
      <WebAppAdText>
        Access our web app at <b>ourskylight.com</b>
      </WebAppAdText>

      <FreeTrialVideo />

      <NextStepLink to={nextStepLink} className="btn btn-success">
        Next Step
      </NextStepLink>
    </FreeTrialPageCtn>
  )
}

WelcomeToFreeTrial.propTypes = {
  frame: PropTypes.object,
}

const Title = styled.h2`
  grid-area: title;
  font-weight: 600;
  text-align: center;
  font-size: 1.8rem;
`

const Subtitle = styled.h5`
  grid-area: subtitle;
  text-align: center;
  font-size: 1.25rem;
`

const PlusFeaturesList = styled.ul`
  grid-area: list;
  padding-inline-start: 0;
  margin-bottom: 0;
  margin-top: 1rem;
`

const PlusFeatureListItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  img[alt='Checkmark'] {
    height: 40px;
    width: calc(40 * 1.09134615385);
  }
`

const MobileAppAdText = styled.aside`
  grid-area: mobileAd;
`

const BadgeAnchorCtn = styled.a``

const BtnGroup = styled.section`
  grid-area: btnGroup;
  display: flex;
  a:not(:first-child) {
    margin-left: 1rem;
  }
`

const WebAppAdText = styled.aside`
  grid-area: webAd;
`

const NextStepLink = styled(Link)`
  grid-area: nextBtn;
  width: fit-content;
  justify-self: center;
  align-self: center;
  vertical-align: middle;
  text-align: center;
`

const FreeTrialPageCtn = styled.div`
  display: grid;
  grid-template-areas:
    'title      title'
    'subtitle    subtitle'
    'list        video'
    'mobileAd    video'
    'btnGroup    video'
    'webAd       nextBtn';
  grid-column-gap: 10%;
  grid-row-gap: 15px;
  @media only screen and (max-width: 750px) {
    grid-template-areas:
      'title'
      'subtitle'
      'list'
      'video'
      'mobileAd'
      'btnGroup'
      'webAd'
      'nextBtn';
    justify-items: center;
  }
  @media only screen and (max-width: 420px) {
    ${BtnGroup} {
      flex-direction: column;
      align-items: center;
      *:not(:first-child) {
        margin: 1rem 0 0 0;
      }
    }
    ${MobileAppAdText}, ${WebAppAdText} {
      text-align: center;
    }
  }
`
