import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { connect } from 'react-redux'

import { show } from '../actions/frames'

import NavContainer from './NavContainer'

import ProgressBar from '../components/ProgressBar'
import Loading from '../components/Loading'

import ActivateFrame from '../components/ActivateFrame'
import SyncCalendar from '../components/SyncCalendar'
import SyncFailure from '../components/SyncFailure'
import PlusSuccess from '../components/PlusSuccess'
import FinalStep from '../components/FinalStep'
import SyncIndvEvents from '../components/SyncIndvEvents'
import { UrlParams } from '../utilities/UrlParams'

import FramePrivacyContainer from './FramePrivacyContainer'
import FrameFriendsContainer from './FrameFriendsContainer'
import CalendarPickerContainer from './CalendarPickerContainer'
import PlusUpsellContainer from './PlusUpsellContainer'
import WelcomeToFreeTrial from '../components/WelcomeToFreeTrial'

// The numbers here dictate the <ProgressBar /> steps
const STEPS = {
  tv: {
    plus: 4,
    'plus-success': 4,
    'plus-reject': 4,
    activate: 5,
    privacy: 3,
    friends: 3,
    'final-step': 6,
  },
  photos: {
    plus: 4,
    'plus-success': 4,
    'plus-reject': 4,
    activate: 5,
    privacy: 3,
    friends: 3,
    'final-step': 6,
  },
  calendar: {
    activate: 4,
    sync: 5,
    'sync-success': 6,
    'sync-failure': 6,
  },
}

class FrameContainer extends Component {
  constructor(props) {
    super(props)
    const urlParams = UrlParams.get()
    this.state = { newHere: !!urlParams.new_here }
    this.showFrame = this.showFrame.bind(this)
    this.getCurrentStep = this.getCurrentStep.bind(this)
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      dispatch,
    } = this.props
    dispatch(show(id))
  }

  getCurrentStep() {
    const {
      frame,
      match: {
        params: { action },
      },
    } = this.props
    if (!frame) return null
    const currentStep = STEPS[frame.attributes.apps[0]][`${action}`]
    return currentStep
  }

  showFrame(action) {
    const { frame, isAdmin, history, dispatch, activationCode, nylasUrl } = this.props
    switch (action) {
      case 'final-step':
        return <FinalStep frame={frame} activationCode={activationCode} />
      case 'activate':
        return <ActivateFrame frame={frame} activationCode={activationCode} dispatch={dispatch} />
      case 'plus':
        return <PlusUpsellContainer frame={frame} history={history} />
      case 'plus-success':
        return <PlusSuccess frame={frame} />
      case 'plus-reject':
        return <WelcomeToFreeTrial frame={frame} />
      case 'sync': {
        const { savedWebcalLink, webcalLinkSaveErrors } = this.props
        return (
          <div className="med-width-ctn">
            <SyncCalendar
              frame={frame}
              isAdmin={isAdmin}
              history={history}
              savedWebcalLink={savedWebcalLink}
              webcalLinkSaveErrors={webcalLinkSaveErrors}
              dispatch={dispatch}
              nylasUrl={nylasUrl}
            />
          </div>
        )
      }
      case 'sync-success': {
        const urlParams = UrlParams.get()
        return <CalendarPickerContainer frame={frame} accountId={urlParams.account_id} />
      }
      case 'sync-indv': {
        return <SyncIndvEvents frame={frame} />
      }
      case 'sync-failure':
        return <SyncFailure frame={frame} />
      case 'privacy':
        return <FramePrivacyContainer frame={frame} history={history} />
      case 'privacy-settings':
        return <FramePrivacyContainer frame={frame} history={history} updating />
      case 'friends':
        return <FrameFriendsContainer frame={frame} history={history} />
      default:
        throw new Error(`Unknown frame action ${action}`)
    }
  }

  render() {
    const {
      history,
      frame,
      match: {
        params: { action },
      },
    } = this.props
    const { newHere } = this.state
    const currentStep = this.getCurrentStep()
    return (
      <div>
        <NavContainer history={history} />
        <div className="new-user-or-frame-ctn">
          {frame ? (
            <React.Fragment>
              {newHere && currentStep && (
                <span className="new-user-progress-bar-ctn">
                  <ProgressBar step={currentStep} />
                </span>
              )}
              {this.showFrame(action)}
            </React.Fragment>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    )
  }
}

FrameContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  frame: PropTypes.object,
  activationCode: PropTypes.string,
  savedWebcalLink: PropTypes.bool.isRequired,
  webcalLinkSaveErrors: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool,
  nylasUrl: PropTypes.string,
}

const mapStateToProps = (state) => {
  const { frame, activationCode } = state.frames
  const { isAdmin } = state.session
  const { savedWebcalLink, webcalLinkSaveErrors } = state.webcalAccounts
  const { nylasUrl } = state.calendars
  return { frame, isAdmin, savedWebcalLink, webcalLinkSaveErrors, activationCode, nylasUrl }
}

export default connect(mapStateToProps)(FrameContainer)
