import { LOG_OUT } from '../../actions/session'

import {
  DEPLOYMENTS_CREATE_REQUEST,
  DEPLOYMENTS_CREATE_SUCCESS,
  DEPLOYMENTS_CREATE_FAILURE,
  DEPLOYMENTS_REQUEST,
  DEPLOYMENTS_SUCCESS,
  DEPLOYMENTS_SHOW_REQUEST,
  DEPLOYMENTS_SHOW_SUCCESS,
  DEPLOYMENT_INTENT_CREATE_SUCCESS,
  DEPLOYMENT_INTENT_CREATE_FAILURE,
  DEPLOYMENT_INTENT_CREATED_CLEAROUT,
  DEPLOYMENT_INTENT_SHOW_REQUEST,
  DEPLOYMENT_INTENT_SHOW_SUCCESS,
  DEPLOYMENT_INTENT_PROMOTE_REQUEST,
  DEPLOYMENT_INTENT_PROMOTE_SUCCESS,
  DEPLOYMENT_INTENT_PROMOTE_FAILURE,
} from '../../actions/admin/deployments'

const DEFAULT_STATE = {
  gettingDeployment: true,
  deployment: null,
  version: null,
  gettingDeployments: false,
  deployments: [],
  versions: [],
  errors: [],
  uploading: false,
  finishedUploading: false,
  createdIntent: null,
  gettingDeploymentIntent: true,
  deploymentIntent: null,
  deploymentIntentErrors: [],
  promotingDeploymentIntent: false,
}

export function deployments(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case DEPLOYMENTS_SHOW_REQUEST:
      return { ...state, gettingDeployment: true, deployment: null, version: null }
    case DEPLOYMENTS_SHOW_SUCCESS:
      return {
        ...state,
        gettingDeployment: false,
        deployment: action.response.data,
        version: action.response.included[0],
      }
    case DEPLOYMENTS_REQUEST:
      return {
        ...state,
        finishedUploading: false,
        gettingDeployments: true,
        deployments: [],
        versions: [],
      }
    case DEPLOYMENTS_SUCCESS:
      return {
        ...state,
        gettingDeployments: false,
        deployments: action.response.data,
        versions: action.response.included,
      }
    case DEPLOYMENTS_CREATE_REQUEST:
      return { ...state, errors: [], uploading: true }
    case DEPLOYMENTS_CREATE_SUCCESS:
      return { ...state, uploading: false, finishedUploading: true }
    case DEPLOYMENTS_CREATE_FAILURE:
      return { ...state, errors: action.errors, uploading: false }
    case LOG_OUT:
      return { ...state, ...DEFAULT_STATE }
    case DEPLOYMENT_INTENT_CREATED_CLEAROUT: {
      return { ...state, createdIntent: null }
    }
    case DEPLOYMENT_INTENT_CREATE_SUCCESS: {
      return { ...state, createdIntent: action.response.data }
    }
    case DEPLOYMENT_INTENT_CREATE_FAILURE: {
      return {
        ...state,
        deploymentIntentErrors: action.errors,
      }
    }
    case DEPLOYMENT_INTENT_SHOW_REQUEST: {
      return {
        ...state,
        deploymentIntent: null,
        gettingDeploymentIntent: true,
        deploymentIntentErrors: [],
        version: null,
      }
    }
    case DEPLOYMENT_INTENT_SHOW_SUCCESS: {
      return {
        ...state,
        deploymentIntent: action.response.data,
        deploymentIntentErrors: [],
        gettingDeploymentIntent: false,
        version: action.response.included[0],
      }
    }
    case DEPLOYMENT_INTENT_PROMOTE_REQUEST: {
      return {
        ...state,
        deploymentIntentErrors: [],
        promoteDeploymentIntent: false,
      }
    }
    case DEPLOYMENT_INTENT_PROMOTE_SUCCESS: {
      return {
        ...state,
        deploymentIntent: action.response.data,
        deploymentIntentErrors: [],
        promotingDeploymentIntent: false,
      }
    }
    case DEPLOYMENT_INTENT_PROMOTE_FAILURE: {
      return {
        ...state,
        deploymentIntentErrors: action.errors,
        promoteDeploymentIntent: false,
      }
    }
    default:
      return state
  }
}
