/* eslint-disable camelcase */
export const MIGRATE_SAME_FRAME_REQUEST = 'MIGRATE_SAME_FRAME_REQUEST'
export const MIGRATE_SAME_FRAME_SUCCESS = 'MIGRATE_SAME_FRAME_SUCCESS'
export const MIGRATE_SAME_FRAME_FAILURE = 'MIGRATE_SAME_FRAME_FAILURE'

export function migrateSameFrameName(id) {
  return {
    type: MIGRATE_SAME_FRAME_REQUEST,
    api: {
      endpoint: `frames/${id}/migrate_to_same_frame_name`,
      isAdmin: true,
      authenticated: true,
      types: [MIGRATE_SAME_FRAME_REQUEST, MIGRATE_SAME_FRAME_SUCCESS, MIGRATE_SAME_FRAME_FAILURE],
      method: 'POST',
    },
  }
}

export const RESTORE_FROM_SOURCE_FRAME_ANCESTORS_REQUEST = 'RESTORE_FROM_SOURCE_FRAME_ANCESTORS_REQUEST'
export const RESTORE_FROM_SOURCE_FRAME_ANCESTORS_SUCCESS = 'RESTORE_FROM_SOURCE_FRAME_ANCESTORS_SUCCESS'
export const RESTORE_FROM_SOURCE_FRAME_ANCESTORS_FAILURE = 'RESTORE_FROM_SOURCE_FRAME_ANCESTORS_FAILURE'

export function restoreFromSourceFrameAncestors(id, source_frame_name) {
  return {
    type: RESTORE_FROM_SOURCE_FRAME_ANCESTORS_REQUEST,
    api: {
      data: { source_frame_name },
      endpoint: `frames/${id}/restore_from_source_frame_ancestors`,
      isAdmin: true,
      authenticated: true,
      types: [
        RESTORE_FROM_SOURCE_FRAME_ANCESTORS_REQUEST,
        RESTORE_FROM_SOURCE_FRAME_ANCESTORS_SUCCESS,
        RESTORE_FROM_SOURCE_FRAME_ANCESTORS_FAILURE,
      ],
      method: 'POST',
    },
  }
}

export const COPY_NEW_FRAME_REQUEST = 'COPY_NEW_FRAME_REQUEST'
export const COPY_NEW_FRAME_SUCCESS = 'COPY_NEW_FRAME_SUCCESS'
export const COPY_NEW_FRAME_FAILURE = 'COPY_NEW_FRAME_FAILURE'

export function copyNewFrame(id, new_frame_name) {
  return {
    type: COPY_NEW_FRAME_REQUEST,
    api: {
      data: { new_frame_name },
      endpoint: `frames/${id}/copy_to_new_frame`,
      isAdmin: true,
      authenticated: true,
      types: [COPY_NEW_FRAME_REQUEST, COPY_NEW_FRAME_SUCCESS, COPY_NEW_FRAME_FAILURE],
      method: 'POST',
    },
  }
}

export const TRANSFER_FRAME_USER_REQUEST = 'TRANSFER_FRAME_USER_REQUEST'
export const TRANSFER_FRAME_USER_SUCCESS = 'TRANSFER_FRAME_USER_SUCCESS'
export const TRANSFER_FRAME_USER_FAILURE = 'TRANSFER_FRAME_USER_FAILURE'

export function transferFrameNewUser(id, email, admin) {
  return {
    type: TRANSFER_FRAME_USER_REQUEST,
    api: {
      data: { email },
      endpoint: `frames/${id}/transfer_to_new_user`,
      isAdmin: admin,
      authenticated: true,
      types: [
        TRANSFER_FRAME_USER_REQUEST,
        TRANSFER_FRAME_USER_SUCCESS,
        TRANSFER_FRAME_USER_FAILURE,
      ],
      method: 'POST',
    },
  }
}
