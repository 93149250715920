import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import Nav from './Nav'
import Errors from './Errors'

export default class EditUser extends Component {
  render() {
    const { email, password, errors, updating, handleSubmit, handleUpdate } = this.props
    return (
      <div>
        <div className="row justify-content-md-center">
          <div className="col-lg-6 col-xl-4">
            <div className="register-box">
              <h2>Update your Email</h2>
              <Errors errors={errors} />
              <form className="mt-4" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="email">Your New Email Address</label>
                  <input
                    type="email"
                    value={email}
                    onChange={handleUpdate('email')}
                    className="form-control"
                    placeholder="janesmith@gmail.com"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Enter your Password to Authorize the Change</label>
                  <input
                    type="password"
                    value={password}
                    onChange={handleUpdate('password')}
                    className="form-control"
                    placeholder="Your password"
                  />
                </div>
                <div className="text-right pt-1">
                  <button
                    type="submit"
                    className="btn btn-lg btn-success"
                    disabled={
                      updating ||
                      !password ||
                      !email ||
                      email.indexOf('@') == -1 ||
                      email.indexOf('.') == -1
                    }
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
            <div className="pt-3 pl-2">
              <small className="text-muted">
                Want to change your password?{' '}
                <Link to={`/reset-password/${email}`}>Reset it here</Link>.
              </small>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

EditUser.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  errors: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
}
