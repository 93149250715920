import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import Cleave from 'cleave.js/react'

import Nav from './Nav'
import Errors from './Errors'
import ProgressBar from './ProgressBar'

export default class LogIn extends Component {
  constructor(props) {
    super(props)
    this.passwordInstructions = this.passwordInstructions.bind(this)
  }

  passwordInstructions() {
    const { newHere, parentState } = this.props
    if (newHere) {
      return parentState.resettingPassword ? 'pick a new' : 'pick a'
    }
    return 'enter your'
  }

  render() {
    const {
      newHere,
      loggedIn,
      parentState,
      errors,
      handleSubmit,
      loggingIn,
      handleChange,
      toggleTextMeTheApp,
      toggleAgreedToMarketing,
    } = this.props
    return (
      <div>
        <Nav loggedIn={false} isAdmin={false} />
        <div className="new-user-or-frame-ctn">
          {newHere && !parentState.resettingPassword && (
            <span className="new-user-progress-bar-ctn">
              <ProgressBar step={2} />
            </span>
          )}
          <div className="register-box small-width-ctn">
            {parentState.resettingPassword ? (
              <h2>Reset Password</h2>
            ) : (
              <h2>{newHere ? 'Connect To Your Skylight' : 'Log In'}</h2>
            )}
            <Errors errors={errors} />
            <form className="mt-4" onSubmit={handleSubmit}>
              {newHere && !parentState.resettingPassword && (
                <div className="form-group">
                  <label htmlFor="name" className="mb-0">
                    <b>Name</b>
                  </label>
                  <input
                    type="name"
                    value={parentState.name}
                    onChange={handleChange('name')}
                    className="form-control"
                    placeholder="Your Name"
                  />
                </div>
              )}
              {newHere && !parentState.resettingPassword && (
                <div className="form-group">
                  <label htmlFor="phone" className="mb-0">
                    <b>Phone</b> Number
                  </label>
                  <small className="form-text text-muted">
                    We'll <b>never share your phone number</b> with anyone else.
                  </small>
                  <Cleave
                    type="phone"
                    value={parentState.phone}
                    onChange={handleChange('phone')}
                    className="form-control"
                    options={{ numericOnly: true, blocks: [3, 3, 4], delimiter: '-' }}
                    placeholder="123-456-7891"
                  />
                </div>
              )}
              <div className="form-group">
                <label htmlFor="password" className="mb-0">
                  Please {this.passwordInstructions()} <b>Password</b>
                </label>
                {newHere && (
                  <small className="form-text text-muted">
                    This will allow you to log in and manage your devices.
                  </small>
                )}
                <input
                  type="password"
                  required
                  value={parentState.password}
                  onChange={handleChange('password')}
                  className="form-control"
                  placeholder="Your Password"
                />
                {newHere && !parentState.resettingPassword && (
                  <>
                    <div className="form-check pt-3">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onChange={toggleAgreedToMarketing}
                          checked={parentState.agreedToMarketing}
                        />
                        <b>Send me promotional updates and discounts</b>
                      </label>
                    </div>
                  </>
                )}
              </div>
              <div className="text-right pt-2">
                <button type="submit" className="btn btn-lg btn-success" disabled={loggingIn}>
                  {loggedIn ? 'Update' : 'Log In'}
                </button>
              </div>
            </form>
          </div>
          <div className="pt-3 pl-2">
            {!newHere && (
              <small className="text-muted">
                Forgot your password?{' '}
                <Link to={`/reset-password/${parentState.email}`}>Reset Password</Link>
              </small>
            )}
          </div>
        </div>
      </div>
    )
  }
}

LogIn.propTypes = {
  parentState: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  newHere: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool,
  toggleTextMeTheApp: PropTypes.func.isRequired,
  toggleAgreedToMarketing: PropTypes.func.isRequired,
}
