import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import CalendarCategories from '../components/CalendarCategories'

import { list, create, update, destroy } from '../actions/categories'

import { Analytics } from '../utilities/Analytics'

class CalendarCategoriesContainer extends Component {
  constructor(props) {
    super(props)
    this.createCategory = this.createCategory.bind(this)
    this.removeCategory = this.removeCategory.bind(this)
    this.updateCategory = this.updateCategory.bind(this)
  }

  componentDidMount() {
    const { dispatch, frame } = this.props
    dispatch(list(frame.id))
    Analytics.track('Viewed Calendar Categories')
  }

  createCategory(data) {
    const { dispatch, frame } = this.props
    Analytics.track('Added Calendar Category', data)
    dispatch(create(frame.id, data))
  }

  removeCategory(category, reassignToCategoryId) {
    const { dispatch, frame } = this.props
    Analytics.track('Removed Calendar Category')
    dispatch(destroy(frame.id, category.id, reassignToCategoryId))
  }

  updateCategory(category, attributes) {
    const { dispatch, frame } = this.props
    Analytics.track('Updated Calendar Category')
    dispatch(update(frame.id, category.id, attributes))
  }

  render() {
    const { categories, gettingCategories } = this.props
    return (
      <CalendarCategories
        categories={categories}
        loading={gettingCategories}
        createCategory={this.createCategory}
        removeCategory={this.removeCategory}
        updateCategory={this.updateCategory}
      />
    )
  }
}

CalendarCategoriesContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  frame: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  gettingCategories: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  const { frame } = state.frames
  const { categories, gettingCategories } = state.categories
  return { frame, categories, gettingCategories }
}

export default connect(mapStateToProps)(CalendarCategoriesContainer)
