import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { connect } from 'react-redux'

import { logIn, JUST_RESET_PASSWORD_CLEAROUT } from '../actions/session'
import LogIn from '../components/LogIn'

import { Analytics } from '../utilities/Analytics'
import { UrlParams } from '../utilities/UrlParams'

class LogInContainer extends Component {
  constructor(props) {
    super(props)
    const { token } = props.match.params
    const { email } = props
    this.state = {
      token,
      email,
      name: '',
      phone: '',
      password: '',
      resettingPassword: false,
      textMeTheApp: true,
      agreedToMarketing: true,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.redirectIfNecessary = this.redirectIfNecessary.bind(this)
    this.redirectIfNecessary()
  }

  componentDidMount() {
    const { match } = this.props
    let kind = match.params.token ? 'new_user' : 'existing_user'
    const urlParams = UrlParams.get()
    if (kind === 'new_user' && urlParams.from_email) kind = 'reset_email'
    if (urlParams.password_reset) this.setState({ resettingPassword: true })
    Analytics.track('Viewed Log In', { kind })
    window.scrollTo(0, 0)
  }

  componentDidUpdate() {
    this.redirectIfNecessary()
  }

  redirectIfNecessary() {
    const { history, dispatch, loggedIn, justResetPassword } = this.props
    const { email, token } = this.state
    if (loggedIn) {
      const urlParams = UrlParams.get()
      if (!urlParams.from_email) {
        history.push('/?just_logged_in=true')
      } else if (justResetPassword) {
        history.push('/')
        dispatch({ type: JUST_RESET_PASSWORD_CLEAROUT })
      }
    } else if (!email && !token) {
      history.push('/register')
    }
  }

  handleChange(key) {
    return (event) => {
      const newState = this.state
      newState[key] = event.target.value
      this.setState(newState)
    }
  }

  handleSubmit(event) {
    const { dispatch } = this.props
    dispatch(logIn(this.state))
    event.preventDefault()
  }

  render() {
    const { loggingIn, loggedIn, errors } = this.props
    const { token, textMeTheApp, agreedToMarketing } = this.state
    return (
      <LogIn
        parentState={this.state}
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        newHere={!!token}
        loggingIn={loggingIn}
        loggedIn={loggedIn}
        errors={errors}
        toggleTextMeTheApp={() => this.setState({ textMeTheApp: !textMeTheApp })}
        toggleAgreedToMarketing={() => this.setState({ agreedToMarketing: !agreedToMarketing })}
      />
    )
  }
}

LogInContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  justResetPassword: PropTypes.bool.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  email: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  const { justResetPassword, loggedIn, loggingIn, email, errors } = state.session
  return { justResetPassword, loggedIn, loggingIn, email, errors }
}

export default connect(mapStateToProps)(LogInContainer)
