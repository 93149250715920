export const EXPORTS_CREATE_REQUEST = 'EXPORTS_CREATE_REQUEST'
export const EXPORTS_CREATE_SUCCESS = 'EXPORTS_CREATE_SUCCESS'
export const EXPORTS_CREATE_FAILURE = 'EXPORTS_CREATE_FAILURE'

export function create() {
  return {
    type: EXPORTS_CREATE_REQUEST,
    api: {
      endpoint: 'exports',
      isAdmin: true,
      authenticated: true,
      types: [EXPORTS_CREATE_REQUEST, EXPORTS_CREATE_SUCCESS, EXPORTS_CREATE_FAILURE],
      method: 'POST',
    },
  }
}
