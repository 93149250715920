import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import GroceryItems from '../components/GroceryItems'

import { list, create, update } from '../actions/groceryItems'

import { Analytics } from '../utilities/Analytics'

class GroceryItemsContainer extends Component {
  constructor(props) {
    super(props)
    this.createItem = this.createItem.bind(this)
    this.removeItem = this.removeItem.bind(this)
  }

  componentDidMount() {
    const { dispatch, frame } = this.props
    dispatch(list(frame.id))
    Analytics.track('Viewed Grocery Items')
  }

  createItem(text) {
    const { dispatch, frame } = this.props
    Analytics.track('Added Grocery Item', { text })
    dispatch(create(frame.id, { text }))
  }

  removeItem(item) {
    const { dispatch, frame } = this.props
    Analytics.track('Removed Grocery Item')
    dispatch(update(frame.id, item.id, { status: 'completed' }))
  }

  render() {
    const { groceryItems, gettingGroceryItems } = this.props
    return (
      <GroceryItems
        groceryItems={groceryItems}
        loading={gettingGroceryItems}
        createItem={this.createItem}
        removeItem={this.removeItem}
      />
    )
  }
}

GroceryItemsContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  frame: PropTypes.object.isRequired,
  groceryItems: PropTypes.array.isRequired,
  gettingGroceryItems: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  const { frame } = state.frames
  const { groceryItems, gettingGroceryItems } = state.groceryItems
  return { frame, groceryItems, gettingGroceryItems }
}

export default connect(mapStateToProps)(GroceryItemsContainer)
