import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class TransferModal extends Component {
  constructor(props) {
    super(props)
    this.state = { newUserEmail: '' }
    this.changeUserEmail = this.changeUserEmail.bind(this)
    this.transfer = this.transfer.bind(this)
  }

  changeUserEmail(e) {
    this.setState({ newUserEmail: e.target.value })
  }

  transfer() {
    const { frame, dismiss, transferToNewUser, admin } = this.props
    const { newUserEmail } = this.state
    transferToNewUser(frame.id, newUserEmail, admin)
    dismiss()
  }

  render() {
    const { dismiss, frame } = this.props
    const { newUserEmail } = this.state
    return (
      <div
        id="migration-modal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Transfer {frame.attributes.name} to New Owner</h5>
              <button type="button" className="close" aria-label="Close" onClick={dismiss}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                What is the <b>personal email address</b> of the user who should own this Device?
              </p>
              <div className="alert alert-warning" role="alert">
                You will lose ownership and they will gain <b>full ownership</b> over determining
                who has access to this Device. You will still have access to send to the Device.
              </div>
              <input
                type="email"
                className="form-control mt-4"
                value={newUserEmail}
                onChange={this.changeUserEmail}
                placeholder="janesmith@gmail.com"
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary pull-left" onClick={dismiss}>
                Nevermind
              </button>
              <button type="button" className="btn btn-primary" onClick={this.transfer}>
                Transfer!
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

TransferModal.propTypes = {
  frame: PropTypes.object.isRequired,
  dismiss: PropTypes.func.isRequired,
  transferToNewUser: PropTypes.func.isRequired,
  admin: PropTypes.bool,
}
