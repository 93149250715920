import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Cleave from 'cleave.js/react'

import { Analytics } from '../utilities/Analytics'
import { digitalDeepLink } from '../actions/sms'

const DigitalDeepLink = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const { loggedIn } = useSelector((state) => state.session)

  const disableSubmit = phoneNumber.length !== 12

  useEffect(() => {
    Analytics.track('Saw Digital deep link send SMS form')
  }, [dispatch, loggedIn, history, params])

  const requestText = async (e) => {
    e.preventDefault()
    if (disableSubmit) return
    setSubmitted(true)
    Analytics.track('Submitted Digital deep link send SMS form')
    dispatch(digitalDeepLink(phoneNumber))
  }

  return (
    <Container>
      <Title>Download the Skylight App for access to Skylight Digital!</Title>
      {submitted ? (
        <Header>Thanks! You should receive a text shortly!</Header>
      ) : (
        <>
          <Header>Send app link to my phone</Header>
          <PhoneInput onSubmit={requestText}>
            <label htmlFor="phone" className="mb-1">
              Your mobile phone number
            </label>
            <Cleave
              type="phone"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="form-control"
              options={{ numericOnly: true, blocks: [3, 3, 4], delimiter: '-' }}
              placeholder="123-456-7891"
            />
          </PhoneInput>
          <button
            type="button"
            className="btn btn-primary"
            onClick={requestText}
            disabled={disableSubmit}
          >
            Send Skylight Digital App Link
          </button>
        </>
      )}
    </Container>
  )
}

DigitalDeepLink.propTypes = {}

export default DigitalDeepLink

const Container = styled.div`
  max-width: 650px;
  margin: 2.5rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h1`
  font-weight: bold;
  text-align: center;
  font-size: 2.75rem;
  @media (max-width: 650px) {
    font-size: 2rem;
  }
`

const GetSkylight = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  width: 100%;
`

GetSkylight.Text = styled.p`
  margin: 0 0 0 0.875rem;
`

const PhoneInput = styled.form`
  input {
    width: 350px;
  }
  margin-bottom: 20px;
`

const Header = styled.h2`
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
`
