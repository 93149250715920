import {
  CREATE_PLUS_PERMISSION_REQUEST,
  CREATE_PLUS_PERMISSION_SUCCESS,
  CREATE_PLUS_PERMISSION_FAILURE,
  PLUS_PERMISSION_SUCCESS,
  PLUS_PERMISSION_CLEAROUT,
} from '../actions/plusPermissions'

const DEFAULT_STATE = {
  creatingPlusPermission: false,
  createdPlusPermission: false,
  plusPermissionErrors: [],
}

export function plusPermissions(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case CREATE_PLUS_PERMISSION_REQUEST:
      return {
        ...state,
        creatingPlusPermission: true,
        createdPlusPermission: false,
        plusPermissionErrors: [],
      }
    case CREATE_PLUS_PERMISSION_SUCCESS:
      return {
        ...state,
        creatingPlusPermission: false,
        createdPlusPermission: true,
        plusPermissionErrors: [],
      }
    case CREATE_PLUS_PERMISSION_FAILURE:
      return {
        ...state,
        creatingPlusPermission: false,
        createdPlusPermission: false,
        plusPermissionErrors: action.errors,
      }
    case PLUS_PERMISSION_CLEAROUT:
      return {
        ...state,
        creatingPlusPermission: false,
        createdPlusPermission: false,
        plusPermissionErrors: [],
      }
    case PLUS_PERMISSION_SUCCESS: {
      const createdPlusPermission = action.response.data.attributes.subscription_status === 'plus'
      return { ...state, createdPlusPermission }
    }
    default:
      return state
  }
}
