import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class DuplicateDestroyModal extends Component {
  render() {
    const { dismiss, destroyOne, destroyDuplicates } = this.props
    return (
      <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Are you sure?</h5>
              <button type="button" className="close" aria-label="Close" onClick={dismiss}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Do you want to delete this photo from <b>all of your other frames</b> as well?
              </p>
              <p>This cannot be undone!</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-primary" onClick={dismiss}>
                Nevermind
              </button>
              <button type="button" className="btn btn-danger" onClick={destroyDuplicates}>
                Delete from All Frames
              </button>
              <button type="button" className="btn btn-warning" onClick={destroyOne}>
                Just this Frame
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

DuplicateDestroyModal.propTypes = {
  dismiss: PropTypes.func.isRequired,
  destroyOne: PropTypes.func.isRequired,
  destroyDuplicates: PropTypes.func.isRequired,
}
