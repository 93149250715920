export const DEVICE_GROUPS_REQUEST = 'DEVICE_GROUPS_REQUEST'
export const DEVICE_GROUPS_SUCCESS = 'DEVICE_GROUPS_SUCCESS'
export const DEVICE_GROUPS_FAILURE = 'DEVICE_GROUPS_FAILURE'

export function listDeviceGroups() {
  return {
    type: DEVICE_GROUPS_REQUEST,
    api: {
      endpoint: 'device_groups',
      isAdmin: true,
      authenticated: true,
      types: [DEVICE_GROUPS_REQUEST, DEVICE_GROUPS_SUCCESS, DEVICE_GROUPS_FAILURE],
      method: 'GET',
    },
  }
}

export const DEVICE_GROUP_DEPLOYMENT_REQUEST = 'DEVICE_GROUP_DEPLOYMENT_REQUEST'
export const DEVICE_GROUP_DEPLOYMENT_SUCCESS = 'DEVICE_GROUP_DEPLOYMENT_SUCCESS'
export const DEVICE_GROUP_DEPLOYMENT_FAILURE = 'DEVICE_GROUP_DEPLOYMENT_FAILURE'

export function createDeviceGroupDeployment(deviceGroupId, androidVersionId) {
  return {
    type: DEVICE_GROUP_DEPLOYMENT_REQUEST,
    api: {
      data: { android_version_id: androidVersionId },
      endpoint: `device_groups/${deviceGroupId}/deploy`,
      isAdmin: true,
      authenticated: true,
      types: [
        DEVICE_GROUP_DEPLOYMENT_REQUEST,
        DEVICE_GROUP_DEPLOYMENT_SUCCESS,
        DEVICE_GROUP_DEPLOYMENT_FAILURE,
      ],
      method: 'POST',
    },
  }
}

export const ADD_DEVICE_TO_GROUP_REQUEST = 'ADD_DEVICE_TO_GROUP_REQUEST'
export const ADD_DEVICE_TO_GROUP_SUCCESS = 'ADD_DEVICE_TO_GROUP_SUCCESS'
export const ADD_DEVICE_TO_GROUP_FAILURE = 'ADD_DEVICE_TO_GROUP_FAILURE'

export function addDeviceToGroup(deviceGroupId, deviceId) {
  return {
    type: ADD_DEVICE_TO_GROUP_REQUEST,
    api: {
      data: { device_id: deviceId },
      endpoint: `device_groups/${deviceGroupId}/add`,
      isAdmin: true,
      authenticated: true,
      types: [
        ADD_DEVICE_TO_GROUP_REQUEST,
        ADD_DEVICE_TO_GROUP_SUCCESS,
        ADD_DEVICE_TO_GROUP_FAILURE,
      ],
      method: 'POST',
    },
  }
}
