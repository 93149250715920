import {
  FRAMES_REQUEST, FRAMES_SUCCESS, FRAMES_FAILURE
} from '../frames'

export function list(userId){
  let endpoint = `users/${ userId }/frames`
  return {
    type: FRAMES_REQUEST,
    api: {
      endpoint,
      isAdmin: true,
      authenticated: true,
      types: [FRAMES_REQUEST, FRAMES_SUCCESS, FRAMES_FAILURE],
      method: 'GET'
    }
  }
}