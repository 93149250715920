import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Nav from './Nav'
import Errors from './Errors'

const PlusShare = ({
  email,
  setEmail,
  name,
  setName,
  sharePlus,
  sharingPlus,
  errors,
  finished,
}) => (
  <React.Fragment>
    <Nav loggedIn={false} isAdmin={false} />
    <div className="new-user-or-frame-ctn">
      <div className="register-box small-width-ctn">
        <h1>Linking Skylight Plus</h1>
        <Errors errors={errors} />
        {finished ? (
          <p>
            <b>Success!</b> You've shared your Plus account with {name}.
          </p>
        ) : (
          <form className="mt-4" onSubmit={sharePlus}>
            <div className="form-group">
              <label htmlFor="email">
                <b>Email you purchased Plus with</b>
              </label>
              <input
                type="email"
                placeholder="youremail@gmail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">
                <b>Frame name to enable Plus for</b>
              </label>
              <input
                type="text"
                placeholder="smithfamilyframe"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control"
              />
            </div>
            <code className="full-width">{name || 'smithfamilyframe'}@ourskylight.com</code>
            <div className="text-right pt-4">
              <button type="submit" className="btn btn-lg btn-success" disabled={sharingPlus}>
                {sharingPlus ? 'Linking...' : 'Link Plus'}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  </React.Fragment>
)

PlusShare.propTypes = {
  setEmail: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  sharingPlus: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  sharePlus: PropTypes.func.isRequired,
  finished: PropTypes.bool.isRequired,
}

export default PlusShare
