import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class NewGroceryItemModal extends Component {
  constructor(props) {
    super(props)
    this.state = { groceryItemText: '', className: 'modal fade' }
    this.changeText = this.changeText.bind(this)
    this.saveIfPresent = this.saveIfPresent.bind(this)
  }

  changeText(e) {
    this.setState({ groceryItemText: e.target.value })
  }

  saveIfPresent(e) {
    const { groceryItemText } = this.state
    const { save } = this.props
    if (groceryItemText.length > 1) save(groceryItemText)
    e.preventDefault()
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ className: 'modal fade show' })
      this.textInput.focus()
    }, 100)
  }

  render() {
    const { save, dismiss } = this.props
    const { groceryItemText, className } = this.state
    return (
      <div className={className} tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog" role="document">
          <form className="mt-4" onSubmit={this.saveIfPresent}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add a Grocery Item</h5>
                <button type="button" className="close" aria-label="Close" onClick={dismiss}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control mt-3"
                    ref={(input) => {
                      this.textInput = input
                    }}
                    value={groceryItemText}
                    onChange={this.changeText}
                    placeholder="Apples"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary pull-left" onClick={dismiss}>
                  Nevermind
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={groceryItemText.length == 0}
                  onClick={() => save(groceryItemText)}
                >
                  Add!
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

NewGroceryItemModal.propTypes = {
  save: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
}
