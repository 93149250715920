export const FRAMES_CREATE_REQUEST = 'FRAMES_CREATE_REQUEST'
export const FRAMES_CREATE_SUCCESS = 'FRAMES_CREATE_SUCCESS'
export const FRAMES_CREATE_FAILURE = 'FRAMES_CREATE_FAILURE'

export function create(args, isAdmin) {
  return {
    type: FRAMES_CREATE_REQUEST,
    api: {
      isAdmin,
      endpoint: 'frames',
      authenticated: true,
      types: [FRAMES_CREATE_REQUEST, FRAMES_CREATE_SUCCESS, FRAMES_CREATE_FAILURE],
      data: args,
      method: 'POST',
    },
  }
}

export const FRAMES_SHOW_REQUEST = 'FRAMES_SHOW_REQUEST'
export const FRAMES_SHOW_SUCCESS = 'FRAMES_SHOW_SUCCESS'
export const FRAMES_SHOW_FAILURE = 'FRAMES_SHOW_FAILURE'

export function show(id) {
  return {
    type: FRAMES_SHOW_REQUEST,
    api: {
      endpoint: ['frames', id].join('/'),
      authenticated: true,
      types: [FRAMES_SHOW_REQUEST, FRAMES_SHOW_SUCCESS, FRAMES_SHOW_FAILURE],
      method: 'GET',
    },
  }
}

export const FRAMES_SHOW_META_REQUEST = 'FRAMES_SHOW_META_REQUEST'
export const FRAMES_SHOW_META_SUCCESS = 'FRAMES_SHOW_META_SUCCESS'
export const FRAMES_SHOW_META_FAILURE = 'FRAMES_SHOW_META_FAILURE'

export function showMeta(id) {
  return {
    type: FRAMES_SHOW_META_REQUEST,
    api: {
      endpoint: ['frames', id, 'meta'].join('/'),
      authenticated: true,
      types: [FRAMES_SHOW_META_REQUEST, FRAMES_SHOW_META_SUCCESS, FRAMES_SHOW_META_FAILURE],
      method: 'GET',
    },
  }
}

export const FRAMES_REQUEST = 'FRAMES_REQUEST'
export const FRAMES_SUCCESS = 'FRAMES_SUCCESS'
export const FRAMES_FAILURE = 'FRAMES_FAILURE'

export function list(isAdmin, searchQuery, page, showDeleted = true) {
  let endpoint = 'frames?'
  if (searchQuery) endpoint += `q=${encodeURIComponent(searchQuery)}&`
  endpoint += `show_deleted=${showDeleted}&`
  if (page) endpoint += `page=${page}`

  return {
    type: FRAMES_REQUEST,
    api: {
      isAdmin,
      endpoint,
      authenticated: true,
      types: [FRAMES_REQUEST, FRAMES_SUCCESS, FRAMES_FAILURE],
      method: 'GET',
    },
  }
}

export const FRAMES_UPDATE_REQUEST = 'FRAMES_UPDATE_REQUEST'
export const FRAMES_UPDATE_SUCCESS = 'FRAMES_UPDATE_SUCCESS'
export const FRAMES_UPDATE_FAILURE = 'FRAMES_UPDATE_FAILURE'

export const FRAMES_PRIVACY_UPDATE_FINISHED = 'FRAMES_PRIVACY_UPDATE_FINISHED'

export function update(id, data) {
  return {
    type: FRAMES_UPDATE_REQUEST,
    api: {
      data,
      endpoint: ['frames', id].join('/'),
      authenticated: true,
      types: [FRAMES_UPDATE_REQUEST, FRAMES_UPDATE_SUCCESS, FRAMES_UPDATE_FAILURE],
      method: 'PUT',
    },
  }
}

export const FRAMES_RESET_REQUEST = 'FRAMES_TOGGLE_RESET_REQUEST'
export const FRAMES_RESET_SUCCESS = 'FRAMES_TOGGLE_RESET_SUCCESS'
export const FRAMES_RESET_FAILURE = 'FRAMES_TOGGLE_RESET_FAILURE'

export function reset(id) {
  return {
    type: FRAMES_RESET_REQUEST,
    api: {
      isAdmin: true,
      endpoint: ['frames', id, 'reset'].join('/'),
      authenticated: true,
      types: [FRAMES_RESET_REQUEST, FRAMES_RESET_SUCCESS, FRAMES_RESET_FAILURE],
      method: 'POST',
    },
  }
}

export const FRAMES_REFRESH_REQUEST = 'FRAMES_REFRESH_REQUEST'
export const FRAMES_REFRESH_SUCCESS = 'FRAMES_REFRESH_SUCCESS'
export const FRAMES_REFRESH_FAILURE = 'FRAMES_REFRESH_FAILURE'

export function refresh(id) {
  return {
    type: FRAMES_RESET_REQUEST,
    api: {
      isAdmin: true,
      endpoint: ['frames', id, 'refresh'].join('/'),
      authenticated: true,
      types: [FRAMES_REFRESH_REQUEST, FRAMES_REFRESH_SUCCESS, FRAMES_REFRESH_FAILURE],
      method: 'POST',
    },
  }
}

export const CLEAR_ALL_MESSAGES_REQUEST = 'CLEAR_ALL_MESSAGES_REQUEST'
export const CLEAR_ALL_MESSAGES_SUCCESS = 'CLEAR_ALL_MESSAGES_SUCCESS'
export const CLEAR_ALL_MESSAGES_FAILURE = 'CLEAR_ALL_MESSAGES_FAILURE'

export function clearAllMessages() {
  return {
    type: CLEAR_ALL_MESSAGES_REQUEST,
    api: {
      endpoint: 'frames/clear_all',
      authenticated: true,
      types: [CLEAR_ALL_MESSAGES_REQUEST, CLEAR_ALL_MESSAGES_SUCCESS, CLEAR_ALL_MESSAGES_FAILURE],
      method: 'POST',
    },
  }
}

export const UPDATE_SELECTED_FRAMES = 'UPDATE_SELECTED_FRAMES'

export const FRAMES_MESSAGE_COUNTS_REQUEST = 'FRAMES_MESSAGE_COUNTS_REQUEST'
export const FRAMES_MESSAGE_COUNTS_SUCCESS = 'FRAMES_MESSAGE_COUNTS_SUCCESS'
export const FRAMES_MESSAGE_COUNTS_FAILURE = 'FRAMES_MESSAGE_COUNTS_FAILURE'

export function messageCountsShow(id) {
  return {
    type: FRAMES_MESSAGE_COUNTS_REQUEST,
    api: {
      isAdmin: true,
      endpoint: `frames/${id}/message_counts`,
      authenticated: true,
      types: [
        FRAMES_MESSAGE_COUNTS_REQUEST,
        FRAMES_MESSAGE_COUNTS_SUCCESS,
        FRAMES_MESSAGE_COUNTS_FAILURE,
      ],
      method: 'GET',
    },
  }
}

export const FRAMES_COPY_MESSAGES_REQUEST = 'FRAMES_COPY_MESSAGES_REQUEST'
export const FRAMES_COPY_MESSAGES_SUCCESS = 'FRAMES_COPY_MESSAGES_SUCCESS'
export const FRAMES_COPY_MESSAGES_FAILURE = 'FRAMES_COPY_MESSAGES_FAILURE'

export function copyMessagesToFrames(id, selectedMessageIds, selectedFrameIds) {
  return {
    type: FRAMES_COPY_MESSAGES_REQUEST,
    api: {
      isAdmin: false,
      endpoint: `frames/${id}/copy_to_frames`,
      authenticated: true,
      data: {
        message_ids: selectedMessageIds,
        new_frame_ids: selectedFrameIds,
      },
      types: [
        FRAMES_COPY_MESSAGES_REQUEST,
        FRAMES_COPY_MESSAGES_SUCCESS,
        FRAMES_COPY_MESSAGES_FAILURE,
      ],
      method: 'POST',
    },
  }
}

export const CLEAR_LOCAL_FRAMES = 'CLEAR_LOCAL_FRAMES'

export function clearLocalFrames() {
  return {
    type: CLEAR_LOCAL_FRAMES,
  }
}

export const FRAME_ACTIVATION_CODE_REQUEST = 'FRAME_ACTIVATION_CODE_REQUEST'
export const FRAME_ACTIVATION_CODE_SUCCESS = 'FRAME_ACTIVATION_CODE_SUCCESS'
export const FRAME_ACTIVATION_CODE_FAILURE = 'FRAME_ACTIVATION_CODE_FAILURE'

export function getActivationCode(id) {
  return {
    type: FRAME_ACTIVATION_CODE_REQUEST,
    api: {
      isAdmin: false,
      endpoint: `frames/${id}/activation_code`,
      authenticated: true,
      types: [
        FRAME_ACTIVATION_CODE_REQUEST,
        FRAME_ACTIVATION_CODE_SUCCESS,
        FRAME_ACTIVATION_CODE_FAILURE,
      ],
      method: 'POST',
    },
  }
}
