import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Notifs extends Component {
  render() {
    const { notifs } = this.props
    if (notifs.length == 0) return null
    return (
      <div className="alert alert-primary" role="alert">
        {notifs.join('. ')}
      </div>
    )
  }
}

Notifs.propTypes = {
  notifs: PropTypes.array.isRequired,
}
