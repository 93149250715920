import {
  INVITATION_REQUEST,
  INVITATION_SUCCESS,
  INVITATION_FAILURE,
  RESET_INVITATION_REQUEST_STATUS,
} from '../actions/sms'

const DEFAULT_STATE = {
  invitationRequestStatus: 'uninitiated',
  gettingInvitation: false,
}

export function sms(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case INVITATION_REQUEST: {
      return { ...state, gettingInvitation: true }
    }
    case INVITATION_SUCCESS: {
      return { ...state, gettingInvitation: false, invitationRequestStatus: 'success' }
    }
    case INVITATION_FAILURE: {
      return { ...state, gettingInvitation: false, invitationRequestStatus: 'fail' }
    }
    case RESET_INVITATION_REQUEST_STATUS: {
      return { ...state, gettingInvitation: false, invitationRequestStatus: 'uninitiated' }
    }
    default: {
      return { ...state }
    }
  }
}
