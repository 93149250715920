import {
  WEBCAL_ACCOUNTS_REQUEST,
  WEBCAL_ACCOUNTS_SUCCESS,
  WEBCAL_ACCOUNTS_DESTROY_SUCCESS,
  WEBCAL_ACCOUNTS_CREATE_REQUEST,
  WEBCAL_ACCOUNTS_CREATE_SUCCESS,
  WEBCAL_ACCOUNTS_CREATE_FAILURE,
} from '../actions/webcalAccounts'

const DEFAULT_STATE = {
  gettingWebcalAccounts: true,
  webcalAccounts: [],
  savedWebcalLink: false,
  webcalLinkSaveErrors: [],
}

export function webcalAccounts(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case WEBCAL_ACCOUNTS_REQUEST:
      return Object.assign({}, state, DEFAULT_STATE)
    case WEBCAL_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        gettingWebcalAccounts: false,
        webcalAccounts: action.response.data,
      })
    case WEBCAL_ACCOUNTS_DESTROY_SUCCESS:
      let destroyedAccount = action.response.data
      let remainingAccounts = []
      state.webcalAccounts.forEach((c) => {
        if (c.id != destroyedAccount.id) remainingAccounts.push(c)
      })
      return Object.assign({}, state, { webcalAccounts: remainingAccounts })
    case WEBCAL_ACCOUNTS_CREATE_REQUEST:
      return Object.assign({}, state, { savedWebcalLink: false, webcalLinkSaveErrors: [] })
    case WEBCAL_ACCOUNTS_CREATE_SUCCESS:
      return Object.assign({}, state, { savedWebcalLink: true, webcalLinkSaveErrors: [] })
    case WEBCAL_ACCOUNTS_CREATE_FAILURE:
      return Object.assign({}, state, {
        savedWebcalLink: false,
        webcalLinkSaveErrors: action.errors,
      })
    default:
      return state
  }
}
