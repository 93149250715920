export const FLEET_HEALTHS_REQUEST = 'FLEET_HEALTHS_REQUEST'
export const FLEET_HEALTHS_SUCCESS = 'FLEET_HEALTHS_SUCCESS'
export const FLEET_HEALTHS_FAILURE = 'FLEET_HEALTHS_FAILURE'

export function list() {
  return {
    type: FLEET_HEALTHS_REQUEST,
    api: {
      endpoint: 'fleet_health_summaries',
      isAdmin: true,
      authenticated: true,
      types: [FLEET_HEALTHS_REQUEST, FLEET_HEALTHS_SUCCESS, FLEET_HEALTHS_FAILURE],
      method: 'GET',
    },
  }
}

export const FLEET_HEALTH_COMPARISON_CLEAROUT = 'FLEET_HEALTH_COMPARISON_CLEAROUT'

export const FLEET_HEALTHS_EXPERIMENTAL_REQUEST = 'FLEET_HEALTHS_EXPERIMENTAL_REQUEST'
export const FLEET_HEALTHS_EXPERIMENTAL_SUCCESS = 'FLEET_HEALTHS_EXPERIMENTAL_SUCCESS'
export const FLEET_HEALTHS_EXPERIMENTAL_FAILURE = 'FLEET_HEALTHS_EXPERIMENTAL_FAILURE'

export function showExperimental(id) {
  return {
    type: FLEET_HEALTHS_EXPERIMENTAL_REQUEST,
    api: {
      endpoint: `fleet_health_summaries/${id}`,
      isAdmin: true,
      authenticated: true,
      types: [
        FLEET_HEALTHS_EXPERIMENTAL_REQUEST,
        FLEET_HEALTHS_EXPERIMENTAL_SUCCESS,
        FLEET_HEALTHS_EXPERIMENTAL_FAILURE,
      ],
      method: 'GET',
    },
  }
}

export const FLEET_HEALTHS_CONTROL_REQUEST = 'FLEET_HEALTHS_CONTROL_REQUEST'
export const FLEET_HEALTHS_CONTROL_SUCCESS = 'FLEET_HEALTHS_CONTROL_SUCCESS'
export const FLEET_HEALTHS_CONTROL_FAILURE = 'FLEET_HEALTHS_CONTROL_FAILURE'

export function showControl(id) {
  return {
    type: FLEET_HEALTHS_CONTROL_REQUEST,
    api: {
      endpoint: `fleet_health_summaries/${id}`,
      isAdmin: true,
      authenticated: true,
      types: [
        FLEET_HEALTHS_CONTROL_REQUEST,
        FLEET_HEALTHS_CONTROL_SUCCESS,
        FLEET_HEALTHS_CONTROL_FAILURE,
      ],
      method: 'GET',
    },
  }
}
