import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import SearchHeader from './SearchHeader'
import Loading from './Loading'
import TransferModal from './admin/TransferModal'
import Errors from './Errors'
import { transferFrameNewUser } from '../actions/admin/frameMigration'
import { Analytics } from '../utilities/Analytics'
import CodeModal from './admin/CodeModal'
import { getActivationCode } from '../actions/frames'

export default class Frames extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searching: false,
      frameToTransfer: null,
      frameToGetCode: null,
    }
    this.toggleSearch = this.toggleSearch.bind(this)
    this.transferModal = this.transferModal.bind(this)
    this.codeModal = this.codeModal.bind(this)
    this.dismissCodeModal = this.dismissCodeModal.bind(this)
    this.dismissTransferModal = this.dismissTransferModal.bind(this)
    this.transferToNewUser = this.transferToNewUser.bind(this)
  }

  transferModal(frame) {
    this.setState({ frameToTransfer: frame })
  }

  codeModal(frame) {
    const { dispatch } = this.props
    dispatch(getActivationCode(frame.id))
    this.setState({ frameToGetCode: frame })
  }

  dismissTransferModal() {
    this.setState({ frameToTransfer: null })
  }

  dismissCodeModal() {
    this.setState({ frameToGetCode: null })
  }

  transferToNewUser(id, email) {
    const { dispatch } = this.props
    dispatch(transferFrameNewUser(id, email, false))
  }

  toggleSearch() {
    this.setState((prevState) => ({
      searching: !prevState.searching,
    }))
  }

  renderButtons() {
    const { subscriptionStatus, justBoughtPlus } = this.props
    return (
      <>
        <Link to="/frames/new" className="btn btn-success">
          Activate New Device
        </Link>
        {subscriptionStatus !== 'plus' && !justBoughtPlus && (
          <Link
            to="/link-plus-account"
            className="ml-2 btn upgrade-to-plus-btn"
            onClick={() => Analytics.track('Clicked Link Plus Account From Homepage')}
          >
            Link Plus Account
          </Link>
        )}
      </>
    )
  }

  render() {
    const {
      frames,
      hasBulkFeatures,
      toggleClearingAll,
      loading,
      search,
      updateSearchQuery,
      searchQuery,
      lastSearched,
      errors,
      activationCode,
    } = this.props
    const { searching, frameToTransfer, frameToGetCode } = this.state

    return (
      <div>
        <Errors errors={errors} />
        {frameToTransfer && (
          <TransferModal
            frame={frameToTransfer}
            dismiss={this.dismissTransferModal}
            transferToNewUser={this.transferToNewUser}
          />
        )}
        {frameToGetCode && (
          <CodeModal
            frame={frameToGetCode}
            activationCode={activationCode}
            dismiss={this.dismissCodeModal}
          />
        )}
        <div className="row align-items-center">
          <div className="col-7">
            <h2>My Devices</h2>
          </div>
          {hasBulkFeatures ? (
            <div className="col-5 text-right">
              {searching ? (
                <SearchHeader
                  listFunc={search}
                  searchQuery={searchQuery}
                  updateSearchQuery={updateSearchQuery}
                  cancelFunc={this.toggleSearch}
                  title="Search Frames"
                />
              ) : (
                <div className="btn-group" role="group">
                  <button type="button" className="btn btn-info" onClick={this.toggleSearch}>
                    <span className="oi oi-magnifying-glass" title="Search" aria-hidden="true" />{' '}
                    Search
                  </button>
                  <button type="button" className="btn btn-danger" onClick={toggleClearingAll}>
                    <span className="oi oi-trash" title="Clear All Frames" aria-hidden="true" />{' '}
                    Clear All
                  </button>
                  <Link to="/messages/new" className="btn btn-primary">
                    <span className="oi oi-image" title="Send to All Frames" aria-hidden="true" />{' '}
                    Send
                  </Link>
                </div>
              )}
            </div>
          ) : (
            <div className="col-5 text-right d-none d-sm-block">{this.renderButtons()}</div>
          )}
        </div>
        <div className="row d-xs-inline-block d-sm-none">
          <div className="col-12">{this.renderButtons()}</div>
        </div>
        <hr />
        {loading ? (
          <Loading />
        ) : (
          <div>
            {frames.map((frame) => {
              const calendar = frame.attributes.apps[0] === 'calendar'
              const action = calendar ? 'Share events' : 'Email photos'
              return (
                <div key={frame.id}>
                  <div className="row align-items-center">
                    <div className="col-6">
                      <h4 className="mb-0">
                        <Link to={`/frames/${frame.id}/messages`}>{frame.attributes.name}</Link>{' '}
                        {frame.attributes.plus && (
                          <small>
                            <span className="plus-label">plus</span>
                          </small>
                        )}
                        {!frame.attributes.plus && frame.attributes.trialing && (
                          <small>
                            <span className="trial-label">plus trial</span>
                          </small>
                        )}
                      </h4>
                      <p className="mb-0">
                        <small className="text-muted">
                          {action} to <b>{frame.attributes.name}@ourskylight.com</b>
                        </small>
                      </p>
                      {!frame.attributes.activated && frame.attributes.mine && (
                        <Link to="#" onClick={() => this.codeModal(frame)}>
                          Get Activation Code
                        </Link>
                      )}
                    </div>
                    {frame.attributes.mine ? (
                      <div className="col-6">
                        {!calendar && (
                          <p className="text-right privacy-mgmt-links mb-0">
                            <Link to={`/frames/${frame.id}/privacy?updating=true`}>
                              {frame.attributes.open_to_public ? 'Open to Public' : 'Private'}
                            </Link>
                            {!frame.attributes.open_to_public && (
                              <>
                                &nbsp;|{' '}
                                <Link to={`/frames/${frame.id}/friends?updating=true`}>
                                  Manage Senders
                                </Link>
                              </>
                            )}
                            <br />
                            <Link to="#" onClick={() => this.transferModal(frame)}>
                              Transfer Frame Ownership
                            </Link>
                          </p>
                        )}
                        {calendar && (
                          <>
                            <p className="text-right">
                              <Link to={`/frames/${frame.id}/messages?tab=calendars`}>
                                <span
                                  className="oi oi-loop-circular"
                                  title="Re-Sync Calendar"
                                  aria-hidden="true"
                                />{' '}
                                Manage Synced Calendars
                              </Link>
                            </p>
                            <p className="text-right">
                              <Link to="#" onClick={() => this.transferModal(frame)}>
                                Transfer Calendar Ownership
                              </Link>
                            </p>
                            <p className="text-right">
                              <Link to={`/frames/${frame.id}/messages?tab=photos`}>
                                <span
                                  className="oi oi-image"
                                  title="Manage Slideshow Photos"
                                  aria-hidden="true"
                                />{' '}
                                Manage Slideshow Photos
                              </Link>
                            </p>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="col-6" />
                    )}
                  </div>
                </div>
              )
            })}
            {frames.length === 0 && (
              <div className="mt-5">
                <h4 className="text-center text-muted">
                  No frames matched your search for "{lastSearched}"
                </h4>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

Frames.propTypes = {
  frames: PropTypes.array,
  dispatch: PropTypes.func,
  hasBulkFeatures: PropTypes.bool.isRequired,
  toggleClearingAll: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  search: PropTypes.func.isRequired,
  updateSearchQuery: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  lastSearched: PropTypes.string.isRequired,
  subscriptionStatus: PropTypes.string,
  justBoughtPlus: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  activationCode: PropTypes.string,
}
