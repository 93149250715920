import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class ClearAllFramesModal extends Component {
  constructor(props) {
    super(props)
    this.state = { className: 'modal fade' }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ className: 'modal fade show' })
    }, 100)
  }

  render() {
    const { clearAll, dismiss } = this.props
    const { className } = this.state
    return (
      <div className={className} tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Are you sure?</h5>
              <button type="button" className="close" aria-label="Close" onClick={dismiss}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Are you sure you want to remove <b>all photos</b> from <b>all of your frames</b>?
              </p>
              <p>This cannot be undone!</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-primary pull-left" onClick={dismiss}>
                Nevermind
              </button>
              <button type="button" className="btn btn-danger" onClick={clearAll}>
                Clear All Frames
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ClearAllFramesModal.propTypes = {
  clearAll: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
}
