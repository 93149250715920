import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class MigrationModal extends Component {
  constructor(props) {
    super(props)
    this.state = { newFrame: false, newFrameName: '', sourceFrameName: '', migrationIntent: 'restoreFromAncestors' }
    this.changeFrameName = this.changeFrameName.bind(this)
    this.changeSourceFrameName = this.changeSourceFrameName.bind(this)
    this.setMigrationIntent = this.setMigrationIntent.bind(this)
    this.migrate = this.migrate.bind(this)
  }

  changeFrameName(e) {
    this.setState({ newFrameName: e.target.value })
  }

  changeSourceFrameName(e) {
    this.setState({ sourceFrameName: e.target.value })
  }

  setMigrationIntent(e) {
    this.setState({ migrationIntent: e.target.value })
  }

  migrate() {
    const { frame, dismiss, copyToNewFrame, migrateToSameFrameName, restoreFromSourceFrameAncestors } = this.props
    const { newFrameName, migrationIntent, sourceFrameName } = this.state

    switch (migrationIntent) {
      case 'restoreFromAncestors':
        migrateToSameFrameName(frame.id)
        break
      case 'migrateAssetsToAnotherFrame':
        copyToNewFrame(frame.id, newFrameName)
        break
      case 'restoreFromSourceFrameAncestor':
        restoreFromSourceFrameAncestors(frame.id, sourceFrameName)
        break
    }

    dismiss()
  }

  render() {
    const { dismiss, frame } = this.props
    const { sourceFrameName, newFrameName, migrationIntent } = this.state
    const isCal = frame.attributes.apps.indexOf('calendar') !== -1
    return (
      <div
        id="migration-modal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Migrate {isCal ? 'Events' : 'Photos'} from {frame.attributes.name}
              </h5>
              <button type="button" className="close" aria-label="Close" onClick={dismiss}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you migrating to a new {isCal ? 'Calendar' : 'Frame'}?</p>
              <div className="form-check mt-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="migrationType"
                  aria-label="Same Frame"
                  value="restoreFromAncestors"
                  onChange={this.setMigrationIntent}
                  checked={migrationIntent == 'restoreFromAncestors'}
                />
                <label className="form-check-label" htmlFor="Same Frame">
                  <button
                    style={{ background: 'none', border: 'none' }}
                    type="button"
                    onClick={() =>
                      this.setMigrationIntent({ target: { value: 'restoreFromAncestors' } })
                    }
                  >
                    <b>No</b>{' '}
                    <small>
                      Just restore from the <b>deleted</b> versions of{' '}
                      <b>{frame.attributes.name}</b>
                    </small>
                  </button>
                </label>
              </div>
              <hr />
              {/* Second option */}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="migrationType"
                  aria-label="restore from source frame ancestor"
                  value="restoreFromSourceFrameAncestor"
                  onChange={this.setMigrationIntent}
                  checked={migrationIntent == 'restoreFromSourceFrameAncestor'}
                />
                <label className="form-check-label" htmlFor="restore from source frame ancestor">
                  <button
                    style={{ background: 'none', border: 'none' }}
                    type="button"
                    onClick={() =>
                      this.setMigrationIntent({
                        target: { value: 'restoreFromSourceFrameAncestor' },
                      })
                    }
                  >
                    <b>No</b>{' '}
                    <small>
                      Restore from a <b>deleted</b> version of a {isCal ? 'Calendar' : 'Frame'}
                    </small>
                    <div>
                      <small>(requires special admin clearance)</small>
                    </div>
                  </button>
                </label>
              </div>
              <div>
                {migrationIntent == 'restoreFromSourceFrameAncestor' && (
                  <input
                    type="text"
                    className="form-control mt-4"
                    value={sourceFrameName}
                    aria-label="Source Frame Name"
                    onChange={this.changeSourceFrameName}
                    placeholder={`Deleted ${isCal ? 'Calendar' : 'Frame'} Name`}
                  />
                )}
              </div>
              {/* End of second option */}
              <hr />
              {/* Third option */}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="migrationType"
                  aria-label="New Frame"
                  value="migrateAssetsToAnotherFrame"
                  onChange={this.setMigrationIntent}
                  checked={migrationIntent == 'migrateAssetsToAnotherFrame'}
                />
                <label className="form-check-label" htmlFor="New Frame">
                  <button
                    style={{ background: 'none', border: 'none' }}
                    type="button"
                    onClick={() =>
                      this.setMigrationIntent({ target: { value: 'migrateAssetsToAnotherFrame' } })
                    }
                  >
                    <b>Yes</b>{' '}
                    <small>
                      Send from <b>{frame.attributes.name}</b> to{' '}
                      <b>{newFrameName || `a New ${isCal ? 'Calendar' : 'Frame'}`}</b>
                    </small>
                  </button>
                </label>
              </div>
              {migrationIntent == 'migrateAssetsToAnotherFrame' && (
                <input
                  type="text"
                  className="form-control mt-4"
                  value={newFrameName}
                  aria-label="New Frame Name"
                  onChange={this.changeFrameName}
                  placeholder={`New ${isCal ? 'Calendar' : 'Frame'} Name`}
                />
              )}
            </div>
            {/* End of third option */}
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary pull-left" onClick={dismiss}>
                Nevermind
              </button>
              <button type="button" className="btn btn-primary" onClick={this.migrate}>
                Migrate!
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

MigrationModal.propTypes = {
  frame: PropTypes.object.isRequired,
  dismiss: PropTypes.func.isRequired,
  copyToNewFrame: PropTypes.func.isRequired,
  restoreFromSourceFrameAncestors: PropTypes.func.isRequired,
  migrateToSameFrameName: PropTypes.func.isRequired,
}
