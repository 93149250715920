export const GROCERY_ITEMS_CREATE_REQUEST = 'GROCERY_ITEMS_CREATE_REQUEST'
export const GROCERY_ITEMS_CREATE_SUCCESS = 'GROCERY_ITEMS_CREATE_SUCCESS'
export const GROCERY_ITEMS_CREATE_FAILURE = 'GROCERY_ITEMS_CREATE_FAILURE'

export function create(frameId, data) {
  let endpoint = `frames/${frameId}/grocery_items`
  return {
    type: GROCERY_ITEMS_CREATE_REQUEST,
    api: {
      data,
      endpoint,
      authenticated: true,
      types: [
        GROCERY_ITEMS_CREATE_REQUEST,
        GROCERY_ITEMS_CREATE_SUCCESS,
        GROCERY_ITEMS_CREATE_FAILURE,
      ],
      method: 'POST',
    },
  }
}

export const GROCERY_ITEMS_REQUEST = 'GROCERY_ITEMS_REQUEST'
export const GROCERY_ITEMS_SUCCESS = 'GROCERY_ITEMS_SUCCESS'
export const GROCERY_ITEMS_FAILURE = 'GROCERY_ITEMS_FAILURE'

export function list(frameId) {
  let endpoint = `frames/${frameId}/grocery_items`
  return {
    type: GROCERY_ITEMS_REQUEST,
    api: {
      endpoint,
      authenticated: true,
      types: [GROCERY_ITEMS_REQUEST, GROCERY_ITEMS_SUCCESS, GROCERY_ITEMS_FAILURE],
      method: 'GET',
    },
  }
}

export const GROCERY_ITEMS_UPDATE_REQUEST = 'GROCERY_ITEMS_UPDATE_REQUEST'
export const GROCERY_ITEMS_UPDATE_SUCCESS = 'GROCERY_ITEMS_UPDATE_SUCCESS'
export const GROCERY_ITEMS_UPDATE_FAILURE = 'GROCERY_ITEMS_UPDATE_FAILURE'

export function update(frameId, id, data) {
  let endpoint = `frames/${frameId}/grocery_items/${id}`
  return {
    type: GROCERY_ITEMS_UPDATE_REQUEST,
    api: {
      data,
      endpoint,
      authenticated: true,
      types: [
        GROCERY_ITEMS_UPDATE_REQUEST,
        GROCERY_ITEMS_UPDATE_SUCCESS,
        GROCERY_ITEMS_UPDATE_FAILURE,
      ],
      method: 'PUT',
    },
  }
}
