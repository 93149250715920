import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class UpdateCaptionModal extends Component {
  constructor(props) {
    super(props)
    this.state = { captionText: props.currentText }
  }

  render() {
    const { captionText } = this.state
    const { dismiss, destroyCaption, updateCaption } = this.props
    return (
      <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Caption</h5>
              <button type="button" className="close" aria-label="Close" onClick={dismiss}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>What do you want the caption to say?</p>
              <input
                type="text"
                value={captionText}
                onChange={(e) => this.setState({ captionText: e.target.value })}
                className="form-control"
                placeholder="Enter your caption here"
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-primary" onClick={dismiss}>
                Nevermind
              </button>
              <button type="button" className="btn btn-danger" onClick={destroyCaption}>
                Delete Caption
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => updateCaption(captionText)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

UpdateCaptionModal.propTypes = {
  currentText: PropTypes.string,
  dismiss: PropTypes.func.isRequired,
  destroyCaption: PropTypes.func.isRequired,
  updateCaption: PropTypes.func.isRequired,
}
