export const SPAM_APPROVAL_REQUEST = 'SPAM_APPROVAL_REQUEST'
export const SPAM_APPROVAL_SUCCESS = 'SPAM_APPROVAL_SUCCESS'
export const SPAM_APPROVAL_FAILURE = 'SPAM_APPROVAL_FAILURE'

export function spamApproval(id) {
  return {
    type: SPAM_APPROVAL_REQUEST,
    api: {
      endpoint: `messages/${id}/spam_approval`,
      isAdmin: true,
      authenticated: true,
      types: [SPAM_APPROVAL_REQUEST, SPAM_APPROVAL_SUCCESS, SPAM_APPROVAL_FAILURE],
      method: 'POST',
    },
  }
}

export const ADMIN_MESSAGE_SHOW_REQUEST = 'ADMIN_MESSAGE_SHOW_REQUEST'
export const ADMIN_MESSAGE_SHOW_SUCCESS = 'ADMIN_MESSAGE_SHOW_SUCCESS'
export const ADMIN_MESSAGE_SHOW_FAILURE = 'ADMIN_MESSAGE_SHOW_FAILURE'

export function show(id) {
  return {
    type: ADMIN_MESSAGE_SHOW_REQUEST,
    api: {
      endpoint: `messages/${id}`,
      isAdmin: true,
      authenticated: true,
      types: [ADMIN_MESSAGE_SHOW_REQUEST, ADMIN_MESSAGE_SHOW_SUCCESS, ADMIN_MESSAGE_SHOW_FAILURE],
      method: 'GET',
    },
  }
}
