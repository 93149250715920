import { LOG_OUT } from '../actions/session'

import {
  PASSWORD_RESETS_UNFLAG_SUCCESS,
  PASSWORD_RESETS_REQUEST,
  PASSWORD_RESETS_SUCCESS,
  PASSWORD_RESETS_FAILURE,
} from '../actions/passwordResets'

const DEFAULT_STATE = {
  requestingReset: false,
  errors: [],
  success: false,
}

export function passwordResets(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case PASSWORD_RESETS_UNFLAG_SUCCESS:
      return Object.assign({}, state, {
        requestingReset: false,
        errors: [],
        success: false,
      })
    case PASSWORD_RESETS_REQUEST:
      return Object.assign({}, state, {
        requestingReset: true,
        errors: [],
        success: false,
      })
    case PASSWORD_RESETS_SUCCESS:
      return Object.assign({}, state, {
        requestingReset: false,
        errors: [],
        success: true,
      })
    case PASSWORD_RESETS_FAILURE:
      return Object.assign({}, state, {
        requestingReset: false,
        errors: action.errors,
        success: false,
      })
    case LOG_OUT:
      return Object.assign({}, state, DEFAULT_STATE)
    default:
      return state
  }
}
