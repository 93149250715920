const TESTS = {
  upsell: ['on', 'off'],
}
const randomValue = (possibleValues) => {
  const randomIndex = Math.floor(Math.random() * possibleValues.length)
  return possibleValues[randomIndex]
}
export const ABTestGroup = {
  forKey: (key) => {
    const test = TESTS[key]
    if (!test) throw new Error(`Invalid a/b test key! ${key}`)
    const namespacedKey = `abTest:${key}`
    const existing = localStorage.getItem(namespacedKey)
    if (existing) return existing
    const newValue = randomValue(test)
    localStorage.setItem(namespacedKey, newValue)
    return newValue
  },
}
