import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import styled from 'styled-components'
import FullStory from 'react-fullstory'

import { Link } from 'react-router-dom'

import { Analytics } from '../utilities/Analytics'
import { UrlParams } from '../utilities/UrlParams'
import googleSignIn from '../../assets/images/google-sign-in.png'

import { create } from '../actions/webcalAccounts'
import { getNylasUrl } from '../actions/calendars'

import Loading from './Loading'
import Errors from './Errors'

export default class SyncCalendar extends Component {
  constructor(props) {
    super(props)
    const urlParams = UrlParams.get()
    const showingSyncForm = !!urlParams.showing_sync_form
    const email = urlParams.email || props.frame.attributes.notification_email
    let webcalVsNylas = !!urlParams.email ? 'nylas' : null
    if (urlParams.provider) webcalVsNylas = urlParams.provider
    this.state = {
      email,
      showingSyncForm,
      webcalVsNylas,
      webcalLink: '',
      hideNoThanks: showingSyncForm,
      loading: false,
      savingWebcalLink: false,
      googleScope: props.isAdmin ? 'calendar' : 'calendar.readonly',
    }
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleWebcalLinkChange = this.handleWebcalLinkChange.bind(this)
    this.handleEmailProviderChange = this.handleEmailProviderChange.bind(this)
    this.toggleForm = this.toggleForm.bind(this)
    this.goToGoogle = this.goToGoogle.bind(this)
    this.webcalOrNylasForm = this.webcalOrNylasForm.bind(this)
    this.saveWebcalLink = this.saveWebcalLink.bind(this)
    this.goToStep = this.goToStep.bind(this)
    this.setTwoWaySyncAndGoToGmailStepTwo = this.setTwoWaySyncAndGoToGmailStepTwo.bind(this)
  }

  componentDidMount() {
    Analytics.track('Saw Calendar Sync Option')
    window.scrollTo(0, 0)
  }

  componentDidUpdate() {
    const { history, savedWebcalLink, webcalLinkSaveErrors, frame, nylasUrl } = this.props
    const { savingWebcalLink } = this.state
    if (savingWebcalLink) {
      if (savedWebcalLink) {
        this.setState({ savingWebcalLink: false })
        history.push(`/frames/${frame.id}/sync-success?finished=true`)
      } else if (webcalLinkSaveErrors.length > 0) {
        this.setState({ savingWebcalLink: false })
      }
    }

    if (nylasUrl) {
      this.setState({ loading: false })
      window.location = nylasUrl
    }
  }

  setTwoWaySyncAndGoToGmailStepTwo() {
    this.setState({ googleScope: 'calendar' })
    this.goToStep('gmail')
  }

  goToStep(webcalVsNylas) {
    this.setState({ webcalVsNylas })
    window.scrollTo(0, 0)
  }

  saveWebcalLink() {
    const { dispatch, frame } = this.props
    const { webcalLink } = this.state
    dispatch(create(frame.id, webcalLink))
    this.setState({ savingWebcalLink: true })
  }

  webcalOrNylasForm() {
    const { frame, isAdmin, webcalLinkSaveErrors, history } = this.props
    const {
      email,
      webcalVsNylas,
      webcalLink,
      emailProvider,
      searchExecuted,
      googleScope,
      savingWebcalLink,
    } = this.state
    switch (webcalVsNylas) {
      case 'gmail':
        return (
          <div>
            <p>How would you like to sync your Google Calendar?</p>
            <SettingGroup>
              <FormCheck className="form-check">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="googleScope"
                  value="calendar"
                  onChange={(e) => this.setState({ googleScope: e.target.value })}
                  checked={googleScope === 'calendar'}
                />
                <TextyButton
                  type="button"
                  onClick={() => this.setState({ googleScope: 'calendar' })}
                >
                  <label className="form-check-label" htmlFor="googleScope">
                    <b>Two-way sync</b>
                    <br />
                    <small>
                      If you edit or delete a synced Google Calendar event directly from your
                      Skylight Calendar, the change will reflect back to your Google Calendar.
                    </small>
                  </label>
                </TextyButton>
              </FormCheck>
              <FormCheck className="form-check">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="googleScope"
                  value="calendar.readonly"
                  onChange={(e) => this.setState({ googleScope: e.target.value })}
                  checked={googleScope === 'calendar.readonly'}
                />
                <TextyButton
                  type="button"
                  onClick={() => this.setState({ googleScope: 'calendar.readonly' })}
                >
                  <label className="form-check-label" htmlFor="googleScope">
                    <b>One-way sync</b>
                    <br />
                    <small>
                      You cannot make any changes to your Google Calendar events directly from your
                      Skylight Calendar. You can delete events on your Skylight, but the change will
                      not reflect back to your Google Calendar.
                    </small>
                  </label>
                </TextyButton>
              </FormCheck>
            </SettingGroup>
            <div className="form-group">
              <label htmlFor="email">
                Which <b>Gmail</b> address is the calendar associated with?
                <input
                  type="email"
                  value={email}
                  onChange={this.handleEmailChange}
                  className="form-control"
                />
              </label>

              <br />
              <p className="text-info">
                Don't worry, you can sync <b>another email address</b> next.
              </p>
              <p className="text-right">
                Confused?{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://skylight.zendesk.com/hc/en-us/categories/360001535832-Skylight-Calendar"
                >
                  Learn more about Sync
                </a>
                .
              </p>
            </div>
            <HorzButtonGroup>
              <div>
                <Link className="btn btn-outline-primary" to={`/frames/${frame.id}/sync-indv`}>
                  Nevermind, I’ll invite <br />
                  <b>Individual Events</b>
                </Link>
              </div>
              <div>
                {!!email && email.indexOf('@') !== -1 && email.indexOf('.') !== -1 && (
                  <a href="#" onClick={this.goToGoogle}>
                    <img src={googleSignIn} alt="Sign in with Google" />
                  </a>
                )}
              </div>
            </HorzButtonGroup>
          </div>
        )
      case 'nylas':
        return (
          <div>
            <div className="form-group">
              <label htmlFor="email">
                Which <b>Outlook</b> address is the calendar associated with?
                <input
                  type="email"
                  value={email}
                  onChange={this.handleEmailChange}
                  className="form-control"
                />
              </label>

              <br />
              <p className="text-info">
                Don't worry, you can sync <b>another email address</b> next.
              </p>
              <p className="text-right">
                Confused?{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://skylight.zendesk.com/hc/en-us/categories/360001535832-Skylight-Calendar"
                >
                  Learn more about Sync
                </a>
                .
              </p>
            </div>
            <HorzButtonGroup>
              <div>
                <Link className="btn btn-outline-primary" to={`/frames/${frame.id}/sync-indv`}>
                  Nevermind, I’ll invite <br />
                  <b>Individual Events</b>
                </Link>
              </div>
              <div>
                {!!email && email.indexOf('@') !== -1 && email.indexOf('.') !== -1 && (
                  <button type="button" className="btn btn-primary" onClick={this.toggleForm}>
                    Sync with <br />
                    <b>{email}</b>
                  </button>
                )}
              </div>
            </HorzButtonGroup>
          </div>
        )
      case 'sorry':
        return (
          <div>
            <p>
              <b>Sorry!</b> Skylight Sync only supports <b>Gmail</b>, <b>Outlook</b>, <b>Yahoo</b>,
              and <b>Apple</b> Calendar accounts, currently.
            </p>
            <p>
              You can still send individual events by adding{' '}
              <code>{frame.attributes.name}@ourskylight.com</code> as an invitee to any event.
            </p>
            <div className="row mt-5 mb-2">
              <div className="col-12 text-right">
                <Link className="btn btn-success" to="/">
                  Finish
                </Link>
              </div>
            </div>
          </div>
        )
      case 'cozi':
        return (
          <div>
            <div className="form-group">
              <p>
                Start by going to{' '}
                <a href="https://my.cozi.com" target="_blank" rel="noopener noreferrer">
                  https://my.cozi.com
                </a>{' '}
                on your desktop computer and <b>logging in</b> to your Cozi account.
              </p>
            </div>
            <div className="row mb-2 mt-5">
              <div className="col-6 text-left">
                <button type="button" className="btn btn-info" onClick={() => this.goToStep(null)}>
                  Back
                </button>
              </div>
              <div className="col-6 text-right">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.goToStep('cozi-step-two')}
                >
                  I've <b>logged in</b> to Cozi
                </button>
              </div>
            </div>
          </div>
        )
      case 'cozi-step-two':
        return (
          <div>
            <div className="form-group">
              <p>
                Then click on <i>Calendar</i> > <i>Set up</i> > <i>Share Appointments</i>:
              </p>
              <div id="cozi-cal-config" />
            </div>
            <div className="row mb-2 mt-5">
              <div className="col-4 text-left">
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => this.goToStep('cozi')}
                >
                  Back
                </button>
              </div>
              <div className="col-8 text-right">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.goToStep('cozi-step-three')}
                >
                  I Clicked <b>Share Appointments</b>
                </button>
              </div>
            </div>
          </div>
        )
      case 'cozi-step-three':
        return (
          <div>
            <div className="form-group">
              <p>
                In the list of your Calendars, click on <i>Not Shared</i>, switch it to{' '}
                <i>Shared</i>, then click on <i>Send URL</i>:
              </p>
              <div id="cozi-cal-share" />
            </div>
            <div className="row mb-2 mt-5">
              <div className="col-6 text-left">
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => this.goToStep('cozi-step-two')}
                >
                  Back
                </button>
              </div>
              <div className="col-6 text-right">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.goToStep('cozi-step-four')}
                >
                  I've Clicked <b>Send URL</b>
                </button>
              </div>
            </div>
          </div>
        )
      case 'cozi-step-four':
        return (
          <div>
            <div className="form-group">
              <p>
                Send the URL to <b>{frame.attributes.name}@ourskylight.com</b>:
              </p>
              <div id="cozi-cal-send" />
            </div>
            <div className="row mb-2 mt-5">
              <div className="col-6 text-left">
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => this.goToStep('cozi-step-three')}
                >
                  Back
                </button>
              </div>
              <div className="col-6 text-right">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => history.push(`/frames/${frame.id}/sync-success?finished=true`)}
                >
                  I've Sent the URL
                </button>
              </div>
            </div>
          </div>
        )
      case 'yahoo':
        return (
          <div>
            <div className="form-group">
              <p>
                Go to the Yahoo Calendar app on your <b>Computer</b>, click the down arrow to the
                right of your calendar name, and click on <b>Share</b>:
              </p>
              <div id="yahoo-cal-config" />
            </div>
            <div className="row mb-2 mt-5">
              <div className="col-6 text-left">
                <button type="button" className="btn btn-info" onClick={() => this.goToStep(null)}>
                  Back
                </button>
              </div>
              <div className="col-6 text-right">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.goToStep('yahoo-step-two')}
                >
                  I've clicked the <b>Share</b>
                </button>
              </div>
            </div>
          </div>
        )
      case 'yahoo-step-two':
        return (
          <div>
            <div className="form-group">
              <p>
                Check the <b>Generate Links</b> checkbox, and then copy the ICS link to import into
                your Calendar:
              </p>
              <div id="yahoo-cal-links" />
            </div>
            <div className="row mb-2 mt-5">
              <div className="col-6 text-left">
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => this.goToStep('yahoo')}
                >
                  Back
                </button>
              </div>
              <div className="col-6 text-right">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.goToStep('webcal-manual')}
                >
                  I've Copied the <b>Link</b>
                </button>
              </div>
            </div>
          </div>
        )
      case 'webcal':
        return (
          <div>
            <SyncSubscribediCalLink
              href="https://skylight.zendesk.com/hc/en-us/articles/360031188251"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => Analytics.track('Clicked Subscribed Calendar Guide')}
            >
              Need to sync a calendar you don't own?
            </SyncSubscribediCalLink>
            <div className="form-group">
              <p>
                Start by opening the Calendar app and tapping on <b>Calendars</b>, then tap the{' '}
                <b>i</b> next to a calendar name:
              </p>
              <div id="apple-cal-info-button" />
            </div>
            <div className="row mb-2 mt-5">
              <div className="col-6 text-left">
                <button type="button" className="btn btn-info" onClick={() => this.goToStep(null)}>
                  Back
                </button>
              </div>
              <div className="col-6 text-right">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.goToStep('webcal-step-two')}
                >
                  I've clicked the <span className="oi oi-info" /> Button
                </button>
              </div>
            </div>
          </div>
        )
      case 'webcal-step-two':
        return (
          <div>
            <p>
              Then toggle <b>Public Calendar</b> to on and tap the "<b>Share Link...</b>" button:
            </p>
            <div id="apple-cal-public-link" />
            <div className="row mb-2 mt-5">
              <div className="col-6 text-left">
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => this.goToStep('webcal')}
                >
                  Back
                </button>
              </div>
              <div className="col-6 text-right">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.goToStep('webcal-step-three')}
                >
                  I've switched to Public
                  <br />
                  and tapped Share Link...
                </button>
              </div>
            </div>
          </div>
        )
      case 'webcal-step-three':
        return (
          <div>
            <p>
              Then email the Calendar Url to <b>{frame.attributes.name}@ourskylight.com</b> using
              your favorite email app (Mail, Gmail, Outlook, etc):
            </p>
            <div id="apple-cal-share-options" />
            <div id="apple-cal-email" />
            <div className="row mb-2 mt-5">
              <div className="col-3 text-left">
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => this.goToStep('webcal-step-two')}
                >
                  Back
                </button>
              </div>
              <div className="col-9 text-right">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => history.push(`/frames/${frame.id}/sync-success?finished=true`)}
                >
                  I've emailed the URL
                  <br />
                  to {frame.attributes.name}@ourskylight.com
                </button>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12">
                <p className="text-muted">
                  Don't want to email it? You can also{' '}
                  <a href="#" onClick={() => this.goToStep('webcal-manual')}>
                    enter the link manually
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        )
      case 'webcal-manual':
        return (
          <div className="mt-5">
            {savingWebcalLink ? (
              <h3 className="text-center">Validating URL...</h3>
            ) : (
              <div className="form-group">
                {webcalLinkSaveErrors.length > 0 && <Errors errors={webcalLinkSaveErrors} />}
                <label htmlFor="webcalLink">
                  What is the <b>public share URL</b> for your calendar?
                </label>
                <p className="text-sm text-gray">
                  If it is an Apple Calendar, it usually includes <code>caldav.icloud.com</code>,
                  otherwise it often ends in <code>.ics</code>
                </p>
                <input
                  type="text"
                  placeholder="For example: webcal://p52-caldav.icloud.com/published/2/abc123"
                  value={webcalLink}
                  onChange={this.handleWebcalLinkChange}
                  className="form-control"
                />
                <br />
              </div>
            )}
            {!savingWebcalLink && (
              <div className="row mb-2">
                <div className="col-12 text-right">
                  <button
                    type="button"
                    disabled={!webcalLink}
                    className="btn btn-success"
                    onClick={this.saveWebcalLink}
                  >
                    Sync
                  </button>
                </div>
              </div>
            )}
          </div>
        )
      case 'apple':
        return (
          <div>
            <div className="form-group">
              <label htmlFor="email">
                Which <b>Apple</b> email address is the calendar associated with?
                <input
                  type="email"
                  value={email}
                  onChange={this.handleEmailChange}
                  className="form-control"
                />
              </label>

              <br />
              <p className="text-info">
                YOU'LL NEED AN{' '}
                <a href="https://support.apple.com/en-us/HT204397">APP-SPECIFIC PASSWORD</a>
              </p>
              <p className="text-right">
                Confused?{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://skylight.zendesk.com/hc/en-us/categories/360001535832-Skylight-Calendar"
                >
                  Learn more about Sync
                </a>
                .
              </p>
            </div>
            <HorzButtonGroup>
              <div>
                <Link className="btn btn-outline-primary" to={`/frames/${frame.id}/sync-indv`}>
                  Nevermind, I’ll invite <br />
                  <b>Individual Events</b>
                </Link>
              </div>
              <div>
                {!!email && email.indexOf('@') !== -1 && email.indexOf('.') !== -1 && (
                  <button type="button" className="btn btn-primary" onClick={this.toggleForm}>
                    Sync with <br />
                    <b>{email}</b>
                  </button>
                )}
              </div>
            </HorzButtonGroup>
          </div>
        )
      default:
        return (
          <div>
            <p>What kind of Calendar do you use?</p>
            <div className="row mt-2 mb-3">
              <div className="col-12 d-flex justify-content-center">
                <a
                  href="#"
                  className="calendar-icon-btn google"
                  onClick={this.setTwoWaySyncAndGoToGmailStepTwo}
                >
                  Google
                </a>
                <a
                  href="#"
                  className="calendar-icon-btn outlook"
                  onClick={() => this.goToStep('nylas')}
                >
                  Outlook
                </a>
                <a
                  href="#"
                  className="calendar-icon-btn apple"
                  onClick={() => this.goToStep('webcal')}
                >
                  Apple
                </a>
                {isAdmin && (
                  <a
                    href="#"
                    className="calendar-icon-btn apple"
                    onClick={() => this.goToStep('apple')}
                  >
                    iCloud
                  </a>
                )}
              </div>
            </div>
            <div className="row mt-2 mb-3">
              <div className="col-12 d-flex justify-content-center">
                <a
                  href="#"
                  className="calendar-icon-btn cozi"
                  onClick={() => this.goToStep('cozi')}
                >
                  Cozi
                </a>
                <a
                  href="#"
                  className="calendar-icon-btn yahoo"
                  onClick={() => this.goToStep('yahoo')}
                >
                  Yahoo
                </a>
                <a
                  href="#"
                  className="calendar-icon-btn other"
                  onClick={() => this.goToStep('webcal-manual')}
                >
                  Other
                </a>
              </div>
            </div>
          </div>
        )
    }
  }

  toggleForm() {
    const { frame, dispatch } = this.props
    const { email, showingSyncForm } = this.state
    if (showingSyncForm) {
      this.setState({ loading: true })
      dispatch(getNylasUrl(frame.id, email))
    } else {
      this.setState({ showingSyncForm: true })
    }
  }

  goToGoogle() {
    const { frame } = this.props
    const { email, googleScope } = this.state
    this.setState({ loading: true })
    const stateParams = JSON.stringify({ email, frame_id: frame.id })
    // eslint-disable-next-line no-undef
    window.location = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&access_type=offline&response_type=code&scope=https://www.googleapis.com/auth/${googleScope}&prompt=consent&login_hint=${email}&redirect_uri=${GOOGLE_REDIRECT_URI}&state=${stateParams}`
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  handleWebcalLinkChange(event) {
    this.setState({ webcalLink: event.target.value })
  }

  handleEmailProviderChange(event) {
    this.setState({ emailProvider: event.target.value })
  }

  render() {
    const { frame } = this.props
    const { showingSyncForm, loading, hideNoThanks, webcalVsNylas } = this.state
    if (loading) {
      return <Loading />
    }
    return (
      <div>
        <FullStory org="R6R1G" />
        {webcalVsNylas && (
          <p>
            <a href="#" onClick={() => this.goToStep(null)}>
              &lt; back
            </a>
          </p>
        )}
        {!webcalVsNylas && showingSyncForm && (
          <p>
            <a href="#" onClick={() => this.setState({ showingSyncForm: false })}>
              &lt; back
            </a>
          </p>
        )}
        <div className="register-box">
          <h1>{showingSyncForm ? 'Sync Calendar' : 'Sharing Events To Skylight'}</h1>
          {showingSyncForm ? (
            this.webcalOrNylasForm()
          ) : (
            <div>
              <div>
                <p>There are two ways to get events into your Skylight Calendar:</p>
                <ol>
                  <li>Add your Skylight email address as a guest to each event</li>
                  <li>Sync your entire calendar, so events appear and update automatically!</li>
                </ol>
              </div>
              <div className="row mt-5 mb-2">
                <div className="col-6 text-left">
                  <Link className="btn btn-outline-primary" to={`/frames/${frame.id}/sync-indv`}>
                    Sync <br />
                    <b>Individual Events</b>
                  </Link>
                </div>
                <div className="col-6 text-right">
                  <button type="button" className="btn btn-primary" onClick={this.toggleForm}>
                    Sync with <br />
                    <b>Whole Calendar</b>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

SyncCalendar.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  dispatch: PropTypes.func.isRequired,
  frame: PropTypes.object.isRequired,
  savedWebcalLink: PropTypes.bool.isRequired,
  webcalLinkSaveErrors: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool,
  nylasUrl: PropTypes.string,
}

const SyncSubscribediCalLink = styled.a`
  display: inline-block;
  margin: 0.6rem 0 0.6rem 0;
`

const HorzButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 650px) {
    a,
    button {
      font-size: 0.875rem;
    }
  }
`
const SettingGroup = styled.section`
  margin-top: 2rem;
  &:not(:last-of-type) {
    border-bottom: 1px solid lightgray;
  }
`

const TextyButton = styled.button`
  border: 0;
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background: white;
  text-align: left;
`

const FormCheck = styled.div`
  margin: 0 1rem 1.5rem;
`

const Input = styled.input`
  margin-left: -1.5rem;
`
