export const CREATE_PLUS_PERMISSION_REQUEST = 'CREATE_PLUS_PERMISSION_REQUEST'
export const CREATE_PLUS_PERMISSION_SUCCESS = 'CREATE_PLUS_PERMISSION_SUCCESS'
export const CREATE_PLUS_PERMISSION_FAILURE = 'CREATE_PLUS_PERMISSION_FAILURE'

export const PLUS_PERMISSION_CLEAROUT = 'PLUS_PERMISSION_CLEAROUT'

export function create(email) {
  const data = { email }
  return {
    type: CREATE_PLUS_PERMISSION_REQUEST,
    api: {
      data,
      endpoint: 'plus_permissions',
      authenticated: true,
      types: [
        CREATE_PLUS_PERMISSION_REQUEST,
        CREATE_PLUS_PERMISSION_SUCCESS,
        CREATE_PLUS_PERMISSION_FAILURE,
      ],
      method: 'POST',
    },
  }
}

export function publicCreate(data) {
  return {
    type: CREATE_PLUS_PERMISSION_REQUEST,
    api: {
      data,
      endpoint: 'public_plus_permissions',
      authenticated: false,
      types: [
        CREATE_PLUS_PERMISSION_REQUEST,
        CREATE_PLUS_PERMISSION_SUCCESS,
        CREATE_PLUS_PERMISSION_FAILURE,
      ],
      method: 'POST',
    },
  }
}

export const PLUS_PERMISSION_REQUEST = 'PLUS_PERMISSION_REQUEST'
export const PLUS_PERMISSION_SUCCESS = 'PLUS_PERMISSION_SUCCESS'
export const PLUS_PERMISSION_FAILURE = 'PLUS_PERMISSION_FAILURE'

export function show() {
  return {
    type: PLUS_PERMISSION_REQUEST,
    api: {
      endpoint: 'plus_permissions',
      authenticated: true,
      types: [PLUS_PERMISSION_REQUEST, PLUS_PERMISSION_SUCCESS, PLUS_PERMISSION_FAILURE],
      method: 'GET',
    },
  }
}

export const START_TRIAL_REQUEST = 'START_TRIAL_REQUEST'
export const START_TRIAL_SUCCESS = 'START_TRIAL_SUCCESS'
export const START_TRIAL_FAILURE = 'START_TRIAL_FAILURE'

export function startTrial() {
  return {
    type: START_TRIAL_REQUEST,
    api: {
      endpoint: 'plus_permissions/start_trial',
      authenticated: true,
      types: [START_TRIAL_REQUEST, START_TRIAL_SUCCESS, START_TRIAL_FAILURE],
      method: 'POST',
    },
  }
}
