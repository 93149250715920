import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Nav from './Nav'
import Errors from './Errors'

export default class Register extends Component {
  render() {
    const {
      parentState,
      handleChange,
      handleSubmit,
      toggleHasAnAccount,
      registering,
      errors,
    } = this.props
    const hasAnAccount = parentState.has_an_account
    return (
      <div>
        <Nav loggedIn={false} isAdmin={false} />
        <div className="new-user-or-frame-ctn">
          <div className="register-box small-width-ctn">
            <h2>{hasAnAccount ? 'Log in' : 'Connect To Your Skylight'}</h2>
            <Errors errors={errors} />
            <form className="mt-4" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email">
                  Please enter your <b>personal email</b> here.
                </label>
                <input
                  type="email"
                  value={parentState.email}
                  onChange={handleChange('email')}
                  className="form-control"
                  placeholder="janesmith@gmail.com"
                />
                {!hasAnAccount && (
                  <SmallText>
                    This must be an email account that you check. If not, some Skylight features
                    won't work for you. Don't worry -- <b>we won't spam you</b>!
                  </SmallText>
                )}
              </div>
              <ButtonGroup>
                <p className="mb-0">
                  <small className="text-muted">
                    <span>
                      {hasAnAccount ? 'New here? ' : 'Aleady have an account? '}
                      <a
                        role="button"
                        tabIndex="0"
                        className="log-in-link"
                        onClick={toggleHasAnAccount}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) toggleHasAnAccount()
                        }}
                      >
                        {hasAnAccount ? 'Register' : 'Log in'}
                      </a>
                    </span>
                  </small>
                </p>
                <div className="text-right pt-1">
                  <button type="submit" className="btn btn-lg btn-success" disabled={registering}>
                    {hasAnAccount ? 'Next' : 'Next Step'}
                  </button>
                </div>
              </ButtonGroup>
            </form>
          </div>
          <div className="pt-3 pl-2">
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.recallrtr.com/skylightadapter"
              >
                Important Product Recall Information
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

Register.propTypes = {
  parentState: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  toggleHasAnAccount: PropTypes.func.isRequired,
  registering: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
}

const SmallText = styled.p`
  font-size: 0.95rem;
  margin: 1rem 0 1.5rem;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
