export const INVITATION_REQUEST = 'INVITATION_REQUEST'
export const INVITATION_SUCCESS = 'INVITATION_SUCCESS'
export const INVITATION_FAILURE = 'INVITATION_FAILURE'

export function requestInvitationText(phoneNumber) {
  return {
    type: INVITATION_REQUEST,
    api: {
      endpoint: 'sms_invitation',
      data: { phone_number: phoneNumber },
      authenticated: true,
      types: [INVITATION_REQUEST, INVITATION_SUCCESS, INVITATION_FAILURE],
      method: 'POST',
    },
  }
}

export const RESET_INVITATION_REQUEST_STATUS = 'RESET_INVITATION_REQUEST_STATUS'

export function resetInvitationRequestStatus() {
  return {
    type: 'RESET_INVITATION_REQUEST_STATUS',
  }
}

export const FRAME_SHARE_DEEP_LINK_SMS_REQUEST = 'FRAME_SHARE_DEEP_LINK_SMS_REQUEST'
export const FRAME_SHARE_DEEP_LINK_SMS_SUCCESS = 'FRAME_SHARE_DEEP_LINK_SMS_SUCCESS'
export const FRAME_SHARE_DEEP_LINK_SMS_FAILURE = 'FRAME_SHARE_DEEP_LINK_SMS_FAILURE'

export function frameShareDeepLink(frameId, shareToken, phoneNumber) {
  return {
    type: FRAME_SHARE_DEEP_LINK_SMS_REQUEST,
    api: {
      endpoint: `frames/${frameId}/share_tokens/${shareToken}/send_sms`,
      data: { phone_number: phoneNumber },
      authenticated: false,
      types: [
        FRAME_SHARE_DEEP_LINK_SMS_REQUEST,
        FRAME_SHARE_DEEP_LINK_SMS_SUCCESS,
        FRAME_SHARE_DEEP_LINK_SMS_FAILURE,
      ],
      method: 'POST',
    },
  }
}

export const DIGITAL_DEEP_LINK_SMS_REQUEST = 'DIGITAL_DEEP_LINK_SMS_REQUEST'
export const DIGITAL_DEEP_LINK_SMS_SUCCESS = 'DIGITAL_DEEP_LINK_SMS_SUCCESS'
export const DIGITAL_DEEP_LINK_SMS_FAILURE = 'DIGITAL_DEEP_LINK_SMS_FAILURE'

export function digitalDeepLink(phoneNumber) {
  return {
    type: DIGITAL_DEEP_LINK_SMS_REQUEST,
    api: {
      endpoint: `sms_digital_deep_link`,
      data: { phone_number: phoneNumber },
      authenticated: false,
      types: [
        DIGITAL_DEEP_LINK_SMS_REQUEST,
        DIGITAL_DEEP_LINK_SMS_SUCCESS,
        DIGITAL_DEEP_LINK_SMS_FAILURE,
      ],
      method: 'POST',
    },
  }
}
