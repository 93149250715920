import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import SearchHeader from '../SearchHeader'

export default class FramesHeader extends Component {
  constructor(props) {
    super()
    this.handleShowDeletedFramesChange = this.handleShowDeletedFramesChange.bind(this)
    this.updateShowDeletedFrames = props.updateShowDeletedFrames
    this.toggleFunc = props.toggleFunc
  }

  handleShowDeletedFramesChange(event) {
    this.updateShowDeletedFrames(event.target.checked)
    this.toggleFunc(event.target.checked)
  }

  render() {
    const {
      selectedFrameIds,
      deploymentId,
      handleDeploymentIdChange,
      deployments,
      versions,
      addToDeployment,
      groupId,
      handleGroupIdChange,
      groups,
      addToGroup,
      searchFunc,
      searchQuery,
      updateSearchQuery,
      showDeletedFrames,
    } = this.props
    return (
      <div className="row align-items-center">
        <div className="col-4">
          <h2>Frames</h2>
        </div>
        <div className="col-4 text-center">
          {selectedFrameIds.length > 0 ? (
            <>
              <form className="form-inline">
                <select
                  className="custom-select mr-2"
                  value={deploymentId}
                  onChange={handleDeploymentIdChange}
                >
                  <option value="">Pick a Version</option>
                  {deployments.map((deployment) => {
                    const version = versions.find(
                      (v) => v.id === deployment.relationships.android_version.data.id
                    )
                    return (
                      <option key={deployment.id} value={deployment.id}>
                        {version.attributes.kind} {version.attributes.name}
                      </option>
                    )
                  })}
                </select>
                <button
                  type="submit"
                  className="btn btn-info"
                  disabled={deploymentId === ''}
                  onClick={addToDeployment}
                >
                  Add to Deployment
                </button>
              </form>
              <hr />
              <form className="form-inline">
                <select
                  className="custom-select mr-2"
                  value={groupId}
                  onChange={handleGroupIdChange}
                >
                  <option value="">Pick a Group</option>
                  {groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.attributes.title}
                    </option>
                  ))}
                </select>
                <button
                  type="submit"
                  className="btn btn-info"
                  disabled={groupId === ''}
                  onClick={addToGroup}
                >
                  Add to Group
                </button>
              </form>
            </>
          ) : (
            <Link to="/admin-dash/frames/new" className="btn btn-success">
              Activate New Device
            </Link>
          )}
        </div>
        <div className="col-4 text-right row justify-content-end me-0">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              defaultChecked={showDeletedFrames}
              value={showDeletedFrames}
              onClick={this.handleShowDeletedFramesChange}
              aria-label="Checkbox for showing deleted frames"
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Show Deleted Frames
            </label>
          </div>
          <SearchHeader
            listFunc={searchFunc}
            searchQuery={searchQuery}
            updateSearchQuery={updateSearchQuery}
            title="Frames"
          />
        </div>
      </div>
    )
  }
}

FramesHeader.propTypes = {
  selectedFrameIds: PropTypes.array.isRequired,
  deploymentId: PropTypes.string,
  deployments: PropTypes.array.isRequired,
  versions: PropTypes.array.isRequired,
  handleDeploymentIdChange: PropTypes.func.isRequired,
  addToDeployment: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  handleGroupIdChange: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  addToGroup: PropTypes.func.isRequired,
  searchFunc: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  updateSearchQuery: PropTypes.func.isRequired,
  showDeletedFrames: PropTypes.bool,
  updateShowDeletedFrames: PropTypes.func.isRequired,
  toggleFunc: PropTypes.func.isRequired,
}
