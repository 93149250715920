import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class CodeModal extends Component {
  render() {
    const { dismiss, frame, activationCode } = this.props
    return (
      <div
        id="migration-modal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{frame.attributes.name} Activation Code</h5>
              <button type="button" className="close" aria-label="Close" onClick={dismiss}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="row modal-body justify-content-md-center align-items-center">
              <code className="text-lg text-center">{activationCode}</code>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={dismiss}>
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CodeModal.propTypes = {
  frame: PropTypes.object.isRequired,
  dismiss: PropTypes.func.isRequired,
  activationCode: PropTypes.string,
}
