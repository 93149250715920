import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { DateConverter } from '../../utilities/DateConverter'

import NavContainer from '../../containers/NavContainer'
import Loading from '../Loading'

import { listDeployments, deprecate } from '../../actions/admin/versions'
import { listDeviceGroups } from '../../actions/admin/deviceGroups'

const AndroidVersion = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { gettingVersionDeployments, versionDeployments, versionApks, version } = useSelector(
    (state) => state.androidVersions
  )
  const { groups } = useSelector((state) => state.deviceGroups)
  useEffect(() => {
    dispatch(listDeployments(id))
    dispatch(listDeviceGroups())
  }, [dispatch, id])

  return (
    <>
      <NavContainer history={history} />
      <div className="row justify-content-md-center">
        {gettingVersionDeployments ? (
          <Loading />
        ) : (
          <div className="col-lg-8 col-xl-6">
            <div className="row align-items-center">
              <div className="col-2">
                <p className="mb-0">
                  <button type="button" onClick={history.goBack} className="btn btn-sm btn-info">
                    ← Back
                  </button>
                </p>
              </div>
              <div className="col-7">
                <h1 className="text-center mb-0">
                  Version {version.attributes.name}
                  <span className="small"> [{version.attributes.status}]</span>
                </h1>
                <p className="text-center text-muted">
                  Uploaded at <b>{DateConverter.call(version.attributes.created_at)}</b>
                </p>
              </div>
              <div className="col-3">
                <div className="btn-group" role="group">
                  {!version.attributes.name.toLowerCase().startsWith('dev') && (
                    <Link
                      to={`/admin-dash/versions/${id}/deploy`}
                      className="btn btn-sm btn-success"
                    >
                      Ship
                    </Link>
                  )}
                  {version.attributes.status !== 'deprecated' && (
                    <button
                      type="submit"
                      onClick={() => dispatch(deprecate(version.id))}
                      className="btn btn-sm btn-danger"
                    >
                      Deprecate
                    </button>
                  )}
                </div>
              </div>
            </div>
            <br />
            <div className="row align-items-center">
              <div className="col-12">
                <h3>APKs</h3>
              </div>
            </div>
            <>
              {versionApks.map((apk) => {
                /* eslint-disable camelcase */
                const { apk_url, signing_key_md5, asset_key } = apk.attributes
                /* eslint-enable camelcase */
                return (
                  <div key={apk.id} className="row">
                    <div className="col-6">
                      <p>
                        <a href={apk_url}>{asset_key}</a>
                      </p>
                    </div>
                    <div className="col-6 text-right">{signing_key_md5}</div>
                  </div>
                )
              })}
            </>
            <div className="row align-items-center">
              <div className="col-12">
                <h3>Deployments</h3>
              </div>
            </div>
            <>
              {versionDeployments.map((deployment) => {
                /* eslint-disable camelcase */
                const { device_group_id } = deployment.attributes
                const group = device_group_id
                  ? groups.find((g) => g.id === device_group_id.toString())
                  : null
                /* eslint-enable camelcase */
                return (
                  <div key={deployment.id} className="row">
                    <div className="col-6">
                      <p>
                        <Link to={`/admin-dash/deployments/${deployment.id}`}>
                          {DateConverter.call(deployment.attributes.created_at)}
                        </Link>
                      </p>
                    </div>
                    {group ? (
                      <div className="col-6 text-right">
                        Deployed to all of {group.attributes.title}
                      </div>
                    ) : (
                      <>
                        <div className="col-3 text-right">
                          {deployment.attributes.percent}% Frames
                        </div>
                        <div className="col-3 text-right">
                          {deployment.attributes.cal_percent}% Cals
                        </div>
                      </>
                    )}
                  </div>
                )
              })}
            </>
          </div>
        )}
      </div>
    </>
  )
}

AndroidVersion.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
}

export default AndroidVersion
