import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import { FileExtensions } from '../utilities/FileExtensions'

const MAX_CAPTION_LENGTH = 50

export default class NewMessage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasFile: false,
      caption: '',
      acceptableFiles: [],
    }
    this.uploadMessage = this.uploadMessage.bind(this)
    this.updateCaption = this.updateCaption.bind(this)
    this.multipleFiles = this.multipleFiles.bind(this)
    this.setFiles = this.setFiles.bind(this)
  }

  setFiles(fileArr) {
    const acceptableFiles = []
    for (let i = 0; i < fileArr.length; i++) {
      acceptableFiles.push(fileArr[i])
    }
    this.setState({ hasFile: true, acceptableFiles })
  }

  updateCaption(e) {
    this.setState({ caption: e.target.value })
  }

  multipleFiles() {
    const { hasFile, acceptableFiles } = this.state
    if (!hasFile) return false
    return acceptableFiles.length > 1
  }

  uploadMessage(event) {
    event.preventDefault()
    const { handleUpload } = this.props
    const { caption, acceptableFiles } = this.state
    handleUpload(acceptableFiles, acceptableFiles.length > 1 ? null : caption)
  }

  render() {
    const { frames, selectedFrameIds, toggleFrame } = this.props
    const { acceptableFiles, caption } = this.state
    const cantSend = selectedFrameIds.length === 0 || acceptableFiles.length === 0
    const canCaption = acceptableFiles.length === 1
    const multiple = this.multipleFiles()
    return (
      <div className="col-lg-6 col-xl-4">
        <div className="register-box">
          <h3>Send New Photos</h3>
          <form onSubmit={this.uploadMessage}>
            <label htmlFor="frame names">
              <b>Which frames should receive the photos?</b>
            </label>
            {frames.map((frame) => (
              <div key={frame.id} className="form-group form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedFrameIds.indexOf(frame.id) !== -1}
                  onChange={() => toggleFrame(frame.id)}
                />
                <label
                  onClick={() => toggleFrame(frame.id)}
                  className="form-check-label"
                  htmlFor={frame.attributes.name}
                >
                  {frame.attributes.name}
                </label>
              </div>
            ))}
            <div className="form-group">
              <label htmlFor="file">
                <b>Please select one or more Photos</b>
              </label>
              <ChooseFileInput
                className="form-control"
                type="file"
                accept={FileExtensions.all.map((e) => `.${e}`).join(',')}
                multiple
                ref={(ref) => {
                  this.uploadInput = ref
                }}
                onChange={() => this.setFiles(this.uploadInput.files)}
              />
            </div>
            {canCaption && (
              <div className="form-group">
                <label htmlFor="file">
                  <b>Add a Caption</b> to <b>{acceptableFiles[0].name}</b> <small>Optional</small>
                </label>
                <input
                  className="form-control"
                  type="text"
                  maxLength={MAX_CAPTION_LENGTH}
                  value={caption}
                  onChange={this.updateCaption}
                />
              </div>
            )}
            <div className="row">
              <div className="col-6">
                <Link to="/" className="btn btn-info">
                  ← Cancel
                </Link>
              </div>
              <div className="col-6 text-right">
                <button
                  type="submit"
                  className={`btn btn-success ${cantSend && 'btn-disabled'}`}
                  disabled={cantSend}
                >
                  Send Photo{multiple ? 's' : ''}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

NewMessage.propTypes = {
  handleUpload: PropTypes.func.isRequired,
  frames: PropTypes.array.isRequired,
  selectedFrameIds: PropTypes.array.isRequired,
  toggleFrame: PropTypes.func.isRequired,
}

const ChooseFileInput = styled.input`
  padding: 2px 0.75rem 2px 4px;
`
