export const Analytics = {
  identify: (id, email) => {
    try {
      // eslint-disable-next-line no-undef
      amplitude.getInstance().setUserId(email)
    } catch (err) {
      console.error('Identify failed:')
      console.error(err)
    }
  },
  track: (event, attrs, groups) => {
    try {
      if (groups) {
        // eslint-disable-next-line no-undef
        amplitude.getInstance().logEventWithGroups(event, attrs, groups)
      } else {
        // eslint-disable-next-line no-undef
        amplitude.getInstance().logEvent(event, attrs)
      }
    } catch (err) {
      console.error('Event failed:')
      console.error(err)
    }
  },
  updateUser: (attrs) => {
    try {
      // eslint-disable-next-line no-undef
      const newIdentity = new amplitude.Identify()
      Object.keys(attrs).forEach((key) => newIdentity.setOnce(key, attrs[key]))
      // eslint-disable-next-line no-undef
      amplitude.identify(newIdentity)
    } catch (err) {
      console.error('Update failed:')
      console.error(err)
    }
  },
}
