import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

function BackToTop({ isFooterShowing }) {
  return (
    <Btn type="button" onClick={() => window.scrollTo(0, 0)} isFooterShowing={isFooterShowing}>
      <span className="oi oi-chevron-top" />
      <Text>Top</Text>
    </Btn>
  )
}

BackToTop.propTypes = {
  isFooterShowing: PropTypes.bool,
}

export default React.memo(BackToTop)

const Btn = styled.button`
  position: ${(props) => (props.isFooterShowing ? 'static' : 'fixed')};
  width: 2.5em;
  height: 2.5em;
  right: 1em;
  bottom: 1em;
  border: black solid 2px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:focus {
    outline: none;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
  &:active {
    box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25), 0 3px 7px -3px rgba(0, 0, 0, 0.3);
  }
`

const Text = styled.p`
  font-size: 0.8em;
  font-weight: 600 !important;
`
