import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { list } from '../actions/frames'

import NavContainer from './NavContainer'

import NewMessage from '../components/NewMessage'
import UploadProgress from '../components/UploadProgress'
import Loading from '../components/Loading'
import TrialBanner from '../components/TrialBanner'

import { Session } from '../utilities/Session'
import { Analytics } from '../utilities/Analytics'

import { UrlParams } from '../utilities/UrlParams'

class NewMessageContainer extends Component {
  constructor(props) {
    super(props)
    let urlParams = UrlParams.get()
    let fromFrameId = urlParams.from_frame_id
    let selectedFrameIds = fromFrameId ? [fromFrameId] : []
    this.state = { selectedFrameIds, filesToUpload: null, loadedFrames: false, uploading: false }
    this.handleUpload = this.handleUpload.bind(this)
    this.toggleFrame = this.toggleFrame.bind(this)
    this.renderUploadBox = this.renderUploadBox.bind(this)
  }

  componentDidMount() {
    let { dispatch } = this.props
    dispatch(list())
    Analytics.track('Viewed New Message')
    window.scrollTo(0, 0)
  }

  handleUpload(filesToUpload, caption) {
    this.setState({ filesToUpload, caption, uploading: true })
  }

  toggleFrame(id) {
    let { selectedFrameIds } = this.state
    let index = selectedFrameIds.indexOf(id)
    if (index == -1) {
      selectedFrameIds.push(id)
    } else {
      selectedFrameIds.splice(index, 1)
    }
    this.setState({ selectedFrameIds })
  }

  renderUploadBox() {
    const { dispatch, frames, uploadUrls, errors } = this.props
    const { uploading, filesToUpload, caption, selectedFrameIds } = this.state
    if (uploading) {
      return (
        <UploadProgress
          dispatch={dispatch}
          errors={errors}
          uploads={filesToUpload}
          uploadUrls={uploadUrls}
          frameIds={selectedFrameIds}
          caption={caption}
        />
      )
    } else {
      return (
        <NewMessage
          frames={frames}
          selectedFrameIds={this.state.selectedFrameIds}
          toggleFrame={this.toggleFrame}
          handleUpload={this.handleUpload}
        />
      )
    }
  }

  render() {
    const { history } = this.props
    const { trialDaysRemaining, gettingFrames } = this.props
    return (
      <div>
        {trialDaysRemaining && <TrialBanner trialDaysRemaining={trialDaysRemaining} />}
        <NavContainer history={history} />
        <div className="row justify-content-md-center">
          {gettingFrames ? (
            <div className="col-lg-8 col-xl-6">
              <Loading />
            </div>
          ) : (
            this.renderUploadBox()
          )}
        </div>
      </div>
    )
  }
}

NewMessageContainer.propTypes = {
  history: PropTypes.object.isRequired,
  frames: PropTypes.array.isRequired,
  gettingFrames: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  gettingUploadUrl: PropTypes.bool.isRequired,
  uploadUrls: PropTypes.array.isRequired,
  uploading: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  trialDaysRemaining: PropTypes.number,
}

const mapStateToProps = (state) => {
  const { frames, gettingFrames, trialDaysRemaining } = state.frames
  const { uploading, gettingUploadUrl, uploadUrls, errors } = state.messages
  return {
    frames,
    gettingFrames,
    trialDaysRemaining,
    uploading,
    gettingUploadUrl,
    uploadUrls,
    errors,
  }
}

export default connect(mapStateToProps)(NewMessageContainer)
