import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import NavContainer from '../NavContainer'
import { create } from '../../actions/admin/exports'

class ExportContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      creatingNewExport: false,
    }
    this.toggleCreatingNewExport = this.toggleCreatingNewExport.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(event) {
    this.toggleCreatingNewExport()
    const { dispatch } = this.props
    dispatch(create(this.state, true))
  }

  toggleCreatingNewExport() {
    this.setState({ creatingNewExport: !this.creatingNewExport })
  }

  render() {
    const { history } = this.props
    const { creatingNewExport } = this.state
    const date = new Date()
    const prevDate = new Date(date - 1000 * 60 * 60 * 24 * 30)
    return (
      <div>
        <NavContainer history={history} />
        <div className="row align-items-center">
          <div className="col-8">
            <h2>Data Export</h2>
          </div>
          <div className="col-4 text-right">
            {creatingNewExport || (
              <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>
                Export
              </button>
            )}
          </div>
        </div>
        <hr />
        <div className="row align-items-center">
          <div className="col-12">
            <h3>Export Last 30 days of customer data</h3>
            <p>
              From {prevDate.toDateString()} to {date.toDateString()}.
            </p>
            {creatingNewExport && <h5>Export is on its way!</h5>}
          </div>
        </div>
      </div>
    )
  }
}

ExportContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  const { loggedIn, isAdmin } = state.session
  const { creatingNewExport } = state
  return {
    loggedIn,
    isAdmin,
    creatingNewExport,
  }
}

export default connect(mapStateToProps)(ExportContainer)
