import React, { Component } from 'react'
import styled from 'styled-components'

const MAX_DOTS = 3
const DOT_DELAY_MILLISECONDS = 250

export default class Loading extends Component {
  constructor(props) {
    super(props)
    this.state = { waitedFor: 1 }
    this.updateDots = this.updateDots.bind(this)
  }

  componentDidMount() {
    this.dotUpdater = setTimeout(this.updateDots, DOT_DELAY_MILLISECONDS)
  }

  componentWillUnmount() {
    clearTimeout(this.dotUpdater)
  }

  updateDots() {
    const { waitedFor } = this.state
    this.setState({ waitedFor: waitedFor + 1 })
    this.dotUpdater = setTimeout(this.updateDots, DOT_DELAY_MILLISECONDS)
  }

  render() {
    const { waitedFor } = this.state
    const numDots = waitedFor % (MAX_DOTS + 1)
    return (
      <div className="register-box">
        <TextCtn className="px-3">
          <h1>Loading{'.'.repeat(numDots)}</h1>
        </TextCtn>
      </div>
    )
  }
}

Loading.propTypes = {}

const TextCtn = styled.div`
  display: flex;
  justify-content: center;
`
