import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Debounce } from '../utilities/Debounce'

const DEBOUCE_MILLISECONDS = 500

export default class SearchHeader extends Component {
  constructor(props) {
    super(props)
    this.handleSearchQueryChange = this.handleSearchQueryChange.bind(this)
    this.refreshFrames = Debounce.wrap(this.refreshFrames, DEBOUCE_MILLISECONDS)
    this.cancel = this.cancel.bind(this)
    this.updateSearchQuery = props.updateSearchQuery
    this.listFunc = props.listFunc
    this.cancelFunc = props.cancelFunc
  }

  handleSearchQueryChange(event) {
    this.updateSearchQuery(event.target.value)
    this.refreshFrames(event.target.value)
  }

  refreshFrames(query) {
    this.listFunc(query)
  }

  cancel() {
    this.handleSearchQueryChange({ target: { value: '' } })
    this.cancelFunc()
  }

  render() {
    const { searchQuery, cancelFunc } = this.props
    return (
      <div className="form-group mb-0">
        <div className="input-group">
          <input
            type="name"
            value={searchQuery}
            onChange={this.handleSearchQueryChange}
            className="form-control"
            placeholder="Search"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
          {cancelFunc && (
            <button type="button" className="btn btn-info btn-square" onClick={this.cancel}>
              <span className="oi oi-x" title="Cancel" aria-hidden="true" />
            </button>
          )}
        </div>
      </div>
    )
  }
}

SearchHeader.propTypes = {
  listFunc: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  updateSearchQuery: PropTypes.func.isRequired,
  cancelFunc: PropTypes.func,
}
