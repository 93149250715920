import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import NavContainer from '../containers/NavContainer'
import { deleteAccount } from '../actions/user'
import Errors from './Errors'

export default function DeleteAccountPage({ history }) {
  const dispatch = useDispatch()

  const { deletingUser, userErrors } = useSelector((state) => state.user)

  const confirmDeleteAccount = () => {
    dispatch(deleteAccount())
  }

  return (
    <div>
      <NavContainer history={history} />
      <div>
        <div className="row justify-content-md-center">
          <div className="col-lg-6 col-xl-4">
            <Box>
              <h2>Delete account</h2>
              <Errors errors={userErrors} />

              <br />

              <h5>This will result in:</h5>
              <ul>
                <li>Deleting all the Skylight devices you own.</li>
                <li>Deleting your profile and related account information from our system.</li>
                <li>Signing out of all devices using your account</li>
              </ul>

              <b>This cannot be undone.</b>

              <Buttons>
                <CancelButton onClick={() => history.goBack()} />
                <DeleteButton $loading={deletingUser} onClick={confirmDeleteAccount} />
              </Buttons>
            </Box>
          </div>
        </div>
      </div>
    </div>
  )
}

DeleteAccountPage.propTypes = {
  history: PropTypes.object.isRequired,
}

const Box = styled.div.attrs({
  className: 'register-box',
})``

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 18px;
`

const CancelButton = styled.button.attrs({
  className: 'btn btn-outline-primary',
  children: 'Cancel',
})``

const DeleteButton = styled.button.attrs(({ $loading }) => ({
  className: 'btn btn-danger',
  children: $loading ? 'Deleting...' : 'Delete',
  disabled: $loading,
}))``
