import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'

import { useSelector, useDispatch } from 'react-redux'
import NavContainer from '../../containers/NavContainer'
import Loading from '../Loading'
import NewDeployment from './NewDeployment'

import { list } from '../../actions/admin/versions'
import { create } from '../../actions/admin/deployments'

const STATUS_COLORS = {
  beta: 'info',
  live: 'success',
  deprecated: 'danger',
}

const AndroidVersions = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [creatingNewDeployment, setCreatingNewDeployment] = useState(false)
  const [versionCode, handleVersionCodeChange] = useState('')
  const [versionName, handleVersionNameChange] = useState('')
  const [versionKind, handleVersionKindChange] = useState('application')

  const handleAndroidVersionUpload = data => {
    data.append('code', versionCode)
    data.append('name', versionName)
    data.append('kind', versionKind)
    dispatch(create(data))
  }

  const { gettingVersions, versions } = useSelector(state => state.androidVersions)
  useEffect(() => {
    dispatch(list())
  }, [dispatch])

  const { errors, uploading, finishedUploading } = useSelector(state => state.deployments)

  useEffect(() => {
    if (finishedUploading) {
      dispatch(list())
      setCreatingNewDeployment(false)
    }
  }, [dispatch, finishedUploading])

  return (
    <>
      <NavContainer history={history} />
      {creatingNewDeployment ? (
        <div className="row justify-content-md-center">
          <NewDeployment
            errors={errors}
            uploading={uploading}
            versionCode={versionCode}
            versionName={versionName}
            versionKind={versionKind}
            handleVersionCodeChange={handleVersionCodeChange}
            handleVersionNameChange={handleVersionNameChange}
            handleVersionKindChange={handleVersionKindChange}
            handleAndroidVersionUpload={handleAndroidVersionUpload}
            nevermind={() => setCreatingNewDeployment(false)}
          />
        </div>
      ) : (
        <div className="row justify-content-md-center">
          <div className="col-lg-8 col-xl-6">
            <div className="row align-items-center">
              <div className="col-8">
                <h1>Android Versions</h1>
              </div>
              <div className="col-4 text-right">
                <a
                  href="#"
                  onClick={() => setCreatingNewDeployment(true)}
                  className="btn btn btn-success"
                >
                  Upload Version
                </a>
              </div>
            </div>
            {gettingVersions ? (
              <Loading />
            ) : (
              <>
                {versions.map(version => (
                  <div key={version.id} className="row mt-3 align-items-center">
                    <div className="col-sm-12 mt-2">
                      <h4 className="mb-0">
                        <Link to={`/admin-dash/versions/${version.id}`}>
                          Version {version.attributes.code}
                        </Link>{' '}
                        &nbsp;
                        <small className="text-muted">
                          v{version.attributes.name} {version.attributes.kind} |{' '}
                          <span className={`text-${STATUS_COLORS[version.attributes.status]}`}>
                            {version.attributes.status}
                          </span>
                        </small>
                      </h4>
                      <p>
                        <b>MD5</b>{' '}
                        <small className="text-muted">{version.attributes.md5_hash}</small>
                      </p>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

AndroidVersions.propTypes = {}

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export default AndroidVersions
