import {
  CALENDARS_REQUEST,
  CALENDARS_SUCCESS,
  CALENDARS_FAILURE,
  CALENDARS_LIST_REQUEST,
  CALENDARS_LIST_SUCCESS,
  CALENDARS_LIST_FAILURE,
  CALENDARS_DESTROY_SUCCESS,
  NYLAS_URL_REQUEST,
  NYLAS_URL_SUCCESS,
} from '../actions/calendars'

const DEFAULT_STATE = {
  loading: true,
  activeCalendars: [],
  availableCalendars: [],
  calendarAccounts: [],
  nylasUrl: null,
}

export function calendars(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case CALENDARS_REQUEST:
      return { ...state, ...DEFAULT_STATE }
    case CALENDARS_SUCCESS: {
      let activeCalendars = []
      if (action.response.meta && action.response.meta.active_calendars) {
        activeCalendars = action.response.meta.active_calendars
      }
      return { ...state, activeCalendars, loading: false, availableCalendars: action.response.data }
    }
    case CALENDARS_FAILURE:
      return { ...state, ...DEFAULT_STATE }
    case CALENDARS_LIST_REQUEST:
      return { ...state, ...DEFAULT_STATE }
    case CALENDARS_LIST_SUCCESS:
      return { ...state, loading: false, calendarAccounts: action.response.data }
    case CALENDARS_LIST_FAILURE:
      return { ...state, ...DEFAULT_STATE }
    case CALENDARS_DESTROY_SUCCESS: {
      const destroyedCalendar = action.response.data
      const remainingCalendars = []
      state.calendarAccounts.forEach((c) => {
        if (c.id === destroyedCalendar.id) {
          if (destroyedCalendar.attributes.active_calendars.length > 0) {
            remainingCalendars.push(destroyedCalendar)
          }
        } else {
          remainingCalendars.push(c)
        }
      })
      return { ...state, calendarAccounts: remainingCalendars }
    }
    case NYLAS_URL_REQUEST:
      return { ...state, loading: true, nylasUrl: null }
    case NYLAS_URL_SUCCESS:
      return { ...state, loading: false, nylasUrl: action.response.data.authorization_request_url }
    default:
      return state
  }
}
