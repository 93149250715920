import { LOG_OUT } from './session'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE'

export const USER_SHOW_REQUEST = 'USER_SHOW_REQUEST'
export const USER_SHOW_SUCCESS = 'USER_SHOW_SUCCESS'
export const USER_SHOW_FAILURE = 'USER_SHOW_FAILURE'

export function update(data) {
  return {
    type: USER_UPDATE_REQUEST,
    api: {
      data,
      endpoint: 'user',
      authenticated: true,
      types: [USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAILURE],
      method: 'PUT',
    },
  }
}

export const USER_PATCH_REQUEST = 'USER_PATCH_REQUEST'
export const USER_PATCH_SUCCESS = 'USER_PATCH_SUCCESS'
export const USER_PATCH_FAILURE = 'USER_PATCH_FAILURE'

export function patch(data) {
  return {
    type: USER_PATCH_REQUEST,
    api: {
      data,
      endpoint: 'user/profile',
      authenticated: true,
      types: [USER_PATCH_REQUEST, USER_PATCH_SUCCESS, USER_PATCH_FAILURE],
      method: 'PATCH',
    },
  }
}

export function show(data) {
  return {
    type: USER_SHOW_REQUEST,
    api: {
      data,
      endpoint: 'user',
      authenticated: true,
      types: [USER_SHOW_REQUEST, USER_SHOW_SUCCESS, USER_SHOW_FAILURE],
      method: 'GET',
    },
  }
}

export const USER_NOTIF_CLEAROUT = 'USER_NOTIF_CLEAROUT'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = LOG_OUT
export const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE'

export function deleteAccount() {
  return {
    type: USER_DELETE_REQUEST,
    api: {
      endpoint: 'user',
      authenticated: true,
      types: [USER_DELETE_REQUEST, USER_DELETE_SUCCESS, USER_DELETE_FAILURE],
      method: 'DELETE',
    },
  }
}
