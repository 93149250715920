import React, { useRef, memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useLocation, useHistory } from 'react-router-dom'

import greenCheck from '../../assets/images/green-check.png'

function MessageItem({
  message,
  frame,
  setSelectedImgYPos,
  createdAtStr,
  currentlyViewingMessage,
  isSelected,
  isSelectingMessages,
  toggleSelectedMessage,
  frameOwner,
}) {
  const history = useHistory()
  const location = useLocation()
  const imgRef = useRef()
  const userId = useSelector((s) => s.session.id)
  const ownsFrameOrMessage = message.attributes.sender_id === parseInt(userId) || frameOwner

  if (isSelectingMessages && !ownsFrameOrMessage) return null

  if (currentlyViewingMessage && message.id === currentlyViewingMessage.id && imgRef.current) {
    const positionFromTop = imgRef.current.getBoundingClientRect().top + window.pageYOffset
    setSelectedImgYPos(positionFromTop)
  }

  function handleMessageClick(messageId, e) {
    if (isSelectingMessages) {
      if (ownsFrameOrMessage) toggleSelectedMessage(messageId, e)
    } else {
      history.push({
        pathname: `/frames/${frame.id}/messages/${message.id}`,
        state: { background: location },
      })
    }
  }

  return (
    <div className="frame-message-box mb-3" ref={imgRef}>
      <MessageImageBox
        onClick={(e) => handleMessageClick(message.id, e)}
        isSelectingMessages={isSelectingMessages}
        isSelected={isSelected}
      >
        {ownsFrameOrMessage && (
          <PhotoSelectorIndicator
            isSelectingMessages={isSelectingMessages}
            onClick={(e) => toggleSelectedMessage(message.id, e)}
          >
            {isSelected ? (
              <SelectedIndicator type="image" aria-label="Photo Selector" src={greenCheck} />
            ) : (
              <EmptyIndicator>
                <svg height="25" width="25" viewBox="0 0 25 25">
                  <circle
                    cx="12"
                    cy="12"
                    r="10.75"
                    stroke="#c8c8c8"
                    strokeWidth="2.5"
                    fill="none"
                  />
                  <path d="M9.3,16.5 L17.6,7.9" stroke="white" strokeWidth="1.5" />
                  <path d="M10.2,16.5 L6.4,12.8" stroke="white" strokeWidth="1.5" />
                </svg>
              </EmptyIndicator>
            )}
          </PhotoSelectorIndicator>
        )}
        {message.attributes.asset_type === 'video' ? (
          <div className="video-thumbnail-container">
            <div
              className="video-thumbnail"
              style={{ backgroundImage: `url(${message.attributes.thumbnail_url})` }}
            />
            <div className="video-play-icon" />
          </div>
        ) : (
          <img
            src={message.attributes.thumbnail_url || message.attributes.asset_url}
            alt=""
            className="message-img"
          />
        )}
      </MessageImageBox>
      <p className="pt-3 mb-0 text-center sent-by-email">
        Sent by <b>{message.attributes.from_email}</b>
      </p>
      <p className="text-center sent-at-timestamp">
        at <b>{createdAtStr}</b>
      </p>
    </div>
  )
}

MessageItem.propTypes = {
  message: PropTypes.object.isRequired,
  frame: PropTypes.object.isRequired,
  setSelectedImgYPos: PropTypes.func,
  createdAtStr: PropTypes.string,
  currentlyViewingMessage: PropTypes.object,
  isSelected: PropTypes.bool,
  isSelectingMessages: PropTypes.bool,
  toggleSelectedMessage: PropTypes.func,
  frameOwner: PropTypes.bool.isRequired,
}

export default memo(MessageItem)

const SharedSelectorIndicatorStyles = css`
  cursor: pointer;
  border: 0 !important;
  position: absolute;
  top: 5px;
  left: 20px;
  width: 24px;
  height: 24px;
  &:focus,
  &:active {
    outline: 0;
  }
`

const PhotoSelectorIndicator = styled.span`
  display: ${(props) => (props.isSelectingMessages ? 'inline' : 'none')};
`

const SelectedIndicator = styled.input`
  ${SharedSelectorIndicatorStyles}
`

const EmptyIndicator = styled.div`
  ${SharedSelectorIndicatorStyles}
  svg > path {
    display: none;
  }
  &:hover {
    svg > circle {
      fill: #c8c8c8;
    }
    svg > path {
      display: inline !important;
    }
  }
`

const MessageImageBox = styled.div`
  opacity: ${(props) => (props.isSelected ? 0.8 : '')};
  &:hover {
    cursor: pointer;
    opacity: ${(props) => (props.isSelectingMessages ? '' : 0.8)};
    ${PhotoSelectorIndicator} {
      display: inline;
      opacity: ${(props) => (props.isSelectingMessages ? 0.7 : '')};
      svg > circle {
        fill: ${(props) => (props.isSelectingMessages ? '#c8c8c8' : '')};
      }
      svg > path {
        display: ${(props) => (props.isSelectingMessages ? 'inline' : 'none')};
      }
    }
  }
`
