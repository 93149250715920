import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { create, PASSWORD_RESETS_UNFLAG_SUCCESS } from '../actions/passwordResets'

import ResetPassword from '../components/ResetPassword'

import { Analytics } from '../utilities/Analytics'

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { email: props.match.params.email }
    this.requestReset = this.requestReset.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.redirectIfNecessary = this.redirectIfNecessary.bind(this)
    this.redirectIfNecessary()
  }

  redirectIfNecessary() {
    if (this.props.success) {
      this.props.history.push('/check-your-email')
      this.props.dispatch({ type: PASSWORD_RESETS_UNFLAG_SUCCESS })
    }
  }

  componentDidUpdate() {
    this.redirectIfNecessary()
  }

  componentDidMount() {
    Analytics.track('Viewed Password Reset')
    window.scrollTo(0, 0)
  }

  requestReset(event) {
    Analytics.track('Requested Password Reset')
    this.props.dispatch(create(this.state.email))
    event.preventDefault()
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  render() {
    const { errors, requestingReset } = this.props
    return (
      <ResetPassword
        parentState={this.state}
        errors={errors}
        requestingReset={requestingReset}
        requestReset={this.requestReset}
        handleEmailChange={this.handleEmailChange}
      />
    )
  }
}

ResetPasswordContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const { errors, success, requestingReset } = state.passwordResets
  return { errors, success, requestingReset }
}

export default connect(mapStateToProps)(ResetPasswordContainer)
