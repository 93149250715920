export const FRAME_USERS_REQUEST = 'FRAME_USERS_REQUEST'
export const FRAME_USERS_SUCCESS = 'FRAME_USERS_SUCCESS'
export const FRAME_USERS_FAILURE = 'FRAME_USERS_FAILURE'

export function list(frameId) {
  return {
    type: FRAME_USERS_REQUEST,
    api: {
      endpoint: ['frames', frameId, 'users'].join('/'),
      authenticated: true,
      types: [FRAME_USERS_REQUEST, FRAME_USERS_SUCCESS, FRAME_USERS_FAILURE],
      method: 'GET',
    },
  }
}

export const FRAME_USERS_CREATE_REQUEST = 'FRAME_USERS_CREATE_REQUEST'
export const FRAME_USERS_CREATE_SUCCESS = 'FRAME_USERS_CREATE_SUCCESS'
export const FRAME_USERS_CREATE_FAILURE = 'FRAME_USERS_CREATE_FAILURE'

export function create(frameId, email, blocked) {
  return {
    type: FRAME_USERS_CREATE_REQUEST,
    api: {
      endpoint: ['frames', frameId, 'users'].join('/'),
      authenticated: true,
      types: [FRAME_USERS_CREATE_REQUEST, FRAME_USERS_CREATE_SUCCESS, FRAME_USERS_CREATE_FAILURE],
      data: { email, blocked },
      method: 'POST',
    },
  }
}

export const FRAME_USERS_UPDATE_REQUEST = 'FRAME_USERS_UPDATE_REQUEST'
export const FRAME_USERS_UPDATE_SUCCESS = 'FRAME_USERS_UPDATE_SUCCESS'
export const FRAME_USERS_UPDATE_FAILURE = 'FRAME_USERS_UPDATE_FAILURE'

export function block(frameId, userId) {
  return {
    type: FRAME_USERS_UPDATE_REQUEST,
    api: {
      endpoint: ['frames', frameId, 'users', userId].join('/'),
      authenticated: true,
      types: [FRAME_USERS_UPDATE_REQUEST, FRAME_USERS_UPDATE_SUCCESS, FRAME_USERS_UPDATE_FAILURE],
      method: 'DELETE',
    },
  }
}

export function unblock(frameId, userId) {
  return {
    type: FRAME_USERS_UPDATE_REQUEST,
    api: {
      endpoint: ['frames', frameId, 'users', userId, 'approve'].join('/'),
      authenticated: true,
      types: [FRAME_USERS_UPDATE_REQUEST, FRAME_USERS_UPDATE_SUCCESS, FRAME_USERS_UPDATE_FAILURE],
      method: 'POST',
    },
  }
}

export const FRAME_USERS_SET_ERROR = 'FRAME_USERS_SET_ERROR'

export function setError(err) {
  return {
    type: FRAME_USERS_SET_ERROR,
    err,
  }
}
