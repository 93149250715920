import React, { useState, useEffect } from 'react'
import { Switch, Route, useHistory, useLocation, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Modal from 'react-modal'
import styled from 'styled-components'

import FrameMessagesContainer from '../containers/FrameMessagesContainer'
import MessageContainer from '../containers/MessageContainer'

import { destroyMultiple } from '../actions/messages'

function MessageModalOrMessagePage() {
  const location = useLocation()
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const background = location.state && location.state.background

  const curMessage = useSelector((state) => state.messages.message)
  const pages = useSelector((state) => state.messages.pages)
  const preloadedMessages = useSelector((state) => state.messages.preloadedMessages)

  const flattenedPages = pages.reduce((acc, cur) => [...acc, ...cur.messages], [])
  const [curMessageIndex, setCurMessageIndex] = useState(null)
  const [selectedImgYPos, setSelectedImgYPos] = useState(null)

  const selectedMessageIds = useSelector((state) => state.messages.selectedMessageIds)
  const isPlural = selectedMessageIds.length !== 1

  useEffect(() => {
    if (curMessage) {
      const curIndex = flattenedPages.findIndex((el) => el.id === curMessage.id)
      setCurMessageIndex(curIndex)
    }
  }, [curMessage, flattenedPages])

  function navigateMessages(direction) {
    if (direction === 'left') {
      if (curMessageIndex === 0) return
      history.push(`/frames/${params.id}/messages/${flattenedPages[curMessageIndex - 1].id}`, {
        background,
      })
    } else if (direction === 'right') {
      // at the end of a page
      if (curMessageIndex === flattenedPages.length - 1) {
        if (preloadedMessages.length > 0) {
          // scroll to bottom of page to activate infinite scroll
          window.scrollTo(0, document.body.scrollHeight)
          history.push(`/frames/${params.id}/messages/${preloadedMessages[0].id}`, {
            background,
          })
        }
      } else {
        history.push(`/frames/${params.id}/messages/${flattenedPages[curMessageIndex + 1].id}`, {
          background,
        })
      }
    }
  }

  function handleKeyDown(e) {
    if (e.keyCode === 37) {
      navigateMessages('left')
    } else if (e.keyCode === 39) {
      navigateMessages('right')
    }
  }

  function closeModal(e) {
    e.stopPropagation()
    history.push(`/frames/${params.id}/messages`)
  }

  function scrollToImgUnlessInView() {
    const isSelectedImgInView =
      selectedImgYPos > window.scrollY && selectedImgYPos < window.scrollY + window.innerHeight
    if (!isSelectedImgInView) window.scrollTo(0, selectedImgYPos)
  }

  function deleteSelectedMessages(e) {
    dispatch(destroyMultiple(params.id, selectedMessageIds))
    closeModal(e)
  }

  const rootEl = document.getElementById('root')
  Modal.setAppElement(rootEl)

  return (
    <React.Fragment>
      <Switch location={background || location}>
        <Route
          path="/frames/:frameId/messages/:id"
          render={({ match }) => (
            <MessageContainer history={history} match={match} params={params} />
          )}
        />
        <Route
          path="/frames/:id/messages"
          render={({ match }) => (
            <FrameMessagesContainer
              history={history}
              match={match}
              location={location}
              setSelectedImgYPos={setSelectedImgYPos}
            />
          )}
        />
      </Switch>
      {background && location.pathname.match(/frames\/\d+\/messages\/\d+/) && (
        <Route
          path="/frames/:frameId/messages/:id"
          render={({ match }) => (
            <div
              role="presentation"
              onKeyDown={handleKeyDown}
              className="flex-row align-items-center"
            >
              <Modal
                isOpen={!!background}
                onRequestClose={closeModal}
                onAfterClose={scrollToImgUnlessInView}
                className="message-modal-container"
                shouldFocusAfterRender
              >
                <button
                  type="button"
                  className="no-style-btn modal-nav-btn icon-btn"
                  disabled={curMessageIndex === 0}
                  onClick={() => navigateMessages('left')}
                >
                  <span className="oi oi-chevron-left" title="back" aria-hidden="true" />
                </button>
                <MessageContainer isModal history={history} params={params} match={match} />
                <button
                  type="button"
                  className="no-style-btn modal-nav-btn icon-btn"
                  disabled={
                    !preloadedMessages.length && curMessageIndex + 1 === flattenedPages.length
                  }
                  onClick={() => navigateMessages('right')}
                >
                  <span className="oi oi-chevron-right" title="next" aria-hidden="true" />
                </button>
              </Modal>
            </div>
          )}
        />
      )}
      {background && location.pathname.match(/frames\/\d+\/messages\/delete_multiple/) && (
        <Route
          path="/frames/:frameId/messages/delete_multiple"
          render={() => (
            <Modal
              isOpen={!!background}
              onRequestClose={closeModal}
              className="small-modal"
              overlayClassName="small-modal-overlay"
            >
              <DeleteConfirmationHeader>
                Delete Selected Item{isPlural ? 's' : ''}?
              </DeleteConfirmationHeader>
              <p className="text-center">
                {isPlural ? 'These' : 'This'} item{isPlural ? 's' : ''} will be permanently deleted
                from this device.
              </p>
              <section className="d-flex flex-row justify-content-center">
                <CancelBtn
                  type="button"
                  className="btn btn-outline-primary mr-3"
                  onClick={closeModal}
                >
                  Cancel
                </CancelBtn>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => deleteSelectedMessages(e)}
                >
                  Delete
                </button>
              </section>
            </Modal>
          )}
        />
      )}
    </React.Fragment>
  )
}

export default MessageModalOrMessagePage

const DeleteConfirmationHeader = styled.h4`
  text-align: center;
  font-weight: 800;
`

const CancelBtn = styled.button`
  &:hover,
  &:active {
    background-color: #adb5bd !important;
    border-color: #adb5bd !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem #dee2e6 !important;
  }
`
