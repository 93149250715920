import React, { useState } from 'react'
import styled from 'styled-components'
import { MdClose } from 'react-icons/md'

import MobileIcon from '../../assets/images/icon-192.jpg'

const MobilePromoBanner = () => {
  const [showMobilePromoBanner, setShowMobilePromoBanner] = useState(
    JSON.parse(localStorage.getItem('showMobilePromoBanner'))
  )

  const isAndroid = /android/i.test(window.navigator.userAgent)

  const handleClose = () => {
    localStorage.setItem('showMobilePromoBanner', false)
    setShowMobilePromoBanner(false)
  }

  if (!showMobilePromoBanner) return null

  return (
    <Container>
      <MainContent>
        <MdClose size="18" onClick={() => handleClose()} />

        <img src={MobileIcon} alt="Skylight Mobile" width="40" height="40" />
        <TextContent>
          <Header>Skylight App</Header>
          <Subheader>Free on the {isAndroid ? 'Play' : 'App'} store</Subheader>
        </TextContent>
      </MainContent>
      <InstallLink
        className="btn btn-primary"
        href="https://bit.ly/skylightapp"
        onClick={() => handleClose()}
      >
        Install
      </InstallLink>
    </Container>
  )
}

MobilePromoBanner.propTypes = {}

export default MobilePromoBanner

const Container = styled.div`
  background: #f2f1f5;
  margin: 0 -15px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 600px) {
    display: none;
  }
`

const MainContent = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 1rem;
    &:hover,
    &:active {
      opacity: 0.7;
    }
  }
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.75rem;
`

const Header = styled.h4`
  font-weight: bold;
  font-size: 0.95rem;
  margin: 0;
`

const Subheader = styled.h4`
  font-size: 0.95rem;
  margin: 0;
`

const InstallLink = styled.a`
  text-decoration: none;
  color: white;
  font-weight: bold;
  padding: 4px 12px;
`
