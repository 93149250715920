import {
  CATEGORIES_REQUEST,
  CATEGORIES_SUCCESS,
  CATEGORIES_CREATE_SUCCESS,
  CATEGORIES_UPDATE_SUCCESS,
  CATEGORIES_DESTROY_SUCCESS,
} from '../actions/categories'

const DEFAULT_STATE = {
  gettingCategories: true,
  categories: [],
}

export function categories(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case CATEGORIES_REQUEST:
      return Object.assign({}, state, DEFAULT_STATE)
    case CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        gettingCategories: false,
        categories: action.response.data,
      })
    case CATEGORIES_CREATE_SUCCESS:
      let categories = state.categories.slice(0)
      categories.push(action.response.data)
      return Object.assign({}, state, { categories })
    case CATEGORIES_UPDATE_SUCCESS:
      let updatedCategory = action.response.data
      let updatedCategories = state.categories.slice(0)
      updatedCategories.forEach((category, i, a) => {
        if (updatedCategory.id === category.id) a[i] = updatedCategory
      })
      return Object.assign({}, state, { categories: updatedCategories })
    case CATEGORIES_DESTROY_SUCCESS:
      let destroyedCategory = action.response.data
      let remainingCategories = []
      state.categories.forEach((c) => {
        if (c.id != destroyedCategory.id) remainingCategories.push(c)
      })
      return Object.assign({}, state, { categories: remainingCategories })
    default:
      return state
  }
}
