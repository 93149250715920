export const CALENDARS_REQUEST = 'CALENDARS_REQUEST'
export const CALENDARS_SUCCESS = 'CALENDARS_SUCCESS'
export const CALENDARS_FAILURE = 'CALENDARS_FAILURE'

export function show(frameId, accountId) {
  const endpoint = ['frames', frameId, 'calendars', accountId].join('/')
  return {
    type: CALENDARS_REQUEST,
    api: {
      endpoint,
      authenticated: true,
      types: [CALENDARS_REQUEST, CALENDARS_SUCCESS, CALENDARS_FAILURE],
      method: 'GET',
    },
  }
}

export const CALENDARS_UPDATE_REQUEST = 'CALENDARS_UPDATE_REQUEST'
export const CALENDARS_UPDATE_SUCCESS = 'CALENDARS_UPDATE_SUCCESS'
export const CALENDARS_UPDATE_FAILURE = 'CALENDARS_UPDATE_FAILURE'

export function update(frameId, accountId, data) {
  return {
    type: CALENDARS_UPDATE_REQUEST,
    api: {
      data,
      endpoint: ['frames', frameId, 'calendars', accountId].join('/'),
      authenticated: true,
      types: [CALENDARS_UPDATE_REQUEST, CALENDARS_UPDATE_SUCCESS, CALENDARS_UPDATE_FAILURE],
      method: 'PUT',
    },
  }
}

export const CALENDARS_LIST_REQUEST = 'CALENDARS_LIST_REQUEST'
export const CALENDARS_LIST_SUCCESS = 'CALENDARS_LIST_SUCCESS'
export const CALENDARS_LIST_FAILURE = 'CALENDARS_LIST_FAILURE'

export function listCalendars(frameId) {
  const endpoint = ['frames', frameId, 'calendars'].join('/')
  return {
    type: CALENDARS_LIST_REQUEST,
    api: {
      endpoint,
      authenticated: true,
      types: [CALENDARS_LIST_REQUEST, CALENDARS_LIST_SUCCESS, CALENDARS_LIST_FAILURE],
      method: 'GET',
    },
  }
}

export const CALENDARS_DESTROY_REQUEST = 'CALENDARS_DESTROY_REQUEST'
export const CALENDARS_DESTROY_SUCCESS = 'CALENDARS_DESTROY_SUCCESS'
export const CALENDARS_DESTROY_FAILURE = 'CALENDARS_DESTROY_FAILURE'

export function destroyCalendar(frameId, accountId, calendarId) {
  const endpoint = ['frames', frameId, 'calendars', accountId, 'accounts', 'destroy'].join('/')
  return {
    type: CALENDARS_DESTROY_REQUEST,
    api: {
      endpoint,
      data: { id: calendarId },
      authenticated: true,
      types: [CALENDARS_DESTROY_REQUEST, CALENDARS_DESTROY_SUCCESS, CALENDARS_DESTROY_FAILURE],
      method: 'POST',
    },
  }
}

export const NYLAS_URL_REQUEST = 'NYLAS_URL_REQUEST'
export const NYLAS_URL_SUCCESS = 'NYLAS_URL_SUCCESS'
export const NYLAS_URL_FAILURE = 'NYLAS_URL_FAILURE'

export function getNylasUrl(frameId, email) {
  let endpoint = ['frames', frameId, 'calendars', 'authorization_request_url'].join('/')
  endpoint += `?platform=web&provider=nylas&login_hint=${email}`

  return {
    type: NYLAS_URL_REQUEST,
    api: {
      endpoint,
      authenticated: true,
      types: [NYLAS_URL_REQUEST, NYLAS_URL_SUCCESS, NYLAS_URL_FAILURE],
      method: 'GET',
    },
  }
}
