import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { connect } from 'react-redux'

import { list, create, block, unblock, setError } from '../actions/frameUsers'

import FrameFriends from '../components/FrameFriends'

import { Analytics } from '../utilities/Analytics'
import { UrlParams } from '../utilities/UrlParams'

const FRAME_BLOCK_CONFIRMATION =
  "Are you sure you want to block this person? They will not be able to send photos to your Frame, and any photos they've already sent will be removed."

const CAL_BLOCK_CONFIRMATION =
  'Are you sure you want to block this person? They will not be able to send or view events on your Calendar'

class FrameFriendsContainer extends Component {
  constructor(props) {
    super(props)
    const urlParams = UrlParams.get()
    this.state = {
      friendEmail: '',
      activating: !!urlParams.activating,
      newHere: !!urlParams.new_here,
    }
    this.isCalendar = props.frame.attributes.apps[0] === 'calendar'
    this.handleFriendEmailChange = this.handleFriendEmailChange.bind(this)
    this.submitNewFrameFriend = this.submitNewFrameFriend.bind(this)
    this.blockFriend = this.blockFriend.bind(this)
    this.unblockFriend = this.unblockFriend.bind(this)
    this.buttonsFor = this.buttonsFor.bind(this)
  }

  componentDidMount() {
    const { dispatch, frame } = this.props
    dispatch(list(frame.id))
    Analytics.track('Viewed Frame Friends')
    window.scrollTo(0, 0)
  }

  handleFriendEmailChange(event) {
    this.setState({ friendEmail: event.target.value })
  }

  submitNewFrameFriend(event) {
    event.preventDefault()

    const { friendEmail } = this.state
    const { dispatch, frame, users, frameUsers } = this.props

    const owner = frameUsers.find((frameUser) => frameUser.attributes.is_owner)
    const ownerId = owner.relationships.user.data.id
    const ownerEmail = users.find((user) => user.id === ownerId).attributes.email
    if (friendEmail === ownerEmail) {
      dispatch(setError("Can't add your own email"))
      return
    }

    const curFrameUserEmails = users.map((user) => user.attributes.email)
    if (curFrameUserEmails.includes(friendEmail)) {
      dispatch(setError('Email is already in the list'))
      return
    }

    dispatch(create(frame.id, friendEmail))

    this.setState({ friendEmail: '' })
    Analytics.track('Added New Frame Friend')
  }

  blockFriend(id) {
    const { dispatch, frame } = this.props
    if (confirm(this.isCalendar ? CAL_BLOCK_CONFIRMATION : FRAME_BLOCK_CONFIRMATION)) {
      dispatch(block(frame.id, id))
      Analytics.track('Blocked Frame Friend')
    }
  }

  unblockFriend(id) {
    const { dispatch, frame } = this.props
    dispatch(unblock(frame.id, id))
    Analytics.track('Unblocked Frame Friend')
  }

  buttonsFor(frameUser) {
    const { id } = frameUser.relationships.user.data
    switch (frameUser.attributes.status) {
      case 'approved':
        return (
          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={() => this.blockFriend(id)}
          >
            <span
              className="oi oi-circle-x d-none d-sm-inline-block"
              title="Block"
              aria-hidden="true"
            />
            &nbsp;Block
          </button>
        )
      case 'awaiting_approval':
        return (
          <div className="btn-group" role="group" aria-label="Approve or Reject">
            <button
              type="button"
              className="btn btn-sm btn-danger d-none d-sm-inline-block"
              onClick={() => this.blockFriend(id)}
            >
              <span className="oi oi-circle-x" title="Block" aria-hidden="true" />
              &nbsp;Block
            </button>
            <button
              type="button"
              className="btn btn-sm btn-success"
              onClick={() => this.unblockFriend(id)}
            >
              <span
                className="oi oi-circle-check d-none d-sm-inline-block"
                title="Approve"
                aria-hidden="true"
              />
              &nbsp;Approve
            </button>
          </div>
        )
      case 'rejected':
        return (
          <button
            type="button"
            className="btn btn-sm btn-success"
            onClick={() => this.unblockFriend(id)}
          >
            <span
              className="oi oi-circle-check d-none d-sm-inline-block"
              title="Approve"
              aria-hidden="true"
            />
            &nbsp;Unblock
          </button>
        )
      default:
        throw new Error('Unknown frameUser status')
    }
  }

  render() {
    const { frame, users, frameUsers, findingFriends, errors, isAdmin } = this.props
    const { activating, friendEmail, newHere } = this.state
    return (
      <FrameFriends
        isAdmin={isAdmin}
        frame={frame}
        findingFriends={findingFriends}
        users={users}
        frameUsers={frameUsers}
        activating={activating}
        newHere={newHere}
        errors={errors}
        isCalendar={this.isCalendar}
        submitNewFrameFriend={this.submitNewFrameFriend}
        handleFriendEmailChange={this.handleFriendEmailChange}
        newFriendEmail={friendEmail}
        blockFriend={this.blockFriend}
        unblockFriend={this.unblockFriend}
        buttonsFor={this.buttonsFor}
      />
    )
  }
}

FrameFriendsContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  frameUsers: PropTypes.array.isRequired,
  findingFriends: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  frame: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  const { isAdmin } = state.session
  const { users, frameUsers, findingFriends, errors } = state.frameUsers
  return { users, frameUsers, findingFriends, errors, isAdmin }
}

export default connect(mapStateToProps)(FrameFriendsContainer)
