export const UrlParams = {
  get: () => {
    let pairs = window.location.search.replace('?', '').split('&')
    let params = {}
    pairs.forEach((pair) => {
      let [k, v] = pair.split('=')
      params[k] = v
    })
    return params
  },
}
