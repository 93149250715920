import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import NavContainer from './NavContainer'
import PlusLink from '../components/PlusLink'
import PlusUpsell from '../components/PlusUpsell'

import { create, PLUS_PERMISSION_CLEAROUT, startTrial } from '../actions/plusPermissions'
import { show } from '../actions/user'

import { plusPurchasesInstance, RESET_ERROR } from '../actions/plusReceipts'

import { Analytics } from '../utilities/Analytics'
import { UrlParams } from '../utilities/UrlParams'

class PlusLinkContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { email: '', code: '', showRedeemCode: false }
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleCodeChange = this.handleCodeChange.bind(this)
    this.toggleShowRedeemCode = this.toggleShowRedeemCode.bind(this)
    this.searchAlreadyPurchased = this.searchAlreadyPurchased.bind(this)
    this.redeemCode = this.redeemCode.bind(this)
    this.backToDash = this.backToDash.bind(this)
    this.noThanks = this.noThanks.bind(this)
  }

  componentDidUpdate() {
    const { dispatch, history, createdPlusPermission, frame, justBoughtPlus } = this.props
    if (createdPlusPermission || justBoughtPlus) {
      dispatch({ type: PLUS_PERMISSION_CLEAROUT })
      dispatch({ type: RESET_ERROR })
      if (frame) {
        Analytics.track('Upgraded to Plus During Activation')
        history.push(`/frames/${frame.id}/plus-success?activating=true`)
      } else {
        history.push('/')
      }
    }
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  handleCodeChange(event) {
    this.setState({ code: event.target.value })
  }

  toggleShowRedeemCode() {
    const { showRedeemCode } = this.state
    this.setState({ showRedeemCode: !showRedeemCode })
  }

  searchAlreadyPurchased(event) {
    const { email } = this.state
    const { dispatch } = this.props
    dispatch(create(email))
    Analytics.track('Linked to Existing Plus Account')
    dispatch(show())
    event.preventDefault()
  }

  redeemCode(event) {
    const { code } = this.state
    const { dispatch } = this.props
    dispatch(plusPurchasesInstance(code))
    dispatch(show())
    event.preventDefault()
  }

  backToDash() {
    const { history, dispatch } = this.props
    dispatch({ type: RESET_ERROR })
    history.push('/')
  }

  noThanks() {
    const { history, frame, dispatch } = this.props
    dispatch({ type: RESET_ERROR })
    history.push(`/frames/${frame.id}/activate?activating=true`)
  }

  render() {
    const {
      history,
      plusPermissionErrors,
      creatingPlusPermission,
      plusPurchaseInstanceLoading,
      back,
      frame,
      toggleAlreadyPurchased,
      alreadyPurchased,
      redeemError,
    } = this.props
    const { email, code, showRedeemCode } = this.state
    const linkComponent = (
      <PlusLink
        errors={plusPermissionErrors}
        redeemError={redeemError}
        searching={creatingPlusPermission}
        plusPurchaseInstanceLoading={plusPurchaseInstanceLoading}
        email={email}
        code={code}
        showRedeemCode={showRedeemCode}
        handleEmailChange={this.handleEmailChange}
        handleCodeChange={this.handleCodeChange}
        toggleShowRedeemCode={this.toggleShowRedeemCode}
        searchAlreadyPurchased={this.searchAlreadyPurchased}
        redeemCode={this.redeemCode}
        back={back || this.backToDash}
      />
    )
    // Coming from Frame Activation Flow
    if (frame) {
      if (alreadyPurchased) {
        return linkComponent
      }
      return (
        <PlusUpsell
          frame={frame}
          noThanks={this.noThanks}
          toggleAlreadyPurchased={toggleAlreadyPurchased}
        />
      )
    }
    // Coming Direct (from Settings Menu)
    return (
      <div>
        <NavContainer history={history} />
        <div className="row justify-content-md-center">
          <div className="col-lg-6 col-xl-5">{linkComponent}</div>
        </div>
      </div>
    )
  }
}

PlusLinkContainer.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  frame: PropTypes.object,
  createdPlusPermission: PropTypes.bool,
  creatingPlusPermission: PropTypes.bool,
  plusPermissionErrors: PropTypes.array,
  plusPurchaseInstanceLoading: PropTypes.bool,
  back: PropTypes.func,
  toggleAlreadyPurchased: PropTypes.func,
  alreadyPurchased: PropTypes.bool,
  redeemError: PropTypes.string,
  justBoughtPlus: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const {
    creatingPlusPermission,
    createdPlusPermission,
    plusPermissionErrors,
  } = state.plusPermissions

  const { error, justBoughtPlus, plusPurchaseInstanceLoading } = state.plus
  return {
    creatingPlusPermission,
    createdPlusPermission,
    plusPermissionErrors,
    plusPurchaseInstanceLoading,
    redeemError: error,
    justBoughtPlus,
  }
}

export default connect(mapStateToProps)(PlusLinkContainer)
