import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SketchPicker } from 'react-color'

export default class UpdateCategoryModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categoryLabel: props.category.attributes.label,
      categoryColor: props.category.attributes.color,
      displayColorPicker: false,
      className: 'modal fade',
    }
    this.toggleColorPicker = this.toggleColorPicker.bind(this)
    this.dismissColorPicker = this.dismissColorPicker.bind(this)
    this.changeColor = this.changeColor.bind(this)
    this.changeLabel = this.changeLabel.bind(this)
    this.saveIfPresent = this.saveIfPresent.bind(this)
  }

  toggleColorPicker() {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  dismissColorPicker() {
    this.setState({ displayColorPicker: false })
  }

  changeColor(color) {
    this.setState({ categoryColor: color.hex })
  }

  changeLabel(e) {
    this.setState({ categoryLabel: e.target.value })
  }

  saveIfPresent(e) {
    const { categoryLabel, categoryColor } = this.state
    const { update } = this.props
    if (categoryLabel.length > 1) update({ label: categoryLabel, color: categoryColor })
    e.preventDefault()
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ className: 'modal fade show' })
      this.labelInput.focus()
    }, 100)
  }

  render() {
    const { update, dismiss } = this.props
    const { categoryLabel, categoryColor, className, displayColorPicker } = this.state
    return (
      <div className={className} tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog" role="document">
          <form className="mt-4" onSubmit={this.saveIfPresent}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Category</h5>
                <button type="button" className="close" aria-label="Close" onClick={dismiss}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="label" className="mb-0">
                    <b>Category Label</b>
                  </label>
                  <input
                    type="text"
                    name="label"
                    className="form-control"
                    ref={(input) => {
                      this.labelInput = input
                    }}
                    value={categoryLabel}
                    onChange={this.changeLabel}
                    placeholder="Family Events"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="color" className="mb-0">
                    <b>Color Coding</b> <small>click to change</small>
                  </label>
                  <div className="category-color-swatch-container" onClick={this.toggleColorPicker}>
                    <div
                      className="category-color-swatch"
                      style={{ backgroundColor: categoryColor }}
                    />
                  </div>
                  {displayColorPicker ? (
                    <div className="category-color-popover">
                      <div className="category-color-cover" onClick={this.dismissColorPicker} />
                      <SketchPicker color={categoryColor} onChange={this.changeColor} />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary pull-left" onClick={dismiss}>
                  Nevermind
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={categoryLabel.length == 0}
                  onClick={() => update({ label: categoryLabel, color: categoryColor })}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

UpdateCategoryModal.propTypes = {
  update: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
}
