import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import SyncedCalendars from '../components/SyncedCalendars'
import CalendarEventsContainer from './CalendarEventsContainer'
import CalendarCategoriesContainer from './CalendarCategoriesContainer'
import GroceryItemsContainer from './GroceryItemsContainer'
import FrameFriendsContainer from './FrameFriendsContainer'

import { listCalendars, destroyCalendar } from '../actions/calendars'
import { listWebcalAccounts, destroyWebcalAccount } from '../actions/webcalAccounts'

import { Analytics } from '../utilities/Analytics'
import { UrlParams } from '../utilities/UrlParams'

const TABS = [
  { key: 'events', label: 'Events' },
  { key: 'calendars', label: 'Synced Cals' },
  { key: 'users', label: 'Users' },
  { key: 'grocery', label: 'Groceries' },
  { key: 'categories', label: 'Color Categories' },
]

class CalendarContainer extends Component {
  constructor(props) {
    super(props)
    const urlParams = UrlParams.get()
    this.state = { currentTab: urlParams.tab || 'events', navBarExpanded: false }
    this.destroyCalendarAccount = this.destroyCalendarAccount.bind(this)
    this.destroyWebcal = this.destroyWebcal.bind(this)
    this.goToCalendars = this.goToCalendars.bind(this)
    this.toggleNavBar = this.toggleNavBar.bind(this)
  }

  componentDidMount() {
    const { dispatch, frame } = this.props
    dispatch(listCalendars(frame.id))
    dispatch(listWebcalAccounts(frame.id))
    Analytics.track('Viewed Calendar')
  }

  goToCalendars() {
    this.setState({ currentTab: 'calendars' })
  }

  toggleNavBar() {
    const { navBarExpanded } = this.state
    this.setState({ navBarExpanded: !navBarExpanded })
  }

  destroyCalendarAccount(accountId, calendarId) {
    if (confirm('Are you sure you want to stop syncing this calendar and delete all its events?')) {
      const { dispatch, frame } = this.props
      dispatch(destroyCalendar(frame.id, accountId, calendarId))
    }
  }

  destroyWebcal(accountId) {
    if (confirm('Are you sure you want to stop syncing this calendar and delete all its events?')) {
      const { dispatch, frame } = this.props
      dispatch(destroyWebcalAccount(frame.id, accountId))
    }
  }

  render() {
    const { history, calendarAccounts, webcalAccounts, frame } = this.props
    const { currentTab, navBarExpanded } = this.state
    return (
      <div>
        <nav className="navbar navbar-expand-md navbar-light bg-transparent justify-content-between cal-navbar">
          <Link to="/" className="btn btn-sm btn-info">
            ←
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleNavBar}
            aria-expanded={navBarExpanded}
            aria-label="Toggle Calendar Navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            id="calendar-nav"
            className={`collapse navbar-collapse ${
              navBarExpanded ? 'show' : 'justify-content-end'
            }`}
          >
            <ul className={navBarExpanded ? 'navbar-nav text-right border-bottom' : 'nav nav-tabs'}>
              {TABS.map((t) => (
                <li key={t.key} className="nav-item">
                  <a
                    href="#"
                    onClick={() => this.setState({ currentTab: t.key, navBarExpanded: false })}
                    className={currentTab === t.key ? 'nav-link active' : 'nav-link'}
                  >
                    {t.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </nav>
        {currentTab === 'events' && <CalendarEventsContainer goToCalendars={this.goToCalendars} />}
        {currentTab === 'calendars' && (
          <SyncedCalendars
            calendarAccounts={calendarAccounts}
            webcalAccounts={webcalAccounts}
            destroyWebcalAccount={this.destroyWebcal}
            destroyCalendarAccount={this.destroyCalendarAccount}
            frame={frame}
          />
        )}
        {currentTab === 'grocery' && <GroceryItemsContainer />}
        {currentTab === 'categories' && <CalendarCategoriesContainer />}
        {currentTab === 'users' && <FrameFriendsContainer frame={frame} history={history} />}
      </div>
    )
  }
}

CalendarContainer.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  frame: PropTypes.object.isRequired,
  calendarAccounts: PropTypes.array.isRequired,
  webcalAccounts: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => {
  const { calendarAccounts } = state.calendars
  const { webcalAccounts } = state.webcalAccounts
  return { calendarAccounts, webcalAccounts }
}

export default connect(mapStateToProps)(CalendarContainer)
