import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { LOG_OUT } from '../actions/session'
import { show } from '../actions/reminderProfiles'

import Nav from '../components/Nav'

import { UrlParams } from '../utilities/UrlParams'
// import { SafePendo } from '../utilities/SafePendo'

class NavContainer extends Component {
  constructor(props) {
    super(props)
    this.signOut = this.signOut.bind(this)
    this.redirectToRegisterIfNecessary = this.redirectToRegisterIfNecessary.bind(this)
    this.redirectToRegisterIfNecessary()
  }

  componentDidMount() {
    const { loggedIn, dispatch } = this.props
    if (loggedIn) {
      dispatch(show())
    }
  }

  componentDidUpdate() {
    this.redirectToRegisterIfNecessary()
  }

  redirectToRegisterIfNecessary() {
    const { loggedIn, history } = this.props
    if (!loggedIn) {
      let registerUrl = '/register'
      if (window.location.pathname !== '/') {
        registerUrl += `?has_an_account=true`
        const urlParams = UrlParams.get()
        if (urlParams.redirect_if_necessary)
          registerUrl += `&redirect_to=${window.location.pathname}`
      }
      history.push(registerUrl)
    }
  }

  signOut() {
    const { dispatch } = this.props
    dispatch({ type: LOG_OUT })
  }

  render() {
    const {
      email,
      loggedIn,
      isAdmin,
      uploading,
      reminderProfileId,
      reminderProfileToken,
      subscriptionStatus,
    } = this.props
    return (
      <Nav
        email={email}
        signOut={this.signOut}
        loggedIn={!!loggedIn}
        isAdmin={isAdmin}
        uploading={uploading}
        reminderProfileId={reminderProfileId}
        reminderProfileToken={reminderProfileToken}
        subscriptionStatus={subscriptionStatus}
      />
    )
  }
}

NavContainer.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  email: PropTypes.string,
  loggedIn: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  uploading: PropTypes.bool.isRequired,
  reminderProfileId: PropTypes.number,
  reminderProfileToken: PropTypes.string,
  subscriptionStatus: PropTypes.string,
}

const mapStateToProps = (state) => {
  const { loggedIn, isAdmin, email } = state.session
  const { reminderProfileId, reminderProfileToken } = state.reminderProfiles
  const { uploading } = state.messages
  return {
    loggedIn,
    email,
    isAdmin,
    uploading,
    reminderProfileId,
    reminderProfileToken,
  }
}

export default connect(mapStateToProps)(NavContainer)
