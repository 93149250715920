import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Errors from './Errors'

import whoCanSendIcon from '../../assets/images/who-can-send.png'
import whoCanViewIcon from '../../assets/images/who-can-view.png'

import rightArrow from '../../assets/images/right-arrow.png'
import downArrow from '../../assets/images/down-arrow.png'

export default class FramePrivacy extends Component {
  render() {
    const {
      updatingFrame,
      errors,
      togglePrivacy,
      openToPublic,
      email,
      changeEmail,
      saveSettings,
      toggleMessageViewability,
      messageViewability,
      showingAdvancedSettings,
      toggleAdvancedSettings,
    } = this.props
    return (
      <div className="register-box" style={{ maxWidth: 576 }}>
        <Errors errors={errors} />
        <Title>Privacy Settings</Title>
        <SettingGroup>
          <SettingGroupTitleSection>
            <SettingGroupTitle>
              <SettingTitleIcon src={whoCanSendIcon} alt="Who can Send" /> Sending
            </SettingGroupTitle>
            <SettingGroupHeader>
              Who can <b>send</b> photos to your Skylight?
            </SettingGroupHeader>
          </SettingGroupTitleSection>
          <FormCheck className="form-check">
            <Input
              className="form-check-input"
              type="radio"
              name="openToPublic"
              value="closed"
              onChange={togglePrivacy}
              checked={!openToPublic}
            />
            <TextyButton
              type="button"
              onClick={() => togglePrivacy({ target: { value: 'closed' } })}
            >
              <label className="form-check-label" htmlFor="Closed">
                <b>People I approve</b>
              </label>
            </TextyButton>
            <br />
            <small>
              We'll email you at &nbsp;
              <br className="d-sm-none" />{' '}
              <input
                type="email"
                label="Your Personal"
                className="form-control inline-text-field"
                value={email}
                onChange={changeEmail}
                placeholder="Your Personal Email"
              />{' '}
              &nbsp;
              <br className="d-sm-none" /> to approve new senders.
            </small>
          </FormCheck>
          {!openToPublic && (
            <AdvancedSettingsLink onClick={toggleAdvancedSettings}>
              Advanced settings{' '}
              {showingAdvancedSettings ? (
                <img src={downArrow} alt="Down Arrow" />
              ) : (
                <img src={rightArrow} alt="Right Arrow" />
              )}
            </AdvancedSettingsLink>
          )}
          <br />
          <br />
          {(openToPublic || showingAdvancedSettings) && (
            <FormCheck className="form-check">
              <Input
                className="form-check-input"
                type="radio"
                name="openToPublic"
                value="open"
                onChange={togglePrivacy}
                checked={openToPublic}
              />
              <TextyButton
                type="button"
                onClick={() => togglePrivacy({ target: { value: 'open' } })}
              >
                <label className="form-check-label" htmlFor="Open">
                  <b>Anyone with the email address</b>
                  <br />
                  <small style={{ color: '#C64A4F' }}>
                    Warning! With this enabled, anyone can send photos to your Frame without
                    approval if they have your unique email address.
                  </small>
                </label>
              </TextyButton>
            </FormCheck>
          )}
        </SettingGroup>

        <SettingGroup>
          <SettingGroupTitleSection>
            <SettingGroupTitle>
              <SettingTitleIcon src={whoCanViewIcon} alt="Who can View" /> Viewing
            </SettingGroupTitle>
            <SettingGroupHeader>
              Who can <b>view</b> photos on your Skylight?
            </SettingGroupHeader>
            <SettingGroupSubHeader>via Skylight Mobile or Skylight Cloud</SettingGroupSubHeader>
          </SettingGroupTitleSection>
          <FormCheck className="form-check">
            <Input
              className="form-check-input"
              type="radio"
              name="messageViewability"
              value="viewable_to_all_senders"
              onChange={toggleMessageViewability}
              checked={messageViewability === 'viewable_to_all_senders'}
            />
            <TextyButton
              type="button"
              onClick={() =>
                toggleMessageViewability({ target: { value: 'viewable_to_all_senders' } })
              }
            >
              <label className="form-check-label" htmlFor="messageViewability">
                <b>Anyone who sends to my Skylight</b>
              </label>
            </TextyButton>
          </FormCheck>
          <FormCheck className="form-check">
            <Input
              className="form-check-input"
              type="radio"
              name="messageViewability"
              value="only_viewable_to_owner"
              onChange={toggleMessageViewability}
              checked={messageViewability === 'only_viewable_to_owner'}
            />
            <TextyButton
              type="button"
              onClick={() =>
                toggleMessageViewability({ target: { value: 'only_viewable_to_owner' } })
              }
            >
              <label className="form-check-label" htmlFor="messageViewability">
                <b>Only Me</b>
                <br />
                <small>Senders can only see photos they have sent</small>
              </label>
            </TextyButton>
          </FormCheck>
        </SettingGroup>

        <div className="text-right mt-5">
          <button
            type="button"
            onClick={saveSettings}
            className="btn btn-primary"
            disabled={updatingFrame}
          >
            Save Privacy Settings
          </button>
        </div>
      </div>
    )
  }
}

FramePrivacy.propTypes = {
  errors: PropTypes.array,
  updatingFrame: PropTypes.bool.isRequired,
  messageViewability: PropTypes.string.isRequired,
  openToPublic: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  changeEmail: PropTypes.func.isRequired,
  togglePrivacy: PropTypes.func.isRequired,
  toggleMessageViewability: PropTypes.func.isRequired,
  saveSettings: PropTypes.func.isRequired,
  showingAdvancedSettings: PropTypes.bool.isRequired,
  toggleAdvancedSettings: PropTypes.func.isRequired,
}

const Title = styled.h1`
  font-weight: bold;
  text-align: center;
`

const SettingGroup = styled.section`
  margin-top: 2rem;
  &:not(:last-of-type) {
    border-bottom: 1px solid lightgray;
  }
`

const AdvancedSettingsLink = styled.a`
  color: #474746;
  cursor: pointer;
`

const TextyButton = styled.button`
  border: 0;
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background: white;
  text-align: left;
`

const SettingGroupTitle = styled.h2``

const SettingTitleIcon = styled.img`
  margin-bottom: 6px;
  height: 36px;
  width: 36px;
`

const SettingGroupTitleSection = styled.section`
  margin: 1.5rem 0;
`

const SettingGroupHeader = styled.h5``

const SettingGroupSubHeader = styled.p`
  color: lightgray;
`

const FormCheck = styled.div`
  margin: 0 1rem 1.5rem;
`

const Input = styled.input`
  margin-left: -1.5rem;
`

const NotificationEmailField = styled.span`
  @media only screen and (max-width: 550px) {
    display: none;
  }
`
