import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import Errors from './Errors'
import { UrlParams } from '../utilities/UrlParams'

const STATUSES = {
  approved: 'Approved',
  rejected: 'Blocked',
  awaiting_approval: 'Awaiting Approval',
}

export default class FrameFriends extends Component {
  render() {
    const {
      frame,
      findingFriends,
      users,
      activating,
      newHere,
      errors,
      frameUsers,
      submitNewFrameFriend,
      newFriendEmail,
      handleFriendEmailChange,
      buttonsFor,
      isAdmin,
      isCalendar,
    } = this.props
    const curFrameUsers = frameUsers.filter(
      (frameUser) => frameUser.relationships.user.data.id !== frame.relationships.user.data.id
    )
    const onlyUpdating = UrlParams.get().updating
    let nextStep = isAdmin || onlyUpdating ? '/' : `/frames/${frame.id}/privacy`
    if (activating) nextStep += '?activating=true'
    if (newHere) nextStep += '?new_here=true'
    return (
      <div className="med-width-ctn">
        <div className="register-box">
          <h2 className="font-weight-bold mb-3">Invite Friends To Your Skylight</h2>
          <p>
            Take a minute to list the people who you would like to approve to{' '}
            {isCalendar
              ? 'view your Calendar and send events. '
              : 'send photos and videos to your Skylight. '}
            We'll send them an email to let them know along with a link to download our mobile app!
          </p>
          {!isCalendar && (
            <p>
              <b>
                The more people that you invite now, the more photos you'll get on your Skylight.
              </b>
            </p>
          )}
          {!isCalendar && (
            <p>You can also skip this step, and just share your Skylight email on your own.</p>
          )}
          <Errors errors={errors} />
          {findingFriends ? (
            <h4 className="text-center">Loading...</h4>
          ) : (
            <span>
              {curFrameUsers.length > 0 && (
                <table className="table table-striped">
                  <thead>
                    <tr className="d-none d-sm-table-row">
                      <th scope="col">Status</th>
                      <th scope="col">Email</th>
                      <th scope="col" className="text-right">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {curFrameUsers.map((frameUser) => {
                      const { id } = frameUser.relationships.user.data
                      const user = users.find((u) => u.id === id)
                      return (
                        <tr key={frameUser.id}>
                          <td
                            className={`${frameUser.attributes.status}-status d-none d-sm-table-cell`}
                          >
                            {STATUSES[frameUser.attributes.status]}
                          </td>
                          <td className="limited-width">{user.attributes.email}</td>
                          <td className="text-right">{buttonsFor(frameUser)}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </span>
          )}
          <form className="mt-4" onSubmit={submitNewFrameFriend}>
            <div className="form-group">
              <input
                type="email"
                value={newFriendEmail}
                onChange={handleFriendEmailChange}
                className="form-control"
                placeholder="Your Friend's Email"
              />
            </div>
            <div className="pt-1 mb-1 text-center">
              <button id="add-friend-btn" type="submit" className="btn btn-lg btn-primary">
                Add Friend
              </button>
            </div>
          </form>
          <hr />
          {!isCalendar && (
            <div className="row mt-4">
              <div className="col-6">
                {curFrameUsers.length === 0 && (
                  <Link to={nextStep} className="btn btn-info">
                    Skip for Now
                  </Link>
                )}
              </div>
              <div className="text-right col-6">
                {curFrameUsers.length > 0 && (
                  <Link to={nextStep} className="btn btn-info">
                    Finish
                  </Link>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

FrameFriends.propTypes = {
  frame: PropTypes.object.isRequired,
  findingFriends: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  frameUsers: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
  submitNewFrameFriend: PropTypes.func.isRequired,
  newFriendEmail: PropTypes.string.isRequired,
  handleFriendEmailChange: PropTypes.func.isRequired,
  activating: PropTypes.bool.isRequired,
  buttonsFor: PropTypes.func.isRequired,
  newHere: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isCalendar: PropTypes.bool.isRequired,
}
