import { DEVICE_GROUPS_REQUEST, DEVICE_GROUPS_SUCCESS } from '../../actions/admin/deviceGroups'

const DEFAULT_STATE = {
  gettingGroups: true,
  groups: [],
}

export function deviceGroups(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case DEVICE_GROUPS_REQUEST: {
      return { ...state, gettingGroups: true, groups: [] }
    }
    case DEVICE_GROUPS_SUCCESS: {
      return { ...state, gettingGroups: false, groups: action.response.data }
    }
    default:
      return state
  }
}
