export const PLUS_PURCHASES_REQUEST = 'PLUS_PURCHASES_REQUEST'
export const PLUS_PURCHASES_SUCCESS = 'PLUS_PURCHASES_SUCCESS'
export const PLUS_PURCHASES_FAILURE = 'PLUS_PURCHASES_FAILURE'

export function plusPurchasesInstance(promoCode = null) {
  const priceId = process.env.STRIPE_PRICE_ID

  return {
    type: PLUS_PURCHASES_REQUEST,
    api: {
      data: { price_id: priceId, promo_code: promoCode },
      endpoint: 'plus_purchases',
      authenticated: true,
      types: [PLUS_PURCHASES_REQUEST, PLUS_PURCHASES_SUCCESS, PLUS_PURCHASES_FAILURE],
      method: 'POST',
    },
  }
}

export const SET_BOUGHT_PLUS = 'SET_BOUGHT_PLUS'

export function setBoughtPlus() {
  return {
    type: SET_BOUGHT_PLUS,
  }
}

export const RESET_ERROR = 'RESET_ERROR'

export function resetError() {
  return {
    type: RESET_ERROR,
  }
}
