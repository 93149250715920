import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

import { Link } from 'react-router-dom'

import Nav from './Nav'
import Errors from './Errors'

export default class ResetPassword extends Component {
  render() {
    const { errors, requestingReset, requestReset, handleEmailChange, parentState } = this.props
    return (
      <div>
        <Nav loggedIn={false} isAdmin={false} />
        <div className="row justify-content-md-center">
          <div className="col-lg-6 col-xl-4">
            <div className="register-box">
              <h1>Reset Password</h1>
              <Errors errors={errors} />
              <form className="mt-4" onSubmit={requestReset}>
                <div className="form-group">
                  <label htmlFor="email" className="mb-0">
                    Your Personal Email
                  </label>
                  <input
                    type="email"
                    value={parentState.email}
                    onChange={handleEmailChange}
                    className="form-control"
                    placeholder="Your Personal Email"
                  />
                </div>
                <div className="text-right pt-2">
                  <button
                    type="submit"
                    className="btn btn-lg btn-success"
                    disabled={requestingReset}
                  >
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
            <div className="pt-3 pl-2">
              <small className="text-muted">
                Remembered your Password?{' '}
                <Link to={`/register?has_an_account=true`}>Start Over</Link>
              </small>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ResetPassword.propTypes = {
  parentState: PropTypes.object.isRequired,
  requestingReset: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  requestReset: PropTypes.func.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
}
