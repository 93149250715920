export const VERSIONS_REQUEST = 'VERSIONS_REQUEST'
export const VERSIONS_SUCCESS = 'VERSIONS_SUCCESS'
export const VERSIONS_FAILURE = 'VERSIONS_FAILURE'

export function list() {
  return {
    type: VERSIONS_REQUEST,
    api: {
      endpoint: 'android_versions',
      isAdmin: true,
      authenticated: true,
      types: [VERSIONS_REQUEST, VERSIONS_SUCCESS, VERSIONS_FAILURE],
      method: 'GET',
    },
  }
}

export const VERSION_DEPLOYMENTS_REQUEST = 'VERSION_DEPLOYMENTS_REQUEST'
export const VERSION_DEPLOYMENTS_SUCCESS = 'VERSION_DEPLOYMENTS_SUCCESS'
export const VERSION_DEPLOYMENTS_FAILURE = 'VERSION_DEPLOYMENTS_FAILURE'

export function listDeployments(id) {
  return {
    type: VERSION_DEPLOYMENTS_REQUEST,
    api: {
      endpoint: `android_versions/${id}/deployments`,
      isAdmin: true,
      authenticated: true,
      types: [
        VERSION_DEPLOYMENTS_REQUEST,
        VERSION_DEPLOYMENTS_SUCCESS,
        VERSION_DEPLOYMENTS_FAILURE,
      ],
      method: 'GET',
    },
  }
}

export const VERSION_DEPRECATION_REQUEST = 'VERSION_DEPRECATION_REQUEST'
export const VERSION_DEPRECATION_SUCCESS = 'VERSION_DEPRECATION_SUCCESS'
export const VERSION_DEPRECATION_FAILURE = 'VERSION_DEPRECATION_FAILURE'

export function deprecate(id) {
  return {
    type: VERSION_DEPRECATION_REQUEST,
    api: {
      endpoint: `android_versions/${id}/deprecate`,
      isAdmin: true,
      authenticated: true,
      types: [
        VERSION_DEPRECATION_REQUEST,
        VERSION_DEPRECATION_SUCCESS,
        VERSION_DEPRECATION_FAILURE,
      ],
      method: 'POST',
    },
  }
}
