import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { Analytics } from '../utilities/Analytics'
import { updatePostActivationAttribution } from '../actions/purchaseMetadata'

const SHOPIFY = 'shopify'
const AMAZON = 'amazon'
const SAMS = 'sams_club'
const COSTCO = 'costco'
const TARGET = 'target'
const BESTBUY = 'best_buy'
const GIFT = 'gift'

const SHOPIFY_OR_AMAZON_QUESTIONS = [
  { key: SHOPIFY, label: 'I bought it on SkylightFrame.com' },
  { key: AMAZON, label: 'I bought it on Amazon' },
  {
    key: SAMS,
    label: `I bought it at Sam's Club`,
  },
  {
    key: COSTCO,
    label: `I bought it at Costco`,
  },
  {
    key: TARGET,
    label: `I bought it at Target`,
  },
  {
    key: BESTBUY,
    label: `I bought it at Best Buy`,
  },
  { key: GIFT, label: 'I received it as a gift' },
]

export const SHOW_RETAIL_QUESTION = [SAMS, COSTCO, TARGET, BESTBUY]

const RETAIL_QUESTIONS = [
  { key: 'online', label: 'I bought it online' },
  { key: 'in_store', label: 'I bought it at the store' },
]

const SHOW_SECOND_QUESTION = [AMAZON, SAMS, COSTCO, TARGET, BESTBUY]

// Stolen from https://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array
const shuffle = (a) => {
  let j
  let x
  let i
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1))
    x = a[i]
    a[i] = a[j]
    a[j] = x
  }
  return a
}

const CHANNELS = shuffle([
  { key: 'instagram', label: 'Ad on Instagram' },
  { key: 'fb', label: 'Ad on Facebook' },
  { key: 'youtube', label: 'Ad on YouTube' },
  { key: 'tiktok', label: 'Ad on TikTok' },
  { key: 'wom', label: 'Friend or Family Recommendation' },
  { key: 'google', label: 'Searched on Google' },
  { key: 'amazon', label: 'Searched on Amazon' },
  { key: 'repeat', label: 'Repeat buyer' },
  { key: 'podcast', label: 'Podcast' },
  { key: 'influencer', label: 'Influencer on Instagram, YouTube, or TikTok' },
  { key: 'article', label: 'News Article / Review' },
  { key: 'tv', label: 'TV Commercial' },
  { key: 'mail', label: 'Flyer in the Mail' },
  { key: 'retailer', label: `From a retailer (e.g. Target, Sam's Club)` },
  { key: 'radio', label: 'Radio' },
])

const PostActivationAttributionSurvey = ({ frame }) => {
  const dispatch = useDispatch()
  const [shopifyAmazonOrGift, setShopifyAmazonOrGift] = useState(null)
  const [marketingChannel, setMarketingChannel] = useState(null)
  const [onlineOrStore, setOnlineOrStore] = useState(null)

  const showRetailQuestions = SHOW_RETAIL_QUESTION.includes(shopifyAmazonOrGift)

  useEffect(() => {
    Analytics.track('Viewed Amazon Post Activation Survey')
  }, [])

  useEffect(() => {
    if (shopifyAmazonOrGift) {
      Analytics.track('Answered First Question on Post Activation Survey', {
        answer: shopifyAmazonOrGift,
      })
    }
  }, [shopifyAmazonOrGift])

  useEffect(() => {
    if (onlineOrStore) {
      Analytics.track('Answered Retail Question on Post Activation Survey', {
        answer: onlineOrStore,
      })
    }
  }, [onlineOrStore])

  useEffect(() => {
    if (
      marketingChannel &&
      shopifyAmazonOrGift &&
      ((onlineOrStore !== null && showRetailQuestions) || !showRetailQuestions)
    ) {
      dispatch(
        updatePostActivationAttribution(
          frame,
          marketingChannel,
          showRetailQuestions ? `${shopifyAmazonOrGift}_${onlineOrStore}` : shopifyAmazonOrGift
        )
      )
      Analytics.track('Clicked Channel on Post Activation Survey', { channel: marketingChannel })
    }
  }, [marketingChannel, shopifyAmazonOrGift, onlineOrStore, showRetailQuestions, frame, dispatch])

  return (
    <div className="mt-3">
      <h3>How did you get your Skylight?</h3>
      {SHOPIFY_OR_AMAZON_QUESTIONS.map((q) => (
        <div className="form-check mt-2">
          <input
            className="form-check-input"
            type="radio"
            name="shopifyAmazonOrGift"
            aria-label={q.label}
            value={q.key}
            onChange={() => setShopifyAmazonOrGift(q.key)}
            checked={shopifyAmazonOrGift === q.key}
          />
          <TextyButton type="button" onClick={() => setShopifyAmazonOrGift(q.key)}>
            <label className="form-check-label" htmlFor={q.key}>
              {q.label}
            </label>
          </TextyButton>
        </div>
      ))}
      {shopifyAmazonOrGift && showRetailQuestions && (
        <div className="mt-3">
          <h3>Did you buy it online or in store?</h3>
          {RETAIL_QUESTIONS.map((q) => (
            <div className="form-check mt-2">
              <input
                className="form-check-input"
                type="radio"
                name="onlineOrStore"
                aria-label={q.label}
                value={q.key}
                onChange={() => setOnlineOrStore(q.key)}
                checked={onlineOrStore === q.key}
              />
              <TextyButton type="button" onClick={() => setOnlineOrStore(q.key)}>
                <label className="form-check-label" htmlFor={q.key}>
                  {q.label}
                </label>
              </TextyButton>
            </div>
          ))}
        </div>
      )}

      {shopifyAmazonOrGift && (
        <>
          {SHOW_SECOND_QUESTION.includes(shopifyAmazonOrGift) ? (
            <div className="mt-3">
              <h3>How did you FIRST hear about Skylight?</h3>
              {CHANNELS.concat([{ key: 'other', label: 'Other' }]).map((q) => (
                <div className="form-check mt-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="marketingChannel"
                    aria-label={q.label}
                    value={q.key}
                    onChange={() => setMarketingChannel(q.key)}
                    checked={marketingChannel === q.key}
                  />
                  <TextyButton type="button" onClick={() => setMarketingChannel(q.key)}>
                    <label className="form-check-label" htmlFor={q.key}>
                      {q.label}
                    </label>
                  </TextyButton>
                </div>
              ))}
            </div>
          ) : (
            <div className="alert alert-success my-3">Thank you!</div>
          )}
        </>
      )}
    </div>
  )
}

const TextyButton = styled.button`
  border: 0;
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background: white;
  text-align: left;
`

PostActivationAttributionSurvey.propTypes = {
  frame: PropTypes.object.isRequired,
}

export default PostActivationAttributionSurvey
