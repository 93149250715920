import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { DateConverter } from '../../utilities/DateConverter'

import NavContainer from '../../containers/NavContainer'
import Loading from '../Loading'
import Errors from '../Errors'

import { listDeployments } from '../../actions/admin/versions'
import { listDeviceGroups, createDeviceGroupDeployment } from '../../actions/admin/deviceGroups'
import {
  createDeploymentIntent,
  DEPLOYMENT_INTENT_CREATED_CLEAROUT,
} from '../../actions/admin/deployments'

const NewDeploymentIntent = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [percent, setPercent] = useState('')
  const [calPercent, setCalPercent] = useState('')
  const [generalAudience, setGeneralAudience] = useState(true)
  const [deviceGroupId, setDeviceGroupId] = useState('')

  const [uploading, setUploading] = useState(false)

  const { gettingVersionDeployments, version } = useSelector((state) => state.androidVersions)

  const saveDeployment = (event) => {
    event.preventDefault()
    setUploading(true)
    if (generalAudience) {
      dispatch(createDeploymentIntent(id, { percent, cal_percent: calPercent }))
    } else {
      dispatch(createDeviceGroupDeployment(deviceGroupId, id)).then(() =>
        history.push(`/admin-dash/versions/${version.id}`)
      )
    }
  }

  const { createdIntent, deploymentIntentErrors } = useSelector((state) => state.deployments)
  useEffect(() => {
    if (createdIntent) {
      history.push(`/admin-dash/versions/${id}/deployment-intents/${createdIntent.id}`)
      dispatch({ type: DEPLOYMENT_INTENT_CREATED_CLEAROUT })
    }
  }, [dispatch, createdIntent, history, id])

  useEffect(() => {
    if (deploymentIntentErrors.length > 0) setUploading(false)
  }, [deploymentIntentErrors])

  const { groups } = useSelector((state) => state.deviceGroups)
  const updateGroupId = ({ target: { value } }) => setDeviceGroupId(value)

  useEffect(() => {
    dispatch(listDeployments(id))
    dispatch(listDeviceGroups())
  }, [dispatch, id])

  return (
    <>
      <NavContainer history={history} />
      <div className="row justify-content-md-center">
        {gettingVersionDeployments ? (
          <Loading />
        ) : (
          <div className="col-lg-8 col-xl-6">
            <div className="row align-items-center">
              <div className="col-2">
                <p className="mb-0">
                  <button type="button" onClick={history.goBack} className="btn btn-sm btn-info">
                    ← Back
                  </button>
                </p>
              </div>
              <div className="col-8">
                <h1 className="text-center mb-0">Version {version.attributes.name}</h1>
                <p className="text-center text-muted">
                  Uploaded at <b>{DateConverter.call(version.attributes.created_at)}</b>
                </p>
              </div>
            </div>
            <div className="register-box">
              <h3>New Deployment to</h3>
              <Errors errors={deploymentIntentErrors} />
              <form onSubmit={saveDeployment}>
                <div className="row my-3">
                  <div className="col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="generalAudience"
                        onChange={() => setGeneralAudience(!generalAudience)}
                        checked={generalAudience}
                        value
                      />
                      <TextyButton type="button" onClick={() => setGeneralAudience(true)}>
                        <label className="form-check-label" htmlFor="Closed">
                          <b>A random sample of devices</b>
                        </label>
                      </TextyButton>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="generalAudience"
                        onChange={() => setGeneralAudience(!generalAudience)}
                        checked={!generalAudience}
                        value={false}
                      />
                      <TextyButton type="button" onClick={() => setGeneralAudience(false)}>
                        <label className="form-check-label" htmlFor="Closed">
                          <b>A specific group of devices</b>
                        </label>
                      </TextyButton>
                    </div>
                  </div>
                </div>
                {generalAudience ? (
                  <>
                    <div className="form-group">
                      <label htmlFor="percent">
                        Enter the <b>Deployment Percent</b> (e.g. "10" is 10% of Frames)
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        aria-label="Percent"
                        value={percent}
                        onChange={(e) => setPercent(e.target.value)}
                        placeholder="10"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="calPercent">
                        Enter the <b>Calendar Deployment Percent</b> (e.g. "10" is 10% of Calendars)
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        aria-label="Calendar Percent"
                        value={calPercent}
                        onChange={(e) => setCalPercent(e.target.value)}
                        placeholder="10"
                      />
                    </div>
                  </>
                ) : (
                  <div className="form-group">
                    <label htmlFor="reassign-catgegory">Which group?</label>
                    <select
                      name="reassign-catgegory"
                      className="custom-select mr-2"
                      value={deviceGroupId}
                      onChange={updateGroupId}
                    >
                      <option value="">Pick a Group</option>
                      {groups.map((group) => (
                        <option key={group.id} value={group.id}>
                          {group.attributes.title}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <br />
                <div className="row">
                  <div className="col-6">
                    <button type="button" className="btn btn-lg btn-info" onClick={history.goBack}>
                      ← Nevermind
                    </button>
                  </div>
                  <div className="col-6 text-right">
                    <button type="submit" className="btn btn-lg btn-success" disabled={uploading}>
                      {uploading ? 'Creating...' : 'Create'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const TextyButton = styled.button`
  border: 0;
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background: white;
  text-align: left;
`

NewDeploymentIntent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
}

export default NewDeploymentIntent
