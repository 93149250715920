import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import TransferMessagesButtonWithTooltip from './TransferMessagesButtonWithTooltip'
import BackToTop from './BackToTop'

import redTrashIcon from '../../assets/images/red-trash.png'

function SelectedMessagesActionsBar({ frame, frames, selectedMessageIds, deselectAllMessages }) {
  const location = useLocation()
  const history = useHistory()
  return (
    <StyledSelectedMessagesActionsBar>
      <CancelButton className="no-style-btn" onClick={deselectAllMessages}>
        <svg width="24px" height="24px" viewBox="0 0 24 24">
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
        </svg>
      </CancelButton>

      <CeneteredItemsCtn>
        <NumPhotosSelectedText>
          {selectedMessageIds.length} Item{selectedMessageIds.length === 1 ? '' : 's'} Selected
        </NumPhotosSelectedText>
        <button
          type="button"
          className="no-style-btn png-icon ml-2"
          onClick={() =>
            history.push({
              pathname: `/frames/${frame.id}/messages/delete_multiple`,
              state: { background: location },
            })
          }
        >
          <img src={redTrashIcon} alt="Delete" width="25" height="25" />
        </button>
        <TransferMessagesButtonWithTooltip
          frames={frames}
          currentFrame={frame}
          selectedMessageIds={selectedMessageIds}
        />
      </CeneteredItemsCtn>

      <BackToTop isFooterShowing />
    </StyledSelectedMessagesActionsBar>
  )
}

export default memo(SelectedMessagesActionsBar)

SelectedMessagesActionsBar.propTypes = {
  frame: PropTypes.object.isRequired,
  frames: PropTypes.array,
  selectedMessageIds: PropTypes.array.isRequired,
  deselectAllMessages: PropTypes.func.isRequired,
}

const StyledSelectedMessagesActionsBar = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 20px 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  margin: 0 -15px 0 -15px;
  box-shadow: 0 -2px 4px -1px rgba(0, 0, 0, 0.2), 0 -4px 5px 0 rgba(0, 0, 0, 0.14),
    0 -1px 10px 0 rgba(0, 0, 0, 0.12);
`

const CancelButton = styled.button`
  display: flex;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const NumPhotosSelectedText = styled.span`
  font-family: 'FilsonProRegular';
  font-size: 16px;
  margin-top: 2px;
`

const CeneteredItemsCtn = styled.div`
  display: flex;
  align-items: center;
`
