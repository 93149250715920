export const CALENDAR_EVENTS_REQUEST = 'CALENDAR_EVENTS_REQUEST'
export const CALENDAR_EVENTS_SUCCESS = 'CALENDAR_EVENTS_SUCCESS'
export const CALENDAR_EVENTS_FAILURE = 'CALENDAR_EVENTS_FAILURE'

export function list(frameId, data) {
  let { before, after } = data
  let endpoint = `frames/${frameId}/calendar_events?after=${after.toISOString()}&before=${before.toISOString()}`
  return {
    type: CALENDAR_EVENTS_REQUEST,
    api: {
      endpoint,
      authenticated: true,
      types: [CALENDAR_EVENTS_REQUEST, CALENDAR_EVENTS_SUCCESS, CALENDAR_EVENTS_FAILURE],
      method: 'GET',
    },
  }
}

export const CALENDAR_EVENTS_DESTROY_REQUEST = 'CALENDAR_EVENTS_DESTROY_REQUEST'
export const CALENDAR_EVENTS_DESTROY_SUCCESS = 'CALENDAR_EVENTS_DESTROY_SUCCESS'
export const CALENDAR_EVENTS_DESTROY_FAILURE = 'CALENDAR_EVENTS_DESTROY_FAILURE'

export function destroy(frameId, eventId) {
  let endpoint = `frames/${frameId}/calendar_events/${eventId}`
  return {
    type: CALENDAR_EVENTS_DESTROY_REQUEST,
    api: {
      endpoint,
      authenticated: true,
      types: [
        CALENDAR_EVENTS_DESTROY_REQUEST,
        CALENDAR_EVENTS_DESTROY_SUCCESS,
        CALENDAR_EVENTS_DESTROY_FAILURE,
      ],
      method: 'DELETE',
    },
  }
}
