import {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_SHOW_REQUEST,
  USERS_SHOW_SUCCESS,
  USERS_CREATE_REQUEST,
  USERS_CREATE_SUCCESS,
  USERS_CREATE_FAILURE,
  USERS_DOWNGRADE_SUCCESS,
  USERS_UPGRADE_REQUEST,
  USERS_UPGRADE_SUCCESS,
  USERS_UPGRADE_FAILURE,
  USERS_EXTEND_TRIAL_SUCCESS,
  USERS_NEW_TRIAL_SUCCESS,
  USERS_CANCEL_TRIAL_SUCCESS,
} from '../../actions/admin/users'

const DEFAULT_STATE = { gettingUsers: true, users: [], gettingUser: true, user: null, creatingPlusUser: false, newPlusUser: false, errors: [] }

export function users(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case USERS_REQUEST: {
      return { ...state, gettingUsers: true, users: [], errors: [] }
    }
    case USERS_SUCCESS: {
      return { ...state, gettingUsers: false, users: action.response.data }
    }
    case USERS_SHOW_REQUEST: {
      return { ...state, gettingUser: true, user: null, errors: [] }
    }
    case USERS_CREATE_REQUEST: {
      return { ...state, creatingPlusUser: true, newPlusUser: false, errors: [] }
    }
    case USERS_CREATE_SUCCESS: {
      return { ...state, creatingPlusUser: false, newPlusUser: true, errors: [] }
    }
    case USERS_UPGRADE_REQUEST: {
      return { ...state, errors: [] }
    }
    case USERS_UPGRADE_SUCCESS:
    case USERS_DOWNGRADE_SUCCESS:
    case USERS_EXTEND_TRIAL_SUCCESS:
    case USERS_NEW_TRIAL_SUCCESS:
    case USERS_CANCEL_TRIAL_SUCCESS:
    case USERS_SHOW_SUCCESS: {
      return { ...state, gettingUser: false, user: action.response.data }
    }
    case USERS_CREATE_FAILURE:
    case USERS_UPGRADE_FAILURE: {
      return { ...state, errors: action.errors }
    }
    default:
      return state
  }
}
