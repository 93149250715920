import { LOG_OUT } from '../actions/session'

import {
  FRAME_USERS_REQUEST,
  FRAME_USERS_SUCCESS,
  FRAME_USERS_CREATE_REQUEST,
  FRAME_USERS_CREATE_SUCCESS,
  FRAME_USERS_CREATE_FAILURE,
  FRAME_USERS_UPDATE_SUCCESS,
  FRAME_USERS_SET_ERROR,
} from '../actions/frameUsers'

const DEFAULT_STATE = {
  findingFriends: false,
  users: [],
  frameUsers: [],
  errors: [],
}
export function frameUsers(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case FRAME_USERS_REQUEST: {
      return { ...state, users: [], frameUsers: [], findingFriends: true, errors: [] }
    }
    case FRAME_USERS_SUCCESS: {
      return {
        ...state,
        frameUsers: action.response.data,
        users: action.response.included,
        findingFriends: false,
      }
    }
    case FRAME_USERS_CREATE_REQUEST: {
      return { ...state, errors: [] }
    }
    case FRAME_USERS_CREATE_SUCCESS: {
      const { response } = action
      const { data, included } = response
      const users = state.users.concat(included)
      const frameUsers = state.frameUsers.slice(0)
      frameUsers.push(data)
      return { ...state, users, frameUsers }
    }
    case FRAME_USERS_CREATE_FAILURE: {
      return { ...state, errors: action.errors }
    }
    case FRAME_USERS_UPDATE_SUCCESS: {
      const updatedUser = action.response.data
      const updatedFrameUsers = state.frameUsers.slice(0)
      updatedFrameUsers.forEach((user, i, a) => {
        if (updatedUser.id === user.id) a[i] = updatedUser
      })
      return { ...state, frameUsers: updatedFrameUsers }
    }
    case FRAME_USERS_SET_ERROR: {
      return { ...state, errors: [action.err] }
    }
    case LOG_OUT: {
      return { ...state, ...DEFAULT_STATE }
    }
    default:
      return state
  }
}
