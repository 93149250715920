import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class UpgradeModal extends Component {
  constructor(props) {
    super(props)
    this.state = { plusUserEmail: '' }
    this.changeUserEmail = this.changeUserEmail.bind(this)
  }

  changeUserEmail(e) {
    this.setState({ plusUserEmail: e.target.value })
  }

  render() {
    const { dismiss, save, kind } = this.props
    const { plusUserEmail } = this.state
    return (
      <div id="migration-modal" className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Link to Another { kind } Account</h5>
              <button type="button" className="close" aria-label="Close" onClick={dismiss}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>What is the <b>personal email address</b> of the user who purchased { kind }?</p>
              <p className='text-muted'>Leave blank to upgrade them to { kind } for free.</p>
              <input
                type='email'
                className='form-control mt-4'
                value={plusUserEmail}
                onChange={this.changeUserEmail}
                placeholder='janesmith@gmail.com' />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary pull-left" onClick={dismiss}>Nevermind</button>
              <button type="button" className="btn btn-primary" onClick={() => save(plusUserEmail)}>Upgrade!</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

UpgradeModal.propTypes = {
  dismiss: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired
}