import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import NavContainer from '../../containers/NavContainer'
import Loading from '../Loading'

import { DateConverter } from '../../utilities/DateConverter'
import { list } from '../../actions/admin/fleetHealths'

const FleetHealthList = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { gettingFleetHealths, fleetHealths } = useSelector((state) => state.fleetHealths)

  useEffect(() => {
    dispatch(list())
  }, [dispatch])

  const [comparisonExperimental, setComparisonExperimental] = useState(null)
  const [comparisonControlId, setComparisonControlId] = useState(undefined)

  const dismissComparisonModal = () => {
    setComparisonExperimental(null)
    setComparisonControlId(undefined)
  }

  const handleComparisonControlChange = (e) => {
    setComparisonControlId(e.target.value)
  }

  const navigateToComparison = () => {
    history.push(`/admin-dash/fleet-health/${comparisonControlId}/vs/${comparisonExperimental.id}`)
  }

  const ComparisonPickerModal = () => (
    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Which timeframe should we compare?</h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={dismissComparisonModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              <b>Experimental Group</b>: The week leading up to{' '}
              <b>{DateConverter.call(comparisonExperimental.attributes.window_ends_at)}</b>
            </p>
            <p>
              <b>Control Group</b>:{' '}
            </p>
            <select
              className="custom-select mr-2"
              value={comparisonControlId}
              onChange={handleComparisonControlChange}
            >
              <option value="">Pick a Control Group</option>
              {fleetHealths.map((fleetHealth) => (
                <option key={fleetHealth.id} value={fleetHealth.id}>
                  The week leading up to {DateConverter.call(fleetHealth.attributes.window_ends_at)}
                </option>
              ))}
            </select>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={dismissComparisonModal}
            >
              Nevermind
            </button>
            {comparisonControlId && (
              <button type="button" className="btn btn-success" onClick={navigateToComparison}>
                See Comparison
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <NavContainer history={history} />
      {comparisonExperimental && <ComparisonPickerModal />}
      <div className="row justify-content-md-center">
        <div className="col-lg-12 col-xl-8">
          <h2>Fleet Health Reports</h2>
          <hr />
          <div className="row align-items-center">
            <div className="col-12">
              {gettingFleetHealths ? (
                <Loading />
              ) : (
                <div>
                  {fleetHealths.map((fleetHealth) => (
                    <div key={fleetHealth.id} className="row mt-3 align-items-center">
                      <div className="col-sm-8 mt-2">
                        <h5 className="mb-0">
                          Report from{' '}
                          <b>{DateConverter.call(fleetHealth.attributes.window_ends_at)}</b>
                        </h5>
                      </div>
                      <div className="col-sm-4 mt-2 text-right">
                        <button
                          onClick={() => setComparisonExperimental(fleetHealth)}
                          type="button"
                          className="btn btn-info"
                        >
                          Analyze
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FleetHealthList
