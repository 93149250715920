import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { DateConverter } from '../../utilities/DateConverter'

import NavContainer from '../../containers/NavContainer'
import Loading from '../Loading'
import Errors from '../Errors'

import { showDeploymentIntent, promoteDeploymentIntent } from '../../actions/admin/deployments'

const DeploymentIntent = ({
  match: {
    params: { versionId, id },
  },
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    gettingDeploymentIntent,
    deploymentIntent,
    version,
    promotingDeploymentIntent,
    deploymentIntentErrors,
  } = useSelector(state => state.deployments)
  useEffect(() => {
    dispatch(showDeploymentIntent(versionId, id))
  }, [dispatch, versionId, id])
  // eslint-disable-next-line camelcase
  const { created_at, explanation, status } = deploymentIntent ? deploymentIntent.attributes : {}
  console.log(status)
  return (
    <>
      <NavContainer history={history} />
      <div className="row justify-content-md-center">
        {gettingDeploymentIntent ? (
          <Loading />
        ) : (
          <div className="col-lg-8 col-xl-6">
            <Errors errors={deploymentIntentErrors} />
            <div className="row align-items-center">
              <div className="col-2">
                <p className="mb-0">
                  <button type="button" onClick={history.goBack} className="btn btn-sm btn-info">
                    ← Back
                  </button>
                </p>
              </div>
              <div className="col-8">
                <h1 className="text-center mb-0">Version {version.attributes.name}</h1>
                <p className="text-center text-muted">
                  Suggested at <b>{DateConverter.call(created_at)}</b>
                </p>
              </div>
              <div className="col-2">
                <p className="mb-0 text-right">
                  {status === 'awaiting_approval' ? (
                    <button
                      type="button"
                      disabled={promotingDeploymentIntent}
                      onClick={() => dispatch(promoteDeploymentIntent(versionId, id))}
                      className="btn btn-sm btn-success"
                    >
                      {promotingDeploymentIntent ? 'Approving' : 'Approve!'}
                    </button>
                  ) : (
                    'Approved'
                  )}
                </p>
              </div>
            </div>
            <br />
            <div className="row align-items-center">
              <div className="col-12">
                {explanation === null ? (
                  <p className="text-danger text-center">
                    Summary being prepared. Please reload the page!
                  </p>
                ) : (
                  <table className="table table-striped">
                    <thead>
                      <tr className="d-none d-sm-table-row">
                        <th scope="col">Group</th>
                        <th scope="col">Num Devices</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(explanation).map(key => (
                        <tr key={key}>
                          <td>{key}</td>
                          <td>{explanation[key]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

DeploymentIntent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      versionId: PropTypes.string.isRequired,
    }),
  }),
}

export default DeploymentIntent
