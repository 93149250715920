import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Analytics } from '../utilities/Analytics'

import PlusShare from '../components/PlusShare'

import { publicCreate } from '../actions/plusPermissions'

const PlusShareContainer = ({
  dispatch,
  creatingPlusPermission,
  createdPlusPermission,
  plusPermissionErrors,
}) => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')

  const sharePlus = (event) => {
    dispatch(publicCreate({ email, name }))
    event.preventDefault()
  }

  return (
    <PlusShare
      email={email}
      setEmail={setEmail}
      name={name}
      finished={createdPlusPermission}
      setName={setName}
      sharingPlus={creatingPlusPermission}
      sharePlus={sharePlus}
      errors={plusPermissionErrors}
    />
  )
}

PlusShareContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  creatingPlusPermission: PropTypes.bool.isRequired,
  createdPlusPermission: PropTypes.bool.isRequired,
  plusPermissionErrors: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => {
  const {
    creatingPlusPermission,
    createdPlusPermission,
    plusPermissionErrors,
  } = state.plusPermissions
  return { creatingPlusPermission, createdPlusPermission, plusPermissionErrors }
}

export default connect(mapStateToProps)(PlusShareContainer)
