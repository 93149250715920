import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { UrlParams } from '../../utilities/UrlParams'

import AdminFramesContainer from './AdminFramesContainer'

import FramesHeader from '../../components/admin/FramesHeader'
import Pagination from '../../components/Pagination'

import { clearLocalFrames, list, reset, UPDATE_SELECTED_FRAMES } from '../../actions/frames'
import { listDeployments } from '../../actions/admin/deployments'
import { update } from '../../actions/admin/deviceDeployments'
import { listDeviceGroups, addDeviceToGroup } from '../../actions/admin/deviceGroups'

class AdminDashboardContainer extends Component {
  constructor(props) {
    super(props)
    const urlParams = UrlParams.get()
    this.state = {
      groupId: '',
      deploymentId: '',
      searchQuery: urlParams.q || '',
      showDeletedFrames: urlParams?.show_deleted?.toLowerCase() === 'true' || false,
    }
    this.toggleFrame = this.toggleFrame.bind(this)
    this.addToDeployment = this.addToDeployment.bind(this)
    this.handleDeploymentIdChange = this.handleDeploymentIdChange.bind(this)
    this.addToGroup = this.addToGroup.bind(this)
    this.handleGroupIdChange = this.handleGroupIdChange.bind(this)
    this.searchFrames = this.searchFrames.bind(this)
    this.searchFramesOnToggle = this.searchFramesOnToggle.bind(this)
    this.updateSearchQuery = this.updateSearchQuery.bind(this)
    this.updateShowDeletedFrames = this.updateShowDeletedFrames.bind(this)
    this.toPage = this.toPage.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    const { searchQuery } = this.state
    dispatch(listDeployments())
    dispatch(listDeviceGroups())
    this.searchFrames(searchQuery)
  }

  toggleFrame(id) {
    const { selectedFrameIds, dispatch } = this.props
    const index = selectedFrameIds.indexOf(id)
    if (index === -1) {
      selectedFrameIds.push(id)
    } else {
      selectedFrameIds.splice(index, 1)
    }
    dispatch({ type: UPDATE_SELECTED_FRAMES, selectedFrameIds })
  }

  handleGroupIdChange(event) {
    this.setState({ groupId: event.target.value })
  }

  addToGroup(event) {
    event.preventDefault()
    const { groupId } = this.state
    if (groupId !== '') {
      const { selectedFrameIds, dispatch } = this.props
      selectedFrameIds.forEach((frameId) => dispatch(addDeviceToGroup(groupId, frameId)))
      dispatch({ type: UPDATE_SELECTED_FRAMES, selectedFrameIds: [] })
    }
  }

  handleDeploymentIdChange(event) {
    this.setState({ deploymentId: event.target.value })
  }

  addToDeployment(event) {
    event.preventDefault()
    const { deploymentId } = this.state
    if (deploymentId !== '') {
      const { selectedFrameIds, dispatch } = this.props
      selectedFrameIds.forEach((frameId) => {
        dispatch(update(deploymentId, frameId))
      })
      dispatch({ type: UPDATE_SELECTED_FRAMES, selectedFrameIds: [] })
    }
  }

  updateSearchQuery(searchQuery) {
    this.setState({ searchQuery })
  }

  updateShowDeletedFrames(showDeletedFrames) {
    this.setState({ showDeletedFrames })
  }

  searchFramesOnToggle(showDeletedFrames) {
    const { dispatch, history } = this.props
    const { searchQuery } = this.state
    let url = '/'
    if (searchQuery) {
      url += `?q=${searchQuery}&show_deleted=${showDeletedFrames}`
      dispatch(list(true, searchQuery, 1, showDeletedFrames))
    } else {
      dispatch(clearLocalFrames())
    }
    history.push(url)
    this.setState({ searchQuery })
  }

  searchFrames(searchQuery) {
    const { dispatch, history } = this.props
    const { showDeletedFrames } = this.state
    let url = '/'
    if (searchQuery) {
      url += `?q=${searchQuery}&show_deleted=${showDeletedFrames}`
      dispatch(list(true, searchQuery, 1, showDeletedFrames))
    } else {
      dispatch(clearLocalFrames())
    }
    history.push(url)
    this.setState({ searchQuery })
  }

  toPage(page) {
    const { dispatch, history } = this.props
    const { searchQuery, showDeletedFrames } = this.state
    let url = '/?'
    if (searchQuery) url += `q=${searchQuery}&show_deleted=${showDeletedFrames}&`
    url += `page=${page}`
    history.push(url)
    dispatch(list(true, searchQuery, page, showDeletedFrames))
  }

  render() {
    // FramesHeader props
    const { deploymentId, groupId, searchQuery, showDeletedFrames } = this.state
    const { deployments, versions, groups, selectedFrameIds } = this.props
    // AdminFramesContainer props
    const { dispatch, showSearchEmptyState } = this.props
    // Pagination props
    const { currentPage, numPages } = this.props

    return (
      <div className="col-lg-12 col-xl-10">
        <FramesHeader
          selectedFrameIds={selectedFrameIds}
          deploymentId={deploymentId}
          deployments={deployments}
          versions={versions}
          handleDeploymentIdChange={this.handleDeploymentIdChange}
          addToDeployment={this.addToDeployment}
          groups={groups}
          groupId={groupId}
          handleGroupIdChange={this.handleGroupIdChange}
          addToGroup={this.addToGroup}
          searchFunc={this.searchFrames}
          toggleFunc={this.searchFramesOnToggle}
          searchQuery={searchQuery}
          showDeletedFrames={showDeletedFrames}
          updateShowDeletedFrames={this.updateShowDeletedFrames}
          updateSearchQuery={this.updateSearchQuery}
        />
        <hr />
        <AdminFramesContainer
          dispatch={dispatch}
          toggleFrame={this.toggleFrame}
          searchQuery={searchQuery}
          showSearchEmptyState={showSearchEmptyState}
        />
        {!!numPages && numPages > 1 && (
          <Pagination currentPage={currentPage} numPages={numPages} toPage={this.toPage} />
        )}
      </div>
    )
  }
}

AdminDashboardContainer.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  deployments: PropTypes.array.isRequired,
  versions: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  selectedFrameIds: PropTypes.array.isRequired,
  currentPage: PropTypes.number,
  numPages: PropTypes.number,
  showSearchEmptyState: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const { deployments, versions } = state.deployments
  const { groups } = state.deviceGroups
  const { selectedFrameIds, currentPage, numPages, showSearchEmptyState } = state.frames
  return {
    deployments,
    versions,
    groups,
    selectedFrameIds,
    currentPage,
    numPages,
    showSearchEmptyState,
  }
}

export default connect(mapStateToProps)(AdminDashboardContainer)
