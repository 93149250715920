import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Errors from '../Errors'

export default class NewPlusUser extends Component {
  render() {
    const {
      creatingPlusUser,
      errors,
      handleSubmit,
      handleEmailChange,
      parentState,
    } = this.props
    const { email, successMsg } = parentState
    return (
      <div className="col-lg-6 col-xl-4">
        <div className="register-box">
          <h1>Create a new Plus user</h1>
          <Errors errors={errors} />
          {successMsg && !errors.length && <div className="alert-success">{successMsg}</div>}
          <form className="mt-4" onSubmit={handleSubmit}>
            <div className="form-group">
              <code className="full-width">
                This form will create a new user with free Plus forever; not intended for use by the
                CS team for regular customers.
              </code>
              <label htmlFor="name">New Plus User Email Address</label>
              <div className="input-group">
                <input
                  type="name"
                  value={email}
                  onChange={handleEmailChange}
                  className="form-control"
                  placeholder="e.g., editor@brandname.com"
                />
              </div>
            </div>
            <div className="mt-5 mb-2 text-right">
              <button type="submit" className="btn btn-lg btn-success" disabled={creatingPlusUser}>
                Create Plus User
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

NewPlusUser.propTypes = {
  parentState: PropTypes.object.isRequired,
  creatingPlusUser: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
}
