export const MESSAGES_REQUEST = 'MESSAGES_REQUEST'
export const MESSAGES_SUCCESS = 'MESSAGES_SUCCESS'
export const MESSAGES_PRELOAD_REQUEST = 'MESSAGES_PRELOAD_REQUEST'
export const MESSAGES_PRELOAD_SUCCESS = 'MESSAGES_PRELOAD_SUCCESS'
export const MESSAGES_APPEND_PRELOADED = 'MESSAGES_APPEND_PRELOADED'
export const MESSAGES_FAILURE = 'MESSAGES_FAILURE'

export function list(frameId, reqType, page) {
  let endpoint = ['frames', frameId, 'messages'].join('/')
  if (page) endpoint += `?page=${page}`
  const allReqTypes = {
    init: {
      type: MESSAGES_REQUEST,
      types: [MESSAGES_REQUEST, MESSAGES_SUCCESS, MESSAGES_FAILURE],
    },
    preload: {
      type: MESSAGES_PRELOAD_REQUEST,
      types: [MESSAGES_PRELOAD_REQUEST, MESSAGES_PRELOAD_SUCCESS, MESSAGES_FAILURE],
    },
  }
  const {
    [reqType]: { type, types },
  } = allReqTypes
  return {
    type,
    api: {
      endpoint,
      types,
      authenticated: true,
      method: 'GET',
    },
  }
}

export function appendPreloaded() {
  return {
    type: MESSAGES_APPEND_PRELOADED,
  }
}

export const MESSAGE_SHOW_REQUEST = 'MESSAGE_SHOW_REQUEST'
export const MESSAGE_SHOW_SUCCESS = 'MESSAGE_SHOW_SUCCESS'
export const MESSAGE_SHOW_FAILURE = 'MESSAGE_SHOW_FAILURE'

export function showMessage(frameId, id) {
  return {
    type: MESSAGE_SHOW_REQUEST,
    api: {
      endpoint: ['frames', frameId, 'messages', id].join('/'),
      authenticated: true,
      types: [MESSAGE_SHOW_REQUEST, MESSAGE_SHOW_SUCCESS, MESSAGE_SHOW_FAILURE],
      method: 'GET',
    },
  }
}

export const MESSAGE_DOWNLOAD_REQUEST = 'MESSAGE_DOWNLOAD_REQUEST'
export const MESSAGE_DOWNLOAD_SUCCESS = 'MESSAGE_DOWNLOAD_SUCCESS'
export const MESSAGE_DOWNLOAD_FAILURE = 'MESSAGE_DOWNLOAD_FAILURE'

export function download(frameId, id) {
  return {
    type: MESSAGE_DOWNLOAD_REQUEST,
    api: {
      endpoint: ['frames', frameId, 'messages', id, 'download'].join('/'),
      authenticated: true,
      types: [MESSAGE_DOWNLOAD_REQUEST, MESSAGE_DOWNLOAD_SUCCESS, MESSAGE_DOWNLOAD_FAILURE],
      method: 'GET',
    },
  }
}

export const MESSAGE_DESTROY_REQUEST = 'MESSAGE_DESTROY_REQUEST'
export const MESSAGE_DESTROY_SUCCESS = 'MESSAGE_DESTROY_SUCCESS'
export const MESSAGE_DESTROY_FAILURE = 'MESSAGE_DESTROY_FAILURE'

export function destroy(frameId, id) {
  return {
    type: MESSAGE_DESTROY_REQUEST,
    api: {
      endpoint: ['frames', frameId, 'messages', id].join('/'),
      authenticated: true,
      types: [MESSAGE_DESTROY_REQUEST, MESSAGE_DESTROY_SUCCESS, MESSAGE_DESTROY_FAILURE],
      method: 'DELETE',
    },
  }
}

export const MESSAGE_DESTROY_MULTIPLE_REQUEST = 'MESSAGE_DESTROY_MULTIPLE_REQUEST'
export const MESSAGE_DESTROY_MULTIPLE_SUCCESS = 'MESSAGE_DESTROY_MULTIPLE_SUCCESS'
export const MESSAGE_DESTROY_MULTIPLE_FAILURE = 'MESSAGE_DESTROY_MULTIPLE_FAILURE'

export function destroyMultiple(frameId, selectedMessageIds) {
  return {
    type: MESSAGE_DESTROY_MULTIPLE_REQUEST,
    api: {
      endpoint: `frames/${frameId}/messages/destroy_multiple`,
      data: { message_ids: selectedMessageIds },
      authenticated: true,
      types: [
        MESSAGE_DESTROY_MULTIPLE_REQUEST,
        MESSAGE_DESTROY_MULTIPLE_SUCCESS,
        MESSAGE_DESTROY_MULTIPLE_FAILURE,
      ],
      method: 'DELETE',
    },
  }
}

export const TOGGLE_SELECTED_MESSAGE = 'TOGGLE_SELECTED_MESSAGE'

export function toggleSelectedMessage(messageId) {
  return {
    type: TOGGLE_SELECTED_MESSAGE,
    messageId,
  }
}

export const DESELECT_ALL_MESSAGES = 'DESELECT_ALL_MESSAGES'

export function deselectAllMessages() {
  return {
    type: DESELECT_ALL_MESSAGES,
  }
}

export function destroyDuplicates(frameId, id) {
  return {
    type: MESSAGE_DESTROY_REQUEST,
    api: {
      endpoint: ['frames', frameId, 'messages', id, 'destroy_duplicates'].join('/'),
      authenticated: true,
      types: [MESSAGE_DESTROY_REQUEST, MESSAGE_DESTROY_SUCCESS, MESSAGE_DESTROY_FAILURE],
      method: 'DELETE',
    },
  }
}

export const MESSAGE_DESTROY_ALL_REQUEST = 'MESSAGE_DESTROY_ALL_REQUEST'
export const MESSAGE_DESTROY_ALL_SUCCESS = 'MESSAGE_DESTROY_ALL_SUCCESS'
export const MESSAGE_DESTROY_ALL_FAILURE = 'MESSAGE_DESTROY_ALL_FAILURE'

export function destroyAll(frameId) {
  return {
    type: MESSAGE_DESTROY_ALL_REQUEST,
    api: {
      endpoint: ['frames', frameId, 'messages', 'destroy_all'].join('/'),
      authenticated: true,
      types: [
        MESSAGE_DESTROY_ALL_REQUEST,
        MESSAGE_DESTROY_ALL_SUCCESS,
        MESSAGE_DESTROY_ALL_FAILURE,
      ],
      method: 'DELETE',
    },
  }
}
