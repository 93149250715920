import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Intercom from 'react-intercom'
import ReactRouterPropTypes from 'react-router-prop-types'
import { connect } from 'react-redux'
import NavContainer from './NavContainer'
import AdminDashboardContainer from './admin/AdminDashboardContainer'
import Dashboard from '../components/Dashboard'
import Notifications from '../components/Notifications'
import TrialBanner from '../components/TrialBanner'
import { UrlParams } from '../utilities/UrlParams'
import { list, clearAllMessages } from '../actions/frames'
import { show } from '../actions/user'

class DashboardContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { clearingAll: false, searchQuery: '', lastSearched: '' }
    this.clearAll = this.clearAll.bind(this)
    this.toggleClearingAll = this.toggleClearingAll.bind(this)
    this.updateSearchQuery = this.updateSearchQuery.bind(this)
    this.search = this.search.bind(this)
  }

  componentDidMount() {
    const { loggedIn, isAdmin, dispatch, history } = this.props
    if (loggedIn && !isAdmin) {
      dispatch(show())
      const redirectTo = localStorage.getItem('redirectTo')
      if (redirectTo) {
        localStorage.removeItem('redirectTo')
        history.push(redirectTo)
      } else {
        dispatch(list())
      }
    }
  }

  componentDidUpdate() {
    const { history, isAdmin, gettingFrames, frames, notifications } = this.props
    const { lastSearched } = this.state
    const urlParams = UrlParams.get()
    if (!gettingFrames && !isAdmin && frames.length === 0 && lastSearched === '') {
      history.push('/frames/new?new_here=true')
    } else {
      window.scrollTo(0, 0)
    }
  }

  clearAll() {
    const { dispatch } = this.props
    dispatch(clearAllMessages())
    this.toggleClearingAll()
  }

  updateSearchQuery(searchQuery) {
    this.setState({ searchQuery })
  }

  search() {
    const { searchQuery } = this.state
    const { dispatch } = this.props
    dispatch(list(false, searchQuery))
    this.setState({ lastSearched: searchQuery })
  }

  toggleClearingAll() {
    const { clearingAll } = this.state
    this.setState({ clearingAll: !clearingAll })
  }

  render() {
    const {
      history,
      loggedIn,
      isAdmin,
      id,
      email,
      userNotifs,
      dispatch,
      gettingFrames,
      frames,
      hasBulkFeatures,
      trialDaysRemaining,
      notifications,
      subscriptionStatus,
      justBoughtPlus,
      errors,
      activationCode,
    } = this.props
    const { clearingAll, searchQuery, lastSearched } = this.state
    return (
      <div>
        {trialDaysRemaining && <TrialBanner trialDaysRemaining={trialDaysRemaining} />}
        {subscriptionStatus === 'plus' && !isAdmin && (
          // eslint-disable-next-line no-undef
          <Intercom appID={INTERCOM_APP_ID} user_id={id} email={email} />
        )}
        <NavContainer history={history} subscriptionStatus={subscriptionStatus} />
        {notifications.length > 0 && <Notifications notifications={notifications} />}
        {loggedIn && (
          <div className="row justify-content-md-center">
            {isAdmin ? (
              <AdminDashboardContainer history={history} dispatch={dispatch} />
            ) : (
              <Dashboard
                dispatch={dispatch}
                hasBulkFeatures={hasBulkFeatures}
                loading={gettingFrames}
                frames={frames}
                notifs={userNotifs}
                clearAll={this.clearAll}
                toggleClearingAll={this.toggleClearingAll}
                clearingAll={clearingAll}
                search={this.search}
                updateSearchQuery={this.updateSearchQuery}
                searchQuery={searchQuery}
                lastSearched={lastSearched}
                subscriptionStatus={subscriptionStatus}
                justBoughtPlus={justBoughtPlus}
                errors={errors}
                activationCode={activationCode}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

DashboardContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  dispatch: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  id: PropTypes.string,
  email: PropTypes.string,
  frames: PropTypes.array.isRequired,
  activationCode: PropTypes.string,
  gettingFrames: PropTypes.bool.isRequired,
  hasBulkFeatures: PropTypes.bool.isRequired,
  trialDaysRemaining: PropTypes.number,
  notifications: PropTypes.array.isRequired,
  userNotifs: PropTypes.array.isRequired,
  subscriptionStatus: PropTypes.string,
  justBoughtPlus: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => {
  const { loggedIn, isAdmin, id, email } = state.session
  const { userNotifs, subscriptionStatus } = state.user
  const {
    frames,
    gettingFrames,
    hasBulkFeatures,
    trialDaysRemaining,
    activationCode,
  } = state.frames
  const { errors } = state.frameMigration
  const { notifications, uploading } = state.messages
  const { justBoughtPlus } = state.plus
  return {
    loggedIn,
    isAdmin,
    id,
    email,
    frames,
    activationCode,
    gettingFrames,
    hasBulkFeatures,
    trialDaysRemaining,
    notifications,
    userNotifs,
    subscriptionStatus,
    justBoughtPlus,
    errors,
  }
}

export default connect(mapStateToProps)(DashboardContainer)
