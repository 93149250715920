import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Frames from './Frames'
import Notifs from './Notifs'
import ClearAllFramesModal from './ClearAllFramesModal'

export default class Dashboard extends Component {
  render() {
    const {
      loading,
      frames,
      notifs,
      dispatch,
      hasBulkFeatures,
      clearingAll,
      toggleClearingAll,
      clearAll,
      search,
      updateSearchQuery,
      searchQuery,
      lastSearched,
      subscriptionStatus,
      errors,
      justBoughtPlus,
      activationCode,
    } = this.props
    return (
      <div className="col-lg-12 col-xl-8">
        {clearingAll && <ClearAllFramesModal dismiss={toggleClearingAll} clearAll={clearAll} />}
        <Notifs notifs={notifs} />
        <Frames
          dispatch={dispatch}
          loading={loading}
          frames={frames}
          search={search}
          updateSearchQuery={updateSearchQuery}
          searchQuery={searchQuery}
          hasBulkFeatures={hasBulkFeatures}
          toggleClearingAll={toggleClearingAll}
          lastSearched={lastSearched}
          subscriptionStatus={subscriptionStatus}
          justBoughtPlus={justBoughtPlus}
          errors={errors}
          activationCode={activationCode}
        />
      </div>
    )
  }
}

Dashboard.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  frames: PropTypes.array.isRequired,
  notifs: PropTypes.array.isRequired,
  hasBulkFeatures: PropTypes.bool.isRequired,
  clearingAll: PropTypes.bool.isRequired,
  toggleClearingAll: PropTypes.func.isRequired,
  clearAll: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  updateSearchQuery: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  lastSearched: PropTypes.string.isRequired,
  subscriptionStatus: PropTypes.string.isRequired,
  justBoughtPlus: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  activationCode: PropTypes.string,
}
