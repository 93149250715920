import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Errors from '../Errors'

export default class NewFrame extends Component {
  render() {
    const {
      creatingFrame,
      errors,
      handleSubmit,
      handleNameChange,
      handleEmailChange,
      handleAppsChange,
      parentState,
    } = this.props
    const { name, nameText, email, apps } = parentState
    const exampleEmail = name || 'nana-betty'
    return (
      <div className="col-lg-6 col-xl-4">
        <div className="register-box">
          <h1>Device Setup</h1>
          <Errors errors={errors} />
          <form className="mt-4" onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="apps"
                    defaultChecked
                    value="photos"
                    onChange={handleAppsChange}
                  />{' '}
                  Photo Frame
                </label>
              </div>
              <div className="form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="apps"
                    value="calendar"
                    onChange={handleAppsChange}
                  />{' '}
                  Calendar
                </label>
              </div>
              <div className="form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="apps"
                    value="tv"
                    onChange={handleAppsChange}
                  />{' '}
                  TV
                </label>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="name">Owner Email Address</label>
              <div className="input-group">
                <input
                  type="name"
                  value={email}
                  onChange={handleEmailChange}
                  className="form-control"
                  placeholder="User Email"
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="name">Frame Name</label>
              <div className="input-group">
                <input
                  type="name"
                  value={nameText}
                  onChange={handleNameChange}
                  className="form-control"
                  placeholder="Frame Email"
                />
                <div className="input-group-append">
                  <div className="input-group-text">@ourskylight.com</div>
                </div>
              </div>
            </div>
            <code className="full-width">{exampleEmail}@ourskylight.com</code>
            <div className="mt-5 mb-2 text-right">
              <button type="submit" className="btn btn-lg btn-success" disabled={creatingFrame}>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

NewFrame.propTypes = {
  parentState: PropTypes.object.isRequired,
  creatingFrame: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleAppsChange: PropTypes.func.isRequired,
  handleNameChange: PropTypes.func.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
}
