import {
  ADMIN_MESSAGE_SHOW_REQUEST,
  ADMIN_MESSAGE_SHOW_SUCCESS,
  SPAM_APPROVAL_REQUEST,
  SPAM_APPROVAL_SUCCESS,
  SPAM_APPROVAL_FAILURE,
} from '../../actions/admin/messages'

const DEFAULT_STATE = {
  message: null,
  loading: true,
  spamApproved: false,
}

export function adminMessages(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SPAM_APPROVAL_REQUEST:
    case ADMIN_MESSAGE_SHOW_REQUEST:
      return DEFAULT_STATE
    case SPAM_APPROVAL_SUCCESS:
      return { ...state, message: action.response.data, loading: false, spamApproved: true }
    case SPAM_APPROVAL_FAILURE:
    case ADMIN_MESSAGE_SHOW_SUCCESS: {
      const message = action.response.data
      return {
        ...state,
        message,
        loading: false,
        spamApproved: message.attributes.status !== 'pending_spam_approval',
      }
    }
    default:
      return state
  }
}
