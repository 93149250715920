import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import FullStory from 'react-fullstory'

export default class SyncedCalendars extends Component {
  render() {
    const {
      frame,
      calendarAccounts,
      webcalAccounts,
      destroyCalendarAccount,
      destroyWebcalAccount,
    } = this.props
    const totalAccounts = webcalAccounts.length + calendarAccounts.length
    return (
      <div className="flex-column align-items-center">
        <FullStory org="R6R1G" />
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-center align-items-center">
              <h2 className="text-center text-muted my-4 ml-4.5">Manage my Synced Calendars</h2>
              <Link to={`/frames/${frame.id}/friends`} className="cal-privacy-link">
                <span
                  className="oi oi-cog cal-privacy-icon"
                  title="Calendar Privacy Settings"
                  aria-hidden="true"
                />
              </Link>
            </div>
            {totalAccounts === 0 ? (
              <div className="row mt-4">
                <div className="col-8 offset-2 text-center">
                  <p>
                    <b>Skylight Sync</b> allows you to automatically sync all events from Google and
                    Outlook calendars onto your Skylight Calendar.
                  </p>
                </div>
              </div>
            ) : (
              <div className="mt-4">
                {calendarAccounts.length > 0 && (
                  <div>
                    <Subheader>
                      <b>Synced Email Addresses</b>
                    </Subheader>
                    <div>
                      {calendarAccounts.map((c) =>
                        c.attributes.active_calendars.map((ac) => {
                          let resyncUrl = `/frames/${frame.id}/sync?showing_sync_form=true&email=${c.attributes.email}`
                          if (c.attributes.provider)
                            resyncUrl += `&provider=${c.attributes.provider}`
                          return (
                            <RowItem key={`${c.id}-${ac.id}`}>
                              <CalInfo>
                                <CalName>
                                  <b>{ac.name}</b>
                                </CalName>
                                <CalEmail>{c.attributes.email}</CalEmail>
                              </CalInfo>

                              <CalInteractions>
                                <Button
                                  className="btn btn-sm btn-primary mb-1"
                                  as={Link}
                                  to={resyncUrl}
                                >
                                  <span
                                    className="oi oi-loop-circular"
                                    title="Re-Sync Calendar"
                                    aria-hidden="true"
                                  />
                                  <ButtonDesc> Re-Sync</ButtonDesc>
                                </Button>
                                <Button
                                  type="button"
                                  onClick={() => destroyCalendarAccount(c.id, ac.id)}
                                  className="btn btn-sm btn-danger mb-1 ml-1"
                                >
                                  <span className="oi oi-trash" title="Delete" aria-hidden="true" />
                                  <ButtonDesc> Delete</ButtonDesc>
                                </Button>
                              </CalInteractions>
                            </RowItem>
                          )
                        })
                      )}
                    </div>
                  </div>
                )}
                {webcalAccounts.length > 0 && (
                  <div>
                    <Subheader>
                      <b>Synced Webcals</b>
                    </Subheader>
                    <div>
                      {webcalAccounts.map((c) => (
                        <RowItem key={c.id}>
                          <CalInfo>
                            <CalName>
                              <b>{c.attributes.name || `No Name (${c.id})`}</b>
                            </CalName>
                            <CalEmail>{c.attributes.sync_url}</CalEmail>
                          </CalInfo>
                          <Button
                            type="button"
                            onClick={() => destroyWebcalAccount(c.id)}
                            className="btn btn-sm btn-danger mb-1 ml-1"
                          >
                            <span className="oi oi-trash" title="Delete" aria-hidden="true" />
                            <ButtonDesc> Delete</ButtonDesc>
                          </Button>
                        </RowItem>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 text-center">
            <Link
              to={`/frames/${frame.id}/sync?showing_sync_form=true`}
              className="btn btn-success"
            >
              Sync a New Calendar
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

SyncedCalendars.propTypes = {
  frame: PropTypes.object.isRequired,
  calendarAccounts: PropTypes.array.isRequired,
  webcalAccounts: PropTypes.array.isRequired,
  destroyWebcalAccount: PropTypes.func.isRequired,
  destroyCalendarAccount: PropTypes.func.isRequired,
}

const Subheader = styled.p`
  margin-bottom: 0rem;
  padding-bottom: 0.3rem;
  font-size: 1.4rem;
  border-bottom: 1px solid gray;
`

const RowItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0.5rem;
  &:hover {
    background: whitesmoke;
  }
  &:not(:last-child) {
    border-bottom: 1px solid gainsboro;
  }
`

const CalInfo = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: auto;
  margin-right: 2rem;
`

const CalName = styled.p`
  margin: 0;
`

const CalEmail = styled.p`
  margin: 0;
`

const CalInteractions = styled.section`
  display: flex;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  span:first-child {
    margin-bottom: 0.2rem;
  }
`

const ButtonDesc = styled.span`
  margin-left: 0.25rem;
  @media (max-width: 550px) {
    display: none;
  }
`
