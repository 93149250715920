import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class TrialBanner extends Component {
  render() {
    const { trialDaysRemaining } = this.props
    return (
      <div className="trial-banner">
        <a
          href={`https://www.skylightframe.com/products/skylight-plus?trial_days_remaining=${trialDaysRemaining}&utm_source=app&utm_medium=web&utm_campaign=trial`}
          target="_blank"
        >
          Your Plus free trial ends in{' '}
          <b>
            {trialDaysRemaining} {trialDaysRemaining == 1 ? 'day' : 'days'}
          </b>
          ! Click here to keep access to Videos, Captions, Cloud Storage, and more!
        </a>
      </div>
    )
  }
}

TrialBanner.propTypes = {
  trialDaysRemaining: PropTypes.number.isRequired,
}
