import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import Cleave from 'cleave.js/react'
import Modal from 'react-modal'
import { Spinner } from 'react-bootstrap'
import { requestInvitationText, resetInvitationRequestStatus } from '../actions/sms'

import greenCheck from '../../assets/images/green-check.png'
import redX from '../../assets/images/ex.png'

const PhoneInputModal = ({ isOpen, setVisibility }) => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const dispatch = useDispatch()
  const textRequestStatus = useSelector((s) => s.sms.invitationRequestStatus)
  const gettingText = useSelector((s) => s.sms.gettingText)

  const modalRoot = document.getElementById('phone-input-modal-root')
  Modal.setAppElement(modalRoot)

  const disableSubmit = phoneNumber.length !== 12

  const handleCancel = () => {
    setPhoneNumber('')
    setVisibility(false)
    dispatch(resetInvitationRequestStatus())
  }

  const handleTryAgain = () => {
    dispatch(resetInvitationRequestStatus())
  }

  const requestText = async (e) => {
    e.preventDefault()
    if (disableSubmit) return
    dispatch(requestInvitationText(phoneNumber))
  }

  const ModalContentBasedOnRequestStatus = {
    uninitiated: (
      <ModalContentContainer>
        <Header>Send app link to my phone</Header>
        <PhoneInput onSubmit={requestText}>
          <label htmlFor="phone" className="mb-1">
            Your phone number
          </label>
          <Cleave
            type="phone"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="form-control"
            options={{ numericOnly: true, blocks: [3, 3, 4], delimiter: '-' }}
            placeholder="123-456-7891"
          />
        </PhoneInput>
        <BtnGroup>
          <button type="button" className="btn btn-outline-primary" onClick={handleCancel}>
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={requestText}
            disabled={disableSubmit}
          >
            Send app link
          </button>
        </BtnGroup>
      </ModalContentContainer>
    ),
    success: (
      <CenteredModalContentContainer>
        <Header>Skylight app link will be sent to your phone!</Header>
        <CenteredImgCtn>
          <img src={greenCheck} alt="Success" width="40" height="40" />
        </CenteredImgCtn>
      </CenteredModalContentContainer>
    ),
    fail: (
      <CenteredModalContentContainer>
        <Header>Something went wrong</Header>
        <CenteredImgCtn>
          <img src={redX} alt="Fail" width="40" height="40" />
        </CenteredImgCtn>
        <button type="button" className="btn btn-primary mt-4" onClick={handleTryAgain}>
          Try Again
        </button>
      </CenteredModalContentContainer>
    ),
  }

  return (
    <StyledModal
      className="small-modal"
      overlayClassName="small-modal-overlay"
      isOpen={isOpen}
      onRequestClose={handleCancel}
    >
      {gettingText ? (
        <Spinner animation="border" role="status" variant="primary">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        ModalContentBasedOnRequestStatus[textRequestStatus]
      )}
    </StyledModal>
  )
}

PhoneInputModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setVisibility: PropTypes.func.isRequired,
}

export default PhoneInputModal

const StyledModal = styled(Modal)`
  padding: 30px;
  max-width: 100%;
`

const ModalContentContainer = styled.div`
  max-width: 100%;
  width: max-content;
  min-width: 380px;
`

const CenteredModalContentContainer = styled(ModalContentContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const PhoneInput = styled.form`
  input {
    width: 350px;
  }
`

const Header = styled.h2`
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
`

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  button {
    width: 45%;
  }
`

const CenteredImgCtn = styled.div`
  text-align: center;
`
