import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import FullStory from 'react-fullstory'
import Cleave from 'cleave.js/react'

import { FrameKind } from '../utilities/FrameKind'

import ProgressBar from './ProgressBar'
import Errors from './Errors'

import skylightFrameImg from '../../assets/images/skylight-frame-img.png'
import skylightCalImg from '../../assets/images/skylight-cal-background.png'
import skylightTv from '../../assets/images/skylight-tv.png'
import houseImg from '../../assets/images/house.png'
import someoneElseImg from '../../assets/images/someone-else.png'

const NewFrame = ({
  handleSubmit,
  handleNameChange,
  handleAppsChange,
  handleOwnerInfoChange,
  creatingFrame,
  errors,
  parentState,
  patchOwnerInfo,
  userBirthday,
}) => {
  const { name, nameText, newHere, apps, ownerInfo } = parentState
  const exampleEmail = name || 'smithfamilyframe'
  const app = apps[0]
  const calendar = app === 'calendar'
  const kind = FrameKind.for(app)
  const [currentView, setCurrentView] = useState('binaryChoose')
  const choosingApps = apps.length === 0 && currentView === 'binaryChoose'

  const renderChooseAppOrOwner = () => {
    const handleChooseForWhom = (forWhom) => {
      if (forWhom === 'self' && userBirthday) {
        handleOwnerInfoChange({
          birthday: userBirthday
            .split('-')
            .filter((el) => el.length !== 4)
            .join('/'),
        })
        setCurrentView('setup')
      } else {
        handleOwnerInfoChange({ forWhom })
        setCurrentView('ownerInfoInput')
      }
    }
    return (
      <RegisterBox className="register-box">
        <Title>
          {choosingApps
            ? 'Which Skylight Product?'
            : `Is this ${kind} for yourself or for someone else?`}
        </Title>
        <Errors errors={errors} />
        <ChoiceGroup>
          <AppOptionBtn
            type="button"
            className="no-style-btn"
            onClick={
              choosingApps ? () => handleAppsChange(['photos']) : () => handleChooseForWhom('self')
            }
          >
            {choosingApps ? (
              <SkylightIconContainer width={180} height={110}>
                <img src={skylightFrameImg} alt="Skylight Frame" />
              </SkylightIconContainer>
            ) : (
              <IconContainer>
                <img src={houseImg} alt="Myself" />
              </IconContainer>
            )}
            <ChoiceText>
              <ChoiceLabel>{choosingApps ? 'Photo Frame' : 'Myself'}</ChoiceLabel>
              {choosingApps && <ChoiceSubLabel>Digital Photo Frame</ChoiceSubLabel>}
            </ChoiceText>
          </AppOptionBtn>
          <AppOptionBtn
            type="button"
            className="no-style-btn"
            onClick={
              choosingApps
                ? () => {
                    handleAppsChange(['calendar'])
                    setCurrentView('setup')
                  }
                : () => handleChooseForWhom('gift')
            }
          >
            {choosingApps ? (
              <SkylightIconContainer width={170} height={100}>
                <img src={skylightCalImg} alt="Skylight Calendar" />
              </SkylightIconContainer>
            ) : (
              <IconContainer>
                <img src={someoneElseImg} alt="Someone Else" />
              </IconContainer>
            )}
            <ChoiceText>
              <ChoiceLabel>{choosingApps ? 'Calendar' : 'Someone Else'}</ChoiceLabel>
              {choosingApps && <ChoiceSubLabel>Digital Calendar</ChoiceSubLabel>}
            </ChoiceText>
          </AppOptionBtn>
        </ChoiceGroup>
      </RegisterBox>
    )
  }

  const renderCollectMoreOwnerInfo = () => {
    const isGift = ownerInfo.forWhom === 'gift'
    const { birthday, name: ownerName } = ownerInfo
    const disableSubmit = isGift ? !ownerName || birthday.length !== 5 : birthday.length !== 5

    const handleSubmitOwnerInfo = (e) => {
      e.preventDefault()
      if (disableSubmit) return
      if (!isGift) patchOwnerInfo()
      setCurrentView('setup')
    }

    return (
      <RegisterBox className="register-box">
        <Title>{isGift ? 'Who is the owner?' : 'When is your birthday?'}</Title>
        <LabelAndInputGroup onSubmit={handleSubmitOwnerInfo}>
          {isGift && (
            <>
              <label htmlFor="owner-name">Owner's Name</label>
              <input
                id="owner-name"
                type="text"
                className="form-control"
                value={ownerName}
                onChange={(e) => handleOwnerInfoChange({ name: e.target.value })}
              />
              <AlignStartP>When is the owner's birthday?</AlignStartP>
            </>
          )}
          <>
            <label htmlFor="owner-birthday">Birthday</label>
            <Cleave
              id="owner-birthday"
              className="form-control"
              options={{ date: true, datePattern: ['m', 'd'] }}
              placeholder="MM/DD"
              delimiter="/"
              value={birthday}
              onChange={(e) => handleOwnerInfoChange({ birthday: e.target.value })}
            />
          </>
        </LabelAndInputGroup>
        <p>We’ll send our special offers and birthday greetings on that day</p>
        <HorzButtons>
          <Button type="button" className="btn btn-light" onClick={() => setCurrentView('setup')}>
            Skip
          </Button>
          <Button
            type="submit"
            className="btn btn-success"
            disabled={disableSubmit}
            onClick={handleSubmitOwnerInfo}
          >
            Next
          </Button>
        </HorzButtons>
      </RegisterBox>
    )
  }

  const renderSetup = () => (
    <RegisterBox className="register-box med-width-ctn">
      <Title>{`${kind} Setup`}</Title>
      <Errors errors={errors} />
      <form className="mt-4" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">
            Create your Skylight's unique email address.{' '}
            {kind === 'Frame' &&
              `We recommend you name the ${kind} after the recipient, for example: nana-betty@ourskylight.com`}
          </label>
          <div className="input-group">
            <input
              type="name"
              aria-label={`${kind} Email`}
              value={nameText}
              onChange={handleNameChange}
              className="form-control"
              placeholder={`${kind} Email`}
            />
            <div className="input-group-append">
              <div className="input-group-text">@ourskylight.com</div>
            </div>
          </div>
        </div>
        {calendar ? (
          <p>
            To send an event to your Skylight Calendar, just invite its unique email address as a
            guest to your event:
          </p>
        ) : (
          <p>
            To send a photo to Skylight, you’ll just email the photo to its unique email address:
          </p>
        )}
        <code className="full-width">{exampleEmail}@ourskylight.com</code>
        <div className="mt-5 mb-2 text-right">
          <button type="submit" className="btn btn-lg btn-success" disabled={creatingFrame}>
            Save
          </button>
        </div>
      </form>
    </RegisterBox>
  )

  const viewOptions = {
    binaryChoose: renderChooseAppOrOwner(),
    ownerInfoInput: renderCollectMoreOwnerInfo(),
    setup: renderSetup(),
  }

  return (
    <div className="new-user-or-frame-ctn">
      {calendar && <FullStory org="R6R1G" />}
      {newHere && (
        <span className="new-user-progress-bar-ctn">
          <ProgressBar step={3} />
        </span>
      )}
      {viewOptions[currentView]}
    </div>
  )
}

export default NewFrame

NewFrame.propTypes = {
  parentState: PropTypes.object.isRequired,
  creatingFrame: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleNameChange: PropTypes.func.isRequired,
  handleAppsChange: PropTypes.func.isRequired,
  handleOwnerInfoChange: PropTypes.func.isRequired,
  patchOwnerInfo: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  userBirthday: PropTypes.string,
}

const RegisterBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 650px) {
    width: 100%;
  }
`

const ChoiceGroup = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  > :not(:first-child) {
    margin-left: 2rem;
  }
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    width: 100%;
    > :not(:first-child) {
      margin: 2rem 0;
    }
  }
`

const Title = styled.h1`
  font-weight: bold;
  margin-bottom: 2.5rem;
  text-align: center;
  max-width: 650px;
  @media (max-width: 650px) {
    font-size: 2rem;
  }
`

const AppOptionBtn = styled.button`
  width: 300px;
  height: 260px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 0 20px;
  > img {
    margin-bottom: 10px;
  }
  border: 2px solid rgb(206, 206, 206);
  border-radius: 4px;
  &:hover {
    transition: 0.2s;
    border: 2px solid #52b389;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(29, 145, 130, 0.5);
  }
  @media (max-width: 650px) {
    padding: 20px;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    p {
      margin: 0;
    }
  }
`

const SharedIconContainerStyles = css`
  margin-bottom: 20px;
  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 650px) {
    margin: 0 10px 0 0;
  }
`

const SkylightIconContainer = styled.div`
  ${SharedIconContainerStyles}
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  @media (max-width: 650px) {
    width: ${(props) => `${props.width * 0.7}px`};
    height: ${(props) => `${props.height * 0.7}px`};
  }
`

const IconContainer = styled.div`
  ${SharedIconContainerStyles}

  width: 40%;
  height: 40%;
  @media (max-width: 650px) {
    width: 20%;
    height: 20%;
  }
`

const LabelAndInputGroup = styled.form`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  #owner-name {
    width: 100%;
  }
  #owner-birthday {
    width: 50%;
  }
  margin-bottom: 2rem;
  > label {
    margin-bottom: 0.2rem;
    font-size: 0.9rem;
    font-weight: 600;
    color: #aeaeae;
  }
  > input {
    padding: 0.2rem;
  }
`

const ChoiceText = styled.div`
  min-width: 160px;
  text-align: center;
`

const ChoiceLabel = styled.h3`
  font-size: 24px;
  @media (max-width: 650px) {
    font-size: 18px;
  }
`

const ChoiceSubLabel = styled.p`
  font-size: 18px;
  @media (max-width: 650px) {
    font-size: 16px;
  }
`

const AlignStartP = styled.p`
  align-self: flex-start;
  margin: 2rem 0 0.25rem;
`

const HorzButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.5rem;
`

const Button = styled.button`
  padding: 0.5rem 2.5rem;
  @media (max-width: 650px) {
    width: 48%;
  }
  &:disabled {
    cursor: not-allowed;
  }
`
