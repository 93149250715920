import {
  GROCERY_ITEMS_REQUEST,
  GROCERY_ITEMS_SUCCESS,
  GROCERY_ITEMS_FAILURE,
  GROCERY_ITEMS_CREATE_SUCCESS,
  GROCERY_ITEMS_UPDATE_SUCCESS,
} from '../actions/groceryItems'

const DEFAULT_STATE = {
  gettingGroceryItems: true,
  groceryItems: [],
}

export function groceryItems(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case GROCERY_ITEMS_REQUEST:
      return Object.assign({}, state, DEFAULT_STATE)
    case GROCERY_ITEMS_SUCCESS:
      return Object.assign({}, state, {
        gettingGroceryItems: false,
        groceryItems: action.response.data,
      })
    case GROCERY_ITEMS_CREATE_SUCCESS:
      let items = state.groceryItems.slice(0)
      items.push(action.response.data)
      return Object.assign({}, state, { groceryItems: items })
    case GROCERY_ITEMS_UPDATE_SUCCESS:
      let pendingItems = state.groceryItems.slice(0)
      let updatedItem = action.response.data
      if (updatedItem.attributes.status != 'pending') {
        let indexToRemove = null
        pendingItems.forEach((item, i) => {
          if (item.id === updatedItem.id) indexToRemove = i
        })
        if (indexToRemove != null) pendingItems.splice(indexToRemove, 1)
      }
      return Object.assign({}, state, { groceryItems: pendingItems })
    case GROCERY_ITEMS_FAILURE:
      return Object.assign({}, state, DEFAULT_STATE)
    default:
      return state
  }
}
