import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import User from '../../components/admin/User'
import UpgradeModal from '../../components/admin/UpgradeModal'

import NavContainer from '../NavContainer'

import {
  show,
  upgrade,
  downgrade,
  cancelTrial,
  extendTrial,
  newTrial,
} from '../../actions/admin/users'
import { list } from '../../actions/admin/userFrames'

class UserContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { showingUpgradeModal: false }
    // Plus
    this.showUpgradeModal = this.showUpgradeModal.bind(this)
    this.dismissUpgradeModal = this.dismissUpgradeModal.bind(this)
    this.upgrade = this.upgrade.bind(this)
    this.downgrade = this.downgrade.bind(this)
    this.plusThirty = this.plusThirty.bind(this)
    this.newTrial = this.newTrial.bind(this)
    this.cancelTrial = this.cancelTrial.bind(this)
  }

  componentDidMount() {
    const { match, dispatch } = this.props
    const { id } = match.params
    dispatch(show(id))
    dispatch(list(id))
  }

  showUpgradeModal() {
    this.setState({ showingUpgradeModal: true })
  }

  dismissUpgradeModal() {
    this.setState({ showingUpgradeModal: false })
  }

  upgrade(email) {
    const { match, dispatch } = this.props
    const { id } = match.params
    dispatch(upgrade(id, email))
    this.dismissUpgradeModal()
  }

  downgrade() {
    const { match, dispatch } = this.props
    const { id } = match.params
    dispatch(downgrade(id))
  }

  newTrial() {
    const { match, dispatch } = this.props
    const { id } = match.params
    dispatch(newTrial(id))
  }

  plusThirty() {
    const { match, dispatch } = this.props
    const { id } = match.params
    dispatch(extendTrial(id))
  }

  cancelTrial() {
    const { match, dispatch } = this.props
    const { id } = match.params
    dispatch(cancelTrial(id))
  }

  render() {
    const { history, loggedIn, isAdmin, user, errors, gettingUser } = this.props
    const { showingUpgradeModal } = this.state
    return (
      <div>
        {showingUpgradeModal && (
          <UpgradeModal dismiss={this.dismissUpgradeModal} kind="Plus" save={this.upgrade} />
        )}
        <NavContainer history={history} />
        {loggedIn && isAdmin && (
          <div className="row justify-content-md-center">
            <User
              loading={gettingUser}
              user={user}
              errors={errors}
              backFunc={history.goBack}
              upgrade={this.showUpgradeModal}
              downgrade={this.downgrade}
              plusThirty={this.plusThirty}
              newTrial={this.newTrial}
              cancelTrial={this.cancelTrial}
            />
          </div>
        )}
      </div>
    )
  }
}

UserContainer.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  loggedIn: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  gettingUser: PropTypes.bool.isRequired,
  user: PropTypes.object,
  errors: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => {
  const { loggedIn, isAdmin } = state.session
  const { gettingUser, user, errors } = state.users
  return {
    loggedIn,
    isAdmin,
    gettingUser,
    user,
    errors,
  }
}

export default connect(mapStateToProps)(UserContainer)
