import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class DestroyCategoryModal extends Component {
  constructor(props) {
    super(props)
    this.state = { reassignCategoryId: '', className: 'modal fade' }
    this.changeReassignCategoryId = this.changeReassignCategoryId.bind(this)
    this.remove = this.remove.bind(this)
  }

  changeReassignCategoryId(e) {
    this.setState({ reassignCategoryId: e.target.value })
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ className: 'modal fade show' })
    }, 100)
  }

  remove() {
    const { destroy, dismiss, category } = this.props
    const { reassignCategoryId } = this.state
    destroy(category, reassignCategoryId)
    dismiss()
  }

  render() {
    const { dismiss, categories, category } = this.props
    const { className, reassignCategoryId } = this.state
    const otherCategories = categories.filter((c) => c.id != category.id)
    return (
      <div className={className} tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog" role="document">
          <form className="mt-4">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {otherCategories.length == 0 ? 'Sorry!' : 'Remove a Category'}
                </h5>
                <button type="button" className="close" aria-label="Close" onClick={dismiss}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {otherCategories.length == 0 ? (
                  <div>
                    <p>
                      <b>{category.attributes.label}</b> is your only category. Your calendar needs
                      at least one category.
                    </p>
                    <p>Add a new category first, then you can remove this one.</p>
                  </div>
                ) : (
                  <div className="form-group">
                    <label htmlFor="reassign-catgegory">
                      To which category should we reassign all of <b>{category.attributes.label}</b>
                      's events?
                    </label>
                    <select
                      name="reassign-catgegory"
                      className="custom-select mr-2"
                      value={reassignCategoryId}
                      onChange={this.changeReassignCategoryId}
                    >
                      <option value={''}>Pick a Category</option>
                      {otherCategories.map((c) => {
                        return (
                          <option key={c.id} value={c.id}>
                            {c.attributes.label}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary pull-left" onClick={dismiss}>
                  {otherCategories.length == 0 ? 'Ok' : 'Nevermind'}
                </button>
                {otherCategories.length > 0 && (
                  <button
                    type="button"
                    className="btn btn-danger"
                    disabled={reassignCategoryId == ''}
                    onClick={this.remove}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

DestroyCategoryModal.propTypes = {
  destroy: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
}
