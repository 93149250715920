import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { update } from '../actions/frames'

import FramePrivacy from '../components/FramePrivacy'

import { Analytics } from '../utilities/Analytics'
import { UrlParams } from '../utilities/UrlParams'

class FramePrivacyContainer extends Component {
  constructor(props) {
    super(props)
    const { attributes } = props.frame
    this.state = {
      email: attributes.notification_email,
      openToPublic: attributes.open_to_public,
      showingAdvancedSettings: attributes.open_to_public,
      messageViewability: attributes.message_viewability || 'only_viewable_to_owner',
    }
    this.changeEmail = this.changeEmail.bind(this)
    this.togglePrivacy = this.togglePrivacy.bind(this)
    this.saveSettings = this.saveSettings.bind(this)
    this.toggleMessageViewability = this.toggleMessageViewability.bind(this)
    this.redirectAfterUpdating = this.redirectAfterUpdating.bind(this)
    this.toggleAdvancedSettings = this.toggleAdvancedSettings.bind(this)
  }

  componentDidMount() {
    Analytics.track('Viewed Frame Privacy')
    window.scrollTo(0, 0)
  }

  redirectAfterUpdating() {
    const { history, frame, isAdmin, updating } = this.props
    const isCalendar = frame.attributes.apps[0] === 'calendar'
    const upsellType = isCalendar ? 'activate' : 'plus'
    const onlyUpdating = !!UrlParams.get().updating || updating
    if (frame) {
      if (isAdmin) {
        const friendsUrl = `/frames/${frame.id}/friends`
        history.push(friendsUrl)
      } else if (onlyUpdating) {
        history.push('/')
      } else {
        let upsellLink = `/frames/${frame.id}/${upsellType}`
        const newHere = !!UrlParams.get().new_here
        if (newHere) upsellLink += '?new_here=true'
        upsellLink += '?activating=true'
        history.push(upsellLink)
      }
    }
  }

  changeEmail(event) {
    const email = event.target.value
    this.setState({ email })
  }

  togglePrivacy(event) {
    const openToPublic = event.target.value === 'open'
    Analytics.track('Toggled Frame Privacy', { open_to_public: openToPublic })
    this.setState({ openToPublic })
  }

  toggleMessageViewability(event) {
    this.setState({ messageViewability: event.target.value })
  }

  toggleAdvancedSettings() {
    const { showingAdvancedSettings } = this.state
    this.setState({ showingAdvancedSettings: !showingAdvancedSettings })
  }

  async saveSettings() {
    const { frame, dispatch } = this.props
    const { email, openToPublic, messageViewability } = this.state
    const params = {
      notification_email: email,
      open_to_public: openToPublic,
      message_viewability: messageViewability,
    }
    Analytics.track('Saved Privacy Settings', {
      open_to_public: openToPublic,
      message_viewability: messageViewability,
      new_email: email !== frame.attributes.notification_email,
    })
    await dispatch(update(frame.id, params))

    const { successfulPrivacyUpdate } = this.props
    if (successfulPrivacyUpdate) {
      this.redirectAfterUpdating()
    }
  }

  render() {
    const { updatingFrame, errors } = this.props
    const { email, openToPublic, messageViewability, showingAdvancedSettings } = this.state
    return (
      <FramePrivacy
        updatingFrame={updatingFrame}
        errors={errors}
        email={email}
        changeEmail={this.changeEmail}
        openToPublic={openToPublic}
        togglePrivacy={this.togglePrivacy}
        messageViewability={messageViewability}
        toggleMessageViewability={this.toggleMessageViewability}
        saveSettings={this.saveSettings}
        showingAdvancedSettings={showingAdvancedSettings}
        toggleAdvancedSettings={this.toggleAdvancedSettings}
      />
    )
  }
}

FramePrivacyContainer.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  frame: PropTypes.object.isRequired,
  updatingFrame: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  successfulPrivacyUpdate: PropTypes.bool,
  isAdmin: PropTypes.bool.isRequired,
  updating: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const { errors, successfulPrivacyUpdate, updatingFrame } = state.frames
  const { isAdmin } = state.session
  return { errors, successfulPrivacyUpdate, updatingFrame, isAdmin }
}

export default connect(mapStateToProps)(FramePrivacyContainer)
