export const REMINDER_PROFILES_REQUEST = 'REMINDER_PROFILES_REQUEST'
export const REMINDER_PROFILES_SUCCESS = 'REMINDER_PROFILES_SUCCESS'
export const REMINDER_PROFILES_FAILURE = 'REMINDER_PROFILES_FAILURE'

export function show() {
  return {
    type: REMINDER_PROFILES_REQUEST,
    api: {
      endpoint: 'reminder_profile',
      authenticated: true,
      types: [REMINDER_PROFILES_REQUEST, REMINDER_PROFILES_SUCCESS, REMINDER_PROFILES_FAILURE],
      method: 'GET',
    },
  }
}
