import {
  FLEET_HEALTHS_REQUEST,
  FLEET_HEALTHS_SUCCESS,
  FLEET_HEALTH_COMPARISON_CLEAROUT,
  FLEET_HEALTHS_CONTROL_SUCCESS,
  FLEET_HEALTHS_EXPERIMENTAL_SUCCESS,
} from '../../actions/admin/fleetHealths'

const DEFAULT_STATE = {
  gettingFleetHealths: false,
  fleetHealths: [],
  comparison: null,
  gettingComparisonControl: false,
  gettingComparisonExperimental: false,
}

export function fleetHealths(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case FLEET_HEALTHS_REQUEST: {
      return {
        ...state,
        gettingFleetHealths: true,
        fleetHealths: [],
      }
    }
    case FLEET_HEALTHS_SUCCESS: {
      return {
        ...state,
        gettingFleetHealths: false,
        fleetHealths: action.response.data,
      }
    }
    case FLEET_HEALTH_COMPARISON_CLEAROUT: {
      return {
        ...state,
        comparison: null,
        gettingComparisonControl: true,
        gettingComparisonExperimental: true,
      }
    }
    // The below lines merge two FleetHealthSummaries
    // into a single comparison object,
    // which mirrors the data model of deployments,
    // so that we can use the same dashboard.
    /* eslint-disable camelcase */
    case FLEET_HEALTHS_EXPERIMENTAL_SUCCESS: {
      const comparison = state.comparison || { attributes: {} }
      const {
        new_device_deployed_count,
        existing_device_deployed_count,
        new_device_activation_experiment_latency_buckets,
        new_device_sending_experiment_latency_buckets,
        existing_device_sending_experiment_latency_buckets,
        new_device_experiment_online_rate,
        existing_device_experiment_online_rate,
        new_device_experiment_watchdog_rate,
        existing_device_experiment_watchdog_rate,
        new_device_online_buckets,
        existing_device_online_buckets,
        last_refreshed_health_metrics_at,
        refreshing,
      } = action.response.data.attributes
      comparison.attributes = {
        ...comparison.attributes,
        new_device_deployed_count,
        existing_device_deployed_count,
        new_device_activation_experiment_latency_buckets,
        new_device_sending_experiment_latency_buckets,
        existing_device_sending_experiment_latency_buckets,
        new_device_experiment_online_rate,
        existing_device_experiment_online_rate,
        new_device_experiment_watchdog_rate,
        existing_device_experiment_watchdog_rate,
        new_device_online_buckets,
        existing_device_online_buckets,
        last_refreshed_health_metrics_at,
        refreshing,
      }
      return { ...state, comparison, gettingComparisonExperimental: false }
    }
    case FLEET_HEALTHS_CONTROL_SUCCESS: {
      const comparison = state.comparison || { attributes: {} }
      const {
        new_device_activation_experiment_latency_buckets,
        new_device_sending_experiment_latency_buckets,
        existing_device_sending_experiment_latency_buckets,
        new_device_experiment_online_rate,
        existing_device_experiment_online_rate,
        new_device_experiment_watchdog_rate,
        existing_device_experiment_watchdog_rate,
      } = action.response.data.attributes
      comparison.attributes = {
        ...comparison.attributes,
        new_device_activation_control_latency_buckets: new_device_activation_experiment_latency_buckets,
        new_device_sending_control_latency_buckets: new_device_sending_experiment_latency_buckets,
        existing_device_sending_control_latency_buckets: existing_device_sending_experiment_latency_buckets,
        new_device_control_online_rate: new_device_experiment_online_rate,
        existing_device_control_online_rate: existing_device_experiment_online_rate,
        new_device_control_watchdog_rate: new_device_experiment_watchdog_rate,
        existing_device_control_watchdog_rate: existing_device_experiment_watchdog_rate,
      }
      return { ...state, comparison, gettingComparisonControl: false }
    }
    /* eslint-enable camelcase */
    default:
      return state
  }
}
