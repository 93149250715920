import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Errors from './Errors'

export default class PlusLink extends PureComponent {
  render() {
    const {
      errors,
      redeemError,
      searching,
      plusPurchaseInstanceLoading,
      email,
      handleEmailChange,
      code,
      handleCodeChange,
      showRedeemCode,
      toggleShowRedeemCode,
      searchAlreadyPurchased,
      redeemCode,
      back,
    } = this.props
    return (
      <div className="register-box med-width-ctn">
        <Title>Link your Skylight Plus Account</Title>
        <Subtitle>
          The Skylight Plus subscription allows you to access our cloud portal and mobile app, where
          you can send photos and videos to Skylight, and even add captions!
        </Subtitle>
        <Errors errors={showRedeemCode ? (redeemError ? [redeemError] : []) : errors} />
        <form onSubmit={showRedeemCode ? redeemCode : searchAlreadyPurchased}>
          <div className="form-group">
            <label htmlFor={showRedeemCode ? 'text' : 'email'}>
              <b>
                {showRedeemCode
                  ? 'What is your code?'
                  : 'Which email address did you use to purchase Plus?'}
              </b>
            </label>
            <input
              type={showRedeemCode ? 'text' : 'email'}
              placeholder={showRedeemCode ? 'XXXXXXXXX' : 'youremail@gmail.com'}
              value={showRedeemCode ? code : email}
              onChange={showRedeemCode ? handleCodeChange : handleEmailChange}
              className="form-control"
            />

            <CenteredContentDiv>
              <button
                type="button"
                className="btn btn-text flex-centered"
                onClick={toggleShowRedeemCode}
                style={{
                  color: '#52b389',
                }}
              >
                {showRedeemCode ? 'Purchased from skylightframe.com?' : 'Have a code?'}
              </button>
            </CenteredContentDiv>
          </div>
          <HorzButtonGroup>
            <button type="button" className="btn btn-info" onClick={back}>
              Nevermind
            </button>
            <LearnMoreLink
              href="https://www.skylightframe.com/products/skylight-plus"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-outline-primary flex-centered"
            >
              Learn More
            </LearnMoreLink>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={searching || plusPurchaseInstanceLoading}
            >
              {showRedeemCode
                ? searching
                  ? 'Redeeming...'
                  : 'Redeem'
                : searching
                ? 'Searching...'
                : 'Search'}
            </button>
          </HorzButtonGroup>
        </form>
      </div>
    )
  }
}

PlusLink.propTypes = {
  errors: PropTypes.array.isRequired,
  redeemError: PropTypes.string.isRequired,
  searching: PropTypes.bool.isRequired,
  plusPurchaseInstanceLoading: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  handleCodeChange: PropTypes.func.isRequired,
  toggleShowRedeemCode: PropTypes.func.isRequired,
  showRedeemCode: PropTypes.bool.isRequired,
  searchAlreadyPurchased: PropTypes.func.isRequired,
  redeemCode: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
}

const Title = styled.h2`
  font-weight: 600;
  margin-bottom: 1.5rem;
`

const Subtitle = styled.p``

const HorzButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 0.5rem 0;
`

const CenteredContentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 0rem 0;
`

const LearnMoreLink = styled.a`
  &:focus {
    color: #52b389 !important;
  }
  &:hover {
    color: white !important;
  }
`
