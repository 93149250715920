import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import NavContainer from '../NavContainer'

import NewFrame from '../../components/admin/NewFrame'

import { create } from '../../actions/frames'

import { Parameterize } from '../../utilities/Parameterize'

class AdminNewFrameContainer extends Component {  
  constructor(props) {
    super(props)
    this.state = { name: '', nameText: '', email: '', apps: ['photos'] }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleAppsChange = this.handleAppsChange.bind(this)
    this.redirectToActivateIfNecessary = this.redirectToActivateIfNecessary.bind(this)
    this.redirectToActivateIfNecessary()
  }

  redirectToActivateIfNecessary() {
    if (this.props.newFrame) {
      let activateUrl = `/frames/${ this.props.newFrame.id }/activate`
      this.props.history.push(activateUrl)
    }
  }
  
  componentDidUpdate() {
    this.redirectToActivateIfNecessary()
  }

  handleSubmit(event) {
    this.props.dispatch(create(this.state, true))
    event.preventDefault()
  }

  handleNameChange(event) {
    let nameText = event.target.value
    let name = Parameterize.call(nameText)
    this.setState({ name, nameText })
  }

  handleEmailChange(event) {
    let email = event.target.value
    this.setState({ email })
  }

  handleAppsChange(e) {
    this.setState({ apps: [e.target.value] })
  }

  render() {
    const { history, creatingFrame, errors } = this.props
    return (
      <div>
        <NavContainer
          history={history} />
        <div className='row justify-content-md-center'>
          <NewFrame 
            parentState={this.state}
            creatingFrame={creatingFrame}
            errors={errors}
            handleSubmit={this.handleSubmit}
            handleAppsChange={this.handleAppsChange}
            handleNameChange={this.handleNameChange}
            handleEmailChange={this.handleEmailChange}/>
        </div>
      </div>
    )
  }
}

AdminNewFrameContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  newFrame: PropTypes.object,
  creatingFrame: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired
}

const mapStateToProps = (state) => {
  const { creatingFrame, newFrame, errors } = state.frames
  return { creatingFrame, newFrame, errors }
}

export default connect(mapStateToProps)(AdminNewFrameContainer)