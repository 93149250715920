const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const DAYS = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat']

export const DateConverter = {
  time: (str) => {
    const d = new Date(str)
    let hr = d.getHours()
    let min = d.getMinutes()
    if (min < 10) min = `0${min}`
    let ampm = 'am'
    if (hr === 12) {
      ampm = 'pm'
    } else if (hr === 0) {
      hr += 12
    } else if (hr > 12) {
      hr -= 12
      ampm = 'pm'
    }
    return [[hr, min].join(':'), ampm].join('')
  },
  call: (str, excludeHour, useUTC) => {
    const d = new Date(str)
    const day = DAYS[useUTC ? d.getUTCDay() : d.getDay()]
    const date = useUTC ? d.getUTCDate() : d.getDate()
    const month = MONTHS[d.getMonth()]
    const base = excludeHour ? [day] : [DateConverter.time(str), 'on', day]
    const parts = [base.join(' '), ', ', month, ' ', date]
    const year = d.getFullYear()
    if (year !== new Date().getFullYear()) {
      parts.push(', ')
      parts.push(year)
    }
    return parts.join('')
  },
  MMDDYYY: (str) => {
    const d = new Date(str)
    const curDate = d.getDate()
    const curMonth = d.getMonth() + 1
    const curYear = d.getFullYear()
    return `${curMonth}.${curDate}.${curYear}`
  },
}
