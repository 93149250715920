export const DEPLOYMENTS_REQUEST = 'DEPLOYMENTS_REQUEST'
export const DEPLOYMENTS_SUCCESS = 'DEPLOYMENTS_SUCCESS'
export const DEPLOYMENTS_FAILURE = 'DEPLOYMENTS_FAILURE'

export function listDeployments() {
  return {
    type: DEPLOYMENTS_REQUEST,
    api: {
      endpoint: 'deployments',
      isAdmin: true,
      authenticated: true,
      types: [DEPLOYMENTS_REQUEST, DEPLOYMENTS_SUCCESS, DEPLOYMENTS_FAILURE],
      method: 'GET',
    },
  }
}

export const DEPLOYMENTS_CREATE_REQUEST = 'DEPLOYMENTS_CREATE_REQUEST'
export const DEPLOYMENTS_CREATE_SUCCESS = 'DEPLOYMENTS_CREATE_SUCCESS'
export const DEPLOYMENTS_CREATE_FAILURE = 'DEPLOYMENTS_CREATE_FAILURE'

export function create(data) {
  return {
    type: DEPLOYMENTS_CREATE_REQUEST,
    api: {
      data,
      endpoint: 'deployments',
      isAdmin: true,
      authenticated: true,
      types: [DEPLOYMENTS_CREATE_REQUEST, DEPLOYMENTS_CREATE_SUCCESS, DEPLOYMENTS_CREATE_FAILURE],
      method: 'POST',
    },
  }
}

export const DEPLOYMENTS_SHOW_REQUEST = 'DEPLOYMENTS_SHOW_REQUEST'
export const DEPLOYMENTS_SHOW_SUCCESS = 'DEPLOYMENTS_SHOW_SUCCESS'
export const DEPLOYMENTS_SHOW_FAILURE = 'DEPLOYMENTS_SHOW_FAILURE'

export function show(id) {
  return {
    type: DEPLOYMENTS_SHOW_REQUEST,
    api: {
      endpoint: `deployments/${id}`,
      isAdmin: true,
      authenticated: true,
      types: [DEPLOYMENTS_SHOW_REQUEST, DEPLOYMENTS_SHOW_SUCCESS, DEPLOYMENTS_SHOW_FAILURE],
      method: 'GET',
    },
  }
}

export const DEPLOYMENT_HEALTH_METRICS_REQUEST = 'DEPLOYMENT_HEALTH_METRICS_REQUEST'
export const DEPLOYMENT_HEALTH_METRICS_SUCCESS = 'DEPLOYMENT_HEALTH_METRICS_SUCCESS'
export const DEPLOYMENT_HEALTH_METRICS_FAILURE = 'DEPLOYMENT_HEALTH_METRICS_FAILURE'

export function updateHealthMetrics(id) {
  return {
    type: DEPLOYMENT_HEALTH_METRICS_REQUEST,
    api: {
      endpoint: `deployments/${id}/health_metrics`,
      isAdmin: true,
      authenticated: true,
      types: [
        DEPLOYMENT_HEALTH_METRICS_REQUEST,
        DEPLOYMENT_HEALTH_METRICS_SUCCESS,
        DEPLOYMENT_HEALTH_METRICS_FAILURE,
      ],
      method: 'PUT',
    },
  }
}

export const DEPLOYMENT_INTENT_CREATE_REQUEST = 'DEPLOYMENT_INTENT_CREATE_REQUEST'
export const DEPLOYMENT_INTENT_CREATE_SUCCESS = 'DEPLOYMENT_INTENT_CREATE_SUCCESS'
export const DEPLOYMENT_INTENT_CREATE_FAILURE = 'DEPLOYMENT_INTENT_CREATE_FAILURE'

export function createDeploymentIntent(versionId, data) {
  return {
    type: DEPLOYMENT_INTENT_CREATE_REQUEST,
    api: {
      data,
      endpoint: `android_versions/${versionId}/deployment_intents`,
      isAdmin: true,
      authenticated: true,
      types: [
        DEPLOYMENT_INTENT_CREATE_REQUEST,
        DEPLOYMENT_INTENT_CREATE_SUCCESS,
        DEPLOYMENT_INTENT_CREATE_FAILURE,
      ],
      method: 'POST',
    },
  }
}

export const DEPLOYMENT_INTENT_SHOW_REQUEST = 'DEPLOYMENT_INTENT_SHOW_REQUEST'
export const DEPLOYMENT_INTENT_SHOW_SUCCESS = 'DEPLOYMENT_INTENT_SHOW_SUCCESS'
export const DEPLOYMENT_INTENT_SHOW_FAILURE = 'DEPLOYMENT_INTENT_SHOW_FAILURE'

export function showDeploymentIntent(versionId, intentId) {
  return {
    type: DEPLOYMENT_INTENT_SHOW_REQUEST,
    api: {
      endpoint: `android_versions/${versionId}/deployment_intents/${intentId}`,
      isAdmin: true,
      authenticated: true,
      types: [
        DEPLOYMENT_INTENT_SHOW_REQUEST,
        DEPLOYMENT_INTENT_SHOW_SUCCESS,
        DEPLOYMENT_INTENT_SHOW_FAILURE,
      ],
      method: 'GET',
    },
  }
}

export const DEPLOYMENT_INTENT_PROMOTE_REQUEST = 'DEPLOYMENT_INTENT_PROMOTE_REQUEST'
export const DEPLOYMENT_INTENT_PROMOTE_SUCCESS = 'DEPLOYMENT_INTENT_PROMOTE_SUCCESS'
export const DEPLOYMENT_INTENT_PROMOTE_FAILURE = 'DEPLOYMENT_INTENT_PROMOTE_FAILURE'

export function promoteDeploymentIntent(versionId, intentId) {
  return {
    type: DEPLOYMENT_INTENT_PROMOTE_REQUEST,
    api: {
      endpoint: `android_versions/${versionId}/deployment_intents/${intentId}/promote`,
      isAdmin: true,
      authenticated: true,
      types: [
        DEPLOYMENT_INTENT_PROMOTE_REQUEST,
        DEPLOYMENT_INTENT_PROMOTE_SUCCESS,
        DEPLOYMENT_INTENT_PROMOTE_FAILURE,
      ],
      method: 'POST',
    },
  }
}

export const DEPLOYMENT_INTENT_CREATED_CLEAROUT = 'DEPLOYMENT_INTENT_CREATED_CLEAROUT'
