import {
  VERSIONS_REQUEST,
  VERSIONS_SUCCESS,
  VERSION_DEPLOYMENTS_REQUEST,
  VERSION_DEPLOYMENTS_SUCCESS,
  VERSION_DEPRECATION_REQUEST,
  VERSION_DEPRECATION_SUCCESS,
} from '../../actions/admin/versions'

const DEFAULT_STATE = {
  gettingVersions: true,
  versions: [],
  gettingVersionDeployments: true,
  versionDeployments: [],
  versionApks: [],
  version: null,
}

export function androidVersions(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case VERSIONS_REQUEST: {
      return { ...state, gettingVersions: true, versions: [] }
    }
    case VERSIONS_SUCCESS: {
      return { ...state, gettingVersions: false, versions: action.response.data }
    }
    case VERSION_DEPLOYMENTS_REQUEST: {
      return {
        ...state,
        gettingVersionDeployments: true,
        versionDeployments: [],
        versionApks: [],
        version: null,
      }
    }
    case VERSION_DEPLOYMENTS_SUCCESS: {
      const { response } = action
      return {
        ...state,
        gettingVersionDeployments: false,
        versionDeployments: response.data,
        version: response.included.find((include) => include.type === 'android_version'),
        versionApks: response.included.filter((include) => include.type === 'admin_apk'),
      }
    }
    case VERSION_DEPRECATION_SUCCESS: {
      return { ...state, version: action.response.data }
    }
    default:
      return state
  }
}
