import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import YouTubeIframePlayer from './YouTubeIframePlayer'
import PostActivationAttributionSurvey from './PostActivationAttributionSurvey'

import { Analytics } from '../utilities/Analytics'
import { FrameKind } from '../utilities/FrameKind'

import { getPurchaseMetadata } from '../actions/purchaseMetadata'

const FinalStep = ({ frame, activationCode }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPurchaseMetadata(frame))
    Analytics.track('Reached Final Step')
    window.scrollTo(0, 0)
  }, [])

  const { fromAmazon } = useSelector((state) => state.purchaseMetadata)

  const kind = FrameKind.for(frame.attributes.apps[0])
  const calendar = kind === 'Calendar'
  const nextStepLink = '/'
  return (
    <div>
      <div className="register-box">
        <h1>Congrats!</h1>
        <p>Your account is fully set up.</p>
        {activationCode && (
          <div>
            <p>If you haven't already activated your {kind}, here is the code again:</p>
            <code className="text-lg full-width">{activationCode}</code>
          </div>
        )}
        <p className="mt-3">
          {activationCode ? 'After you enter that code, y' : 'Y'}ou can send{' '}
          {calendar ? 'events' : 'photos'} to your Skylight by{' '}
          {calendar
            ? 'inviting this email address as a guest to any calendar event:'
            : 'emailing them (as attachments) to:'}
        </p>
        <code className="full-width">{frame.attributes.name}@ourskylight.com</code>
        {fromAmazon && <PostActivationAttributionSurvey frame={frame} />}
        {kind === 'Frame' && (
          <div className="text-center mt-3">
            <p className="my-0">Still not sure how it works?</p>
            <p className="my-0">
              <b>Check out this quick video on how to use Skylight Frame!</b>
            </p>
            <YouTubeIframePlayer
              youtubeVideoId="nVhIg8n1gUI"
              analyticsTag="first Frame tutorial video"
            />
          </div>
        )}
        <div className="text-right mt-4 mb-2">
          <Link to={nextStepLink} className="btn btn btn-success">
            Finish
          </Link>
        </div>
      </div>
    </div>
  )
}

FinalStep.propTypes = {
  frame: PropTypes.object.isRequired,
  activationCode: PropTypes.string.isRequired,
}

export default FinalStep
