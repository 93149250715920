import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Overlay, Tooltip, Dropdown, Toast } from 'react-bootstrap'
import styled from 'styled-components'

import { copyMessagesToFrames } from '../actions/frames'

import transferToFrameIcon from '../../assets/images/transferToFrameIcon.png'
import greenAirplaneIcon from '../../assets/images/greenAirplane.png'
import uncheckedBox from '../../assets/images/uncheckedBox.png'
import checkedBox from '../../assets/images/checkedBox.png'

export default function TransferMessagesButtonWithTooltip({
  currentFrame,
  frames,
  selectedMessageIds,
}) {
  const dispatch = useDispatch()
  const transferBtnRef = useRef(null)
  const [showToolTip, setShowToolTip] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [showDropdown, setShowDropDown] = useState(false)
  const [selectedFrameIds, setSelectedFrameIds] = useState([])

  const transferMessagesStatus = useSelector((state) => state.frames.transferMessagesStatus)

  function toggleSelectedFrame(frameId) {
    const hasFrameBeenSelected = selectedFrameIds.includes(frameId)
    if (hasFrameBeenSelected) {
      setSelectedFrameIds(selectedFrameIds.filter((id) => id !== frameId))
    } else {
      setSelectedFrameIds([...selectedFrameIds, frameId])
    }
  }

  function handleSendClick() {
    dispatch(copyMessagesToFrames(currentFrame.id, selectedMessageIds, selectedFrameIds))
    setShowDropDown(false)
    setSelectedFrameIds([])
    setShowToast(true)
  }

  return (
    <>
      <StyledToast onClose={() => setShowToast(false)} show={showToast} delay={2500} autohide>
        <ToastHeader>
          <b>Transer {transferMessagesStatus === 'success' ? 'Success!' : 'Failed'}</b>
        </ToastHeader>
        <Toast.Body>
          {transferMessagesStatus === 'success'
            ? 'Your Photos/Videos will be transfered shortly'
            : 'Something went wrong with your transfer, please try again.'}
          }
        </Toast.Body>
      </StyledToast>
      <Dropdown drop="up" show={showDropdown} onToggle={() => setShowDropDown((s) => !s)}>
        <StyledBsDropdownToggle childBsPrefix="no-style-btn png-icon">
          <div
            role="presentation"
            onClick={() => setShowToolTip(false)}
            onMouseEnter={() => setShowToolTip(true)}
            onMouseLeave={() => setShowToolTip(false)}
          >
            <img
              src={transferToFrameIcon}
              alt="Copy To"
              width="25"
              height="25"
              ref={transferBtnRef}
            />
          </div>
        </StyledBsDropdownToggle>

        <StyledBsDropdownMenu role="menu">
          <StyledBsDropdownHeader>
            <h4>Copy to another frame</h4>
            <GreenAirplaneBtn
              type="button"
              className="no-style-btn"
              disabled={!selectedFrameIds.length}
              onClick={handleSendClick}
            >
              <img src={greenAirplaneIcon} alt="Send" width="26" height="26" />
            </GreenAirplaneBtn>
          </StyledBsDropdownHeader>
          <StyledBsDropdownDivider />
          <DropdownItemsCtn>
            {frames.map((f) => {
              if (f.id !== currentFrame.id && f.attributes.apps[0] !== 'calendar') {
                return (
                  <DropdownItem onClick={() => toggleSelectedFrame(f.id)} key={`frame-${f.id}`}>
                    <img
                      src={selectedFrameIds.includes(f.id) ? checkedBox : uncheckedBox}
                      alt="Select Frame"
                      width="18"
                      height="18"
                    />
                    <p>{f.attributes.name}</p>
                  </DropdownItem>
                )
              }
              return null
            })}
          </DropdownItemsCtn>
        </StyledBsDropdownMenu>
      </Dropdown>
      <Overlay target={transferBtnRef.current} show={showToolTip && !showDropdown} placement="top">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {(props) => <StyledTooltip {...props}>Copy To</StyledTooltip>}
      </Overlay>
    </>
  )
}

TransferMessagesButtonWithTooltip.propTypes = {
  currentFrame: PropTypes.object.isRequired,
  frames: PropTypes.array.isRequired,
  selectedMessageIds: PropTypes.array.isRequired,
}

const StyledBsDropdownMenu = styled(Dropdown.Menu)`
  min-width: 13.5rem;
  padding-bottom: 0;
`

const StyledBsDropdownToggle = styled(Dropdown.Toggle)`
  &&.dropdown-toggle:after {
    content: none;
  }
`

const StyledBsDropdownHeader = styled(Dropdown.Header)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.5rem;
  padding: 0;
  h4 {
    font-weight: bold;
    color: black;
    font-size: 0.95rem;
    margin: 0;
  }
  > img {
    :hover {
      cursor: pointer;
    }
  }
`

const StyledBsDropdownDivider = styled(Dropdown.Divider)`
  margin-bottom: 0;
`

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0;
  * {
    margin: 0 0 0 1.25rem;
  }
  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
`

const DropdownItemsCtn = styled.div`
  height: 8rem;
  overflow-y: scroll;
  ${DropdownItem}:first-child {
    margin-top: 0.25rem;
  }
`

const StyledTooltip = styled(Tooltip)`
  /* Override defined SASS Style */
  &.fade.show {
    background-color: transparent;
  }
`

const GreenAirplaneBtn = styled.button`
  padding: 0;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`

const StyledToast = styled(Toast)`
  position: absolute;
  bottom: 4rem;
  left: 1rem;
`

const ToastHeader = styled(Toast.Header)`
  display: flex;
  justify-content: space-between;
`
