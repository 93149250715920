import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import NavContainer from '../../containers/NavContainer'

const RequestApproval = ({
  match: {
    params: { id },
  },
}) => {
  const history = useHistory()
  return (
    <>
      <NavContainer history={history} />
      <div className="row justify-content-md-center">
        <div className="col-lg-8 col-xl-6">
          <h1>Request Access</h1>
          <p>To ensure our customer's privacy, please request access before proceeding.</p>
          <ButtonRow>
            <button
              type="button"
              onClick={() => history.push('/')}
              className="btn btn-info pull-left"
            >
              ← Back
            </button>
            <Link to={`/frames/${id}/messages`} className="btn btn-success pull-right">
              Request Frame Access
            </Link>
          </ButtonRow>
        </div>
      </div>
    </>
  )
}

RequestApproval.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
}

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export default RequestApproval
