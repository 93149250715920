import React, { Component } from 'react'
import PropTypes from 'prop-types'

import NewCategoryModal from './NewCategoryModal'
import DestroyCategoryModal from './DestroyCategoryModal'
import UpdateCategoryModal from './UpdateCategoryModal'
import Loading from './Loading'

export default class CalendarCategories extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showingNewCategoryModal: false,
      categoryToRemove: null,
      categoryToUpdate: null,
    }
    this.toggleModal = this.toggleModal.bind(this)
    this.saveCategory = this.saveCategory.bind(this)
    this.update = this.update.bind(this)
  }

  toggleModal() {
    this.setState({ showingNewCategoryModal: !this.state.showingNewCategoryModal })
  }

  saveCategory(attributes) {
    this.props.createCategory(attributes)
    this.toggleModal()
  }

  update(attributes) {
    const { updateCategory } = this.props
    const { categoryToUpdate } = this.state
    updateCategory(categoryToUpdate, attributes)
    this.setState({ categoryToUpdate: null })
  }

  render() {
    const { showingNewCategoryModal, categoryToRemove, categoryToUpdate } = this.state
    const { loading, categories, removeCategory } = this.props
    return (
      <div>
        {showingNewCategoryModal && (
          <NewCategoryModal save={this.saveCategory} dismiss={this.toggleModal} />
        )}
        {categoryToRemove && (
          <DestroyCategoryModal
            destroy={removeCategory}
            category={categoryToRemove}
            categories={categories}
            dismiss={() => this.setState({ categoryToRemove: null })}
          />
        )}
        {categoryToUpdate && (
          <UpdateCategoryModal
            update={this.update}
            category={categoryToUpdate}
            dismiss={() => this.setState({ categoryToUpdate: null })}
          />
        )}
        <div>
          {loading ? (
            <Loading />
          ) : (
            <div>
              {categories.length == 0 ? (
                <div className="row">
                  <div className="col-8 offset-2">
                    <h2 className="text-center text-muted mt-4">No Event Categories</h2>
                    <p className="text-center mt-4">
                      <b>Categories</b> allow you to color code the events on your calendar.
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="row">
                    <div className="col-12">
                      <h2 className="text-center text-muted my-4">My Categories</h2>
                    </div>
                  </div>
                  {categories.map((category) => {
                    return (
                      <div key={category.id} className="row">
                        <div className="col-12">
                          <div className="grocery-item-wrapper">
                            <div className="row">
                              <div className="col-8">
                                <h3 className="text-muted">
                                  <span
                                    style={{
                                      width: 50,
                                      height: 50,
                                      backgroundColor: category.attributes.color,
                                    }}
                                  >
                                    &nbsp;
                                  </span>{' '}
                                  &nbsp;
                                  {category.attributes.label}
                                </h3>
                              </div>
                              <div className="col-4 text-right">
                                <button
                                  onClick={() => this.setState({ categoryToUpdate: category })}
                                  className="btn btn-sm btn-primary mb-1"
                                >
                                  <span className="oi oi-pencil"></span> Edit
                                </button>
                                &nbsp;
                                <button
                                  onClick={() => this.setState({ categoryToRemove: category })}
                                  className="btn btn-sm btn-info mb-1"
                                >
                                  <span className="oi oi-trash"></span> Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
              <div className="row mt-5">
                <div className="col-12 text-center">
                  <button onClick={this.toggleModal} className="btn btn-success">
                    Add Category
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

CalendarCategories.propTypes = {
  loading: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  createCategory: PropTypes.func.isRequired,
  removeCategory: PropTypes.func.isRequired,
  updateCategory: PropTypes.func.isRequired,
}
