import React from 'react'
import PropTypes from 'prop-types'
import FullStory from 'react-fullstory'

import { Link } from 'react-router-dom'

const SyncIndvEvents = ({ frame }) => (
  <div className="register-box">
    <FullStory org="R6R1G" />
    <h1>Sync Individual Events</h1>
    <p className="my-0">
      Send events to your Skylight Calendar by inviting this email address as a guest to any
      calendar event.
    </p>
    <code className="full-width my-4">
      <big>{frame.attributes.name}@ourskylight.com</big>
    </code>
    <div className="text-right">
      <Link to={`/frames/${frame.id}/final-step`} className="btn btn-success">
        Finish
      </Link>
    </div>
  </div>
)

SyncIndvEvents.propTypes = {
  frame: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    attributes: PropTypes.shape({
      apps: PropTypes.array,
      code: PropTypes.string,
      grace_period_ends: PropTypes.string,
      name: PropTypes.string,
      notification_email: PropTypes.string,
      open_to_public: PropTypes.bool,
      plus: PropTypes.bool,
    }),
    relationships: PropTypes.shape({
      approved_users: PropTypes.shape({
        data: PropTypes.array,
      }),
      user: PropTypes.shape({
        data: PropTypes.shape({
          id: PropTypes.string,
          type: PropTypes.string,
        }),
      }),
    }),
  }),
}

export default SyncIndvEvents
