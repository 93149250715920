import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { connect } from 'react-redux'

import { showMessage, destroy, destroyDuplicates } from '../actions/messages'
import { update as updateCaptionText, destroy as destroyCaptionText } from '../actions/captions'

import NavContainer from './NavContainer'

import Message from '../components/Message'
import ModalMessage from '../components/ModalMessage'
import DuplicateDestroyModal from '../components/DuplicateDestroyModal'
import Loading from '../components/Loading'

import { Session } from '../utilities/Session'
import UpdateCaptionModal from '../components/UpdateCaptionModal'

class MessageContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userId: Session.getId(),
      isAdmin: Session.isAdmin(),
      showingBulkDestroyModal: false,
      showingCaptionUpdateModal: false,
    }
    this.destroyMessage = this.destroyMessage.bind(this)
    this.destroyOne = this.destroyOne.bind(this)
    this.destroyAll = this.destroyAll.bind(this)
    this.destroyCaption = this.destroyCaption.bind(this)
    this.updateCaption = this.updateCaption.bind(this)
  }

  componentDidMount() {
    const { match, dispatch } = this.props
    const { frameId, id } = match.params
    dispatch(showMessage(frameId, id))
  }

  componentDidUpdate(prevProps) {
    const { message, match, history, dispatch } = this.props
    const { frameId, id } = match.params
    if (message && message.attributes.destroyed_at) {
      history.replace(`/frames/${frameId}/messages`)
    }
    if (prevProps.match.params.id !== id) {
      dispatch(showMessage(frameId, id))
    }
  }

  destroyMessage() {
    const { message, hasBulkFeatures } = this.props
    if (hasBulkFeatures) {
      this.setState({ showingBulkDestroyModal: true })
    } else if (
      window.confirm(`Are you sure you want to delete this ${message.attributes.asset_type}?`)
    ) {
      this.destroyOne()
    }
  }

  destroyOne() {
    const { match, dispatch } = this.props
    const { frameId, id } = match.params
    dispatch(destroy(frameId, id))
    this.setState({ showingBulkDestroyModal: false })
    window.location = `/frames/${frameId}/messages`
  }

  destroyAll() {
    const { match, dispatch } = this.props
    const { frameId, id } = match.params
    dispatch(destroyDuplicates(frameId, id))
    this.setState({ showingBulkDestroyModal: false })
    window.location = `/frames/${frameId}/messages`
  }

  destroyCaption() {
    const { dispatch, match } = this.props
    const { frameId, id } = match.params
    dispatch(destroyCaptionText(frameId, id))
    this.setState({ showingCaptionUpdateModal: false })
  }

  updateCaption(text) {
    const { dispatch, match } = this.props
    const { frameId, id } = match.params
    dispatch(updateCaptionText(frameId, id, text))
    this.setState({ showingCaptionUpdateModal: false })
  }

  render() {
    const { showingBulkDestroyModal, showingCaptionUpdateModal, userId, isAdmin } = this.state
    const { history, params, message, isModal, frameOwner } = this.props
    const frameId = params.id
    return (
      <React.Fragment>
        {!isModal && <NavContainer history={history} />}
        {showingBulkDestroyModal && (
          <DuplicateDestroyModal
            destroyOne={this.destroyOne}
            destroyDuplicates={this.destroyAll}
            dismiss={() => this.setState({ showingBulkDestroyModal: false })}
          />
        )}
        {showingCaptionUpdateModal && (
          <UpdateCaptionModal
            currentText={message.attributes.caption}
            destroyCaption={this.destroyCaption}
            updateCaption={this.updateCaption}
            dismiss={() => this.setState({ showingCaptionUpdateModal: false })}
          />
        )}
        {!message ? (
          <div className="d-flex align-items-center justify-content-center">
            <Loading />
          </div>
        ) : isModal ? (
          <ModalMessage
            message={message}
            frameId={frameId}
            userId={userId}
            frameOwner={frameOwner}
            isAdmin={isAdmin}
            updateCaption={() => this.setState({ showingCaptionUpdateModal: true })}
            destroy={this.destroyMessage}
          />
        ) : (
          <div className="row justify-content-md-center">
            <div className="col-lg-8 col-xl-6">
              <Message
                history={history}
                frameId={frameId}
                message={message}
                userId={userId}
                frameOwner={frameOwner}
                destroy={this.destroyMessage}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

MessageContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  params: PropTypes.object.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  dispatch: PropTypes.func.isRequired,
  message: PropTypes.object,
  hasBulkFeatures: PropTypes.bool.isRequired,
  isModal: PropTypes.bool,
  frameOwner: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const { hasBulkFeatures } = state.frames
  const { message, frameOwner } = state.messages
  return { message, hasBulkFeatures, frameOwner }
}

export default connect(mapStateToProps)(MessageContainer)
