import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import { Analytics } from '../utilities/Analytics'

export default class PlusSuccess extends Component {
  componentDidMount() {
    Analytics.track('Saw Plus Success')
    window.scrollTo(0, 0)
  }

  render() {
    const { frame } = this.props
    return (
      <div className="register-box">
        <h4>You Now Have Skylight Plus!</h4>
        <p>Any Frame you own will have Plus features.</p>
        <div className="row mt-4">
          <div className="col-6"></div>
          <div className="text-right col-6">
            <Link
              to={`/frames/${frame.id}/privacy?activating=true`}
              className="btn btn btn-success"
            >
              Next Step
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

PlusSuccess.propTypes = {
  frame: PropTypes.object.isRequired,
}
