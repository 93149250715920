import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import { connect } from 'react-redux'

import { show, showMeta, list as listFrames } from '../actions/frames'
import {
  list,
  destroyAll,
  appendPreloaded,
  toggleSelectedMessage,
  deselectAllMessages,
} from '../actions/messages'

import NavContainer from './NavContainer'
import CalendarContainer from './CalendarContainer'

import FrameMessages from '../components/FrameMessages'
import Loading from '../components/Loading'
import DeleteAllPhotosModal from '../components/DeleteAllPhotosModal'
import TrialBanner from '../components/TrialBanner'
import BackToTop from '../components/BackToTop.js'
import SelectedMessagesActionsBar from '../components/SelectedMessagesActionsBar'

import { UrlParams } from '../utilities/UrlParams'

class FrameMessagesContainer extends Component {
  constructor(props) {
    super(props)
    const urlParams = UrlParams.get()
    this.state = { deletingAll: false, overidingCalendar: urlParams.tab === 'photos' }
    this.preloadMore = this.preloadMore.bind(this)
    this.appendPreloaded = this.appendPreloaded.bind(this)
    this.deleteAll = this.deleteAll.bind(this)
    this.toggleDeletingAll = this.toggleDeletingAll.bind(this)
    this.toggleSelectedMessage = this.toggleSelectedMessage.bind(this)
    this.deselectAllMessages = this.deselectAllMessages.bind(this)
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const { id } = match.params
    dispatch(show(id))
    dispatch(showMeta(id))
    dispatch(list(id, 'init'))
    dispatch(listFrames(false))
    window.scrollTo(0, 0)
  }

  appendPreloaded() {
    const { dispatch } = this.props
    dispatch(appendPreloaded())
  }

  preloadMore(page) {
    const { dispatch, match, numPages } = this.props
    const { id } = match.params

    const pagesLeftToLoad = numPages - page
    if (pagesLeftToLoad >= 1) {
      dispatch(list(id, 'preload', page + 1))
    }
  }

  toggleDeletingAll() {
    this.setState((prevState) => ({
      deletingAll: !prevState.deletingAll,
    }))
  }

  deleteAll() {
    const { dispatch, match, history } = this.props
    const { id } = match.params
    dispatch(destroyAll(id))
    history.push('/')
  }

  toggleSelectedMessage(messageId, e) {
    e.stopPropagation()
    const { dispatch } = this.props
    dispatch(toggleSelectedMessage(messageId))
  }

  deselectAllMessages() {
    const { dispatch } = this.props
    dispatch(deselectAllMessages())
  }

  render() {
    const { history } = this.props
    // FrameMessages props
    const {
      frame,
      frames,
      gettingInitialMessages,
      upsellMeta,
      frameOwner,
      hasBulkFeatures,
      trialDaysRemaining,
      presence,
      isAdmin,
      inProgressUploads,
      message,
      setSelectedImgYPos,
      selectedMessageIds,
    } = this.props
    const { deletingAll, overidingCalendar } = this.state
    const isCalendar =
      frame && !overidingCalendar && frame.attributes.apps.indexOf('calendar') !== -1
    // Pagination props
    const { currentPage, numPages, pages, nonePreloaded } = this.props
    return (
      <div>
        {trialDaysRemaining && <TrialBanner trialDaysRemaining={trialDaysRemaining} />}
        <NavContainer history={history} />
        {!frame || gettingInitialMessages ? (
          <div className="row justify-content-md-center">
            <div className="col-lg-8 col-xl-6">
              <Loading />
            </div>
          </div>
        ) : (
          <div className="row justify-content-md-center">
            {isCalendar ? (
              <div className="col-lg-8 col-xl-6">
                <CalendarContainer frame={frame} history={history} />
              </div>
            ) : (
              <div className="col-lg-10 col-xl-8">
                {deletingAll && (
                  <DeleteAllPhotosModal
                    dismiss={this.toggleDeletingAll}
                    deleteAll={this.deleteAll}
                  />
                )}
                <FrameMessages
                  history={history}
                  frame={frame}
                  frames={frames}
                  upsellMeta={upsellMeta}
                  trialDaysRemaining={trialDaysRemaining}
                  isAdmin={isAdmin}
                  presence={presence}
                  frameOwner={frameOwner}
                  hasBulkFeatures={hasBulkFeatures}
                  deleteAll={this.toggleDeletingAll}
                  currentPage={currentPage}
                  numPages={numPages}
                  preloadMore={this.preloadMore}
                  appendPreloaded={this.appendPreloaded}
                  nonePreloaded={nonePreloaded}
                  pages={pages}
                  frameInProgressUploads={inProgressUploads[frame.attributes.name]}
                  currentlyViewingMessage={message}
                  selectedMessageIds={selectedMessageIds}
                  toggleSelectedMessage={this.toggleSelectedMessage}
                  setSelectedImgYPos={setSelectedImgYPos}
                />
              </div>
            )}
          </div>
        )}

        {selectedMessageIds.length > 0 ? (
          <SelectedMessagesActionsBar
            frame={frame}
            frames={frames}
            selectedMessageIds={selectedMessageIds}
            deselectAllMessages={this.deselectAllMessages}
          />
        ) : (
          !isCalendar && <BackToTop />
        )}
      </div>
    )
  }
}

FrameMessagesContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  frame: PropTypes.object,
  frames: PropTypes.array,
  gettingInitialMessages: PropTypes.bool.isRequired,
  frameOwner: PropTypes.bool.isRequired,
  currentPage: PropTypes.number,
  numPages: PropTypes.number,
  upsellMeta: PropTypes.object,
  hasBulkFeatures: PropTypes.bool.isRequired,
  trialDaysRemaining: PropTypes.number,
  presence: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  pages: PropTypes.array,
  nonePreloaded: PropTypes.bool,
  inProgressUploads: PropTypes.object.isRequired,
  message: PropTypes.object,
  setSelectedImgYPos: PropTypes.func,
  selectedMessageIds: PropTypes.array,
}

const mapStateToProps = (state) => {
  // FrameMessages props
  const { isAdmin } = state.session
  const { frame, hasBulkFeatures, trialDaysRemaining, presence, onRecently, frames } = state.frames
  const {
    gettingInitialMessages,
    upsellMeta,
    frameOwner,
    currentPage,
    numPages,
    pages,
    nonePreloaded,
    inProgressUploads,
    message,
    selectedMessageIds,
  } = state.messages
  return {
    frame,
    frames,
    hasBulkFeatures,
    trialDaysRemaining,
    presence,
    isAdmin,
    gettingInitialMessages,
    upsellMeta,
    frameOwner,
    currentPage,
    numPages,
    pages,
    nonePreloaded,
    inProgressUploads,
    message,
    selectedMessageIds,
  }
}

export default connect(mapStateToProps)(FrameMessagesContainer)
