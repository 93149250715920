import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FullStory from 'react-fullstory'

import { Link } from 'react-router-dom'

import { Analytics } from '../utilities/Analytics'
import { FrameKind } from '../utilities/FrameKind'
import { UrlParams } from '../utilities/UrlParams'
import { getActivationCode } from '../actions/frames'

const PREREQ_OVERRIDES = {
  TV: 'After opening the Skylight app on your TV',
}

export default class ActivateFrame extends Component {
  componentDidMount() {
    const { frame, dispatch } = this.props
    const { apps, name } = frame.attributes
    const app = apps[0]
    Analytics.track(
      'Received Activation Code',
      { app, frameType: FrameKind.for(app) },
      { frame: name }
    )
    window.scrollTo(0, 0)
    // eslint-disable-next-line no-undef
    fbq('track', 'CustomizeProduct')
    dispatch(getActivationCode(frame.id))
  }

  render() {
    const { frame, activationCode } = this.props
    const app = frame.attributes.apps[0]
    const calendar = app === 'calendar'
    const kind = FrameKind.for(app)
    const isActivating = !!UrlParams.get().activating
    let nextStepLink
    if (calendar) {
      nextStepLink = `/frames/${frame.id}/sync`
    } else if (isActivating) {
      nextStepLink = `/frames/${frame.id}/final-step`
    } else {
      nextStepLink = '/'
    }
    if (isActivating) nextStepLink += '?activating'
    const activationPrereqs =
      PREREQ_OVERRIDES[kind] || `After turning your ${kind} on and connecting to Wi-Fi`
    return (
      <div className="register-box">
        {calendar && <FullStory org="R6R1G" />}
        <h1>Activate {kind}</h1>
        {activationCode && (
          <div>
            <p>
              {activationPrereqs}, enter the below code to activate your {kind}.
            </p>
            <code className="text-lg full-width">{activationCode}</code>
          </div>
        )}
        <p className="mt-3">
          {activationCode ? 'After you enter that code, y' : 'Y'}ou can send{' '}
          {calendar ? 'events' : 'photos'} to your Skylight by{' '}
          {calendar
            ? 'inviting this email address as a guest to any calendar event:'
            : 'emailing them (as attachments) to:'}
        </p>
        <code className="full-width">{frame.attributes.name}@ourskylight.com</code>
        <SaveCodeForLaterText>
          If you don't have your {kind} in front of you, save this code for later.
        </SaveCodeForLaterText>
        <div className="text-right mt-5 mb-2">
          <Link to={nextStepLink} className="btn btn btn-success">
            Next Step
          </Link>
        </div>
      </div>
    )
  }
}

ActivateFrame.propTypes = {
  frame: PropTypes.object.isRequired,
  activationCode: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
}

const SaveCodeForLaterText = styled.p`
  margin: 1rem 0 0 0;
`
