import React, { Component } from 'react'
import PropTypes from 'prop-types'

const MAX_PAGES = 10

export default class Pagination extends Component {
  constructor(props) {
    super(props)
    this.pages = this.pages.bind(this)
  }

  pages() {
    let { currentPage, numPages } = this.props
    let pageRange = []
    if (numPages > MAX_PAGES) {
      let i = Math.max(1, parseInt(currentPage - MAX_PAGES / 2))
      let finalPage = Math.min(numPages, i + MAX_PAGES)
      while (i <= finalPage) {
        pageRange.push(i)
        i++
      }
    } else {
      let i = 1
      while (i <= numPages) {
        pageRange.push(i)
        i++
      }
    }
    return pageRange
  }
  render() {
    let { currentPage, numPages, toPage } = this.props
    return (
      <div className="row justify-content-center mt-5">
        <nav aria-label="pagination">
          <ul className="pagination">
            {currentPage > 1 && (
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Previous"
                  onClick={() => toPage(currentPage - 1)}
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </a>
              </li>
            )}
            {this.pages().map((page) => {
              return (
                <li key={page} className={currentPage == page ? 'page-item active' : 'page-item'}>
                  <a className="page-link" href="#" onClick={() => toPage(page)}>
                    {page}
                  </a>
                </li>
              )
            })}
            {currentPage < numPages && (
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Next"
                  onClick={() => toPage(currentPage + 1)}
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </a>
              </li>
            )}
          </ul>
        </nav>
      </div>
    )
  }
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
}
