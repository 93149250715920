import React, { Component } from 'react'
import PropTypes from 'prop-types'

import NewGroceryItemModal from './NewGroceryItemModal'
import Loading from './Loading'

export default class GroceryItems extends Component {
  constructor(props) {
    super(props)
    this.state = { showingNewItemModal: false }
    this.toggleModal = this.toggleModal.bind(this)
    this.saveItem = this.saveItem.bind(this)
  }

  toggleModal() {
    this.setState({ showingNewItemModal: !this.state.showingNewItemModal })
  }

  saveItem(text) {
    this.props.createItem(text)
    this.toggleModal()
  }

  render() {
    const { showingNewItemModal } = this.state
    const { loading, groceryItems, removeItem } = this.props
    return (
      <div>
        {showingNewItemModal && (
          <NewGroceryItemModal save={this.saveItem} dismiss={this.toggleModal} />
        )}
        <div>
          {loading ? (
            <Loading />
          ) : (
            <div>
              {groceryItems.length == 0 ? (
                <div className="row">
                  <div className="col-8 offset-2">
                    <h2 className="text-center text-muted mt-4">No Grocery Items</h2>
                    <p className="text-center mt-4">
                      You can add Grocery Items from your Calendar by tapping the{' '}
                      <span className="grocery-icon"></span> Grocery icon in the bottom right of
                      your device.
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="row">
                    <div className="col-12">
                      <h2 className="text-center text-muted my-4">My Grocery Items</h2>
                    </div>
                  </div>
                  {groceryItems.map((item) => {
                    return (
                      <div className="row">
                        <div className="col-12">
                          <div className="grocery-item-wrapper">
                            <div className="row">
                              <div className="col-8">
                                <h3 className="text-muted">{item.attributes.text}</h3>
                              </div>
                              <div className="col-4 text-right">
                                <button
                                  onClick={() => removeItem(item)}
                                  className="btn btn-sm btn-info"
                                >
                                  <span className="oi oi-trash"></span> Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
              <div className="row mt-5">
                <div className="col-12 text-center">
                  <button onClick={this.toggleModal} className="btn btn-success">
                    Add Grocery Item
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

GroceryItems.propTypes = {
  loading: PropTypes.bool.isRequired,
  groceryItems: PropTypes.array.isRequired,
  createItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
}
