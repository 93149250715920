import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { connect } from 'react-redux'

import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'
import { logInWithGoogle } from '../actions/session'
import Errors from '../components/Errors'
import Nav from '../components/Nav'

class LogInWithGoogleContainer extends Component {
  constructor(props) {
    super(props)

    this.onSuccess = this.onSuccess.bind(this)
    this.redirectIfLoggedIn = this.redirectIfLoggedIn.bind(this)
  }

  componentDidMount() {
    this.redirectIfLoggedIn()
  }

  componentDidUpdate() {
    this.redirectIfLoggedIn()
  }

  onSuccess(data) {
    const { dispatch } = this.props
    dispatch(logInWithGoogle(data.credential))
  }

  static onError(data) {
    console.error(data)
  }

  redirectIfLoggedIn() {
    const { loggedIn, history } = this.props

    if (loggedIn) {
      history.push('/')
    }
  }

  render() {
    const { errors } = this.props
    return (
      <div>
        <Nav loggedIn={false} isAdmin={false} />

        <div className="new-user-or-frame-ctn">
          <Errors errors={errors} />
          <GoogleOAuthProvider clientId="700365098414-i9h2ug063i969ndp6oeajo9f7hiumt53.apps.googleusercontent.com">
            <GoogleLogin onSuccess={this.onSuccess} onError={this.onError} />
          </GoogleOAuthProvider>
        </div>
      </div>
    )
  }
}

LogInWithGoogleContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
}

const mapStateToProps = (state) => {
  const { justResetPassword, loggedIn, loggingIn, email, errors } = state.session
  return { justResetPassword, loggedIn, loggingIn, email, errors }
}

export default connect(mapStateToProps)(LogInWithGoogleContainer)
