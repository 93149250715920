import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Errors extends Component {
  render() {
    const { errors } = this.props
    if (errors.length == 0) return null
    return (
      <div className="alert alert-warning" role="alert">
        {errors.join('. ')}
      </div>
    )
  }
}

Errors.propTypes = {
  errors: PropTypes.array.isRequired,
}
