import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { DateConverter } from '../utilities/DateConverter'

import MessageItem from './MessageItem'

export default class MessagePage extends PureComponent {
  componentDidMount() {
    const { preloadMore, currentPage, numPages } = this.props
    if (numPages > currentPage) preloadMore(currentPage)
  }

  render() {
    const {
      messages,
      frame,
      currentlyViewingMessage,
      setSelectedImgYPos,
      selectedMessageIds,
      toggleSelectedMessage,
      frameOwner,
    } = this.props
    return (
      <React.Fragment>
        {messages.map((message) => {
          const createdAtStr = DateConverter.call(new Date(message.attributes.created_at))
          return (
            <div key={message.id} className="col-md-4">
              <MessageItem
                frameOwner={frameOwner}
                message={message}
                frame={frame}
                createdAtStr={createdAtStr}
                setSelectedImgYPos={setSelectedImgYPos}
                currentlyViewingMessage={currentlyViewingMessage}
                isSelectingMessages={selectedMessageIds.length > 0}
                isSelected={selectedMessageIds.includes(message.id)}
                toggleSelectedMessage={toggleSelectedMessage}
              />
            </div>
          )
        })}
      </React.Fragment>
    )
  }
}

MessagePage.propTypes = {
  preloadMore: PropTypes.func,
  currentPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
  messages: PropTypes.array.isRequired,
  frame: PropTypes.object.isRequired,
  currentlyViewingMessage: PropTypes.object,
  setSelectedImgYPos: PropTypes.func,
  selectedMessageIds: PropTypes.array,
  toggleSelectedMessage: PropTypes.func,
  frameOwner: PropTypes.bool.isRequired,
}
