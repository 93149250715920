export const SHARE_TOKEN_REDEMPTION_REQUEST = 'SHARE_TOKEN_REDEMPTION_REQUEST'
export const SHARE_TOKEN_REDEMPTION_SUCCESS = 'SHARE_TOKEN_REDEMPTION_SUCCESS'
export const SHARE_TOKEN_REDEMPTION_FAILURE = 'SHARE_TOKEN_REDEMPTION_FAILURE'

export function redeemShareToken(frameId, shareToken) {
  return {
    type: SHARE_TOKEN_REDEMPTION_REQUEST,
    api: {
      data: { share_token: shareToken },
      endpoint: `frames/${frameId}/share_token_redemptions`,
      authenticated: true,
      types: [
        SHARE_TOKEN_REDEMPTION_REQUEST,
        SHARE_TOKEN_REDEMPTION_SUCCESS,
        SHARE_TOKEN_REDEMPTION_FAILURE,
      ],
      method: 'POST',
    },
  }
}
