import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

const COLUMN_CLASS = 'col-2 progress-column d-flex justify-content-center'
const CIRCLE_CLASS = 'd-flex progress-circle justify-content-center align-items-center'

export default class ProgressBar extends Component {
  render() {
    const { step } = this.props
    return (
      <div className="row progress-row justify-content-center">
        {[1, 2, 3, 4, 5].map((i) => {
          return (
            <div key={i} className={`${COLUMN_CLASS} ${i <= step ? 'active-progress-column' : ''}`}>
              <div
                className={`${CIRCLE_CLASS} ${
                  i < step ? 'completed-progress-circle' : i == step ? 'active-progress-circle' : ''
                }`}
              >
                {i}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

ProgressBar.propTypes = {
  step: PropTypes.number.isRequired,
}
