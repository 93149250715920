import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import PlusLinkContainer from './PlusLinkContainer'

import { show } from '../actions/plusPermissions'

import { Analytics } from '../utilities/Analytics'

const POLL_MILLI = 5000

class PlusUpsellContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { alreadyPurchased: false }
    this.toggleAlreadyPurchased = this.toggleAlreadyPurchased.bind(this)
    this.poll = this.poll.bind(this)
  }

  componentDidMount() {
    const { frame, history } = this.props
    const { plus, trialing } = frame.attributes
    // Skip the upsell if they already have plus
    if (plus || trialing) {
      if (plus) Analytics.track('Had Plus Before Activation')
      history.push(`/frames/${frame.id}/activate?activating=true`)
    } else {
      Analytics.track('Saw Plus Upsell')
      window.scrollTo(0, 0)
      this.poll()
    }
  }

  componentWillUnmount() {
    if (this.poller) clearTimeout(this.poller)
  }

  poll() {
    const { dispatch, createdPlusPermission } = this.props
    if (!createdPlusPermission) {
      dispatch(show())
      this.poller = setTimeout(this.poll, POLL_MILLI)
    }
  }

  toggleAlreadyPurchased() {
    this.setState((prevState) => ({
      alreadyPurchased: !prevState.alreadyPurchased,
    }))
  }

  render() {
    const { dispatch, history, frame } = this.props
    const { alreadyPurchased } = this.state
    return (
      <PlusLinkContainer
        dispatch={dispatch}
        history={history}
        back={this.toggleAlreadyPurchased}
        frame={frame}
        toggleAlreadyPurchased={this.toggleAlreadyPurchased}
        alreadyPurchased={alreadyPurchased}
      />
    )
  }
}

PlusUpsellContainer.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  frame: PropTypes.object.isRequired,
  createdPlusPermission: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  const { createdPlusPermission } = state.plusPermissions
  return { createdPlusPermission }
}

export default connect(mapStateToProps)(PlusUpsellContainer)
