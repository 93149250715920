export const PASSWORD_RESETS_UNFLAG_SUCCESS = 'PASSWORD_RESETS_UNFLAG_SUCCESS'
export const PASSWORD_RESETS_REQUEST = 'PASSWORD_RESETS_REQUEST'
export const PASSWORD_RESETS_SUCCESS = 'PASSWORD_RESETS_SUCCESS'
export const PASSWORD_RESETS_FAILURE = 'PASSWORD_RESETS_FAILURE'

export function create(email) {
  return {
    type: PASSWORD_RESETS_REQUEST,
    api: {
      endpoint: 'password_resets',
      authenticated: false,
      types: [PASSWORD_RESETS_REQUEST, PASSWORD_RESETS_SUCCESS, PASSWORD_RESETS_FAILURE],
      data: { email },
      method: 'POST',
    },
  }
}
