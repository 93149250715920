import React from 'react'
import styled from 'styled-components'
import useWindowWidth from '../hooks/useWindowWidth'
import thumbnail from '../../assets/images/free-trial-video.jpg'

const MIN_AUTOPLAY_WIDTH = 700

const FreeTrialVideo = () => {
  const { width } = useWindowWidth()
  return (
    <FreeTrialVidCtn>
      <video
        id="upsell-video"
        autoPlay={width > MIN_AUTOPLAY_WIDTH}
        muted
        loop
        controls={width < MIN_AUTOPLAY_WIDTH}
        poster={thumbnail}
      >
        <source
          src="https://skylight-marketing-assets.s3.amazonaws.com/plus-upsell-video.mp4"
          type="video/mp4"
        />
      </video>
    </FreeTrialVidCtn>
  )
}

export default FreeTrialVideo

const FreeTrialVidCtn = styled.div`
  grid-area: video;
  justify-self: center;
  width: 223px;
  height: 450px;
  max-width: 100%;
`
