import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { connect } from 'react-redux'
import { register } from '../actions/session'

import Register from '../components/Register'

import { Analytics } from '../utilities/Analytics'
import { UrlParams } from '../utilities/UrlParams'

class RegisterContainer extends Component {
  constructor(props) {
    super(props)
    const urlParams = UrlParams.get()
    this.state = { email: '', has_an_account: !!urlParams.has_an_account }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.redirectIfNecessary = this.redirectIfNecessary.bind(this)
    this.toggleHasAnAccount = this.toggleHasAnAccount.bind(this)
    this.redirectIfNecessary()
  }

  componentDidMount() {
    const urlParams = UrlParams.get()
    Analytics.track('Viewed Registration', {
      registration_group: urlParams.group,
    })
    if (urlParams.redirect_to) localStorage.setItem('redirectTo', urlParams.redirect_to)
    window.scrollTo(0, 0)
  }

  componentDidUpdate() {
    this.redirectIfNecessary()
  }

  redirectIfNecessary() {
    const { history, loggedIn, passwordResetToken, knowsPassword, checkYourEmail } = this.props
    const showDownloadAppPage = JSON.parse(localStorage.getItem('showDownloadAppPage'))
    const urlParams = UrlParams.get()
    let redirectUrl = null
    if (loggedIn) {
      redirectUrl = '/frames/new'
    } else if (passwordResetToken) {
      redirectUrl = `/log-in/${passwordResetToken}`
    } else if (knowsPassword) {
      redirectUrl = '/log-in'
    } else if (checkYourEmail) {
      redirectUrl = '/check-your-email'
    } else if (showDownloadAppPage !== false && !urlParams.has_an_account) {
      redirectUrl = '/download-app'
    }
    if (redirectUrl) {
      if (Object.keys(urlParams).length > 0) {
        redirectUrl += '?'
        Object.keys(urlParams).forEach((k) => {
          if (k) redirectUrl += `${k}=${urlParams[k]}&`
        })
      }
      history.push(redirectUrl)
    }
  }

  handleChange(key) {
    return (event) => {
      const newState = this.state
      newState[key] = event.target.value
      this.setState(newState)
    }
  }

  toggleHasAnAccount() {
    this.setState((prevState) => ({ has_an_account: !prevState.has_an_account }))
  }

  handleSubmit(event) {
    const { dispatch } = this.props
    dispatch(register(this.state))
    event.preventDefault()
  }

  render() {
    const { registering, errors } = this.props
    return (
      <Register
        parentState={this.state}
        toggleHasAnAccount={this.toggleHasAnAccount}
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        registering={registering}
        errors={errors}
      />
    )
  }
}

RegisterContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  registering: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  passwordResetToken: PropTypes.string,
  checkYourEmail: PropTypes.bool.isRequired,
  knowsPassword: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  const {
    loggedIn,
    registering,
    errors,
    passwordResetToken,
    checkYourEmail,
    knowsPassword,
  } = state.session
  return { loggedIn, registering, errors, passwordResetToken, checkYourEmail, knowsPassword }
}

export default connect(mapStateToProps)(RegisterContainer)
