import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { update, USER_NOTIF_CLEAROUT } from '../actions/user'

import NavContainer from './NavContainer'
import EditUser from '../components/EditUser'

const USER_NOTIF_CLEAROUT_DELAY = 5000

class EditUserContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { email: props.email, password: '' }
    this.redirectIfNecessary = this.redirectIfNecessary.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.redirectIfNecessary()
  }

  redirectIfNecessary() {
    let { history, loggedIn, userNotifs, dispatch } = this.props
    if (!loggedIn) history.push('/register')
    if (userNotifs.length > 0) {
      setTimeout(() => {
        dispatch({ type: USER_NOTIF_CLEAROUT })
      }, USER_NOTIF_CLEAROUT_DELAY)
      history.push('/')
    }
  }

  componentDidUpdate() {
    this.redirectIfNecessary()
  }

  handleUpdate(field) {
    return (e) => {
      let newState = {}
      newState[field] = e.target.value
      this.setState(newState)
    }
  }

  handleSubmit(event) {
    this.props.dispatch(update(this.state))
    event.preventDefault()
  }

  render() {
    const { history, userErrors, updatingUser } = this.props
    const { email, password } = this.state
    return (
      <div>
        <NavContainer history={history} />
        <EditUser
          email={email}
          password={password}
          errors={userErrors}
          updating={updatingUser}
          handleSubmit={this.handleSubmit}
          handleUpdate={this.handleUpdate}
        />
      </div>
    )
  }
}

EditUserContainer.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  updatingUser: PropTypes.bool.isRequired,
  userErrors: PropTypes.array.isRequired,
  userNotifs: PropTypes.array.isRequired,
  loggedIn: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  const { loggedIn, email } = state.session
  const { updatingUser, userErrors, userNotifs } = state.user
  return { loggedIn, email, updatingUser, userErrors, userNotifs }
}

export default connect(mapStateToProps)(EditUserContainer)
