/* eslint-disable camelcase */
import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import NewFrame from '../components/NewFrame'
import NavContainer from './NavContainer'
import { create } from '../actions/frames'
import { patch, show as showUser } from '../actions/user'

import { Analytics } from '../utilities/Analytics'
import { UrlParams } from '../utilities/UrlParams'

import { Parameterize } from '../utilities/Parameterize'

class NewFrameContainer extends Component {
  constructor(props) {
    super(props)
    const urlParams = UrlParams.get()
    this.state = {
      name: '',
      nameText: '',
      newHere: !!urlParams.new_here,
      apps: [],
      ownerInfo: {
        forWhom: '',
        name: '',
        birthday: '',
      },
    }
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleAppsChange = this.handleAppsChange.bind(this)
    this.handleOwnerInfoChange = this.handleOwnerInfoChange.bind(this)
    this.patchOwnerInfo = this.patchOwnerInfo.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    Analytics.track('Viewed New Frame')
    dispatch(showUser())
    window.scrollTo(0, 0)
  }

  handleNameChange(event) {
    const nameText = event.target.value
    const name = Parameterize.call(nameText)
    this.setState({ name, nameText })
  }

  handleAppsChange(apps) {
    this.setState({ apps })
  }

  handleOwnerInfoChange(newOwnerInfo) {
    this.setState((prevState) => ({
      ownerInfo: {
        ...prevState.ownerInfo,
        ...newOwnerInfo,
      },
    }))
  }

  async handleSubmit(event) {
    event.preventDefault()
    const { dispatch } = this.props
    const { name, nameText, newHere, apps, ownerInfo } = this.state
    const newFrameData = { name, nameText, newHere, apps }
    if (ownerInfo.forWhom === 'gift') newFrameData.profile = ownerInfo
    await dispatch(create(newFrameData))
    Analytics.track('Submitted New Frame Name', { ...newFrameData })

    const { newFrame, history } = this.props
    if (newFrame) {
      const isCalendar = newFrame.attributes.apps[0] === 'calendar'
      let nextStepLink = isCalendar
        ? `/frames/${newFrame.id}/activate`
        : `/frames/${newFrame.id}/friends`

      if (newHere) nextStepLink += '?new_here=true'
      nextStepLink += '?activating=true'
      history.push(nextStepLink)
    }
  }

  patchOwnerInfo() {
    const { dispatch } = this.props
    const { ownerInfo } = this.state
    dispatch(patch(ownerInfo))
  }

  render() {
    const { creatingFrame, apps, errors, history, birthday } = this.props
    return (
      <div>
        <NavContainer history={history} />
        <NewFrame
          parentState={this.state}
          errors={errors}
          apps={apps}
          handleAppsChange={this.handleAppsChange}
          handleSubmit={this.handleSubmit}
          handleNameChange={this.handleNameChange}
          handleOwnerInfoChange={this.handleOwnerInfoChange}
          patchOwnerInfo={this.patchOwnerInfo}
          creatingFrame={creatingFrame}
          userBirthday={birthday}
        />
      </div>
    )
  }
}

NewFrameContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  dispatch: PropTypes.func.isRequired,
  creatingFrame: PropTypes.bool.isRequired,
  newFrame: PropTypes.object,
  errors: PropTypes.array.isRequired,
  apps: PropTypes.arrayOf(PropTypes.string),
  birthday: PropTypes.string,
}

const mapStateToProps = (state) => {
  const { creatingFrame, newFrame, errors, frame } = state.frames
  const { birthday } = state.user
  return { creatingFrame, newFrame, errors, frame, birthday }
}

export default connect(mapStateToProps)(NewFrameContainer)
