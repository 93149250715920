/* eslint-disable camelcase */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { show, update, CALENDARS_REQUEST } from '../actions/calendars'

import CalendarPicker from '../components/CalendarPicker'
import SyncSuccess from '../components/SyncSuccess'

import { UrlParams } from '../utilities/UrlParams'

const ONE_SECOND = 1000
const NYLAS_WAIT = 2 * 60 * ONE_SECOND
const NYLAS_POLLING_DELAY_SECONDS = 5
const MIN_CALENDAR_COUNT = 2

class CalendarPickerContainer extends Component {
  constructor(props) {
    super(props)
    const urlParams = UrlParams.get()
    const saved = !!urlParams.finished
    this.state = { selectedCalendarIds: [], saved, progress: 0, initialized: false }
    this.toggleCalendar = this.toggleCalendar.bind(this)
    this.save = this.save.bind(this)
    this.updateProgress = this.updateProgress.bind(this)
    this.toggleSelectAll = this.toggleSelectAll.bind(this)
    this.progressPercent = this.progressPercent.bind(this)
    this.stillPolling = this.stillPolling.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    window.scrollTo(0, 0)
    dispatch({ type: CALENDARS_REQUEST })
    setTimeout(() => this.updateProgress(1), ONE_SECOND)
  }

  componentDidUpdate() {
    const { initialized } = this.state
    const { activeCalendars } = this.props
    if (!initialized && activeCalendars.length > 0) {
      this.setState({
        initialized: true,
        selectedCalendarIds: activeCalendars.map((c) => c.id),
      })
    }
  }

  toggleCalendar(id) {
    const { selectedCalendarIds } = this.state
    const { activeCalendars } = this.props
    const index = selectedCalendarIds.indexOf(id)
    if (index === -1) {
      selectedCalendarIds.push(id)
    } else {
      const existingBefore = activeCalendars.map((c) => c.id)
      if (
        existingBefore.indexOf(id) === -1 ||
        confirm('Are you sure you want to stop syncing this calendar and delete all its events?')
      ) {
        selectedCalendarIds.splice(index, 1)
      }
    }
    this.setState({ selectedCalendarIds })
  }

  toggleSelectAll() {
    const { availableCalendars } = this.props
    const { selectedCalendarIds } = this.state

    if (availableCalendars.length === selectedCalendarIds.length) {
      this.setState({ selectedCalendarIds: [] })
      return
    }

    const availableCalendarsIds = availableCalendars.map((cal) => cal.id)
    const selectedSet = new Set(selectedCalendarIds)
    const unselectedCalendars = availableCalendarsIds.filter((calId) => !selectedSet.has(calId))
    unselectedCalendars.forEach((calId) => this.toggleCalendar(calId))
  }

  save() {
    const { dispatch, frame, availableCalendars, accountId } = this.props
    const { selectedCalendarIds } = this.state
    if (selectedCalendarIds.length > 0) {
      const active_calendars = availableCalendars.filter(
        (cal) => selectedCalendarIds.indexOf(cal.id) !== -1
      )
      dispatch(update(frame.id, accountId, { active_calendars }))
      this.setState({ saved: true })
    } else {
      alert('Please select at least one of your calendars!')
    }
  }

  progressPercent() {
    const { progress } = this.state
    return parseInt(((progress * ONE_SECOND) / NYLAS_WAIT) * 100)
  }

  stillPolling() {
    const { availableCalendars } = this.props
    return availableCalendars.length < MIN_CALENDAR_COUNT && this.progressPercent() < 100
  }

  updateProgress(progress) {
    const { dispatch, frame, accountId } = this.props
    if (this.stillPolling()) {
      if (progress % NYLAS_POLLING_DELAY_SECONDS === 0) {
        dispatch(show(frame.id, accountId))
      }
      setTimeout(() => this.updateProgress(progress + 1), ONE_SECOND)
      this.setState({ progress })
    }
  }

  render() {
    const { loading, availableCalendars, frame } = this.props
    const { selectedCalendarIds, saved } = this.state
    if (loading || this.stillPolling()) {
      return (
        <div className="med-width-ctn">
          <p className="text-center">Downloading your Calendars...</p>
          <div className="progress-bar-background">
            <div className="progress-bar" style={{ width: `${this.progressPercent()}%` }} />
          </div>
        </div>
      )
    }
    if (saved) {
      return <SyncSuccess frame={frame} />
    }
    return (
      <CalendarPicker
        frame={frame}
        availableCalendars={availableCalendars}
        selectedCalendarIds={selectedCalendarIds}
        toggleCalendar={this.toggleCalendar}
        save={this.save}
        toggleSelectAll={this.toggleSelectAll}
      />
    )
  }
}

CalendarPickerContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  frame: PropTypes.object.isRequired,
  availableCalendars: PropTypes.array.isRequired,
  activeCalendars: PropTypes.array.isRequired,
  accountId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const { availableCalendars, activeCalendars, loading } = state.calendars
  return { availableCalendars, activeCalendars, loading }
}

export default connect(mapStateToProps)(CalendarPickerContainer)
