import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import styled from 'styled-components'

import { Link } from 'react-router-dom'

import MessagePage from './MessagePage'
import InProgressUploads from './InProgressUploads'
import IntersectionObs from './IntersectionObs'

import { Analytics } from '../utilities/Analytics'
import { DateConverter } from '../utilities/DateConverter'

export default class FrameMessages extends PureComponent {
  componentDidMount() {
    const { upsellMeta, frameOwner, frame } = this.props
    Analytics.track(
      'Viewed Frame Messages',
      {
        frame_owner: frameOwner,
        saw_plus_upsell: !!upsellMeta,
      },
      {
        frame: frame.attributes.name,
      }
    )
  }

  render() {
    const {
      history,
      frame,
      deleteAll,
      presence,
      nonePreloaded,
      appendPreloaded,
      numPages,
      upsellMeta,
      frameOwner,
      hasBulkFeatures,
      isAdmin,
      trialDaysRemaining,
      pages,
      currentPage,
      preloadMore,
      frameInProgressUploads,
      setSelectedImgYPos,
      currentlyViewingMessage,
      selectedMessageIds,
      toggleSelectedMessage,
    } = this.props
    const needsPlus = upsellMeta && !trialDaysRemaining
    const pluralize = (count, noun, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`
    return (
      <div>
        <FrameHeader>
          <FrameHeaderItem>
            <p className="mb-0">
              <button
                type="button"
                onClick={() => history.push('/')}
                className="btn btn-sm btn-info"
              >
                ← Back
              </button>
            </p>
          </FrameHeaderItem>
          <FrameHeaderItem>
            <h2 className="text-center">{frame.attributes.name}</h2>
            {(hasBulkFeatures || isAdmin) && (
              <p className="text-center mb-0">
                {presence ? (
                  <span>
                    <span className="text-success">{presence}</span>{' '}
                    {isAdmin && (
                      <span className="muted">w/ token {frame.attributes.pushy_token}</span>
                    )}
                  </span>
                ) : (
                  <span className="muted">Has not yet been turned on</span>
                )}
              </p>
            )}
            {isAdmin && (
              <>
                <p className="text-center">
                  {frame.attributes.activated_at
                    ? `Activated on ${DateConverter.MMDDYYY(frame.attributes.activated_at).replace(
                        /\./g,
                        '-'
                      )}`
                    : 'Not activated'}
                </p>
                <p className="text-center">{frame.attributes.hardware_model}</p>
              </>
            )}
            {!!frame.attributes.photos_count && (
              <p className="text-center">
                {pluralize(frame.attributes.photos_count, 'photo')},{' '}
                {pluralize(frame.attributes.videos_count, 'video')}
              </p>
            )}
          </FrameHeaderItem>
          <FrameHeaderItem className="text-right">
            {!needsPlus && (
              <div className="btn-group" role="group">
                {hasBulkFeatures && (
                  <button type="button" className="btn btn-sm btn-danger" onClick={deleteAll}>
                    <span className="oi oi-trash" title="Delete All" aria-hidden="true" /> Delete
                    All
                  </button>
                )}
                <Link
                  to={`/messages/new?from_frame_id=${frame.id}`}
                  className="btn btn-sm btn-success px-2"
                >
                  <span className="oi oi-cloud-upload mr-2" title="Upload" aria-hidden="true" />
                  <span>Upload</span>
                </Link>
              </div>
            )}
          </FrameHeaderItem>
        </FrameHeader>
        <hr />
        {pages[0].messages.length === 0 ? (
          <div className="row justify-content-around">
            {needsPlus ? (
              <div className="col-8 mt-4">
                {frameOwner ? (
                  <div>
                    <h2 className="text-center text-muted">
                      To access your Skylight photos online, upgrade to <b>Skylight Plus</b>
                    </h2>
                    <p className="text-center">
                      You are currently on our basic plan, which does not include access to our web
                      app. With <b>Skylight Plus</b> you can use our mobile and web apps to see all
                      your Skylight photos, send more with a single tap, share them with friends,
                      and download or delete them easily.
                    </p>
                    <p className="text-center">
                      <a
                        className="btn btn-success btn-lg"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={upsellMeta.upgrade_url}
                      >
                        Learn More
                      </a>
                    </p>
                  </div>
                ) : (
                  <div>
                    <h2 className="text-center text-muted">
                      To see photos you’ve sent to this Skylight, upgrade to <b>Skylight Plus</b>
                    </h2>
                    <p className="text-center">
                      The owner of this Skylight is currently on our Basic plan, which does not
                      include access to our web and mobile apps. If this frame upgrades to{' '}
                      <b>Skylight Plus</b>, you’ll be able to use our mobile and web apps to see
                      photos you’ve sent to this Skylight, send more with a tap, share them with
                      friends, and download or delete them easily.
                    </p>
                    <p className="text-center">
                      <a
                        className="btn btn-success btn-lg"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={upsellMeta.upgrade_url}
                      >
                        Learn More
                      </a>
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <div className="col-12 mt-4">
                <h4 className="text-center text-muted">
                  You haven’t sent any photos to this Skylight yet.
                </h4>
                <p className="text-center">
                  Email one now to <b>{frame.attributes.name}@ourskylight.com</b>, <br />
                  and it'll show up here and on your Skylight!
                </p>
              </div>
            )}
          </div>
        ) : (
          <MessageList>
            {frameInProgressUploads && Object.keys(frameInProgressUploads).length > 0 && (
              <InProgressUploads messages={frameInProgressUploads} />
            )}
            {pages.map((page, idx) => {
              const { messages } = page
              return (
                <React.Fragment key={`page-${idx + 1}`}>
                  <MessagePage
                    frameOwner={frameOwner}
                    history={history}
                    currentPage={currentPage}
                    messages={messages}
                    frame={frame}
                    preloadMore={preloadMore}
                    numPages={numPages}
                    setSelectedImgYPos={setSelectedImgYPos}
                    currentlyViewingMessage={currentlyViewingMessage}
                    selectedMessageIds={selectedMessageIds}
                    toggleSelectedMessage={toggleSelectedMessage}
                  />
                  {idx === pages.length - 1 && !nonePreloaded && (
                    <IntersectionObs
                      appendPreloaded={appendPreloaded}
                      numPages={numPages}
                      currentPage={currentPage}
                    />
                  )}
                </React.Fragment>
              )
            })}
          </MessageList>
        )}
      </div>
    )
  }
}

FrameMessages.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  frame: PropTypes.object,
  upsellMeta: PropTypes.object,
  trialDaysRemaining: PropTypes.number,
  frameOwner: PropTypes.bool.isRequired,
  hasBulkFeatures: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  deleteAll: PropTypes.func.isRequired,
  currentPage: PropTypes.number,
  numPages: PropTypes.number,
  preloadMore: PropTypes.func,
  appendPreloaded: PropTypes.func,
  nonePreloaded: PropTypes.bool,
  pages: PropTypes.array,
  frameInProgressUploads: PropTypes.object,
  currentlyViewingMessage: PropTypes.object,
  setSelectedImgYPos: PropTypes.func,
  selectedMessageIds: PropTypes.array,
  toggleSelectedMessage: PropTypes.func,
  presence: PropTypes.string,
}

const FrameHeader = styled.div`
  padding: 0 15px 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FrameHeaderItem = styled.div`
  flex: 1 1 0px;
`

const MessageList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 1rem;
`
