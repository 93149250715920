import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Errors from '../Errors'

export default class NewDeployment extends Component {
  constructor(props) {
    super(props)
    this.uploadAndroidVersion = this.uploadAndroidVersion.bind(this)
  }

  uploadAndroidVersion(event) {
    event.preventDefault()
    const data = new FormData()
    if (this.uploadInput.files[0]) data.append('apk', this.uploadInput.files[0])
    const { handleAndroidVersionUpload } = this.props
    handleAndroidVersionUpload(data)
  }

  render() {
    const {
      errors,
      uploading,
      versionKind,
      versionCode,
      versionName,
      handleVersionKindChange,
      handleVersionCodeChange,
      handleVersionNameChange,
      nevermind,
    } = this.props
    return (
      <div className="col-lg-12 col-xl-8">
        <div className="register-box">
          <h3>New Version</h3>
          <Errors errors={errors} />
          <form onSubmit={this.uploadAndroidVersion}>
            <select
              className="custom-select mr-2"
              value={versionKind}
              onChange={e => handleVersionKindChange(e.target.value)}
            >
              <option value="application">Application</option>
              <option value="watchdog">Watchdog</option>
            </select>
            <br />
            <br />
            <div className="form-group">
              <label htmlFor="file">
                Please upload a <b>new</b> Android Version
              </label>
              <input
                className="form-control"
                type="file"
                ref={ref => {
                  this.uploadInput = ref
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="file">
                Enter the <b>Version Code</b>
              </label>
              <input
                className="form-control"
                type="text"
                value={versionCode}
                onChange={e => handleVersionCodeChange(e.target.value)}
                placeholder="55"
              />
            </div>
            <div className="form-group">
              <label htmlFor="file">
                Enter the <b>Version Name</b>
              </label>
              <input
                className="form-control"
                type="text"
                value={versionName}
                onChange={e => handleVersionNameChange(e.target.value)}
                placeholder="6.0.10"
              />
            </div>
            <div className="row">
              <div className="col-6">
                <a href="#" className="btn btn-lg btn-info" onClick={nevermind}>
                  ← Nevermind
                </a>
              </div>
              <div className="col-6 text-right">
                <button type="submit" className="btn btn-lg btn-success" disabled={uploading}>
                  {uploading ? 'Uploading...' : 'Upload'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

NewDeployment.propTypes = {
  errors: PropTypes.array.isRequired,
  uploading: PropTypes.bool.isRequired,
  versionCode: PropTypes.string,
  versionName: PropTypes.string,
  versionKind: PropTypes.string.isRequired,
  handleVersionKindChange: PropTypes.func.isRequired,
  handleVersionCodeChange: PropTypes.func.isRequired,
  handleVersionNameChange: PropTypes.func.isRequired,
  handleAndroidVersionUpload: PropTypes.func.isRequired,
  nevermind: PropTypes.func.isRequired,
}
