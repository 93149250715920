/* eslint-disable camelcase */
import {
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  USER_SHOW_REQUEST,
  USER_SHOW_SUCCESS,
  USER_SHOW_FAILURE,
  USER_NOTIF_CLEAROUT,
  USER_DELETE_REQUEST,
  USER_DELETE_FAILURE,
  USER_DELETE_SUCCESS,
} from '../actions/user'

const DEFAULT_STATE = {
  updatingUser: false,
  userErrors: [],
  userNotifs: [],
  subscriptionStatus: '',
  email: '',
  trialDaysRemaining: null,
  birthday: '',
}

const UPDATE_MESSAGE = 'You successfully updated your email address!'

export function user(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { ...state, updatingUser: true, userErrors: [], userNotifs: [] }
    case USER_UPDATE_SUCCESS:
      return { ...state, updatingUser: false, userErrors: [], userNotifs: [UPDATE_MESSAGE] }
    case USER_UPDATE_FAILURE:
      return { ...state, updatingUser: false, userErrors: action.errors }
    case USER_NOTIF_CLEAROUT:
      return { ...state, userNotifs: [] }
    case USER_SHOW_REQUEST:
      return { ...state }
    case USER_SHOW_SUCCESS: {
      const {
        subscription_status,
        email,
        trialDaysRemaining,
        birthday,
      } = action.response.data.attributes
      return {
        ...state,
        subscriptionStatus: subscription_status,
        email,
        trialDaysRemaining,
        birthday,
      }
    }
    case USER_SHOW_FAILURE:
      return { ...state, userErrors: [] }

    case USER_DELETE_REQUEST:
      return {
        ...state,
        deletingUser: true,
        userErrors: [],
        userNotifs: [],
      }
    case USER_DELETE_FAILURE: {
      return {
        ...state,
        deletingUser: false,
        userErrors: action.errors,
      }
    }
    case USER_DELETE_SUCCESS: {
      return {
        ...state,
        deletingUser: false,
        userErrors: [],
      }
    }
    default:
      return state
  }
}
