import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import NavContainer from '../../containers/NavContainer'
import Loading from '../Loading'

import { show, spamApproval } from '../../actions/admin/messages'
import { approveForBulkSending } from '../../actions/admin/users'

const SpamApproval = () => {
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const { loading, message, spamApproved } = useSelector((state) => state.adminMessages)

  useEffect(() => {
    dispatch(show(params.id))
  }, [dispatch, params.id])

  const [approvedSender, setApprovedSender] = useState(false)

  const approveSenderForever = () => {
    dispatch(approveForBulkSending(message.attributes.sender_id))
    setApprovedSender(true)
  }

  return (
    <>
      <NavContainer history={history} />
      {loading ? (
        <Loading />
      ) : (
        <div className="row justify-content-md-center">
          <div className="col-lg-8 col-xl-6">
            <div className="row align-items-center">
              <div className="col-12">
                <h1>{spamApproved ? 'Approved!' : 'Potential Spam'}</h1>
                <p>
                  Just sent by <b>{message.attributes.sender_email}</b>. Previously sent by:
                </p>
                {message.attributes.frame_names.map((frameSenderPair) => (
                  <p key={frameSenderPair[1]}>
                    <code>{frameSenderPair[0]}</code> to <code>{frameSenderPair[1]}</code>
                  </p>
                ))}
                {message.attributes.asset_type === 'photo' ? (
                  <img
                    width="100%"
                    src={message.attributes.asset_url}
                    alt=""
                    className="message-img"
                  />
                ) : (
                  <video width="100%" controls>
                    <source src={message.attributes.asset_url} type="video/mp4" />
                  </video>
                )}
                <br />
                <br />
                <div className="row">
                  <div className="col-6">
                    {approvedSender ? (
                      <p>
                        You've pre-approved this sender for all <b>future</b> messages.
                        {!spamApproved && (
                          <span>
                            You still need to Approve this Specific Photo if you want it to go
                            through.
                          </span>
                        )}
                      </p>
                    ) : (
                      <button
                        onClick={approveSenderForever}
                        type="button"
                        className="btn btn-danger"
                      >
                        Approve this Sender Forever
                      </button>
                    )}
                  </div>
                  {!spamApproved && (
                    <div className="col-6" style={{ textAlign: 'right' }}>
                      <button
                        onClick={() => dispatch(spamApproval(message.id))}
                        type="button"
                        className="btn btn-success"
                      >
                        Approve this Specific Photo
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

SpamApproval.propTypes = {}

export default SpamApproval
