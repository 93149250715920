export const USERS_REQUEST = 'USERS_REQUEST'
export const USERS_SUCCESS = 'USERS_SUCCESS'
export const USERS_FAILURE = 'USERS_FAILURE'

export function list(q) {
  let endpoint = 'users'
  if (q) endpoint = `${endpoint}?q=${q}`
  return {
    type: USERS_REQUEST,
    api: {
      endpoint,
      isAdmin: true,
      authenticated: true,
      types: [USERS_REQUEST, USERS_SUCCESS, USERS_FAILURE],
      method: 'GET',
    },
  }
}

export const USERS_CREATE_REQUEST = 'USERS_CREATE_REQUEST'
export const USERS_CREATE_SUCCESS = 'USERS_CREATE_SUCCESS'
export const USERS_CREATE_FAILURE = 'USERS_CREATE_FAILURE'

export function create(data) {
  return {
    type: USERS_CREATE_REQUEST,
    api: {
      data,
      endpoint: 'users',
      isAdmin: true,
      authenticated: true,
      types: [USERS_CREATE_REQUEST, USERS_CREATE_SUCCESS, USERS_CREATE_FAILURE],
      method: 'POST',
    },
  }
}

export const USERS_SHOW_REQUEST = 'USERS_SHOW_REQUEST'
export const USERS_SHOW_SUCCESS = 'USERS_SHOW_SUCCESS'
export const USERS_SHOW_FAILURE = 'USERS_SHOW_FAILURE'

export function show(id) {
  const endpoint = `users/${id}`
  return {
    type: USERS_SHOW_REQUEST,
    api: {
      endpoint,
      isAdmin: true,
      authenticated: true,
      types: [USERS_SHOW_REQUEST, USERS_SHOW_SUCCESS, USERS_SHOW_FAILURE],
      method: 'GET',
    },
  }
}

export const USERS_UPGRADE_REQUEST = 'USERS_UPGRADE_REQUEST'
export const USERS_UPGRADE_SUCCESS = 'USERS_UPGRADE_SUCCESS'
export const USERS_UPGRADE_FAILURE = 'USERS_UPGRADE_FAILURE'

export function upgrade(id, email) {
  const endpoint = `users/${id}/upgrade`
  return {
    type: USERS_SHOW_REQUEST,
    api: {
      endpoint,
      data: { email },
      isAdmin: true,
      authenticated: true,
      types: [USERS_UPGRADE_REQUEST, USERS_UPGRADE_SUCCESS, USERS_UPGRADE_FAILURE],
      method: 'POST',
    },
  }
}

export const USERS_DOWNGRADE_REQUEST = 'USERS_DOWNGRADE_REQUEST'
export const USERS_DOWNGRADE_SUCCESS = 'USERS_DOWNGRADE_SUCCESS'
export const USERS_DOWNGRADE_FAILURE = 'USERS_DOWNGRADE_FAILURE'

export function downgrade(id) {
  const endpoint = `users/${id}/downgrade`
  return {
    type: USERS_SHOW_REQUEST,
    api: {
      endpoint,
      isAdmin: true,
      authenticated: true,
      types: [USERS_DOWNGRADE_REQUEST, USERS_DOWNGRADE_SUCCESS, USERS_DOWNGRADE_FAILURE],
      method: 'POST',
    },
  }
}

export const USERS_EXTEND_TRIAL_REQUEST = 'USERS_EXTEND_TRIAL_REQUEST'
export const USERS_EXTEND_TRIAL_SUCCESS = 'USERS_EXTEND_TRIAL_SUCCESS'
export const USERS_EXTEND_TRIAL_FAILURE = 'USERS_EXTEND_TRIAL_FAILURE'

export function extendTrial(id) {
  const endpoint = `users/${id}/extend_trial`
  return {
    type: USERS_EXTEND_TRIAL_REQUEST,
    api: {
      endpoint,
      isAdmin: true,
      authenticated: true,
      types: [USERS_EXTEND_TRIAL_REQUEST, USERS_EXTEND_TRIAL_SUCCESS, USERS_EXTEND_TRIAL_FAILURE],
      method: 'POST',
    },
  }
}

export const USERS_CANCEL_TRIAL_REQUEST = 'USERS_CANCEL_TRIAL_REQUEST'
export const USERS_CANCEL_TRIAL_SUCCESS = 'USERS_CANCEL_TRIAL_SUCCESS'
export const USERS_CANCEL_TRIAL_FAILURE = 'USERS_CANCEL_TRIAL_FAILURE'

export function cancelTrial(id) {
  const endpoint = `users/${id}/cancel_trial`
  return {
    type: USERS_CANCEL_TRIAL_REQUEST,
    api: {
      endpoint,
      isAdmin: true,
      authenticated: true,
      types: [USERS_CANCEL_TRIAL_REQUEST, USERS_CANCEL_TRIAL_SUCCESS, USERS_CANCEL_TRIAL_FAILURE],
      method: 'POST',
    },
  }
}

export const USERS_NEW_TRIAL_REQUEST = 'USERS_NEW_TRIAL_REQUEST'
export const USERS_NEW_TRIAL_SUCCESS = 'USERS_NEW_TRIAL_SUCCESS'
export const USERS_NEW_TRIAL_FAILURE = 'USERS_NEW_TRIAL_FAILURE'

export function newTrial(id) {
  const endpoint = `users/${id}/new_trial`
  return {
    type: USERS_NEW_TRIAL_REQUEST,
    api: {
      endpoint,
      isAdmin: true,
      authenticated: true,
      types: [USERS_NEW_TRIAL_REQUEST, USERS_NEW_TRIAL_SUCCESS, USERS_NEW_TRIAL_FAILURE],
      method: 'POST',
    },
  }
}

export const USERS_APPROVE_BULK_SENDING_REQUEST = 'USERS_APPROVE_BULK_SENDING_REQUEST'
export const USERS_APPROVE_BULK_SENDING_SUCCESS = 'USERS_APPROVE_BULK_SENDING_SUCCESS'
export const USERS_APPROVE_BULK_SENDING_FAILURE = 'USERS_APPROVE_BULK_SENDING_FAILURE'

export function approveForBulkSending(id) {
  const endpoint = `users/${id}/approve_for_bulk_sending`
  return {
    type: USERS_APPROVE_BULK_SENDING_REQUEST,
    api: {
      endpoint,
      isAdmin: true,
      authenticated: true,
      types: [
        USERS_APPROVE_BULK_SENDING_REQUEST,
        USERS_APPROVE_BULK_SENDING_SUCCESS,
        USERS_APPROVE_BULK_SENDING_FAILURE,
      ],
      method: 'POST',
    },
  }
}
