import React, { useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Analytics } from '../utilities/Analytics'

let YoutubeIframeAPI

function loadYouTubeIframePlayerAPI() {
  // Youtube's recommended way of loading the Iframe API asynchronously
  const tag = document.createElement('script')
  tag.src = 'https://www.youtube.com/iframe_api'
  const firstScriptTag = document.getElementsByTagName('script')[0]
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
}

export default function YouTubeIframePlayer({ youtubeVideoId, analyticsTag }) {
  const player = useRef()
  const hasAlreadyPlayedBefore = useRef(false)

  const handleVideoStateChange = useCallback(
    (e) => {
      if (e.data === 1 && !hasAlreadyPlayedBefore.current) {
        // Code 1 is video playing
        Analytics.track(`Clicked ${analyticsTag}`)
        hasAlreadyPlayedBefore.current = true
      } else if (e.data === 0) {
        // Code 0 is video ended
        Analytics.track(`Watched ${analyticsTag} until the end`)
      }
    },
    [analyticsTag, hasAlreadyPlayedBefore]
  )
  useEffect(() => {
    if (!YoutubeIframeAPI) {
      YoutubeIframeAPI = new Promise((resolve) => {
        loadYouTubeIframePlayerAPI()
        // After API is loaded, resolve the promise with the loaded YT object that gets attached to the window
        // The API will call onYouTubeIframeReady once it has loaded, and it must be in global scope
        window.onYouTubeIframeAPIReady = () => resolve(window.YT)
      })
    }
    if (!player.current) {
      YoutubeIframeAPI.then((YT) => {
        /* Creates the youtube player/video with
            First param: container for the iframe video
            Second param: config object for which video to load and which event listeners to attach to it
        */
        player.current = new YT.Player('youtube-tutorial', {
          videoId: youtubeVideoId,
          events: {
            onStateChange: handleVideoStateChange,
          },
        })
      })
    }
  }, [handleVideoStateChange, youtubeVideoId])
  return (
    <div className="youtube-vid-wrapper mt-4">
      <div id="youtube-tutorial" />
    </div>
  )
}

YouTubeIframePlayer.propTypes = {
  youtubeVideoId: PropTypes.string.isRequired,
  analyticsTag: PropTypes.string,
}
