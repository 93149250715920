import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { DateConverter } from '../utilities/DateConverter'

import redTrashIcon from '../../assets/images/red-trash.png'
import downloadIcon from '../../assets/images/download.png'
import captionEditIcon from '../../assets/images/checkedBox.png'

const MIN_IMG_WIDTH = 500

export default function ModalMessage({
  message,
  frameId,
  userId,
  destroy,
  updateCaption,
  isAdmin,
  frameOwner,
}) {
  const [isSmallLandscape, setIsSmallLandscape] = useState(false)
  function resizeIfSmallLandscape(e) {
    setIsSmallLandscape(false)
    if (
      e.target.naturalWidth < MIN_IMG_WIDTH &&
      e.target.naturalWidth > e.target.naturalHeight + 10 // Dont resize square-like images
    ) {
      setIsSmallLandscape(true)
    }
  }
  const canDeleteMessage = frameOwner || message.attributes.sender_id === parseInt(userId)
  const downloadUrl = `/api/frames/${frameId}/messages/${message.id}/download?user_id=${userId}&message_download_token=${message.attributes.download_token}`
  const createdAt = new Date(message.attributes.created_at)

  return (
    <InnerModalCtn>
      <HeaderCtn>
        <Link to={`/frames/${frameId}/messages`} className="no-style-btn link">
          &lt; back
        </Link>
        <IconBtnCtn>
          {isAdmin && (
            <button type="button" className="no-style-btn png-icon" onClick={updateCaption}>
              <img src={captionEditIcon} alt="Download" width="24" height="24" />
            </button>
          )}
          <a className="no-style-btn mr-1 png-icon" href={downloadUrl}>
            <img src={downloadIcon} alt="Download" width="24" height="24" />
          </a>
          {canDeleteMessage && (
            <button type="button" className="no-style-btn png-icon" onClick={destroy}>
              <img src={redTrashIcon} alt="Delete" width="24" height="24" />
            </button>
          )}
        </IconBtnCtn>
      </HeaderCtn>
      {message.attributes.asset_type === 'photo' ? (
        <img
          src={message.attributes.asset_url}
          alt=""
          onLoad={(e) => resizeIfSmallLandscape(e)}
          width={isSmallLandscape ? '100%' : ''}
          className="modal-message"
        />
      ) : (
        <video
          controls
          className="modal-message"
          onLoad={(e) => resizeIfSmallLandscape(e)}
          poster={message.attributes.thumbnail_url}
        >
          <source src={message.attributes.asset_url} type="video/mp4" />
        </video>
      )}
      <CaptionCtn>
        <p>
          <span className="caption-title">Sent by </span>
          <b>{message.attributes.from_email}</b>
        </p>
        <p>
          <span className="caption-title">Date </span>
          <b>{DateConverter.MMDDYYY(createdAt)}</b>
        </p>
        {message.attributes.caption && (
          <p>
            <span className="caption-title">Caption </span>
            <b>{message.attributes.caption}</b>
          </p>
        )}
      </CaptionCtn>
    </InnerModalCtn>
  )
}

ModalMessage.propTypes = {
  message: PropTypes.object.isRequired,
  frameId: PropTypes.string.isRequired,
  destroy: PropTypes.func.isRequired,
  updateCaption: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  userId: PropTypes.string,
  frameOwner: PropTypes.bool,
}

const InnerModalCtn = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-rows: 6% 88% 6%;
  grid-template-columns: auto;
`

const HeaderCtn = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const IconBtnCtn = styled.div`
  display: flex;
  align-items: center;
`

const CaptionCtn = styled.section`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1%;
  p {
    margin-block-start: 0;
    margin-block-end: 0;
    align-self: center;
    margin-right: 2rem;
  }
  @media only screen and (max-width: 550px) {
    font-size: 0.9rem;
  }
`
