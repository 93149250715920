export const CAPTION_DESTROY_REQUEST = 'CAPTION_DESTROY_REQUEST'
export const CAPTION_DESTROY_SUCCESS = 'CAPTION_DESTROY_SUCCESS'
export const CAPTION_DESTROY_FAILURE = 'CAPTION_DESTROY_FAILURE'

export function destroy(frameId, id) {
  return {
    type: CAPTION_DESTROY_REQUEST,
    api: {
      endpoint: ['frames', frameId, 'messages', id, 'caption'].join('/'),
      authenticated: true,
      types: [CAPTION_DESTROY_REQUEST, CAPTION_DESTROY_SUCCESS, CAPTION_DESTROY_FAILURE],
      method: 'DELETE',
    },
  }
}

export const CAPTION_UPDATE_REQUEST = 'CAPTION_UPDATE_REQUEST'
export const CAPTION_UPDATE_SUCCESS = 'CAPTION_UPDATE_SUCCESS'
export const CAPTION_UPDATE_FAILURE = 'CAPTION_UPDATE_FAILURE'

export function update(frameId, id, caption) {
  return {
    type: CAPTION_UPDATE_REQUEST,
    api: {
      data: { caption },
      endpoint: ['frames', frameId, 'messages', id, 'caption'].join('/'),
      authenticated: true,
      types: [CAPTION_UPDATE_REQUEST, CAPTION_UPDATE_SUCCESS, CAPTION_UPDATE_FAILURE],
      method: 'PUT',
    },
  }
}
