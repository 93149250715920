import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Notifications extends Component {
  render() {
    const { notifications } = this.props
    return (
      <div className="row justify-content-md-center">
        <div className="alert alert-warning" role="alert">
          {notifications.join('. ')}
        </div>
      </div>
    )
  }
}

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired,
}
