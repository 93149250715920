import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Frames from '../../components/admin/Frames'
import MigrationModal from '../../components/admin/MigrationModal'
import TransferModal from '../../components/admin/TransferModal'
import Errors from '../../components/Errors'

import { refresh, reset, messageCountsShow, getActivationCode } from '../../actions/frames'
import {
  copyNewFrame,
  migrateSameFrameName,
  restoreFromSourceFrameAncestors,
  transferFrameNewUser,
} from '../../actions/admin/frameMigration'
import CodeModal from '../../components/admin/CodeModal'

class AdminFramesContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      frameToTransfer: null,
      frameToMigrate: null,
      frameToGetCode: null,
      countsBeingLoaded: {},
    }
    this.resetFrame = this.resetFrame.bind(this)
    this.refreshFrame = this.refreshFrame.bind(this)
    this.transferModal = this.transferModal.bind(this)
    this.dismissTransferModal = this.dismissTransferModal.bind(this)
    this.codeModal = this.codeModal.bind(this)
    this.dismissCodeModal = this.dismissCodeModal.bind(this)
    this.migrateModal = this.migrateModal.bind(this)
    this.dismissMigrationModal = this.dismissMigrationModal.bind(this)
    this.copyToNewFrame = this.copyToNewFrame.bind(this)
    this.migrateToSameFrameName = this.migrateToSameFrameName.bind(this)
    this.restoreFromSourceFrameAncestors = this.restoreFromSourceFrameAncestors.bind(this)
    this.transferToNewUser = this.transferToNewUser.bind(this)
    this.getMessageCounts = this.getMessageCounts.bind(this)
  }

  getMessageCounts(e, id) {
    const { dispatch } = this.props
    const { countsBeingLoaded } = this.state
    countsBeingLoaded[id] = true
    this.setState({ countsBeingLoaded })
    dispatch(messageCountsShow(id))
    e.preventDefault()
  }

  transferModal(frame) {
    this.setState({ frameToTransfer: frame })
  }

  dismissTransferModal() {
    this.setState({ frameToTransfer: null })
  }

  codeModal(frame) {
    const { dispatch } = this.props
    dispatch(getActivationCode(frame.id))
    this.setState({ frameToGetCode: frame })
  }

  dismissCodeModal() {
    this.setState({ frameToGetCode: null })
  }

  migrateModal(frame) {
    this.setState({ frameToMigrate: frame })
  }

  dismissMigrationModal() {
    this.setState({ frameToMigrate: null })
  }

  copyToNewFrame(id, newFrameName) {
    const { dispatch } = this.props
    dispatch(copyNewFrame(id, newFrameName))
  }

  migrateToSameFrameName(id) {
    const { dispatch } = this.props
    dispatch(migrateSameFrameName(id))
  }

  restoreFromSourceFrameAncestors(id, sourceFrameName) {
    const { dispatch } = this.props
    dispatch(restoreFromSourceFrameAncestors(id, sourceFrameName))
  }

  transferToNewUser(id, email) {
    const { dispatch } = this.props
    dispatch(transferFrameNewUser(id, email))
  }

  resetFrame(frame) {
    const { dispatch } = this.props
    if (confirm(`Are you sure you want to reset ${frame.attributes.name}?`)) {
      dispatch(reset(frame.id))
    }
  }

  refreshFrame(frame) {
    const { dispatch } = this.props
    if (confirm(`Are you sure you want to refresh ${frame.attributes.name}?`)) {
      dispatch(refresh(frame.id))
    }
  }

  render() {
    // Migration props
    const { frameToTransfer, frameToGetCode, frameToMigrate, countsBeingLoaded } = this.state
    const { errors, searchQuery } = this.props
    // Frames props
    const {
      gettingFrames,
      frames,
      users,
      toggleFrame,
      selectedFrameIds,
      canReset,
      messageCounts,
      showSearchEmptyState,
      activationCode,
    } = this.props
    return (
      <span>
        <Errors errors={errors} />
        {frameToTransfer && (
          <TransferModal
            frame={frameToTransfer}
            dismiss={this.dismissTransferModal}
            admin
            transferToNewUser={this.transferToNewUser}
          />
        )}
        {frameToGetCode && (
          <CodeModal
            frame={frameToGetCode}
            activationCode={activationCode}
            dismiss={this.dismissCodeModal}
          />
        )}
        {frameToMigrate && (
          <MigrationModal
            frame={frameToMigrate}
            dismiss={this.dismissMigrationModal}
            copyToNewFrame={this.copyToNewFrame}
            restoreFromSourceFrameAncestors={this.restoreFromSourceFrameAncestors}
            migrateToSameFrameName={this.migrateToSameFrameName}
          />
        )}
        <Frames
          loading={gettingFrames}
          frames={frames}
          users={users}
          selectedFrameIds={selectedFrameIds}
          canReset={canReset}
          toggleFrame={toggleFrame}
          resetFrame={this.resetFrame}
          refreshFrame={this.refreshFrame}
          transferModal={this.transferModal}
          codeModal={this.codeModal}
          migrateModal={this.migrateModal}
          messageCounts={messageCounts}
          countsBeingLoaded={countsBeingLoaded}
          getMessageCounts={this.getMessageCounts}
          searchQuery={searchQuery}
          showSearchEmptyState={showSearchEmptyState}
          activationCode={activationCode}
        />
      </span>
    )
  }
}

AdminFramesContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  gettingFrames: PropTypes.bool.isRequired,
  frames: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  selectedFrameIds: PropTypes.array.isRequired,
  canReset: PropTypes.bool.isRequired,
  toggleFrame: PropTypes.func,
  errors: PropTypes.array,
  messageCounts: PropTypes.object,
  searchQuery: PropTypes.string,
  showSearchEmptyState: PropTypes.bool,
  activationCode: PropTypes.string,
}

const mapStateToProps = (state) => {
  const {
    gettingFrames,
    frames,
    users,
    selectedFrameIds,
    messageCounts,
    canReset,
    activationCode,
  } = state.frames
  const { errors } = state.frameMigration
  return {
    frames,
    gettingFrames,
    users,
    selectedFrameIds,
    messageCounts,
    canReset,
    errors,
    activationCode,
  }
}

export default connect(mapStateToProps)(AdminFramesContainer)
