import {
  PURCHASE_METADATA_REQUEST,
  PURCHASE_METADATA_SUCCESS,
  PURCHASE_METADATA_FAILURE,
} from '../actions/purchaseMetadata'

const DEFAULT_STATE = {
  fromAmazon: null,
}

export function purchaseMetadata(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case PURCHASE_METADATA_REQUEST: {
      return { ...state, fromAmazon: null }
    }
    case PURCHASE_METADATA_SUCCESS: {
      return { ...state, fromAmazon: action.response.data.from_amazon }
    }
    case PURCHASE_METADATA_FAILURE: {
      return { ...state, fromAmazon: false }
    }
    default: {
      return { ...state }
    }
  }
}
