import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DateConverter } from '../utilities/DateConverter'

export default class Message extends Component {
  render() {
    const { history, message, frameId, userId, destroy, frameOwner } = this.props
    const downloadUrl = `/api/frames/${frameId}/messages/${message.id}/download?user_id=${userId}&message_download_token=${message.attributes.download_token}`
    const createdAt = new Date(message.attributes.created_at)
    const canDeleteMessage = frameOwner || message.attributes.sender_id === parseInt(userId)

    return (
      <div>
        <div className="row align-items-center">
          <div className="col-6">
            <p className="mb-0">
              <button type="button" onClick={history.goBack} className="btn btn-sm btn-info">
                ← Back
              </button>
            </p>
          </div>
          <div className="col-6 text-right">
            <div className="btn-group" role="group">
              {canDeleteMessage && (
                <button type="button" className="btn btn-sm btn-danger" onClick={destroy}>
                  <span className="oi oi-trash" title="Delete" aria-hidden="true" /> Delete
                </button>
              )}
              <a className="btn btn-sm btn-success" href={downloadUrl}>
                <span className="oi oi-cloud-download" title="Download" aria-hidden="true" />{' '}
                Download Photo
              </a>
            </div>
          </div>
        </div>
        <hr />
        <div className="row justify-content-around align-items-center">
          <div className="col-12">
            <div className="big-frame-message-box mb-3">
              <p className="py-1 text-center">
                Sent by <b>{message.attributes.from_email}</b> at{' '}
                <b>{DateConverter.call(createdAt)}</b>
              </p>
              {message.attributes.asset_type === 'photo' ? (
                <img src={message.attributes.asset_url} alt="" className="message-img" />
              ) : (
                <video controls>
                  <source src={message.attributes.asset_url} type="video/mp4" />
                </video>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Message.propTypes = {
  history: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  frameId: PropTypes.string.isRequired,
  destroy: PropTypes.func.isRequired,
  userId: PropTypes.string,
  frameOwner: PropTypes.bool,
}
