import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import FreeTrialVideo from './FreeTrialVideo'
import { Analytics } from '../utilities/Analytics'
import { PLUS_UPSELL_FEATURES } from '../constants/constants'

import blackCheck from '../../assets/images/black-check.png'
import skyTVUpsell from '../../assets/images/sky-tv-upsell.png'

export default function PlusUpsell({ frame, toggleAlreadyPurchased, noThanks }) {
  const isTV = frame.attributes.apps[0] === 'tv'
  const shopifyLink = isTV
    ? 'https://www.skylightframe.com/tv'
    : 'https://www.skylightframe.com/products/skylight-plus'
  return (
    <UpsellPageCtn className="register-box large-width-ctn">
      <Title>{isTV ? 'Purchase Skylight TV' : 'Upgrade to Skylight Plus'}</Title>
      {isTV ? (
        <UpsellPriceText>Turn your TV into a big-screen photo frame for $19/year</UpsellPriceText>
      ) : (
        <UpsellPriceText>
          Access these features on your Frame with Skylight Plus for $39/year
        </UpsellPriceText>
      )}
      <PlusFeaturesList>
        {Object.keys(PLUS_UPSELL_FEATURES).map((featureTitle) => (
          <PlusFeatureListItem key={featureTitle}>
            <section>
              <img src={blackCheck} alt="Checkmark" />
            </section>
            <section className="ml-2">
              <b>{featureTitle}</b>
              {PLUS_UPSELL_FEATURES[featureTitle]}
            </section>
          </PlusFeatureListItem>
        ))}
      </PlusFeaturesList>
      <BtnGroup>
        <NoThanksBtn type="button" className="btn flex-centered" onClick={noThanks}>
          No Thanks
        </NoThanksBtn>
        <button
          className="btn btn-outline-primary flex-centered"
          type="button"
          onClick={toggleAlreadyPurchased}
        >
          I Already Purchased {isTV ? 'Skylight TV' : 'Plus'}
        </button>
        <LearnMoreLink
          className="btn btn-primary flex-centered"
          href={shopifyLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => Analytics.track('Clicked Buy Now from Plus Upsell')}
        >
          Buy Now
        </LearnMoreLink>
      </BtnGroup>
      {isTV ? (
        <TVUpsellCtn>
          <TVUpsellImg alt="Skylight TV" src={skyTVUpsell} />
        </TVUpsellCtn>
      ) : (
        <FreeTrialVideo />
      )}
    </UpsellPageCtn>
  )
}

PlusUpsell.propTypes = {
  toggleAlreadyPurchased: PropTypes.func.isRequired,
  noThanks: PropTypes.func.isRequired,
  frame: PropTypes.shape({
    attributes: PropTypes.shape({
      apps: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
}

const Title = styled.h2`
  grid-area: title;
  font-weight: 600;
  text-align: center;
`

const UpsellPriceText = styled.span`
  grid-area: upsellPrice;
  font-weight: 700;
  align-self: center;
  font-size: 1.3rem;
`

const PlusFeaturesList = styled.ul`
  grid-area: featuresList;
  padding-inline-start: 0;
  margin-bottom: 0;
  margin-top: 1rem;
`

const PlusFeatureListItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  img[alt='Checkmark'] {
    height: 40px;
    width: calc(40 * 1.09134615385);
  }
`

const BtnGroup = styled.section`
  height: fit-content;
  grid-area: btnGroup;
  display: flex;
  justify-content: space-between;
  max-width: 550px;
`

const NoThanksBtn = styled.button`
  background-color: #b35363;
  color: white;
  &:hover {
    background-color: #732532;
  }
`

const LearnMoreLink = styled.a`
  &:focus {
    color: #52b389 !important;
  }
`

const TVUpsellCtn = styled.div`
  grid-area: video;
  justify-self: center;
  max-width: 100%;
`

const TVUpsellImg = styled.img`
  width: 100%;
`

const UpsellPageCtn = styled.div`
  display: grid;
  grid-template-areas:
    'title         title'
    'upsellPrice   video'
    'featuresList  video'
    'btnGroup      video';
  grid-column-gap: 10%;
  grid-row-gap: 15px;
  @media only screen and (max-width: 750px) {
    grid-template-areas:
      'title'
      'upsellPrice'
      'featuresList'
      'video'
      'btnGroup';
    ${UpsellPriceText} {
      justify-self: center;
    }
    ${BtnGroup} {
      max-width: 100%;
      justify-content: space-around;
    }
  }
  @media only screen and (max-width: 420px) {
    ${BtnGroup} {
      flex-direction: column;
      align-items: center;
      *:not(:first-child) {
        margin-top: 1rem;
      }
      > * {
        width: 80%;
      }
    }
  }
`
