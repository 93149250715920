import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import Errors from './Errors'
import { FileExtensions } from '../utilities/FileExtensions'
import { HttpWrapper } from '../utilities/HttpWrapper'

import { STARTED_UPLOAD, FINISHED_UPLOAD, NEW_IN_PROGRESS_UPLOAD } from '../actions/uploadUrls'

const UPLOAD_ENDPOINT = 'upload_urls'

const allFinished = (progress) => {
  let finished = true
  Object.values(progress).forEach((file) => {
    if (file && Object.keys(file).length > 0) {
      Object.values(file).forEach((percent) => {
        if (percent < 100) finished = false
      })
    } else {
      finished = false
    }
  })
  return finished
}

export default class UploadProgress extends Component {
  constructor(props) {
    super(props)
    const progress = {}
    props.uploads.forEach((f) => (progress[f.name] = null))
    this.state = { progress }
    this.requestUploadUrls = this.requestUploadUrls.bind(this)
    this.uploadFile = this.uploadFile.bind(this)
    this.updateProgress = this.updateProgress.bind(this)
    this.successNotification = this.successNotification.bind(this)
    this.getUploadUrlAndUpload = this.getUploadUrlAndUpload.bind(this)
  }

  componentDidMount() {
    this.requestUploadUrls()
  }

  componentWillUnmount() {
    console.log('Unmounting uploader')
  }

  getUploadUrlAndUpload(data, file, assetType) {
    const self = this
    HttpWrapper.call(UPLOAD_ENDPOINT, 'POST', data, true).then((resp) => {
      self.uploadFile(file, resp.data.data, assetType)
    })
  }

  requestUploadUrls() {
    const { dispatch, uploads, frameIds, caption } = this.props
    const { progress } = this.state
    dispatch({ type: STARTED_UPLOAD })
    let finishedUploading = false
    while (!finishedUploading) {
      const nextUpload = uploads.filter((f) => progress[f.name] === null).pop()
      if (nextUpload) {
        progress[nextUpload.name] = {}
        this.setState({ progress })
        const ext = nextUpload.name.split('.').pop().toLowerCase()
        const data = { ext, frame_ids: frameIds }
        if (FileExtensions.video.indexOf(ext) !== -1 || FileExtensions.zip.indexOf(ext) !== -1) {
          this.getUploadUrlAndUpload(data, nextUpload, 'video')
        } else if (FileExtensions.image.indexOf(ext) !== -1) {
          const url = URL.createObjectURL(nextUpload)
          const img = new Image()
          img.onload = () => {
            if (caption) {
              data.caption = caption
              data.original_width = img.width
              data.original_height = img.height
            }
            this.getUploadUrlAndUpload(data, nextUpload, 'photo')
          }
          img.onerror = () => this.getUploadUrlAndUpload(data, nextUpload, 'photo')
          img.src = url
        } else if (FileExtensions.zip.indexOf(ext) !== -1) {
          this.getUploadUrlAndUpload(data, nextUpload)
        }
      } else {
        finishedUploading = true
      }
    }
  }

  updateProgress(name, url, percent) {
    const { dispatch } = this.props
    const { progress } = this.state
    progress[name][url] = percent
    this.setState({ progress })
    const finished = allFinished(progress)
    if (finished) dispatch({ type: FINISHED_UPLOAD })
  }

  uploadFile(file, uploadUrls, assetType) {
    const { dispatch } = this.props
    const self = this
    uploadUrls.forEach((d) => {
      const xhr = new XMLHttpRequest()
      xhr.open('PUT', d.url)
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            self.updateProgress(file.name, d.url, 100)
            if (assetType) dispatch({ type: NEW_IN_PROGRESS_UPLOAD, assetType, ...d })
          }
        }
      }
      xhr.upload.onprogress = function (e) {
        const percentComplete = Math.ceil((e.loaded / e.total) * 100)
        self.updateProgress(file.name, d.url, percentComplete)
      }
      xhr.overrideMimeType(file.type)
      xhr.ontimeout = (err) => {
        xhr.abort()
        self.updateProgress(file.name, d.url, -1)
      }
      xhr.send(file)
    })
  }

  successNotification() {
    const { progress } = this.state
    const totalFiles = Object.keys(progress).length
    if (totalFiles === 1) {
      return 'One file successfully sent.'
    }
    return `All ${totalFiles} files successfully sent.`
  }

  render() {
    const { progress } = this.state
    const { errors } = this.props
    const finished = allFinished(progress)
    return (
      <div className="col-lg-6 col-xl-4">
        <div className="register-box">
          <h3>{finished ? 'All Finished!' : 'Sending Love...'}</h3>
          {finished && (
            <div className="alert alert-success mt-3" role="alert">
              {this.successNotification()}
            </div>
          )}
          <Errors errors={errors} />
          <div className="row my-5">
            <div className="col-12">
              <div className="text-center">
                <Link to="/" className="btn btn-lg btn-primary">
                  Back to Dashboard!
                </Link>
              </div>
            </div>
          </div>
          {Object.keys(progress).map((filename) => {
            let percent = null
            const hasProgress = progress[filename] && Object.keys(progress[filename]).length > 0
            if (hasProgress) {
              const completed = Object.values(progress[filename]).reduce((a, b) => a + b, 0)
              percent = (completed / (Object.keys(progress[filename]).length * 100)) * 100
            }
            return (
              <div key={filename}>
                <p className="text-muted ellipsis mt-3">
                  Sen{percent === 100 ? 't' : 'ding'} {filename}
                </p>
                <div className="progress-bar-background d-flex align-items-center">
                  {hasProgress ? (
                    <div className="progress-bar" style={{ width: `${percent}%` }} />
                  ) : (
                    <p className="text-center my-0 w-100">Preparing Upload...</p>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

UploadProgress.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uploads: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
  frameIds: PropTypes.array.isRequired,
  caption: PropTypes.string,
}
