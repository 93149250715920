import {
  PLUS_PURCHASES_REQUEST,
  PLUS_PURCHASES_SUCCESS,
  PLUS_PURCHASES_FAILURE,
  SET_BOUGHT_PLUS,
  RESET_ERROR,
} from '../actions/plusReceipts'

const DEFAULT_STATE = {
  justBoughtPlus: false,
  hasPlus: true,
  plusPurchaseInstanceLoading: false,
  plusPurchaseInstance: null,
  error: null,
}

export function plus(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_BOUGHT_PLUS: {
      return {
        ...state,
        justBoughtPlus: true,
        trialDaysRemaining: null,
        plusBillingProvider: 'stripe',
      }
    }
    case PLUS_PURCHASES_REQUEST: {
      return {
        ...state,
        plusPurchaseInstance: null,
        plusPurchaseInstanceLoading: true,
        error: null,
      }
    }
    case PLUS_PURCHASES_SUCCESS: {
      const { subscription_id, client_secret, subscription_status } = action.response.data
      return {
        ...state,
        plusPurchaseInstance: {
          subscriptionId: subscription_id,
          clientSecret: client_secret,
        },
        plusPurchaseInstanceLoading: false,
        hasPlus: subscription_status === 'active',
        justBoughtPlus: subscription_status === 'active',
        error: null,
      }
    }
    case PLUS_PURCHASES_FAILURE: {
      const { errors = [] } = action
      return {
        ...state,
        plusPurchaseInstance: null,
        plusPurchaseInstanceLoading: false,
        error: errors?.length > 0 ? errors[0] : null,
      }
    }
    case RESET_ERROR: {
      return { ...state, error: null }
    }
    default: {
      return state
    }
  }
}
